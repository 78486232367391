import * as ng from 'angular';
import './panel-edit-row-radio.scss';

// tslint:disable-next-line:class-name
export interface buttonInterface {
    value: string|number|boolean;
    label: string;
}

export class MoleculePanelEditRowRadioController implements ng.IController {
    public static $inject: string[] = [];
    public value: string|number|boolean;
    public arrangement: string;
    public buttons: buttonInterface;
    public disabled: boolean;
    public onChangeCallback: (selection: unknown) => {/* */};
    public onChangeCallbackParams: unknown;

    public $onInit(): void {
        this.arrangement = this.arrangement || 'vertical';
    }
}

export class MoleculePanelEditRowRadioComponent implements ng.IComponentOptions {
    public bindings = {
        arrangement: '@',
        buttons: '<',
        disabled: '<',
        onChangeCallback: '<?',
        onChangeCallbackParams: '<?',
        value: '='
    };
    public transclude = {
        label: '?rowLabel'
    };
    public controller = MoleculePanelEditRowRadioController;
    public template = require('./panel-edit-row-radio.html');
}
