import ng from 'angular';

import {
    AlertManagerService,
    AuthContextService,
    BundleModelService,
    DomainHelper,
    DomainJobModelService,
    DomainModelService,
    VhostModelService,
    WebspaceModelService
} from '@/services';
import { DomainContactValidatorService } from '@/services/domain/domain-contact-validator';
import { DomainApi, DnsApi, BundleApi, ViewTypes } from '@/types';
import { EditPanelRight, WizardServiceHelperDomain, EditPanelStatus } from '@/atomic-components/molecules';
import { DoNotUpdateSlaveZoneConst, HandleDefaultLabels, UiRights } from '@/configuration';

interface IDomainUpdateResponse {
    response: DomainApi.Domain;
}
export class OrganismEditFormDomainProductController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'bundleModel',
        'domainContactValidator',
        'domainJobModel',
        'domainModel',
        'vhostModel',
        'webspaceModel',
        'wizardServiceHelperDomain'
    ];

    public domain: DomainApi.Domain;
    public selectedNameservers: any;
    public ownNameServers: DomainApi.Nameserver[] = [];
    public zoneData: DnsApi.Zone;
    public panelRightSettings: EditPanelRight;
    public records: DnsApi.Record[];
    public initialStatus: EditPanelStatus;
    public bundle: { status: string } = undefined;
    public bundleId: BundleApi.Bundle['id'] = '';
    public tags: { name: string; tag: string };
    public showObjectId: boolean;
    public panelRight: EditPanelRight;
    public designateAgent = false;
    public domainHasUnfinishedJob = false;
    public isValidDomainContacts = true;
    public showDeleteDomainPanel = true;
    public showRestoreDomainPanel = true;
    public handleList: ViewTypes.DomainContactObject[] = [];

    public originalDomain: DomainApi.Domain;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private bundleModel: BundleModelService,
        private domainContactValidator: DomainContactValidatorService,
        private domainJobModel: DomainJobModelService,
        private domainModel: DomainModelService,
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService,
        private wizardServiceHelperDomain: WizardServiceHelperDomain
    ) {}

    public $onInit = async (): Promise<void> => {
        this.showDeleteDomainPanel = AuthContextService.isGranted(UiRights.DOM_DOMAINS_DELETE);
        this.showRestoreDomainPanel = AuthContextService.isGranted(UiRights.DOM_DOMAINS_RESTORE);
        if (AuthContextService.isGranted(UiRights.DOM_JOBS_LIST)) {
            void this.domainJobModel.findRunningJobsForDomain(this.domain).then((result) => {
                this.domainHasUnfinishedJob = result.status === 'success' && result.response.data.length > 0;
            });
        }

        this.originalDomain = {...this.domain};
        this.panelRight = {
            editPanelButton: AuthContextService.isGranted(UiRights.DOM_DOMAINS_EDIT)
        };

        if (this.originalDomain.deletionScheduledFor !== null) {
            this.initialStatus = EditPanelStatus.DELETIONSCHEDULED;
        }
        this.panelRightSettings = {
            editPanelButton: this.isEditable
        };

        if (
            AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST)
            && [undefined, null, ''].indexOf(this.domain.bundleId) < 0
        ) {
            void this.bundleModel.findOneById(this.domain.bundleId).then((bundles) => this.bundle = bundles[0]);
        }

        this._setBundleId();
        this._setContacts();

        // Check if the contacts are valid.
        if (['restricted', 'active', 'restorable'].includes(this.domain.status)) {
            await this.domainContactValidator.contactSetUsableFor(
                this.domain.contacts,
                [ DomainHelper.getTld(this.domain.nameUnicode) ],
                false,
                this.domain.accountId
            ).then(
                (reply: DomainApi.ContactSetUsableProblem[]) => {
                    if (reply && reply.length > 0) {
                        this.isValidDomainContacts = false;
                    } else {
                        this.isValidDomainContacts = true;
                    }
            });
        }
    };

    public get bundleIsDeleted(): boolean {
        return [undefined, null].indexOf(this.bundle) < 0 && this.bundle.status === 'restorable';
    }

    public get isEditable(): boolean {
        return this.domain.status !== 'ordered';
    }

    public cancelDeletion = (): Promise<boolean> => {
        return this.domainModel.cancelDelete([this.domain]).then((res) => {
            if (res[0].status !== 'error') {
                this.alertManager.success(this.$translate.instant('TR_100119-f8274d_TR'));
            }

            return Promise.reject(false);
        });
    };

    public save = (): Promise<IDomainUpdateResponse> => {
        const origDomain = ng.copy(this.domain);
        this.domain.nameservers = this.selectedNameservers.nameservers;
        (this.domain as any).nameserverSetId = this.selectedNameservers.id;

        this.domain.contacts = this.handleList.map(
            (handle) => ({
                contact: handle.contactId,
                type: (handle.contactType)
            })
        );

        if (this.domain.nameservers === null || this.ownNameServers.length > 0) {
            this.domain.nameservers = this.ownNameServers;
        }

        if (this.hasSameNameservers(origDomain.nameservers, this.domain.nameservers) || this.zoneData === undefined) {
            return this._updateDomain(this.domain);
        } else {
            return this.wizardServiceHelperDomain.updateZone(this.domain, DoNotUpdateSlaveZoneConst)
                .then((res): Promise<any> => {
                    if (res.status === undefined) {
                        return Promise.reject(res.errors);
                    }

                    if (res.status !== 'deferred') {
                        this.alertManager.success(this.$translate.instant('TR_100119-3da002_TR'));
                    }

                    return this._updateDomain(this.domain);
                }
            ) as Promise<IDomainUpdateResponse>;
        }
    };

    public cancelContacts = (): void => {
        this._setContacts();
        this.designateAgent = false;
    };

    public get isUkDomain(): boolean {
        return this.domain.name.length === this.domain.name.indexOf('.uk') + 3;
    }

    private _setContacts = (): void => {
        this.handleList = this.domain.contacts.map(
            (contact) => ({
                contactId: contact.contact,
                contactType: contact.type,
                label: this._getHandleLabel(contact.type)
            })
        );
    };

    private _setBundleId = (): void => {
        const vHostFilter = { field: 'vhostDomainNameUnicode', value: this.domain.nameUnicode };
        if ([undefined, null].indexOf(this.domain.bundleId) < 0 && this.domain.bundleId !== '') {
            this.bundleId = this.domain.bundleId;
            return;
        }
        if (!AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST)) {
            return;
        }
        void this.vhostModel.listWithoutPagination(1, 1, vHostFilter).then((vhostList) => {
            if (
                vhostList.data !== undefined
                && vhostList.data.length === 1
                && vhostList.data[0].webspaceId !== undefined
                && vhostList.data[0].webspaceId !== ''
            ) {
                const webspaceFilter = { field: 'webspaceId', value: vhostList.data[0].webspaceId };
                void this.webspaceModel.listWithoutPagination(1, 1, webspaceFilter).then((webspaceList) => {
                    if (
                        webspaceList.data !== undefined
                        && webspaceList.data.length === 1
                        && [undefined, null].indexOf(webspaceList.data[0].bundleId) < 0
                        && webspaceList.data[0].bundleId !== ''
                    ) {
                        this.bundleId = webspaceList.data[0].bundleId;
                    }
                });
            }
        });
    };

    private _updateDomain = (domain: DomainApi.Domain): Promise<IDomainUpdateResponse> => {
        return this.domainModel.update(domain, this.designateAgent).then((res) => {
            this.alertManager.success(this.$translate.instant('TR_100119-e1c214_TR'));
            return { response: res.response };
        });
    };

    private hasSameNameservers = (array1: DomainApi.Nameserver[], array2: DomainApi.Nameserver[]): boolean => {
        return (
            array1.every((firstEntry) =>
                array2.some(
                    (secondEntry) =>
                        firstEntry.name === secondEntry.name
                        && JSON.stringify(firstEntry.ips.sort()) === JSON.stringify(secondEntry.ips.sort())
                )
            )
            && array2.every((secondEntry) =>
                array1.some(
                    (firstEntry) =>
                        firstEntry.name === secondEntry.name
                        && JSON.stringify(firstEntry.ips.sort()) === JSON.stringify(secondEntry.ips.sort())
                )
            )
        );
    };

    private _getHandleLabel = (contactType: DomainApi.DomainContactType): string => {
        let label = '';
        HandleDefaultLabels.some(
            (labelObject) => {
                if (labelObject.contactType === contactType) {
                    label = this.$translate.instant(labelObject.label);
                    return true;
                }

                return false;
            }
        );

        return label;
    };
}

export class OrganismEditFormDomainProductComponent implements ng.IComponentOptions {
    public bindings = {
        domain: '<',
        records: '<',
        showObjectId: '<',
        tags: '<',
        zoneData: '<zone'
    };
    public controller = OrganismEditFormDomainProductController;
    public template = require('./domain-product-edit.html');
}
