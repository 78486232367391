import * as ng from 'angular';

export interface FormTextareaModelOptions {
    debounce?: number;
}

export class AtomFormInputTextareaController implements ng.IController {
    public static $inject: string[] = [];

    public value: string;
    public rows: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public callbackOnPaste: (paste: any) => any;
    public readonly: boolean;
    public disabled: boolean;
    public disableRandomisedId: boolean;
    public inputId: string;
    public modelOptions: FormTextareaModelOptions;
    public placeholder: string;
    public catchFocus: boolean;
    public submit: () => void;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;

    private _issetInputId = false;

    $onInit(): void {
        this.readonly = this.readonly || false;
        this.disabled = this.disabled || false;
        this.rows = this.rows || '5';
        this.modelOptions = this.modelOptions || {};
        this.clearable = this.clearable || false;
    }

    onChange(): void {
        if (this.callbackOnChange !== undefined) {
            this.callbackOnChange({value: this.value});
        }
    }

    onBlur(): void {
        if (this.callbackOnBlur !== undefined) {
            this.callbackOnBlur(this.callbackOnBlurParams);
        }
    }

    public clearSearchstring = () => {
        if (this.clearable && !this.readonly) {
            this.value = '';

            if (this.callbackOnClear !== undefined) {
                this.callbackOnClear(this.callbackOnClearParams);
            }
        }
    };

    public showAutofocus = () => {
        if (this.catchFocus === true) {
            return true;
        } else {
            return undefined;
        }
    };

    public keyDownEvent = (event) => {
        if (this.submit && !this.disabled && event.ctrlKey && event.keyCode === 13) { // CTRL + ENTER
            this.submit();
        }
    };

    public onPaste = (paste) => {
        if (this.callbackOnPaste !== undefined) { // If paste
            this.callbackOnPaste(paste);
        }
    };

    public get callInputId(): string {
        if (!this.disableRandomisedId && !this._issetInputId) {
            const uniqueId = (Date.now().toString(36) + Math.random().toString(36).substr(2, 5)).toUpperCase();

            if ([undefined, null, ''].indexOf(this.inputId) >= 0) {
                this.inputId = uniqueId;
            } else {
                this.inputId += ':' + uniqueId;
            }

            this._issetInputId = true;
        }

        return this.inputId;
    }
}

export class AtomFormInputTextareaComponent implements ng.IComponentOptions {
    public bindings = {
        value: '=',
        rows: '<',
        callbackOnChange: '<',
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnPaste: '<?',
        readonly: '<',
        disabled: '<',
        disableRandomisedId: '<?',
        inputId: '@?',
        modelOptions: '<',
        placeholder: '@',
        catchFocus: '<',
        submit: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<'
    };
    public controller =  AtomFormInputTextareaController;
    public template = require('./textarea.html');
}
