import * as ng from 'angular';

import { MoleculeFormEditController } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import {
    MoleculePanelEditTableController
} from '@/atomic-components/molecules/panels/panel-edit/table/panel-edit-table';
import {
    OrganismEditFormNextcloudController
} from '@/atomic-components/organisms/forms/edit-forms/nextcloud-edit/nextcloud-edit';
import { NextcloudUnlimitedStorageValue, NextcloudUserPasswordCharacterPoolsConst } from '@/configuration';
import {
    FilterUtils,
    ManagedApplicationRobotService,
    ProductHelperService,
    ProductsRobotService,
    ValidateMaxLength,
    ValidateNotEmpty
} from '@/services';
import { Finding, ManagedApplicationApi, ProductApi, UI } from '@/types';

export interface PaginatedUsers {
    data: unknown[];
    pagination: {
        currentPage: number;
        entries: number;
        limit: number;
    };
}

export class MoleculePanelEditRowTableNextcloudUsersController implements ng.IController {
    public static $inject: string[] = ['$state', '$timeout', '$translate', 'managedApplicationRobot', 'productsRobot'];

    public $editForm: MoleculeFormEditController;
    public $organismEditForm: OrganismEditFormNextcloudController;
    public $panelEditTable: MoleculePanelEditTableController;
    public NextcloudUnlimitedStorageValue = NextcloudUnlimitedStorageValue;
    public apiUsers: PaginatedUsers = {
        data: [],
        pagination: {
            currentPage: 1,
            entries: 0,
            limit: 5
        }
    };
    public availableGroupNames: string[];
    public groupListInitialized = false;
    public isUnfinishedStatus = false;
    public maxStorage: number;
    public nextcloudProduct: ProductApi.TemplateProduct;
    public nextcloudUsers: ManagedApplicationApi.NextcloudUser[] = [];
    public nextcloudUsersExtensible = false;
    public nextcloudUsersExtensibleMaxValue = 0;
    public noUsersFound = false;
    public productUpgradeLink = '';
    public userUpgradeLink = '';
    public showUserList = false;
    public userPasswordCharacterPools = NextcloudUserPasswordCharacterPoolsConst;
    public deleteUserActions = [
        {value: true, label: this.$translate.instant('56d53dd2-b42b-4014-a4f9-2f4621204f69')},
        {value: false, label: this.$translate.instant('TR_110621-01401c_TR')}
    ];
    public nextcloudUserPasswordValidationOptions = {
        allowEmpty: false,
        minLength: 10,
        maxLength: 255,
        characterPools: this.userPasswordCharacterPools,
        minCharacterPoolsUsed: 3,
        illegalValues: ([] as string[])
    };
    public userNameValidationInstructions: {}[];

    private _searchString = '';
    private _userStorageQuotaBackup: number[] = [];

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private productsRobot: ProductsRobotService
    ) {}

    public async $onInit(): Promise<void> {
        this.userNameValidationInstructions = [
            {
                settings: null,
                validator: new ValidateMaxLength(this.$translate, 64)
            },
            {
                settings: null,
                validator: new ValidateNotEmpty(this.$translate)
            },
        ];

        this.maxStorage = this.$organismEditForm.nextcloud.storageQuota / 1024;
        this.productUpgradeLink = this.$state.current.name.indexOf('storage-products') >= 0
            ? 'storage.storage-products.id.upgrade'
            : 'nextcloud.id.upgrade';
        this.userUpgradeLink = this.$state.current.name.indexOf('storage-products') >= 0
            ? 'storage.storage-products.id.addons'
            : 'nextcloud.id.addons';
        this.updateGroupNameList();
        this.updateUserList(true);

        if (this.$organismEditForm.nextcloudHasUnfinishedJob) {
            this.isUnfinishedStatus = true;
        }

        this.nextcloudProduct = (await this.productsRobot.productFind(this.$organismEditForm.nextcloud.productCode))
            .response as ProductApi.TemplateProduct;

        const nextcloudUsersMaximum = ProductHelperService.wrapSpecificationItems(
            this.nextcloudProduct.specificationItems
        ).nextcloudUsersMaximum?.intValue;

        if (Number.isInteger(nextcloudUsersMaximum)) {
            this.nextcloudUsersExtensible = true;
            this.nextcloudUsersExtensibleMaxValue = nextcloudUsersMaximum;
        } else {
            this.nextcloudUsersExtensible = false;
            this.nextcloudUsersExtensibleMaxValue = 0;
        }
    }

    get searchString(): string {
        return this._searchString;
    }

    set searchString(value: string) {
        if (this._searchString !== value && typeof value === 'string') {
            this.apiUsers.pagination.currentPage = 1;
            this._searchString = value;
            this.updateUserList(true);
        }
        this._searchString = value;
    }

    public usernameOrPasswordChangedCallback = (user: ManagedApplicationApi.NextcloudUser): void => {
        if (user.props?.passwordValidationOptions === undefined) {
            return;
        }

        user.props.passwordValidationOptions = undefined;

        void this.$timeout(() => {
            // check for duplicate Username
            if (user.props.isNewUser) {
                const filter = {
                    subFilter: [
                        {
                            field: 'NextcloudUserUsername',
                            value: user.username
                        },
                        {
                            field: 'NextcloudId',
                            value: this.$organismEditForm.nextcloud.id
                        }
                    ],
                    subFilterConnective: 'AND'
                };
                void this._findUserByFilter(filter, 1, 1, true).then(
                    (foundUsers: ManagedApplicationApi.NextcloudUser[]) => {
                        user.props.showUsernameAlreadyTakenError = foundUsers.length > 0;
                    }
                );
            } else {
                user.props.showUsernameAlreadyTakenError = false;
            }

            // make sure the username can't be used as a password
            user.props.passwordValidationOptions = ng.copy(this.nextcloudUserPasswordValidationOptions);
            user.props.passwordValidationOptions.illegalValues = [user.username];
        });
    };

    public toggleUserEdit = (userData: { index: number; user: ManagedApplicationApi.NextcloudUser }): void => {
        void this.$timeout(() => {
            const user = userData.user;
            if (user.props.isBeingEdited) {
                if (this.$editForm.validateAll()) {
                    if (!user.props.emailDuplicationCheck && !user.props.showUsernameAlreadyTakenError) {
                        // user choose a too high value ... use the max value
                        if (user.props.storageInGb === undefined) {
                            user.props.storageInGb = this.maxStorage;
                            user.storageQuota = this.maxStorage * 1024;
                        }
                        user.props.changePassword = false;
                        user.props.type = 'edit';
                        user.props.isBeingEdited = !user.props.isBeingEdited;
                        this._updateCurrentUser(userData.index, user);
                    }
                }
            } else {
                user.props.initialState = undefined;
                user.props.initialState = ng.copy(user);
                user.props.isBeingEdited = !user.props.isBeingEdited;
            }
        });
    };

    public get unsavedChanges(): boolean {
        return this.$organismEditForm.nextcloudUsersNew.some((user) => user.props.isBeingEdited === true);
    }

    public groupOptionsChanged = (availableGroups: string[]): void => {
        if (
            availableGroups?.length > 0
            && !this.availableGroupNames.includes(availableGroups[availableGroups.length - 1])
        ) {
            this.groupListInitialized = false;
            this.availableGroupNames.push(availableGroups[availableGroups.length - 1]);
            void this.$timeout(() => { this.groupListInitialized = true; }, 100);
        }
    };

    public cancelUserEdit = (userData: { index: number; user: ManagedApplicationApi.NextcloudUser }): void => {
        let user = userData.user;

        if (user.props.initialState) {
            user = ng.copy(user.props.initialState);
            user.props.initialState = undefined;
            this._updateCurrentUser(userData.index, user);
        } else {
            this._removeCurrentUser(userData.index);
        }
    };

    public get showProductUpgradeNeededNotice(): boolean {
        return (
            this.nextcloudUsersExtensible
            && this.nextcloudUsersExtensibleMaxValue <= this.apiUsers.pagination.entries
        ) || (
            !this.nextcloudUsersExtensible
            && this.$organismEditForm.nextcloud.maxNumberOfUsers <= this.apiUsers.pagination.entries
        );
    }

    public get showUserUpgradeNeededNotice(): boolean {
        return (
            this.nextcloudUsersExtensible
            && this.nextcloudUsersExtensibleMaxValue > this.apiUsers.pagination.entries
            && this.$organismEditForm.nextcloud.maxNumberOfUsers <= this.apiUsers.pagination.entries
        );
    }

    public get dropdownPlaceholderText(): string {
        if (this.$organismEditForm.availableGroups.length > 0) {
            return this.$translate.instant('TR_050819-abaaa9_TR');
        } else {
            return this.$translate.instant('TR_270819-68b2b5_TR');
        }
    }

    public updateGroupNameList = (): void => {
        this.groupListInitialized = false;
        this.availableGroupNames = this.$organismEditForm.availableGroups;
        void this.$timeout(() => { this.groupListInitialized = true; }, 100);
    };

    public limitUserStorageTo = (newLimit: { value: string; params: { index: number } }): void => {
        this.$organismEditForm.nextcloudUsersNew[newLimit.params.index].storageQuota =
            parseInt('' + newLimit.value, 10) * 1024;
    };

    public toggleUnlimitedStorage = (value: {status: boolean; params: { index: number } }): void => {
        void this.$timeout(() => {
            const index = value.params.index;
            if (!this._userStorageQuotaBackup[index]) {
                this._userStorageQuotaBackup[index] = this.$organismEditForm.nextcloudUsersNew[index].storageQuota;
            }

            if (this.$organismEditForm.nextcloudUsersNew[index].unlimitedStorage) {
                this.$organismEditForm.nextcloudUsersNew[index].props.storageInGb = 1;
                this.$organismEditForm.nextcloudUsersNew[index].storageQuota = NextcloudUnlimitedStorageValue;
            } else {
                this.$organismEditForm.nextcloudUsersNew[index].storageQuota = this._userStorageQuotaBackup[index];
                this.$organismEditForm.nextcloudUsersNew[index].props.storageInGb =
                    this._userStorageQuotaBackup[index] / 1024;
            }
        });
    };

    // mark user for deletion
    public toggleUserDelete = (user: ManagedApplicationApi.NextcloudUser): void => {
        user.props.isMarkedForRemoval = !user.props.isMarkedForRemoval;

        if (user.props.isMarkedForRemoval) {
            for (const existingUser of this.$organismEditForm.nextcloudUsersNew) {
                if (existingUser.props.usernameForFileTransfer === user.username) {
                    existingUser.props.usernameForFileTransfer = '';
                    existingUser.props.deleteUserFiles = true;
                }
            }
        } else {
            user.props.deleteUserFiles = true;
            user.props.usernameForFileTransfer = '';
        }

        this.$editForm.validateAll();
    };

    public getGroupListText = (groupList: string[] | null | undefined): string => {
        if ([null, undefined].indexOf(groupList) >= 0 || (Array.isArray(groupList) && groupList.length === 0)) {
            return '';
        } else {
            return groupList.join(', ');
        }
    };

    public checkForDuplicateEmail = (userData: { index: number; user: ManagedApplicationApi.NextcloudUser }): void => {
        if (!userData.user) {
            return;
        }

        // check for duplicate Username
        const filter = {
            subFilter: [
                {
                    field: 'NextcloudUserEmailAddress',
                    value: userData.user.emailAddress
                },
                {
                    field: 'NextcloudId',
                    value: this.$organismEditForm.nextcloud.id
                }
            ],
            subFilterConnective: 'AND'
        };

        void this._findUserByFilter(filter, 2, 1, true).then(
            (foundUsers: ManagedApplicationApi.NextcloudUser[]) => {
                void this.$timeout(() => {
                    this.$organismEditForm.nextcloudUsersNew[userData.index].props.emailDuplicationCheck = foundUsers
                        .some(
                            (existingUser) => existingUser.username !== userData.user.username
                        );
                });
            }
        );
    };

    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type,@typescript-eslint/no-unused-vars
    public updateUserList = (limit: number|boolean = 5, page = 1) => {
        const filterFields = [
            'NextcloudUserName',
            'NextcloudUserUsername',
            'NextcloudUserEmailAddress',
            'NextcloudGroupName'
        ];

        const simpleFilterSpecialCases = [
            FilterUtils.buildSpecialCases.id('NextcloudUserId'),
            FilterUtils.buildSpecialCases.customField()
        ];

        const filters = FilterUtils.buildFullFilter(
            [],
            this.searchString,
            filterFields,
            simpleFilterSpecialCases,
            [{ field: 'NextcloudId', value: this.$organismEditForm.nextcloud.id }]
        );

        void this._findUserByFilter(
            filters,
            this.apiUsers.pagination.limit,
            this.apiUsers.pagination.currentPage
        ).then((response: UI.SynchronousAPIResponse<ManagedApplicationApi.FindNextcloudUsersResult>) => {
            const res = response.response;
            this.noUsersFound = res.data.length === 0;

            // clean existing user Data only keep exited, added and deleted users
            this.$organismEditForm.nextcloudUsersNew = this._filterAllButEditedUsers(
                this.$organismEditForm.nextcloudUsersNew
            );

            // remove all duplicates
            res.data = this._removeDuplicateUsers(res.data);

            // initialize user flags
            res.data = this._populateUserFlags(res.data);

            // extend users (add user flags) and add them
            for (const user of res.data) {
                this.$organismEditForm.nextcloudUsersNew.push(user);
            }

            this.apiUsers.data = res.data;
            this.apiUsers.pagination.currentPage = res.page;
            this.apiUsers.pagination.entries = res.totalEntries;
            this.apiUsers.pagination.limit = res.limit;

            this._applySearchFilter();
            void this.$timeout(() => { this.showUserList = true; });
        });
    };

    private _findUserByFilter = (
        filters: Finding.Filter,
        limit: number,
        currentPage: number,
        returnUserObjects = false
    ): Promise<
        ManagedApplicationApi.NextcloudUser[]
        | UI.SynchronousAPIResponse<ManagedApplicationApi.FindNextcloudUsersResult>
        > => {
        const userPromise = this.managedApplicationRobot.nextcloudUsersFind(
            filters,
            limit,
            currentPage,
            {
                field: 'NextcloudUserName',
                order: 'ASC'
            }
        );

        if (returnUserObjects) {
            return userPromise.then(
                (apiResponse: UI.SynchronousAPIResponse<ManagedApplicationApi.FindNextcloudUsersResult>) => {
                    return apiResponse.response.data;
                }
            );
        } else {
            return userPromise as Promise<UI.SynchronousAPIResponse<ManagedApplicationApi.FindNextcloudUsersResult>>;
        }
    };

    private _applySearchFilter = (): void => {
        void this.$timeout(() => {
            this.noUsersFound = true;

            for (const user of this.$organismEditForm.nextcloudUsersNew) {
                user.props.isHidden = user.name.indexOf(this.searchString) === -1
                    && user.username.indexOf(this.searchString) === -1
                    && user.emailAddress.indexOf(this.searchString) === -1
                    && !user.groups.some((group) => group.indexOf(this.searchString) >= 0);

                if (!user.props.isHidden) {
                    this.noUsersFound = false;
                }
            }
        });
    };

    private _updateCurrentUser = (index: number, user: ManagedApplicationApi.NextcloudUser): void => {
        this.$organismEditForm.nextcloudUsersNew[index] = user;
    };

    private _removeCurrentUser = (index: number): void => {
        this.$organismEditForm.nextcloudUsersNew = this.$organismEditForm.nextcloudUsersNew.filter(
            (existingUser, currentIndex) => currentIndex !== index
        );
    };

    private _removeDuplicateUsers = (
        nextcloudUsers: ManagedApplicationApi.NextcloudUser[]
    ): ManagedApplicationApi.NextcloudUser[] => {
        return nextcloudUsers.filter(
            (user) => !this.$organismEditForm.nextcloudUsersNew
                .some(
                    (existingUser) => existingUser.username === user.username
                )
        );
    };

    private _filterAllButEditedUsers = (
        nextcloudUsers: ManagedApplicationApi.NextcloudUser[]
    ): ManagedApplicationApi.NextcloudUser[] => {
        return nextcloudUsers.filter(
            (user) => user.props.type === 'edit'
                || (user.props.isNewUser && !user.props.isMarkedForRemoval)
                || (user.props.isMarkedForRemoval && !user.props.isNewUser)
        );
    };

    private _populateUserFlags = (
        existingNextcloudUsers: ManagedApplicationApi.NextcloudUser[]
    ): ManagedApplicationApi.NextcloudUser[] => {
        for (const user of existingNextcloudUsers) {
            user.props = {
                isMarkedForRemoval: false,
                isNewUser: false,
                isBeingEdited: false,
                isHidden: false,
                changePassword: false,
                showUsernameAlreadyTakenError: false,
                usernameForFileTransfer: '',
                deleteUserFiles: true,
                storageInGb: user.storageQuota === NextcloudUnlimitedStorageValue
                    ? 1
                    : (parseInt(`${user.storageQuota}`, 10) / 1024),
                type: 'existing',
                validationErrorList: {
                    name: [],
                    username: [],
                    emailAddress: []
                },
                passwordValidationOptions: { minLength: 10 }
            };
            user.unlimitedStorage = user.storageQuota === NextcloudUnlimitedStorageValue;
        }

        return existingNextcloudUsers;
    };

    public get usersMarkedForDeletion(): string[] {
        return this.$organismEditForm.nextcloudUsersNew
            .filter((user: ManagedApplicationApi.NextcloudUser) => user.props.isMarkedForRemoval)
            .map((user: ManagedApplicationApi.NextcloudUser) => user.username);
    }

    public userListCallback = (
        limit?: number,
        page?: number,
        filters?: Finding.Filter,
        sort?: Finding.SortOptions
    ): PromiseLike<ManagedApplicationApi.FindNextcloudUsersResult> => {
        const filter = {
            subFilter: [
                {
                    field: 'NextcloudId',
                    value: this.$organismEditForm.nextcloud.id,
                    relation: 'equal'
                }
            ],
            subFilterConnective: 'AND'
        };

        // filter out users that have been marked for removal
        for (const userMarkedForDeletion of this.usersMarkedForDeletion) {
            filter.subFilter.push(
                {
                    field: 'NextcloudUserUsername',
                    value: userMarkedForDeletion,
                    relation: 'unequal'
                }
            );
        }

        return this.managedApplicationRobot.nextcloudUsersFind(
            filter,
            limit,
            page,
            sort ?? {
                field: 'NextcloudUserName',
                order: 'ASC'
            }
        ).then(
            (apiResponse: UI.SynchronousAPIResponse<ManagedApplicationApi.FindNextcloudUsersResult>) => {
                return apiResponse.response;
            }
        );
    };

    public deleteUserFilesWasToggled = (
        data: { value: boolean; params: { user: ManagedApplicationApi.NextcloudUser } }
    ): void => {
        if (data.value) {
            data.params.user.props.usernameForFileTransfer = null;
        }
    };

    public userDisplayFilter = (ncUser: ManagedApplicationApi.NextcloudUser): string => {
        return ncUser.name + ' (' + ncUser.username + ')';
    };
}

export class MoleculePanelEditRowTableNextcloudUsersComponent implements ng.IComponentOptions {
    public require = {
        $editForm: '^moleculeFormEdit',
        $organismEditForm: '^organismEditFormNextcloud',
        $panelEditTable: '^moleculePanelEditTable'
    };

    public bindings = {
        searchString: '='
    };

    public controller = MoleculePanelEditRowTableNextcloudUsersController;
    public controllerAs = '$panelEditRowTable';
    public template = require('./nextcloud-users.html');
}
