import { AuthContextService } from './auth-context';
import { CacheService } from './cache';
import { Request } from './rpc-client';

import * as Sentry from '@sentry/browser';
import * as ng from 'angular';
import { IRoutesArray, routesArray } from '@/components/all-routes';

export class NavigationService {
    public static $inject: string[] = [
        '$rootScope',
        '$state',
        'cache'
    ];

    constructor(
        private $rootScope: ng.IRootScopeService,
        private $state: ng.ui.IStateService,
        private cache: CacheService,
    ) {
        $rootScope.$on(
            '$stateChangeStart',
            () => NavigationService.changingState = true
        );

        ['$stateChangeCancel', '$stateChangeError', '$stateChangeSuccess', '$stateNotFound']
        .forEach(
            (event) => $rootScope.$on(
                event,
                (e) => {
                    NavigationService.changingState = false;
                }
            )
        );
    }

    public static lastTarget: any = {};

    private static changingState = false;

    public static handleTransitionErrors = (error: any) => {
        if (typeof error?.message !== 'string' || !error.message.startsWith('transition ')) {
            return Promise.reject(error);
        }

        switch (error.message) {
            // A $state.go was called while another was already in progress.
            case 'transition superseded': return;

            // Happens when you use event.preventDefault() in an event handler for '$stateChangeStart'.
            // We do this in special cases - configured in app.ts
            case 'transition prevented':
                Sentry.captureException(error);
                return;

            // Happens when the state is not found and there is no redirect to another state.
            case 'transition aborted':
                Sentry.captureException(error);
                return;

            // Switching to the new state was not possible for some reason.
            case 'transition failed':
                Sentry.captureException(error);
                return;

            // Unknown other cause that starts with "transition ", but isn't caused by UI-Router.
            default:
                return Promise.reject(error);

        }
    };

    private counter = 0;


    public toLogin = () => {
        this.$state.go('login').then(
            undefined,
            NavigationService.handleTransitionErrors
        );
    };

    public go = async (stateName: string, paramChanges?: any, options?: ng.ui.IStateOptions): Promise<any> => {
        if (NavigationService.changingState) {
            return await this.$state.go(stateName, paramChanges, options);
        } else {
            const count = ++this.counter;

            return await Request.cancelAllRequests('state change using navigation service')
            .then(
                () => {
                    if (this.counter === count) {
                        this.$state.go(stateName, paramChanges, options).then(
                            undefined,
                            NavigationService.handleTransitionErrors
                        );
                    }
                }
            );
        }
    };

    public reloadCurrentState = (clearAllCaches = false, hardReload = false) => {
        if (clearAllCaches) {
            this.cache.clearAll();
        }

        if (hardReload) { // this is a last resort kind of solution, only use when all else failed
            // https://developer.mozilla.org/en-US/docs/Web/API/Location/reload#location.reload_has_no_parameter
            location.reload();
        } else {
            this.$state.go(this.$state.current.name, undefined, { reload: true }).then(
                undefined,
                NavigationService.handleTransitionErrors
            );
        }
    };

    public goToLastTarget = () => {
        const lastTarget = NavigationService.lastTarget;

        if (
            NavigationService.lastTarget.state
            && [
                'login',
                'password-forgotten',
                'password-reset',
                'logout',
                'enableTwoFaOnLogin'
            ]
            .indexOf(lastTarget.state.name) < 0
        ) {
            this.$state.go(NavigationService.lastTarget.state.name, NavigationService.lastTarget.params).then(
                null,
                NavigationService.handleTransitionErrors
            );
        } else {
            this.$state.go('dashboard').then(
                null,
                NavigationService.handleTransitionErrors
            );
        }
    };

    public onSessionExpired = () => {
        this.setUnauthenticated();
        return this.$state.go('login', {expired: true}).then(
            undefined,
            NavigationService.handleTransitionErrors
        );
    };

    private setUnauthenticated = () => {
        const oldUser = AuthContextService.user;
        const oldAccount = AuthContextService.account;
        AuthContextService.clear();

        Sentry.setUser({});

        this.$rootScope.$emit('logout', oldUser, oldAccount);
    };
}
