import { DomainInfoHelperService } from '@/services';
import { TldListService } from './../domain/tld-list';
import { Validator } from './abstract-validator';

import * as ng from 'angular';

export class ValidateSubDomain extends Validator {
    private _allowEmpty: boolean;
    private _domainInfoHelper: DomainInfoHelperService;
    private _tldList: {unicode: string; ace: string}[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        private tldList: TldListService,
        allowEmpty?
    ) {
        super();

        this._domainInfoHelper = domainInfoHelper;
        this._allowEmpty = allowEmpty || false;
        this._tldList = this.tldList.getList();
    }

    public validate = (value: string) => {
        if (this._allowEmpty && (value === undefined || value.length === 0)) {
            return [{ text: this.$translate.instant('TR_090119-2705b2_TR') }];
        }

        const parts = value.split('.');

        if (parts.length <= 2) {
            return [{ text: this.$translate.instant('TR_170119-27e329_TR') }];
        }

        if (parts[0] === '*') {
            return [{ text: this.$translate.instant('TR_170119-7cecca_TR') }];
        }

        const lookupTld = this._domainInfoHelper.getDomainTld(parts);

        if (lookupTld.unicode === 'invalid') {
            return [{ text: this.$translate.instant('TR_170119-55c20b_TR') }];
        }

        return [];
    };
}
