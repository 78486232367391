import ng from 'angular';

import { OrganismEditFormNextcloudController } from '@/atomic-components/organisms';
import { BillingCycleToContractPeriodeConst, UiRights } from '@/configuration';
import { CalculatePriceFilter } from '@/filters';
import {
    AlertManagerService, AuthContextService, ManagedApplicationRobotService, PriceHelperService,
    PriceModelService, ProductHelperService, ProductsModelService
} from '@/services';
import * as Types from '@/types';

export class OrganEditPanelNextcloudBackupsController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'alertManager',
        'calculatePriceFilter',
        'managedApplicationRobot',
        'priceHelper',
        'priceModel',
        'productsModel'
    ];
    public $editFormOrganism: OrganismEditFormNextcloudController;

    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public nextcloudPrice: Types.BillingApi.ArticlePurchasePrice;

    public modelConfirmation: boolean;
    public isNextcloudRoute: boolean;
    public isAdmin = false;

    public isWaitingForFreemiumCheck = true;
    public isFreeProduct: boolean;
    public isBusinessProduct: boolean;

    public panelRight = {
        editPanelButton: AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT)
    };

    private _modelActivation: boolean;
    public get modelActivation() {
        return this._modelActivation;
    }
    public set modelActivation(newValue) {
        this._modelActivation = newValue;
        this.modelConfirmation = false;
    }

    public get showConfirmationDialog() {
        return this.nextcloud.backupEnabled !== this.modelActivation;
    }

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private calculatePriceFilter: CalculatePriceFilter,
        public managedApplicationRobot: ManagedApplicationRobotService,
        public priceHelper: PriceHelperService,
        private priceModel: PriceModelService,
        private productModel: ProductsModelService
    ) {}

    public $onInit(): PromiseLike<void> {
        this.isNextcloudRoute = this.$state.current.name.split('.')[0] === 'nextcloud';
        this.modelActivation = this.nextcloud.backupEnabled;
        this.isAdmin = AuthContextService.isRootOrCompanyAccount;

        this.isFreeProduct = this.nextcloud.productCode.includes('free');

        this.isBusinessProduct = this.nextcloud.productCode.includes('business');

        return this.productModel.findProduct(this.nextcloud.productCode, this.$translate.use()).then(
            (storageProduct) => {
                if (!storageProduct?.response?.specificationItems) {
                    return;
                }

                this.isWaitingForFreemiumCheck = false;

                const specification = ProductHelperService.wrapSpecificationItems(
                    storageProduct.response.specificationItems
                );

                const backupProductCodeTemplate = specification.backupProductCode?.value as string;

                void this.priceModel.getPriceByProductCode(this.nextcloud.productCode).then(
                    (productPrice: Types.BillingApi.ArticlePurchasePrice) => {
                        const contractPeriode: number = BillingCycleToContractPeriodeConst[
                            productPrice.contractPeriod as keyof typeof BillingCycleToContractPeriodeConst
                        ];
                        const backupProductCode = this.priceHelper.replaceBillingCycleInProductCodeTemplate(
                            backupProductCodeTemplate,
                            `${contractPeriode}`
                        );

                        void this.priceModel.getPriceByProductCode(backupProductCode).then(
                            (response: Types.BillingApi.ArticlePurchasePrice) => {
                                if (response) {
                                    // Timeout is needed to refresh the view as soon as the price is fetched.
                                    void this.$timeout(
                                        () => {
                                            this.nextcloudPrice = response;
                                        }
                                    );
                                }
                            }
                        );
                    }
                );
            }
        );
    }

    public get calculatePrice() {
        if ([undefined].indexOf(this.nextcloudPrice) >= 0) {
            return 0;
        }
        const type = AuthContextService.account.isCommercialCustomer ? 'net' : 'gross';

        return this.calculatePriceFilter(
            this.nextcloudPrice.netAmount,
            this.nextcloudPrice.amounts[0].vatRate,
            1,
            type,
            undefined,
            false,
            this.nextcloudPrice.productCode,
            this.nextcloudPrice.contractPeriod
        );
    }

    public reset = () => {
        this.nextcloud = ng.copy(this.$state.$current.locals.globals.storageProduct);
        this.modelActivation = this.nextcloud.backupEnabled;
    };

    public cancel = () => {
        this.reset();
    };

    public save = () => {
        if (
            (!this.modelConfirmation && !this.nextcloud.backupEnabled)
            || this.nextcloud.backupEnabled === this.modelActivation
        ) {
            return;
        }

        const nextcloudCopy = ng.copy(this.nextcloud);
        nextcloudCopy.backupEnabled = this.modelActivation;

        // Create backup request.
        return this.managedApplicationRobot
            .nextcloudUpdate(nextcloudCopy)
            .then(
                (response) => {
                    if (response.status !== 'error') {
                        this.alertManager.success(this.$translate.instant('BACKUP.ACTION.START.SUCCESS'));
                        return response;
                    }

                    return Promise.reject(false);
                },
                () => {
                    return Promise.reject(false);
                }
            );
    };
}

export class OrganEditPanelNextcloudBackupsComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./nextcloud-backups.html');
    public controller = OrganEditPanelNextcloudBackupsController;
}
