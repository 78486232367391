import * as ng from 'angular';
import { AuthContextService, PriceCacheService } from '../';
import { BillingCycleToContractPeriodeConst } from '../../configuration';

export class BillingHelperService {
    private _valueOffset = 10000;

    constructor(
        private priceCache: PriceCacheService
    ) {}

    public loadStripeScript() {
        // const stripeScriptUrl = 'https://checkout.stripe.com/checkout.js';
        const stripeScriptUrl = 'https://js.stripe.com/v3/';
        let isFound = false;
        const scripts = document.getElementsByTagName('script');

        // tslint:disable-next-line:prefer-for-of
        for (let i = 0; i < scripts.length; ++i) {
            if (
                scripts[i].getAttribute('src') != null
                && scripts[i].getAttribute('src').indexOf(stripeScriptUrl) >= 0
            ) {
                isFound = true;
            }
        }

        if (!isFound) {
            const dynamicScripts = [stripeScriptUrl];

            // tslint:disable-next-line:prefer-for-of
            for (let i = 0; i < dynamicScripts .length; i++) {
                const node = document.createElement('script');
                node.src = dynamicScripts [i];
                node.type = 'text/javascript';
                node.async = false;
                node.charset = 'utf-8';
                document.getElementsByTagName('head')[0].appendChild(node);
            }
        }
    }

    /**
     * Billing markups follow these rules:
     * 1. The data type is integer to avoid float imprecision.
     * 2. The unit is a hundredth percent.
     * 3. The markup is a factor. Combined with 1 and 2, this means that a value of 10000
     * results in the price being unchanged.
     * 4. We display the markup as a percentage added to / substracted from the price,
     * not a factor multiplied with the price.
     */
    public convertRealValueToDisplayValue = (value: string|number = '0') => {
        const realValue: number = parseFloat(`${value}`);
        const displayValue: number = (realValue - this._valueOffset) / 100;

        // Get rid of decimals introduced by float imprecision
        return parseFloat(`${displayValue}`);
    };

    public convertDisplayValueToRealValue = (value: string|number = '0') => {
        const displayValue: number = parseFloat(`${value}`);
        const realValue: number = (displayValue * 100) + this._valueOffset;

        // Get rid of decimals introduced by float imprecision
        return parseFloat(`${realValue}`);
    };

    public getPrices = (service: string, accountId: string) => {
        switch (service) {
            case 'machine': return this.priceCache.listVmPrices(accountId).then(
                (ret) => {
                    return ret;
                }
            );
            case 'email': return this.priceCache.listMailPrices(accountId);
            case 'bundle': return this.priceCache.listWebhostingPrices(accountId);
            case 'ssl': return this.priceCache.listSslPrices(accountId);
            case 'database': return this.priceCache.listDatabasePrices(accountId);
            case 'webhosting': return this.priceCache.listWebhostingPrices(accountId);
            case 'managedapplication': return this.priceCache.listManagedApplicationPrices(accountId);
        }
    };

    /**
     *  Get billing cycle as number (months) from contract period word
     **/
    public getBillingCycle = (price) => {
        return price !== undefined
            && price.contractPeriod !== undefined
            && BillingCycleToContractPeriodeConst[price.contractPeriod] !== undefined
                ? BillingCycleToContractPeriodeConst[price.contractPeriod]
                : null;
    };

    public getPriceByProductCode = (productCode, accountId?: string) => {
        return this.priceCache.getPriceByProductCode(
            productCode,
            accountId
        ).then(
            (apiResponse) => {
                return apiResponse.length > 0
                    ? apiResponse[0]
                    : -1; // if account = rootAmin or user has no price view rights!
            }
        );
    };

    public sortBillingCyclesAsc = (billingCycles: number[]) => {
        /**
         * Sort the billingcycles in ascending order
         */
        return billingCycles.sort((a: number, b: number) => {
            if (a > b) {
                return +1;
            }
            if (a < b) {
                return -1;
            }
        });

    };
}
