import * as ng from 'angular';
import { OverviewDataObject } from '../../../../../../organisms';

export class MoleculeOverviewPricelistRowPanelMachinesController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
}

export class MoleculeOverviewPricelistRowPanelMachinesComponent implements ng.IComponentOptions {
    public require = {
        organismPricelistOverview: '^organismPricelistOverview'
    };
    public bindings = {
        overviewData: '<'
    };
    public controller =  MoleculeOverviewPricelistRowPanelMachinesController;
    public controllerAs = 'OverviewPricelistCtrl';
    public template = require('./pricelist-machines-row.html');
}
