export * from './dashboard/dashboard-template';
export * from './machine-edit';
export * from './machine-interfaces/machine-interfaces';
export * from './machine-job-details/machine-job-details-template';
export * from './machine-job-overview';
export * from './machine-managed';
export * from './machine-os-install/machine-os-install-template';
export * from './machine-pool-new';
export * from './machine-pool-overview';
export * from './machine-upgrade';
export * from './machine-vnc';
export * from './machine-wizard';
export * from './virtualmachine-overview';
