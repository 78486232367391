import * as ng from 'angular';
import { PanelHeaderData } from '../../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateMachineManagedDeleteController {
    public static $inject: string[] = ['$translate'];

    public pageHeaderData: PanelHeaderData;
    public machine;

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        this.pageHeaderData = {
            backwardLink: 'managed-servers.id.dashboard',
            backwardText: this.$translate.instant('TR_220319-1ac2e3_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: { machineId: this.machine.id},
            panelIcon: 'adjust',
            panelTitle: this.machine.name
        };
    }
}

export class TemplateMachineManagedDeleteComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<'
    };
    public controller = TemplateMachineManagedDeleteController;
    public template = require('./machine-managed-delete-template.html');
}
