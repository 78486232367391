import { getDefaultCronjob } from '@/configuration/cronjob-default';
import * as ng from 'angular';
import { BillingCycleToContractPeriodeConst, UiRights } from '../../configuration';
import {
    AccountModelService,
    AuthContextService,
    BackupModelService,
    CronjobOverviewModel,
    FilterCacheService,
    InstallerModelService,
    LegacyPhpVhostOverviewModel,
    LocalstorageHelperService,
    ModelHelper,
    NavigationService,
    PriceCacheService,
    RedirectOnNotFoundCallback,
    ResourceModelService,
    UserSettingsManagerService,
    VhostModelService,
    VhostOverviewModel,
    WebhostingHelperService,
    WebhostingJobModelService,
    WebhostingJobOverviewModel,
    WebhostingUserModelService,
    WebspaceModelService,
    WebspaceOverviewModel,
    WebspaceUserOverviewModel
} from '../../services';
import * as Types from '../../types';

export const WEBHOSTING_ROUTES = [
    {
        name: 'webhosting',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/webhosting'
        }
    },
    {
        name: 'webhosting.dashboard',
        state: {
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            onEnter: (navigation: NavigationService, userSettingsManager: UserSettingsManagerService) => {
                userSettingsManager.getUiSettings().then(
                    (result) => {
                        if (result?.overviewSettings?.WebspaceOverviewCtrl?.expertView) {
                            navigation.go('webhosting.webspaces.overview');
                        }
                    }
                );
            },
            resolve: {
                webspaces: (webspaceModel: WebspaceModelService) => {
                    return webspaceModel.list(4, 1, null, {
                        field: 'webspaceLastChangeDate',
                        order: 'DESC'
                    });
                }
            },
            url: '/dashboard',
            views: {
                'content@authenticated': {
                    template: '<template-webhosting-dashboard></template-webhosting-dashboard>'
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.jobs',
        state: {
            data: {
                isGranted: UiRights.WEB_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('WebhostingJobs');
                },
                overviewModel: (webhostingJobOverviewModel: WebhostingJobOverviewModel) => webhostingJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('WebhostingJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-webhosting-job-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-webhosting-job-overview>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.jobs.id',
        state: {
            abstract: true,
            resolve: {
                job: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    webhostingJobModel: WebhostingJobModelService
                ) => {
                    return webhostingJobModel.findOne($stateParams.jobId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{jobId}'
        }
    },
    {
        name: 'webhosting.webspaces.jobs.id.details',
        state: {
            url: '/details',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: `<template-webhosting-job-details
                                    job="$resolve.job"
                                ></template-webhosting-job-details>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            url: '/webspaces'
        }
    },
    {
        name: 'webhosting.webspaces.overview',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('Webspaces');
                },
                overviewModel: (webspaceOverviewModel: WebspaceOverviewModel) => webspaceOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (localstorageHelper: LocalstorageHelperService, filterCache: FilterCacheService) => {
                    const userCameFrom = localstorageHelper.getLastRouterState();

                    // when user came from webhosting dashboard overwrite the simpleFilter
                    if (userCameFrom?.name === 'webhosting.dashboard') {
                        const simpleFilter = filterCache.resolveSimpleFilter('WebspacesAndVhosts');
                        simpleFilter.show = false;

                        return simpleFilter;
                    }

                    return filterCache.resolveSimpleFilter('Webspaces');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-webspace-overview>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.new',
        state: {
            data: {
                isGranted: UiRights.WEB_WEBSPACE_CREATE
            },
            resolve: {
                profiles: (vhostModel: VhostModelService) => {
                    return vhostModel.listProfiles('').then((answer) => {
                        return answer;
                    });
                }
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-webspace-wizard></template-webspace-wizard>'
                }
            }
        }
    },
    {
        comment: 'Abstract state that does the "mailbox" resolve for all bundle-specific and mailbox-specific states.'
            + ' Also prevents entering sub-states without mailbox list rights.',
        name: 'webhosting.webspaces.id',
        state: {
            abstract: true,
            resolve: {
                webspace: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    webspaceModel: WebspaceModelService
                ) => {
                    if ($stateParams.webspaceId === null) {
                        return [];
                    }

                    return webspaceModel.findOne($stateParams.webspaceId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{webspaceId}'
        }
    },
    {
        name: 'webhosting.webspaces.id.edit',
        state: {
            data: {
                isGranted: UiRights.WEB_WEBSPACE_EDIT
            },
            resolve: {
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                },
                webspace: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    webspaceModel: WebspaceModelService
                ) => {
                    if ($stateParams.webspaceId === null) {
                        return [];
                    }

                    return webspaceModel.findOne($stateParams.webspaceId).then(null, redirectOnNotFound);
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-edit
                                    webspace="$resolve.webspace"
                                    show-object-id="$resolve.showObjectId"
                                ></template-webspace-edit>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.storage',
        state: {
            data: {
                isGranted: UiRights.WEB_WEBSPACE_EDIT
            },
            url: '/storage',
            views: {
                'content@authenticated': {
                    template: '<template-webspace-storage webspace="$resolve.webspace"></template-webspace-storage>'
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.admin-options',

        state: {
            data: {
                isGrantedAny: [UiRights.WEB_ADMIN_WEBSPACE_ENABLE_DISABLE, UiRights.WEB_ADMIN_EMAIL_SENDER]
            },
            url: '/admin-options',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: '<template-webspace-admin-options webspace="$resolve.webspace"></template-webspace-admin-options>'
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.php-boost',
        state: {
            data: {
                isGrantedAll: [
                    UiRights.BIL_LIST_PRICES_WEBHOSTING,
                    UiRights.WEB_WEBSPACE_EDIT
                ]
            },
            resolve: {
                billingCycle: (priceCache: PriceCacheService, webspace) => {
                    return priceCache.getPriceByProductCode(
                        webspace.productCode,
                        webspace.accountId
                    ).then((reply) => BillingCycleToContractPeriodeConst[reply[0].contractPeriod]);
                }
            },
            url: '/php-boost',
            views: {
                'content@authenticated': {
                    template: '<template-webspace-php-boost webspace="$resolve.webspace" billing-cycle="$resolve.billingCycle"></template-webspace-php-boost>'
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.ftp-ssh',
        state: {
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            resolve: {
                webspaceAccessUsers: (
                    webspace: Types.WebhostingApi.Webspace,
                    webhostingUserModel: WebhostingUserModelService
                ) => {
                    return webhostingUserModel.list(500, null, { field: 'UserAccessesWebspaceId', value: webspace.id })
                    .then((res) => res.data) as ng.IPromise<Types.WebhostingApi.WebspaceAccess[]>;
                },
                webspaceUsers: (
                    webspace: Types.WebhostingApi.Webspace,
                    webhostingUserModel: WebhostingUserModelService
                ) => {
                    return webhostingUserModel.list(null, null, {field: 'accountId', value: webspace.accountId})
                        .then((res) => res.data) as ng.IPromise<Types.WebhostingApi.FindWebspacesResult[]>;
                }
            },
            url: '/user-overview',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-access-overview
                                    webspace="$resolve.webspace"
                                    webspace-users="$resolve.webspaceUsers"
                                    webspace-access-users="$resolve.webspaceAccessUsers"
                                ></template-webspace-access-overview>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.ftp-ssh.new',
        state: {
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-access-add-user-overview
                                    webspace="$resolve.webspace"
                                ></template-webspace-access-add-user-overview>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.vhosts',
        state: {
            abstract: true,
            url: '/domains'
        }
    },
    {
        data: {
            isGranted: UiRights.WEB_OBJECT_LIST
        },
        name: 'webhosting.webspaces.id.vhosts.overview',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('domain.domain');
                },
                overviewModel: (vhostOverviewModel: VhostOverviewModel) => vhostOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('domain.domain');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-webhosting-vhost-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                    webspace="$resolve.webspace"
                                ></template-webhosting-vhost-overview>`
                }
            }
        }
    },
    {
        data: {
            isGranted: UiRights.WEB_OBJECT_LIST
        },
        name: 'webhosting.webspaces.id.vhosts.legacyphp',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('domain.domain');
                },
                legacyPhpVhostOverviewModel: (legacyPhpVhostOverviewModel: LegacyPhpVhostOverviewModel) => legacyPhpVhostOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('domain.domain');
                }
            },
            url: '/legacyphp',
            views: {
                'content@authenticated': {
                    template: `<template-webhosting-vhost-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.legacyPhpVhostOverviewModel"
                                    webspace="$resolve.webspace"
                                    is-legacy-php-overview="true"
                                ></template-webhosting-vhost-overview>`
                }
            }
        }
    },
    {
        data: {
            isGranted: UiRights.WEB_OBJECT_LIST
        },
        name: 'webhosting.webspaces.id.vhosts.minphpversion',
        state: {
            resolve: {
                phpVersions: (vhostModel: VhostModelService) => {
                    return vhostModel.availablePhpVersions();
                }
            },
            url: '/minphpversion',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-php-min-version
                                    webspace="$resolve.webspace"
                                    php-versions="$resolve.phpVersions"
                                ></template-webspace-php-min-version>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.vhosts.new',
        state: {
            data: {
                isGranted: UiRights.WEB_VHOST_CREATE
            },
            params: {
                domainType: {value: null, squash: true}
            },
            resolve: {
                // Benötigen wir eigentlich im neuem Wizard die Profiles noch ??
                profiles: (webspace, vhostModel: VhostModelService) => {
                    return vhostModel.listProfiles(webspace.id).then((profileList) => {
                        return profileList;
                    });
                },
                ownerAccount: (
                    accountModel: AccountModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    webspace
                ) => {
                    if (webspace.accountId === AuthContextService.account.id) {
                        return AuthContextService.account;
                    }

                    return accountModel.findOne(webspace.accountId).then(null, redirectOnNotFound);
                }
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: `<template-domain-wizard
                            owner-account="$resolve.ownerAccount"
                            webspace="$resolve.webspace"
                        ></template-domain-wizard>`
                }
            }
        }
    },
    {
        // tslint:disable-next-line: max-line-length
        coment: 'This state currently does nothing, but apparently, the AngularJS UI router needs it for internal stuff.',
        name: 'webhosting.webspaces.id.backups',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.BACKUP_BACKUP_LIST
            },
            resolve: {
                backups:
                    (webspace, backupModel: BackupModelService) => [undefined, null, ''].indexOf(webspace) === -1
                        ? backupModel.list('webhosting', 'webspace', webspace.id)
                        : []
            },
            url: '/backups'
        }
    },
    {
        name: 'webhosting.webspaces.id.backups.overview',
        state: {
            url: '/overview',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: '<template-webspace-backups backups="$resolve.backups" webspace="$resolve.webspace"></template-webspace-backups>'
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.cronjobs',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            url: '/cronjobs'
        }
    },
    {
        name: 'webhosting.webspaces.id.cronjobs.overview',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('cronjobs');
                },
                overviewModel: (cronjobOverviewModel: CronjobOverviewModel) => cronjobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('cronjobs');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-cronjob-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    webspace="$resolve.webspace"
                                    overview-model="$resolve.overviewModel"
                                ></template-cronjob-overview>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.cronjobs.new',
        state: {
            data: {
                isGranted: UiRights.WEB_WEBSPACE_EDIT
            },
            resolve: {
                cronjob: getDefaultCronjob
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-cronjob-new cronjob="$resolve.cronjob"></template-cronjob-new>'
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.cronjobs.id',
        state: {
            abstract: true,
            resolve: {
                cronjob: ($stateParams: ng.ui.IStateParamsService, webspace: any) => {
                    return webspace.cronJobs[$stateParams.cronjobId];
                },
                cronjobId: ($stateParams: ng.ui.IStateParamsService) => $stateParams.cronjobId
            },
            url: '/id/{cronjobId}'
        }
    },
    {
        name: 'webhosting.webspaces.id.cronjobs.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-cronjob-edit
                                    cronjob="$resolve.cronjob"
                                ></template-cronjob-edit>`
                }
            }
        }
    },
    {
        name: 'webhosting.vhosts',
        state: {
            abstract: true,
            url: '/domains'
        }
    },
    {
        data: {
            isGranted: UiRights.WEB_OBJECT_LIST
        },
        name: 'webhosting.vhosts.overview',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('domain.domain');
                },
                overviewModel: (vhostOverviewModel: VhostOverviewModel) => vhostOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (localstorageHelper: LocalstorageHelperService, filterCache: FilterCacheService) => {
                    const userCameFrom = localstorageHelper.getLastRouterState();

                    // when user came from webhosting dashboard overwrite the simpleFilter
                    if (userCameFrom?.name === 'webhosting.dashboard') {
                        const simpleFilter = filterCache.resolveSimpleFilter('WebspacesAndVhosts');
                        simpleFilter.show = false;

                        return simpleFilter;
                    }

                    return filterCache.resolveSimpleFilter('domain.domain');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-webhosting-vhost-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-webhosting-vhost-overview>`
                }
            }
        }
    },
    {
        name: 'webhosting.vhosts.new',
        state: {
            data: {
                isGranted: UiRights.WEB_VHOST_CREATE
            },
            params: {
                domainType: {value: null, squash: true}
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-domain-wizard></template-domain-wizard>'
                }
            }
        }
    },
    {
        comment: 'Abstract state that does the "mailbox" resolve for all bundle-specific and mailbox-specific states.'
            + ' Also prevents entering sub-states without mailbox list rights.',
        name: 'webhosting.webspaces.id.vhosts.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.WEB_VHOST_EDIT
            },
            resolve: {
                domain: () => null,
                domainType: () => 'vhost',
                phpIni: ($stateParams: ng.ui.IStateParamsService, vhostModel: VhostModelService) => {
                    return vhostModel.vhostPhpIniList($stateParams.vHostId);
                },
                phpVersions: (vhostModel: VhostModelService) => {
                    return vhostModel.availablePhpVersions();
                },
                vHost: (vhostModel: VhostModelService, $stateParams: ng.ui.IStateParamsService) => {
                    const filter = {field: 'vhostId', value: $stateParams.vHostId};

                    return vhostModel.list(null, 1, filter, null).then(ModelHelper.returnFindSingleData);
                },
                webspace: (vHost, webspaceModel: WebspaceModelService) => {
                    const filter = {field: 'WebspaceId', value: vHost.webspaceId};
                    return webspaceModel.list(null, 1, filter)
                    .then(ModelHelper.returnFindSingleData);
                }
            },
            url: '/id/{vHostId}'
        }
    },
    {
        name: 'webhosting.webspaces.id.vhosts.id.edit',
        state: {
            resolve: {
                phpConfigurationMetaData: (vhostModel: VhostModelService) => {
                    return vhostModel.phpConfigurationMetadata().then((answer) => {
                        return answer;
                    });
                },
                phpIniDefault: (vHost, webspace, webhostingHelper: WebhostingHelperService) => {
                    return webhostingHelper.getDefaultPhpIniSet(webspace.id, vHost.phpVersion, false);
                },
                phpIniList: (phpIni) => {
                    return phpIni;
                },
                phpVersions: (phpVersions) => {
                    return phpVersions;
                },
                profiles: (webspace, vhostModel: VhostModelService) => {
                    return vhostModel.listProfiles(webspace.id).then((answer) => {
                        return answer;
                    });
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-domain-edit
                        webspace="$resolve.webspace"
                        v-host="$resolve.vHost"
                        domain-type="$resolve.domainType"
                        php-ini-list="$resolve.phpIniList"
                        php-ini-default="$resolve.phpIniDefault"
                        profiles="$resolve.profiles"
                        php-versions="$resolve.phpVersions"
                        php-configuration-meta-data="$resolve.phpConfigurationMetaData"
                    ></template-domain-edit>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.vhosts.id.ssl',
        state: {
            resolve: {
                profiles: (webspace, vhostModel: VhostModelService) => {
                    return vhostModel.listProfiles(webspace.id).then((answer) => {
                        return answer;
                    });
                }
            },
            url: '/ssl-edit',
            views: {
                'content@authenticated': {
                    template: `<template-webhosting-vhost-ssl-edit
                        webspace="$resolve.webspace"
                        v-host="$resolve.vHost"
                        php-ini-list="$resolve.phpIniList"
                        profiles="$resolve.profiles"></template-webhosting-vhost-ssl-edit>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.vhosts.id.dir-protection-edit',
        state: {
            resolve: {
                phpIni: (vHost, vhostModel: VhostModelService) => {
                    return vhostModel.vhostPhpIniList(vHost.id).then(
                        (reply) => {
                            return reply;
                        }
                    );
                }
            },
            url: '/dir-protection-edit',
            views: {
                'content@authenticated': {
                    template: `<template-domain-dir-protection
                        v-host="$resolve.vHost"
                        domain="$resolve.domain"
                        php-ini="$resolve.phpIni"
                    ></template-domain-dir-protection>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.vhosts.id.locations-edit', // webhosting.vhosts.id.locations-edit
        state: {
            resolve: {
                phpIni: (vHost, vhostModel: VhostModelService) => {
                    return vhostModel.vhostPhpIniList(vHost.id).then(
                        (reply) => {
                            return reply;
                        }
                    );
                }
            },
            url: '/locations-edit',
            views: {
                'content@authenticated': {
                    template: `<template-domain-locations
                        webspace="$resolve.webspace"
                        v-host="$resolve.vHost"
                        domain="$resolve.domain"
                        php-ini="$resolve.phpIni"
                    ></template-domain-locations>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.vhosts.id.app-install',
        state: {
            resolve: {
                apps: (installerModel: InstallerModelService) => {
                    return installerModel.list('app', {});
                },
                databaseRessource: (
                    webspace: Types.WebhostingApi.Webspace,
                    resourceModel: ResourceModelService
                ) => {
                    if ([undefined, null].indexOf(webspace.webserverId) >= 0) {
                        return null;
                    }

                    /**
                     *  Due to the cumbersome API functionality, it is necessary to load the webspaceResource
                     *  via the Vhost linked webspace and then retrieve the resource for database via the
                     *  Rescouce virtualMachineId.
                     *  ~ pille palle pulle ~ Bon appétit et bon voyage
                    **/
                    return resourceModel.resourceList(
                        null,
                        'Webserver',
                        null,
                        null,
                        { field: 'id', value: webspace.webserverId }
                    ).then((res) => {
                        if (res.data.length !== 0) {
                            return resourceModel.resourceList(null,
                                'Database',
                                null,
                                null,
                                { field: 'virtualMachineId', value: res.data[0].virtualMachineId }
                            ).then((resWebserver) => { // tslint:disable-line:no-shadowed-variable
                                return resWebserver.data.length !== 0
                                    ? resWebserver.data[0]
                                    : null;
                            }) as ng.IPromise<Types.ResourceApi.Resource | null>;
                        }

                        return null;
                    }) as ng.IPromise<Types.ResourceApi.Resource | null>;
                }
            },
            url: '/app-install',
            views: {
                'content@authenticated': {
                    template: `<template-domain-app-install
                        apps="$resolve.apps"
                        webspace="$resolve.webspace"
                        domain="$resolve.domain"
                        v-host="$resolve.vHost"
                        database-ressource="$resolve.databaseRessource"
                        ></template-domain-app-install>`
                }
            }
        }
    },
    {
        name: 'webhosting.webspaces.id.vhosts.id.directory-protection',
        state: {
            url: '/directory-protection',
            views: {
                'content@authenticated': {
                    template: `<template-webhosting-vhost-directory-protection
                        webspace="$resolve.webspace"
                        v-host="$resolve.vHost"
                    ></template-webhosting-vhost-directory-protection>`
                }
            }
        }
    },
    {
        name: 'webhosting.users',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            url: '/users'
        }
    },
    {
        name: 'webhosting.users.overview',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('webhosting.users');
                },
                overviewModel: (webspaceUserOverviewModel: WebspaceUserOverviewModel) => webspaceUserOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('webhosting.users');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-user-overview
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-webspace-user-overview>`
                }
            }
        }
    },
    {
        name: 'webhosting.users.new',
        state: {
            data: {
                isGranted: UiRights.WEB_USER_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-webhosting-user-new></template-webhosting-user-new>'
                }
            }
        }
    },
    {
        name: 'webhosting.users.id',
        state: {
            abstract: true,
            resolve: {
                user: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    webhostingUserModel: WebhostingUserModelService
                ) => webhostingUserModel.findOneById($stateParams.userId).then(null, redirectOnNotFound)
            },
            url: '/id/{userId}'
        }
    },
    {
        name: 'webhosting.users.id.edit',
        state: {
            data: {
                isGranted: UiRights.WEB_USER_EDIT
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: '<template-webhosting-user-edit user="$resolve.user"></template-webhosting-user-edit>'
                }
            }
        }
    }
];
