import ng from 'angular';
import { SslComposerService } from '@/services';
import * as Types from '@/types';

export class OrganismSslWizardWrapperController {
    public static $inject: string[] = [
        'sslComposer'
    ];

    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string; // used in template
    public productFamily: string;
    public account: Types.AccountApi.Account;
    public productCode: string;

    public wizardComposerObject: Types.ViewTypes.WizardComposerObject = {
        apiObject: {
            orderItem: {
                items: undefined,
                name: undefined,
                productCode: undefined,
                type: undefined
            },
            ownerAccountId: undefined
        },
        summaryObject: []
    };
    public viewType = 'config';

    constructor(
        private sslComposer: SslComposerService
    ) {}

    public loadSummaryView = async (metaData: Types.ViewTypes.ProductConfigSslObject): Promise<void> => { // used in template
        this.viewType = 'summary';

        await this.sslComposer.buildSslApiObject(metaData)
            .then((composerObject: Types.ViewTypes.WizardComposerObject) => {
                this.wizardComposerObject = composerObject;
            });
    };

    public loadConfirmView = (): void => { // used in template
        this.viewType = 'confirm';
    };
}

export class OrganismSslWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        outerAccount: '<?account',
        outerProductCode: '<?productCode',
        wizardObjects: '<?'
    };
    public template = require('./ssl-wizard-wrapper.html');
    public controller = OrganismSslWizardWrapperController;
}
