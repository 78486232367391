import * as q from 'q';
import { UiRights } from '../../configuration';
import * as Types from '../../types';
import { AuthContextService } from '../auth-context';
import { FunctionRelayService } from '../function-relay';
import { FileSaverService } from './file-saver';
import { BillingRobotService } from './robot';

export interface DepositInfoObject {
    balance: number;
    creditLimit: number;
    paymentType: string;
    reserved: number;
    voucherBalance: number;
}

export class DepositModelService {
    public static $inject: string[] = [
        'billingRobot',
        'fileSaver',
        'functionRelay'
    ];

    private depositInfo: null | DepositInfoObject;

    constructor(
        private billingRobot: BillingRobotService,
        private fileSaver: FileSaverService,
        private functionRelay: FunctionRelayService
    ) {
        this.getDepositInfo();
    }

    public getDeposit = (subAccount?: Types.AccountApi.Subaccount) => {
        return this.billingRobot.getDeposit(subAccount)
            .then((result) => result.response) as q.IPromise<DepositInfoObject>;
    };

    public redeemVoucher = (voucherCode: string) => {
        return this.billingRobot.redeemVoucher(voucherCode);
    };

    public getVoucherDetails = (voucherCode: string, accountId = AuthContextService.account.id) => {
        return this.billingRobot.getVoucherDetails(voucherCode, accountId);
    };

    public reloadDepositInfo = (subAccount?: Types.AccountApi.Subaccount) => {
        return this.getDepositInfo(subAccount);
    };

    public sufficientCreditForOrder = (orderCost: number = 0): number => {
        return this._calculateRemainingBalance(orderCost);
    };

    public depositCreditStart = (provider: string, value: number) => {
        let paymentParameters: Types.BillingApi.AbstractPaymentParameters;
        const baseUrl = window.location.protocol + '//' + window.location.host + '/';
        let commitUrl = baseUrl + 'billing/options/commit/';
        let cancelUrl = baseUrl + 'billing/options/cancel/';

        switch (provider) {
            case 'paypal':
                commitUrl += 'paypal';
                cancelUrl += 'paypal';

                paymentParameters = {
                    cancelUrl: cancelUrl,
                    commitUrl: commitUrl,
                    type: 'PaypalPaymentParameters'
                } as Types.BillingApi.PaypalPaymentParameters;

                break;

            case 'stripe':
                commitUrl += 'stripe';
                cancelUrl += 'stripe';

                paymentParameters = {
                    cancelUrl: cancelUrl,
                    commitUrl: commitUrl,
                    type: 'StripePaymentParameters'
                } as Types.BillingApi.StripePaymentParameters;

                break;

            default:
                throw new Error(`Unknown payment provider "${provider}"`);
        }

        return this.billingRobot.depositCreditStart(value, paymentParameters);
    };

    public paymentCommit = (provider: string, token: string, payerId?: string, paymentId?: string) => {
        let paymentCommitParameters: Types.BillingApi.AbstractPaymentCommitParameters;

        switch (provider) {
            case 'paypal':
                paymentCommitParameters = {
                    externalPaymentToken: token,
                    payerId: payerId,
                    type: 'PaypalPaymentCommitParameters'
                } as Types.BillingApi.PaypalPaymentCommitParameters;

                break;

            case 'stripe':
                paymentCommitParameters = {
                    paymentId: paymentId,
                    type: 'StripePaymentCommitParameters'
                } as Types.BillingApi.StripePaymentCommitParameters;

                break;

            default:
                throw new Error(`Unknown payment provider "${provider}"`);
        }

        return this.billingRobot.paymentCommit(paymentCommitParameters)
            .then(this.refreshDeposit, this.refreshDeposit);
    };

    public depositPaymentCancel = (externalPaymentToken: string) => {
        return this.billingRobot.paymentCancel(externalPaymentToken);
    };

    // This is the old method - use insteed bankAccountCreate
    public generateDirectDebitForm = (accountHolder, iban: string, bic: string, signatory) => {
        return this.billingRobot.generateDirectDebitForm(accountHolder, iban, bic, signatory)
            .then((result) => result.response)
            .then((data) => this.fileSaver.saveBase64AsPdf(data, 'directDebitForm.pdf'));
    };

    public bankAccountCreate = (
        iban: string,
        accountHolder,
        bic?: string,
        dateOfBirth?,
        signatory?,
        changePaymentMethod = true
    ) => {
        return this.billingRobot.bankAccountCreate(
            iban,
            accountHolder,
            bic,
            signatory,
            dateOfBirth,
            changePaymentMethod
        );
    };

    public paymentMethodChange: (
        paymentSourceId: string,
        paymentType?: string,
        paymentSubType?: string
    ) => q.IPromise<any> = (paymentSourceId, paymentType = 'postpaid', paymentSubType = 'directDebit') => {
        return this.billingRobot.paymentMethodChange(paymentType, paymentSubType, paymentSourceId)
            .then((result) => {
                if (result.response) {
                    return result.response;
                } else {
                    // for some reason the API lately does not always answer with a response object on success
                    // PR-Bugfix: PUI-5684
                    return {
                        status: result.status
                    };
                }
            });
    };

    public paymentMethodAvailability = async (): Promise<any> => {
        const available = await this.billingRobot.availablePaymentProviders()
            .then((result) => result.responses);
        const potentiallyAvailable = await this.billingRobot.potentiallyAvailablePaymentProviders()
            .then((result) => result.responses);

        return potentiallyAvailable.map(
            (paymentMethod) => ({
                paymentMethod: paymentMethod,
                available: available.indexOf(paymentMethod) >= 0
            })
        );
    };

    private refreshDeposit = (result) => {
        this.functionRelay.call('updateDeposit', {});
        return result;
    };

    private getDepositInfo = (account?: Types.AccountApi.Subaccount): q.IPromise<any> => {
        if (AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)) {
            return this.getDeposit(account).then((reply) => this.depositInfo = reply);
        }

        return Promise.resolve(null);
    };

    private _calculateRemainingBalance = (orderCost: number): number => {
        if (
            [undefined, null].indexOf(this.depositInfo) >= 0
            || ['postpaid', 'prepaid'].indexOf(this.depositInfo.paymentType.toLowerCase()) < 0

        ) {
            return null;
        }

        return (this.depositInfo.balance
            + this.depositInfo.voucherBalance
            + this.depositInfo.creditLimit
            - this.depositInfo.reserved
        ) - orderCost;
    };
}
