import * as ng from 'angular';
import { BillingCycleToContractPeriodeConst, UiLanguagesConst, UiRights } from '../../../../../configuration';
import {
    AuthContextService,
    PriceCacheService,
    PriceHelperService,
    ProductsModelService,
    UserSettingsManagerService,
    VhostModelService
} from '../../../../../services';
import { WebhostingApi } from '../../../../../types';
import { OrganismEditFormDomainController } from '../../../../organisms/forms/edit-forms/domain-edit/domain-edit';

import './domain-php-options.scss';
import { MoleculeFormEditController, PanelType } from '@/atomic-components';

export class OrganEditPanelDomainPhpOptionsController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'dateFilter',
        'priceCache',
        'priceHelper',
        'productsModel',
        'userSettingsManager',
        'vhostModel'
    ];

    public $editForm: MoleculeFormEditController;
    public name = 'vhost_php_options';
    public additionalPhpOptions: any[] = [];
    public additionalPhpOptionsDropdownItems: any[] = [];
    public readOnlyOptions: string[] = [];
    public hiddenOptions: string[] = [];
    public initalAdditionalPhpOptionsDropdownItems: any[] = [];
    public initalEditFormAdditionalPhpOptions: any[] = [];
    public phpIniDefaultSettings: WebhostingApi.PhpIniValue[];
    public userSettingShowExpertSettings = false;
    public showExpertSettings = false;
    public showPhpVersionChangedUserNotice = false;
    public $editFormOrganism: OrganismEditFormDomainController;
    public panelRight;
    public phpVersionDropdownOptions: any[] = [];
    public showPhpEnableHint = false;
    public webspace: WebhostingApi.Webspace;
    public changesAffectPayment: boolean;
    public changeIsFreeOfCharge = true;
    public chargeableProductName = '';
    private registrationIndex: number;
    public legacyPhpProductBillingPeriodeText = '';

    private _advancedPhpOptionNamesCache: string[] = [];
    private _advancedPhpOptionNamesCachePhpVersion = '0';
    private _origPhpVersion = '';
    private _initialized = false;
    private availablePhpVersions: string[] = [];
    private basicPhpOptionNames: string[] = [
        'display_errors', 'allow_url_fopen', 'memory_limit', 'max_execution_time', 'allow_url_include'
    ];
    private userGrantedAdminRead = false;
    private userGrantedSuperAdmin = false;
    private userGrantedPricesList = false;
    private legacyProductPriceAccepted = false;
    private phpAddonProducts: any[] = [];
    private bookedLegacyPHPAddonProduct = null;
    public addonPHPVersions: string[] = [];
    private webspaceBillingCycle: number|null = null;
    private _origVhost;
    private _origPhpInit;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private dateFilter,
        private priceCache: PriceCacheService,
        public priceHelper: PriceHelperService,
        private productsModel: ProductsModelService,
        private userSettingsManager: UserSettingsManagerService,
        private vhostModel: VhostModelService
    ) {
        this.userSettingsManager.showAdvancedPhpSettings()
        .then(
            (showAdvancedPhpSettings: boolean) => {
                this.userSettingShowExpertSettings = showAdvancedPhpSettings;
            }
        );
    }

    /* tslint:disable-next-line:no-empty */
    public cancelCallback: () => void = () => {};

    public async $onInit(): Promise<void> {
        this.registrationIndex = this.$editForm.registerValidator(this);
        this.legacyProductPriceAccepted = false;
        this.changeIsFreeOfCharge = true;
        this.$editFormOrganism.legacyPHPChargeableProductCode = '';
        this.legacyPhpProductBillingPeriodeText = '';
        this.userGrantedAdminRead = AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_READ);
        this.userGrantedSuperAdmin = AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_WEBHOSTING_ADMIN);
        this.userGrantedPricesList = AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_WEBHOSTING);

        this.$editFormOrganism.cancelPhpOptions = this.outerCancel;
        this.$editFormOrganism.applyProfileToPhpSettings = this.applyProfile;
        this.initalEditFormAdditionalPhpOptions = ng.copy(this.$editFormOrganism.additionalPhpOptionsList);
        this._initialized = true;
        this._origVhost = ng.copy(this.$editFormOrganism.vHost);
        this._origPhpInit = ng.copy(this.$editFormOrganism.phpIniList);
        this._origPhpVersion = ng.copy(this.$editFormOrganism.vHost.phpVersion);

        const language = UiLanguagesConst[AuthContextService.user.language];

        if (this.userGrantedPricesList) {
            const webspaceProduct = await this.priceCache.getPriceByProductCode(
                this.webspace.productCode,
                this.webspace.accountId
            ).then((reply) => reply[0]);
            this.webspaceBillingCycle = BillingCycleToContractPeriodeConst[webspaceProduct.contractPeriod];
        }

        if (this.webspace.legacyPhpAddonProductCode !== null) {
            this.bookedLegacyPHPAddonProduct = await this.productsModel.findProduct(
                this.webspace.legacyPhpAddonProductCode,
                language
            ).then((res) => {
                res.response.includedVersions = res.response.specificationItems.filter((spec) => spec.name === 'phpVersions')[0].value;
                return res.response;
            });
            this.addonPHPVersions = this.bookedLegacyPHPAddonProduct.includedVersions;
        }

        this.phpAddonProducts = await this.productsModel.findProducts(
            'webhosting',
            'webspace-php-legacy-addons',
            language
        ).then((reply) => reply.response.data.map((product) => {
            if (this.userGrantedPricesList) {
                product.productCode = product.productCodeTemplate.replace('##BILLING_CYCLE##', this.webspaceBillingCycle);
                this.legacyPhpProductBillingPeriodeText = this.priceHelper.getBillingPeriod(String(this.webspaceBillingCycle));
            }
            product.includedVersions = product.specificationItems.filter((spec) => spec.name === 'phpVersions')[0].value;
            product.minVersion = parseFloat(product.includedVersions[0]);
            return product;
        }));
        this.setPhpOptions();
    }

    public $onDestroy = () => {
        this.$editForm.unregisterValidator(this.registrationIndex);
        this.$editForm.validatorStatus[this.registrationIndex] = true;
    };

    private setValidationStatus = () => {
        this.$timeout(
            () => {
                this.$editForm.validatorStatus[this.registrationIndex] = this.changeIsFreeOfCharge || this.legacyProductPriceAccepted;
            }
        );
    };

    public setPhpOptions = () => {
        this._advancedPhpOptionNamesCachePhpVersion = '0';
        this._advancedPhpOptionNamesCache = [];

        this.setAvailablePhpVersions();

        if (this.availablePhpVersions.indexOf(this.$editFormOrganism.vHost.phpVersion) < 0) {
            this.availablePhpVersions.unshift(this.$editFormOrganism.vHost.phpVersion);
        }

        this.phpVersionDropdownOptions = [];
        for (const version of this.availablePhpVersions) {
            let optionName;
            if (this.addonPHPVersions.includes(version)) {
                if (this.webspace.legacyPhpMigrationPeriod !== null) { // kostenpflichtig bis [datum]
                    const migrationPeriodEndDate = this.dateFilter(this.webspace.legacyPhpMigrationPeriod, 'dd.MM.yyyy');
                    optionName = `${version} (${this.$translate.instant('TR_130123-33f78c_TR', { datum: migrationPeriodEndDate })})`;
                } else { // kostenpflichtig
                    optionName = `${version} (${this.$translate.instant('TR_130123-8e8adf_TR')})`;
                }
            } else if (this.webspace.phpVersions.includes(version)) { // aktuelle PHPVersionen (kein addon benötigt)
                optionName = `${version}`;
            } else { // veraltete PHP Versionen (kostenpflichtig)
                optionName = `${version} (${this.$translate.instant('TR_130123-36ae58_TR')})`;
            }
            this.phpVersionDropdownOptions.push({
                name: optionName,
                value: version
            });
        }

        this.$editFormOrganism.basicPhpOptionsList = [];
        this.$editFormOrganism.advancedPhpOptionsList = [];

        if (this._initialized) {
            this.$editFormOrganism.defaultLocation = this.$editFormOrganism.originalDefaultLocation;
        }

        this.createPhpOptionsList(this.basicPhpOptionNames, this.$editFormOrganism.basicPhpOptionsList);
        this.createPhpOptionsList(this.advancedPhpOptionNames, this.$editFormOrganism.advancedPhpOptionsList);
        this.resetPhpAdditionalOptions();
        this.resetPhpDefaultOptions();
        this.createAdditionalPhpOptionsList();
    };

    public get advancedPhpOptionNames() {
        const selectedPhpVersion = this.$editFormOrganism.vHost.phpVersion;
        const phpVersionUnchanged = this._advancedPhpOptionNamesCachePhpVersion === selectedPhpVersion;

        if (this._advancedPhpOptionNamesCache.length > 0 && phpVersionUnchanged) {
            return this._advancedPhpOptionNamesCache;
        }

        this._advancedPhpOptionNamesCachePhpVersion = selectedPhpVersion;

        const setPhpOptions = this.$editFormOrganism.phpIniList.values
        .map((PhpOption) => PhpOption.key);

        this._advancedPhpOptionNamesCache = this.filterAvailablePhpOptions(setPhpOptions, this.basicPhpOptionNames);

        return this._advancedPhpOptionNamesCache;
    }

    public isDeletableOption = (optionName: string) => {
        const deletableOption = this.$editFormOrganism.phpIniList.values
        .filter((phpOption) => phpOption.key === optionName && phpOption.deletable === true);

        return deletableOption.length !== 0;
    };

    public isHiddenOption = (phpOption, editMode = false) => {
        const isHidenOption = this.hiddenOptions.indexOf(phpOption) !== -1;
        if (!editMode && isHidenOption) {
            return !this.userGrantedAdminRead;
        }
        return isHidenOption;
    };

    public resetPhpAdditionalOptions = () => {
        const newPhpAdditionalOptions: any[] = [];

        for (const option of this.$editFormOrganism.additionalPhpOptionsList) {
            if (
                !option.markedForRemoval
                || option.markedForRemoval == null
                || option.markedForRemoval === undefined
            ) {
                option.value = this.getPhpOptionDefaultValue(option.key);
                newPhpAdditionalOptions.push(option);
            }
        }

        this.$editFormOrganism.additionalPhpOptionsList = newPhpAdditionalOptions;
    };

    public resetPhpDefaultOptions = () => {
        if ([undefined].indexOf(this.phpIniDefaultSettings) >= 0) {
            return;
        }

        this.$editFormOrganism.basicPhpOptionsList.forEach(
            (phpIniValue, index, phpOptionsList) => this.setPhpIniDefaultValue(phpIniValue, index, phpOptionsList)
        );

        this.$editFormOrganism.advancedPhpOptionsList.forEach(
            (phpIniValue, index, phpOptionsList) => this.setPhpIniDefaultValue(phpIniValue, index, phpOptionsList)
        );
    };

    public phpVersionChanged = () => {
        this.$timeout(
            () => {
                /**
                 * Set phpVersion from private version cache (the original phpversion).
                 * The phpVersion in the vhost object has already been updated at this
                 * point by the dropdown selection
                 */
                let newPhpVersion: string;
                const vhost = ng.copy(this.$editFormOrganism.vHost);
                if (vhost.phpVersion !== this._origPhpVersion) {
                    newPhpVersion = ng.copy(vhost.phpVersion);
                    vhost.phpVersion = this._origPhpVersion;
                }

                this.vhostModel.vhostNormalize(
                    vhost,
                    this.$editFormOrganism.phpIniList,
                    newPhpVersion
                ).then((res) => {
                    this.$editFormOrganism.vHost = res.response.vhost;
                    this.$editFormOrganism.phpIniList = res.response.phpIni;
                    this.setPhpOptions();
                });

                this._freeOfChargeCheck(newPhpVersion);
            }
        );
    };

    private _freeOfChargeCheck = (targetPHPVersion: string): void => {
        this.legacyProductPriceAccepted = false;
        this.changeIsFreeOfCharge = true;
        this.$editFormOrganism.legacyPHPChargeableProductCode = '';
        const freeOfChargeVersions = this.webspace.phpVersions.concat(this.addonPHPVersions);

        if (!freeOfChargeVersions.includes(targetPHPVersion)) {
            this.changeIsFreeOfCharge = false;
            let chargeableProductCode = this.$editFormOrganism.phpVersions.filter((phpVersion) => phpVersion.version === targetPHPVersion)[0].legacyAddon;

            if (this.userGrantedPricesList) {
                chargeableProductCode = chargeableProductCode.replace('##BILLING_CYCLE##', String(this.webspaceBillingCycle));
                this.chargeableProductName = this.phpAddonProducts.filter((product) => product.productCode === chargeableProductCode)[0].name;
            }

            this.$editFormOrganism.legacyPHPChargeableProductCode = chargeableProductCode;
        }

        this.setValidationStatus();
    };

    public getPhpOptionValue = (option: any, metaData: any) => {
        if (option != null && option !== undefined) {
            return option.value;
        } else {
            return metaData.defaultValue;
        }
    };

    public getPhpOptionDefaultValue = (optionName: string) => {
        for (const option of this.$editFormOrganism.phpIniList.values) {
            if (option.key === optionName) {
                return option.metadata.defaultValue;
            }
        }
        for (const metaData of this.$editFormOrganism.phpConfigurationMetaData) {
            if (metaData.key === optionName) {
                return metaData.defaultValue;
            }
        }
    };

    public createPhpOptionsList = (names: string[], optionsList: any[]) => {
        for (const name of names) {
            const option = this.findPhpOption(name, this.$editFormOrganism.phpIniList.values);
            const metaData = this.findPhpOption(name, this.$editFormOrganism.phpConfigurationMetaData);

            switch (this.getPhpOptionType(option, metaData)) {
                case 'bool':
                    optionsList.push(this.getPhpOptionBool(option, metaData));
                    break;

                case 'dropdown':
                    optionsList.push(this.getPhpOptionDropdown(option, metaData));
                    break;

                case 'input':
                    optionsList.push(this.getPhpOptionInput(option, metaData));
                    break;

                case 'readonly':
                    optionsList.push(this.getPhpOptionReadonly(option));
                    break;
            }
        }
    };

    public phpVersionCompatible = (versions: string[]) => {
        return versions.indexOf(this.$editFormOrganism.vHost.phpVersion) >= 0;
    };

    public addAdditionalPhpOption = (option: any) => {
        this.additionalPhpOptionsDropdownItems = this.additionalPhpOptionsDropdownItems
        .filter((phpOption) => phpOption.name !== option.name);
    };

    public toggleMarkedForRemoval = (option: any) => {
        if (option.markedForRemoval == null || option.markedForRemoval === undefined) {
            option.markedForRemoval = true;
        } else {
            option.markedForRemoval = !option.markedForRemoval;
        }
    };

    public applyProfile = (profileName: string) => {
        if (profileName !== undefined && profileName != null && profileName !== '') {
            let profile = null;

            for (const p of this.$editFormOrganism.profiles) {
                if (p.profileId === profileName) {
                    profile = p;
                }
            }

            if (profile == null) {
                return;
            }

            for (const option of profile.phpIni) {
                let found = false;
                let i = this.findPhpOptionIndex(option.key, this.$editFormOrganism.basicPhpOptionsList);

                if (i != null) {
                    if (option.metadata.type === 'bool') {
                        this.$editFormOrganism.basicPhpOptionsList[i].value = JSON.parse(option.value);
                    } else {
                        this.$editFormOrganism.basicPhpOptionsList[i].value = option.value;
                    }

                    found = true;
                }

                i = this.findPhpOptionIndex(option.key, this.$editFormOrganism.advancedPhpOptionsList);

                if (i != null) {
                    if (option.metadata.type === 'bool') {
                        this.$editFormOrganism.advancedPhpOptionsList[i].value = JSON.parse(option.value);
                    } else {
                        this.$editFormOrganism.advancedPhpOptionsList[i].value = option.value;
                    }

                    found = true;
                }

                i = this.findPhpOptionIndex(option.key, this.$editFormOrganism.additionalPhpOptionsList);

                if (i !== null) {
                    if (option.metadata.type === 'bool') {
                        this.$editFormOrganism.additionalPhpOptionsList[i].value = JSON.parse(option.value);
                    } else {
                        this.$editFormOrganism.additionalPhpOptionsList[i].value = option.value;
                    }

                    this.$editFormOrganism.additionalPhpOptionsList[i].markedForRemoval = false;
                    found = true;
                }

                if (!found) {
                    i = null;
                    i = this.findPhpOptionIndex(option.key, this.$editFormOrganism.phpIniList.values);

                    if (i == null) {
                        this.$editFormOrganism.phpIniList.values.push(option);
                    } else {
                        this.$editFormOrganism.phpIniList.values[i].value = option.value;
                    }

                    this.createAdditionalPhpOptionsList();
                }
            }
        }
    };

    public get locationSetByProfile() {
        if (this.$editFormOrganism.defaultLocation === undefined) {
            return false;
        }
        return this.$editFormOrganism.defaultLocation.setByProfile;
    }

    public get phpDefaultEnabled() {
        if (this.locationSetByProfile) {
            return true;
        }
        return this.$editFormOrganism.defaultLocation.phpEnabled;
    }

    public set phpDefaultEnabled(val: boolean) {
        this.$editFormOrganism.defaultLocation.phpEnabled = val;
    }

    public get phpEnabled() {
        if (this.phpDefaultEnabled) {
            this.showPhpEnableHint = false;
            return true;
        }

        const phpEnabled = this.$editFormOrganism.vHost.locations.some(
            (location) => location.phpEnabled && location.matchType !== 'default'
        );

        this.showPhpEnableHint = phpEnabled;

        return phpEnabled;
    }

    public outerCancel = () => {
        this.$editFormOrganism.vHost = this._origVhost;
        this.$editFormOrganism.phpIniList = this._origPhpInit;
    };

    public cancel = () => {
        this.legacyProductPriceAccepted = false;
        this.changeIsFreeOfCharge = true;
        this.$editFormOrganism.legacyPHPChargeableProductCode = '';
        this.$editFormOrganism.additionalPhpOptionsList = ng.copy(this.initalEditFormAdditionalPhpOptions);
        this.additionalPhpOptionsDropdownItems = ng.copy(this.initalAdditionalPhpOptionsDropdownItems);
        this.showExpertSettings = ng.copy(this.userSettingShowExpertSettings);
        this.showPhpVersionChangedUserNotice = false;

        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };

    private filterAvailablePhpOptions = (setPhpOptions = [], blacklist = []) => {
        const selectedPhpVersion = this.$editFormOrganism.vHost.phpVersion;
        const webspaceIsInPool = [null, undefined, '', [], {}].indexOf(this.$editFormOrganism.webspace.poolId) === -1;
        const availablePhpOptions = [];

        this.$editFormOrganism.phpConfigurationMetaData.map(
            (phpOption) => {
                let isVisibleAdvancedPhpOption = false;

                switch (true) {
                    case (phpOption.accessLevels.indexOf('user') !== -1):
                        isVisibleAdvancedPhpOption = true;
                        break;

                    case (phpOption.accessLevels.indexOf('poolOwner') !== -1):
                        isVisibleAdvancedPhpOption = this.userGrantedAdminRead || webspaceIsInPool;

                        if (!webspaceIsInPool) {
                            this.addToPhpReadOnlyOptionsList(phpOption.key);
                        }

                        break;

                    case (phpOption.accessLevels.indexOf('user') === -1):
                        isVisibleAdvancedPhpOption = this.userGrantedAdminRead;
                        this.addToPhpReadOnlyOptionsList(phpOption.key);

                        break;

                    default:
                        isVisibleAdvancedPhpOption = false;

                        break;
                }

                if (phpOption.phpVersions.indexOf(selectedPhpVersion) === -1) {
                    isVisibleAdvancedPhpOption = false;
                }

                if (blacklist.indexOf(phpOption.key) >= 0) {
                    isVisibleAdvancedPhpOption = false;
                }

                if (setPhpOptions.indexOf(phpOption.key) === -1) {
                    isVisibleAdvancedPhpOption = false;
                }

                if (isVisibleAdvancedPhpOption) {
                    availablePhpOptions.push(phpOption.key);
                }
            }
        );

        setPhpOptions.map(
            (phpOption) => {
                if (
                    availablePhpOptions.indexOf(phpOption) === -1
                    && blacklist.indexOf(phpOption) === -1
                    && blacklist.indexOf(this.basicPhpOptionNames) === -1
                ) {
                    this.addToPhpReadOnlyOptionsList(phpOption);
                    availablePhpOptions.push(phpOption);
                }
            }
        );

        return availablePhpOptions;
    };

    private setAvailablePhpVersions = () => {
        let phpVersions: any[] = this.$editFormOrganism.phpVersions.map(
            (version) => parseFloat(version.version)
        );

        phpVersions.sort();

        phpVersions = phpVersions.map(
            (version) => version.toFixed(1)
        );

        this.availablePhpVersions = phpVersions;

        if (this.availablePhpVersions.indexOf(this.$editFormOrganism.vHost.phpVersion) < 0) {
            this.availablePhpVersions.unshift(this.$editFormOrganism.vHost.phpVersion);
        }
    };

    private addToPhpReadOnlyOptionsList = (phpOption) => {
        if ((this.readOnlyOptions.indexOf(phpOption) === -1)) {
            if (!this.userGrantedSuperAdmin) {
                this.readOnlyOptions.push(phpOption);
            }

            if (!this.userGrantedAdminRead) {
                this.hiddenOptions.push(phpOption);
            }
        }
    };

    private createAdditionalPhpOptionsList = () => {
        const additionalPhpOptionNames: string[] = [];
        this.additionalPhpOptions = [];

        for (const option of this.$editFormOrganism.phpIniList.values) {
            if (
                this.basicPhpOptionNames.indexOf(option.key) < 0
                && this.advancedPhpOptionNames.indexOf(option.key) < 0
                && additionalPhpOptionNames.indexOf(option.key) < 0
            ) {
                additionalPhpOptionNames.push(option.key);
            }
        }

        for (const option of this.$editFormOrganism.phpConfigurationMetaData) {
             if (
                 this.basicPhpOptionNames.indexOf(option.key) < 0
                && this.advancedPhpOptionNames.indexOf(option.key) < 0
                && additionalPhpOptionNames.indexOf(option.key) < 0
            ) {
                additionalPhpOptionNames.push(option.key);
            }
        }

        this.createPhpOptionsList(additionalPhpOptionNames, this.additionalPhpOptions);
        this.additionalPhpOptionsDropdownItems = [];

        for (const option of this.additionalPhpOptions) {
            if (
                this.$editFormOrganism.additionalPhpOptionsList.filter(
                    (selectedOption) => selectedOption.key === option.key
                ).length === 0
                && this.hiddenOptions.indexOf(option.name) === -1
                && option.phpVersions.indexOf(this.$editFormOrganism.vHost.phpVersion) >= 0
            ) {
                this.additionalPhpOptionsDropdownItems.push({
                    name: option.name,
                    value: JSON.stringify(option)
                });
            }
        }

        this.initalAdditionalPhpOptionsDropdownItems = ng.copy(this.additionalPhpOptionsDropdownItems);
    };

    private setPhpIniDefaultValue = (phpIniValue: WebhostingApi.PhpIniValue, index, phpOptionsList) => {
        const optionDefault = this.phpIniDefaultSettings.filter(
            (singlePhpOption) => {
                return singlePhpOption.key === phpIniValue.key;
            }
        )[0];

        const metadata = this.$editFormOrganism.phpConfigurationMetaData
        .filter((meta: WebhostingApi.PhpConfigurationMetadata) => meta.key === phpIniValue.key)[0];

        if ([undefined, null].indexOf(metadata) < 0 && !metadata.resetOnPhpVersionChange) {
            return {};
        }

        if ([undefined, null].indexOf(optionDefault) < 0) {
            let newValue: boolean|string;

            switch (optionDefault.value) {
                case 'true':
                    newValue = true;
                    break;
                case 'false':
                    newValue = false;
                    break;
                default:
                    newValue = optionDefault.value;
                    break;
            }

            phpOptionsList[index].value = newValue;
        }

        return {};
    };

    private findPhpOption = (name: string, optionList: any[], getMetadata?: boolean) => {
        getMetadata = getMetadata || false;
        for (const option of optionList) {
            if (option.key === name) {
                return getMetadata && option.metadata !== undefined
                    ? option.metadata
                    : option;
            }
        }

        return null;
    };

    private findPhpOptionIndex = (name: string, optionList: any[]) => {
        for (const i in optionList) {
            if (optionList[i].key === name) {
                return i;
            }
        }

        return null;
    };

    private getPhpOptionType = (option: any, metaData: any) => {
        let useMetaData: any;

        if (option == null || option === undefined) {
            useMetaData = metaData;
        } else {
            if (option.immutable && (!this.userGrantedSuperAdmin)) {
                return 'readonly';
            }

            useMetaData = option.metadata;
        }

        if (useMetaData.type === 'bool') {
            return 'bool';
        }

        if (useMetaData.availableValues == null || useMetaData.availableValues === undefined) {
            return 'input';
        } else {
            return 'dropdown';
        }
    };

    private getPhpOptionBool = (option: any, metaData: any) => {
        let useMetaData;

        if (option != null && option !== undefined) {
            useMetaData = option.metadata;
        } else {
            useMetaData = metaData;
        }

        return {
            dataType: 'bool',
            formType: 'bool',
            key: useMetaData.key,
            markedForRemoval: false,
            name: useMetaData.key,
            phpVersions: useMetaData.phpVersions,
            value: JSON.parse(this.getPhpOptionValue(option, metaData))
        };
    };

    private getPhpOptionDropdown = (option: any, metaData: any) => {
        let useMetaData;

        if (option != null && option !== undefined) {
            useMetaData = option.metadata;
        } else {
            useMetaData = metaData;
        }

        return {
            dataType: useMetaData.type,
            dropdownItems: this.getPhpDropdownItems(option, metaData),
            formType: 'dropdown',
            key: useMetaData.key,
            markedForRemoval: false,
            name: useMetaData.key,
            phpVersions: useMetaData.phpVersions,
            value: this.getPhpOptionValue(option, metaData)
        };
    };

    private getPhpOptionInput = (option: any, metaData: any) => {
        let useMetaData;

        if (option != null && option !== undefined) {
            useMetaData = option.metadata;
        } else {
            useMetaData = metaData;
        }

        let dataType;
        let validationOptions;

        if (useMetaData.type === 'int') {
            dataType = 'number';
            validationOptions = {
                maximum: useMetaData.max,
                minimum: useMetaData.min
            };
        } else {
            dataType = 'notEmpty';
        }

        return {
            dataType: dataType,
            formType: 'input',
            key: useMetaData.key,
            markedForRemoval: false,
            max: useMetaData.max,
            min: useMetaData.min,
            name: useMetaData.key,
            phpVersions: useMetaData.phpVersions,
            validationOptions: validationOptions,
            value: this.getPhpOptionValue(option, metaData)
        };
    };

    private getPhpOptionReadonly = (option: any) => {
        return {
            dataType: 'string',
            formType: 'readonly',
            key: option.key,
            markedForRemoval: false,
            name: option.key,
            phpVersions: option.metadata ? option.metadata.phpVersions : [],
            value: this.getPhpOptionValue(option, null)
        };
    };

    private getPhpDropdownItems = (option: any, metaData: any) => {
        let items;

        if (option != null && option !== undefined) {
            items = option.metadata.availableValues;
        } else {
            items = metaData.availableValues;
        }

        const dropdownItems: any[] = [];

        for (const item of items) {
            dropdownItems.push({
                name: item,
                value: item
            });
        }

        return dropdownItems;
    };
}

export class OrganEditPanelDomainPhpOptionsComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<cancel',
        panelRight: '<',
        webspace: '<',
        changesAffectPayment: '<paid'
    };
    public require = {
        $editFormOrganism: '^organismEditFormDomain',
        $editForm: '^moleculeFormEdit'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./domain-php-options.html');
    public controller = OrganEditPanelDomainPhpOptionsController;
}
