import * as ng from 'angular';
import { ViewTypes } from '../../../../../../types/view-types';

export class OrganSslWizardProductConfigCertificateContactsController implements ng.IController {
    public static $inject: string[] = [
        '$translate'
    ];

    // check if all required InputField properties are filled out
    public static filledOut = (contact: ViewTypes.SslContact<any>) => {
        // tslint:disable-next-line: forin
        for (const fieldKey in contact.fields) {
            const inputField: ViewTypes.InputField = contact.fields[fieldKey];

            if (inputField && inputField.required === true && [undefined, null, ''].indexOf(inputField.value) >= 0) {
                return false;
            }
        }
        return true;
    };

    public contacts: ViewTypes.ContactContainer;
    public metaData: ViewTypes.ProductConfigSslObject;
    public salutationsDropdown: { name: string; value: string }[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.contacts = {
            administrationContact: {
                fields: {
                    emailAddress: { value: '', required: true },
                    firstName: { value: '', required: true },
                    lastName: { value: '', required: true },
                    phoneNumber: { value: '', required: true },
                    title: { value: '', required: false }
                },
                filledOut: () => false
            },
            technicalContact: {
                fields: {
                    emailAddress: { value: '', required: true },
                    firstName: { value: '', required: true },
                    lastName: { value: '', required: true },
                    phoneNumber: { value: '', required: true },
                    title: { value: '', required: false }
                },
                flags: { checkFilledOut: false },
                filledOut: () => false
            },
            organizationContact: {
                fields: {
                    organizationName: { value: '', required: true },
                    addressLine1: { value: '', required: true },
                    addressLine2: { value: '', required: false },
                    postalCode: { value: '', required: true },
                    city: { value: '', required: true },
                    region: { value: '', required: true },
                    country: { value: '', required: true },
                    phoneNumber: { value: '', required: true },
                    faxNumber: { value: '', required: false }
                },
                filledOut: () => false
            }
        };

        if (this.metaData.sslProductFamily === 'dv-certificates') {
            this.contacts.administrationContact.show = true;
            this.contacts.technicalContact.show = true;
        } else if (this.metaData.sslProductFamily === 'ov-certificates') {
            this.contacts.organizationContact.show = true;
            this.contacts.administrationContact.show = true;
            this.contacts.technicalContact.show = true;
        } else if (this.metaData.sslProductFamily === 'ev-certificates') {
            this.contacts.organizationContact.show = true;
            this.contacts.administrationContact.show = true;
            this.contacts.technicalContact.show = true;
            this.contacts.administrationContact.fields.title.required = true;
            this.contacts.technicalContact.fields.title.required = true;
        }

        this.fillOutFormAdmin();
        this.fillOutFormTechical();
        this.fillOutFormOrganisation();

        this.salutationsDropdown = [
            {
                name: this.$translate.instant('TR_110119-5eaaa9_TR'),
                value: this.$translate.instant('TR_110119-5eaaa9_TR')
            },
            {
                name: this.$translate.instant('TR_110119-f53bc7_TR'),
                value: this.$translate.instant('TR_110119-f53bc7_TR')
            },
            {
                name: this.$translate.instant('TR_110119-1ab706_TR'),
                value: this.$translate.instant('TR_110119-1ab706_TR')
            }
        ];
    }

    public fillOutFormAdmin = () => {
        const contactName = this.metaData.account.name.split(' ');
        this.contacts.administrationContact.fields.title.value = this.metaData.account.title;
        this.contacts.administrationContact.fields.firstName.value = contactName[0] || this.$translate.instant('GENERAL.OBJECT.STATUS.UNKNOWN');
        this.contacts.administrationContact.fields.lastName.value = contactName[1] || this.$translate.instant('GENERAL.OBJECT.STATUS.UNKNOWN');
        this.contacts.administrationContact.fields.emailAddress.value = this.metaData.account.emailAddress;
        this.contacts.administrationContact.fields.phoneNumber.value = this.metaData.account.phoneNumber;
    };

    public fillOutFormTechical = () => {
        const contactName = this.metaData.account.name.split(' ');
        this.contacts.technicalContact.fields.title.value = this.metaData.account.title;
        this.contacts.technicalContact.fields.firstName.value = contactName[0] || this.$translate.instant('GENERAL.OBJECT.STATUS.UNKNOWN');
        this.contacts.technicalContact.fields.lastName.value = contactName[1] || this.$translate.instant('GENERAL.OBJECT.STATUS.UNKNOWN');
        this.contacts.technicalContact.fields.emailAddress.value = this.metaData.account.emailAddress;
        this.contacts.technicalContact.fields.phoneNumber.value = this.metaData.account.phoneNumber;
    };

    public fillOutFormOrganisation = () => {
        // tslint:disable:max-line-length
        this.contacts.organizationContact.fields.phoneNumber.value = this.metaData.account.phoneNumber;
        this.contacts.organizationContact.fields.region.value = this.metaData.sslValidationObject.apiResponse.csr.state;
        this.contacts.organizationContact.fields.country.value = this.metaData.sslValidationObject.apiResponse.csr.country;
        this.contacts.organizationContact.fields.organizationName.value = this.metaData.sslValidationObject.apiResponse.csr.organization;
        this.contacts.organizationContact.fields.city.value = this.metaData.sslValidationObject.apiResponse.csr.locality;
        // tslint:enable:max-line-length
    };

}

export class OrganSslWizardProductConfigCertificateContactsComponent implements ng.IComponentOptions {
    public bindings = {
        contacts: '=',
        metaData: '<'
    };
    public template = require('./config-certificate-contacts.html');
    public controller = OrganSslWizardProductConfigCertificateContactsController;
}
