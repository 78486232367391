import * as ng from 'angular';

export class MoleculeButtonAddController implements ng.IController {
    public static $inject: string[] = [];
    public callbackParam: any;

    public add: (index?: number) => void;
    public disabled: boolean;

    public triggerAddCallback = () => {
        if (this.callbackParam !== undefined) {
            this.add(this.callbackParam);
        } else {
            this.add();
        }
    };
}

export class MoleculeButtonAddComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        add: '<',
        callbackParam: '<?',
        disabled: '<'
    };
    public controller =  MoleculeButtonAddController;
    public template = require('./button-add.html');
}
