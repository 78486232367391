import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import { AccountModelService } from '../../../../../services/account';
import { AuthContextService } from '../../../../../services/auth-context';
import { DepositModelService } from '../../../../../services/billing/deposit-model';

export class MoleculePanelSepaInfoController implements ng.IController {
    public static $inject: string[] = ['accountModel', 'depositModel'];

    private isPrepaid: boolean;
    private userProfileIsIncomplete = false;
    private readonly incompleteProfileFlagKey = 'ShowCompleteAccountDataPanel';

    constructor(
        private accountModel: AccountModelService,
        private depositModel: DepositModelService
    ) {
        if (
            !AuthContextService.isRootOrCompanyAccount
            && AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)
        ) {
            void this.depositModel.getDeposit()
            .then((res) => {
                this.isPrepaid = res.paymentType === 'prepaid';
            });
        }
    }

    public $onInit(): void {
        void this.accountModel.getOwnUserFlags(this.incompleteProfileFlagKey).then(
            (response) => {
                this.userProfileIsIncomplete = (response === 'true')
                    && !AuthContextService.isCurrentUserAccountComplete;
            }
        );
    }

    public get showSepaPanel(): boolean {
        return this.isPrepaid && !this.userProfileIsIncomplete;
    }
}

export class MoleculePanelSepaInfoComponent implements ng.IComponentOptions {
    public controller =  MoleculePanelSepaInfoController;
    public template = require('./panel-sepa-info.html');
}
