import {
    RequestStatus,
    RpcClient,
    RpcClientService
} from '../rpc-client';

import * as Types from '../../types';

export const ManagedApplicationClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory(
        '/api/managedapplication/v1/json/',
        'managedapplication'
    )
];

export class ManagedApplicationRobotService {
    public static $inject: string[] = ['managedApplicationClient'];

    constructor(private managedApplicationClient: RpcClient) {}

    public nextcloudAdminAccessEnable = (
        nextcloud: Types.ManagedApplicationApi.Nextcloud,
        duration: number = 3600,
        impersonate = false,
        email: boolean|string = false
    ) => {
        let payload = {};
        if (email) {
            payload = {
                nextcloudId: nextcloud.id,
                duration: duration,
                emailAddress: email,
                enableImpersonate: impersonate
            };
        } else {
            payload = {
                nextcloudId: nextcloud.id,
                duration: duration,
                enableImpersonate: impersonate
            };
        }
        return this.managedApplicationClient.request('prvNextcloudGrantAdminAccess')
            .execute(payload);
    };

    public nextcloudAdminAccessDisable = (nextcloud: Types.ManagedApplicationApi.Nextcloud) => {
        return this.managedApplicationClient.request('prvNextcloudRevokeAdminAccess')
            .execute({ nextcloudId: nextcloud.id });
    };

    public nextcloudsList = (filter: Types.Finding.Filter, limit: number, page: number, sort?: Types.Finding.SortOptions, timeout?: number, owner?: string) => {
        return this.managedApplicationClient.request('nextcloudsFind')
            .useCache()
            .cacheTimeout(1000 * 60 * 15)
            .timeout(timeout)
            .ownerAccountId(owner)
            .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public nextcloudsListWithoutPagination = (
        filter: Types.Finding.Filter, limit: number, page: number, sort?: Types.Finding.SortOptions, timeout?: number, owner?: string, cacheTimeout?: number
    ) => {
        cacheTimeout = cacheTimeout || (1000 * 60 * 15);
        return this.managedApplicationClient.request('nextcloudsFind')
            .useCache()
            .cacheTimeout(cacheTimeout)
            .skipPagination(true)
            .timeout(timeout)
            .ownerAccountId(owner)
            .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public nextcloudCreate = (
        nextcloud: Types.ManagedApplicationApi.Nextcloud,
        users: Types.ManagedApplicationApi.NextcloudUserWithPassword[],
        appConfigs: Types.ManagedApplicationApi.NextcloudAppConfigInput[],
        voucherCode?: string
    ) /* : q.IPromise<Types.ManagedApplicationApi.Nextcloud> */ => {
        const data = this._nextcloudAppConstraints(nextcloud, users, appConfigs, voucherCode);
        return this.managedApplicationClient.request('nextcloudCreate')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute(
                data
            );
    };

    public nextcloudUpdate = (
        nextcloud: Types.ManagedApplicationApi.Nextcloud,
        appConfigs?: Types.ManagedApplicationApi.NextcloudAppConfigInput[],
        nextcloudConfiguration?: string
    ) /* : q.IPromise<Types.ManagedApplicationApi.Nextcloud> */ => {
        const data = this._nextcloudAppConstraints(nextcloud, undefined, appConfigs, undefined, nextcloudConfiguration);
        return this.managedApplicationClient.request('nextcloudUpdate')
            .bustCache('managedapplication', 'nextcloudUpdate')
            .bustCache('managedapplication', 'nextcloudsFind')
            .bustCache('managedapplication', 'nextcloudAppGetConfiguration')
            .bustCache('managedapplication', 'nextcloudAppsList')
            .execute(data);
    };

    private _nextcloudAppConstraints = (
        nextcloud: Types.ManagedApplicationApi.Nextcloud,
        users: Types.ManagedApplicationApi.NextcloudUserWithPassword[],
        appConfigs?: Types.ManagedApplicationApi.NextcloudAppConfigInput[],
        voucherCode?: string,
        nextcloudConfiguration?: string
    ) => {
        return {
            nextcloud: nextcloud,
            users: users,
            appConfigs: appConfigs,
            voucherCode: voucherCode,
            nextcloudConfiguration: nextcloudConfiguration
        };
    };

    public nextcloudDelete = (
        nextcloudId: string,
        execDate: string = '',
        cancellationReason: string
    ) /* : q.IPromise<void> */ => {
        return this.managedApplicationClient.request('nextcloudDelete')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute(
                {
                    execDate: execDate,
                    nextcloudId: nextcloudId,
                    cancellationReason: cancellationReason
                }
            );
    };

    public nextcloudDeletionCancel = (
        nextcloudId: string
    ) /* : q.IPromise<void> */ => {
        return this.managedApplicationClient.request('nextcloudDeletionCancel')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute({ nextcloudId: nextcloudId });
    };

    public nextcloudRestore = (
        nextcloudId: string
    ) /* : q.IPromise<Types.ManagedApplicationApi.Nextcloud> */ => {
        return this.managedApplicationClient.request('nextcloudRestore')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute({ nextcloudId: nextcloudId });
    };

    public nextcloudPurgeRestorable = (
        nextcloudId: string
    ) /* : q.IPromise<void> */ => {
        return this.managedApplicationClient.request('nextcloudPurgeRestorable')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute({ nextcloudId: nextcloudId });
    };

    public nextcloudChangeProduct = (
        nextcloudId: string,
        newProductCode: string,
        enableBackup: boolean
    ) /* : q.IPromise<Types.ManagedApplicationApi.Nextcloud> */ => {
        return this.managedApplicationClient.request('nextcloudChangeProduct')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute(
                {
                    enableBackup: enableBackup,
                    newProductCode: newProductCode,
                    nextcloudId: nextcloudId
                }
            );
    };

    public nextcloudBookAdditionalTraffic = (
        nextcloudId: string,
        productCode: string
    ) /* : q.IPromise<Types.ManagedApplicationApi.Nextcloud> */ => {
        return this.managedApplicationClient.request('nextcloudBookAdditionalTraffic')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute(
                {
                    nextcloudId: nextcloudId,
                    productCode: productCode
                }
            );
    };

    public nextcloudSetupIndividualDomainName = (
        nextcloudId: string,
        domainName: string,
        autoAdjustDnsRecords: boolean,
        replaceExistingVHost?: boolean
    ) /* : q.IPromise<Types.ManagedApplicationApi.Nextcloud> */ => {
        return this.managedApplicationClient.request('nextcloudSetupIndividualDomainName')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute(
                {
                    autoAdjustDnsRecords: autoAdjustDnsRecords,
                    domainName: domainName,
                    nextcloudId: nextcloudId,
                    replaceExistingVHost: replaceExistingVHost
                }
            );
    };

    public nextcloudRemoveIndividualDomainName = (
        nextcloudId: string
    ) /* : q.IPromise<Types.ManagedApplicationApi.Nextcloud> */ => {
        return this.managedApplicationClient.request('nextcloudRemoveIndividualDomainName')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute(
                {
                    nextcloudId: nextcloudId
                }
            );
    };

    public nextcloudCheckIndividualDomainNameStatus = (
        nextcloudId: string,
        domainName: string
    ) /* : q.IPromise<Types.ManagedApplicationApi.Nextcloud> */ => {
        return this.managedApplicationClient.request('nextcloudCheckIndividualDomainNameStatus')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute(
                {
                    domainName: domainName,
                    nextcloudId: nextcloudId
                }
            );
    };

    public nextcloudModifyUsers = (
        nextcloudId: string,
        usersToAdd: Types.ManagedApplicationApi.NextcloudUserWithPassword[] = null,
        usersToUpdate: Types.ManagedApplicationApi.NextcloudUserWithPassword[] = null,
        userDeleteData: Types.ManagedApplicationApi.NextcloudUserToDelete[] = null,
        groupsToDelete: string[] = null
    ) /* : q.IPromise<void> */ => {
        return this.managedApplicationClient.request('nextcloudModifyUsers')
            .bustCache('managedapplication', 'nextcloudUsersFind')
            .execute(
                {
                    nextcloudId: nextcloudId,
                    userDeleteData: userDeleteData,
                    usersToAdd: usersToAdd,
                    usersToUpdate: usersToUpdate,
                    groupsToDelete: groupsToDelete
                }
            );
    };

    public nextcloudModifyGroupFolders = (
        nextcloudId: string,
        nextcloudGroupfolder: any[] = null,
        groupFoldersToDelete: string[] = null
    ) /* : q.IPromise<void> */ => {
        return this.managedApplicationClient.request('nextcloudModifyGroupFolders')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute(
                {
                    nextcloudId: nextcloudId,
                    groupFolders: nextcloudGroupfolder,
                    groupFoldersToDelete: groupFoldersToDelete
                }
            );
    };

    public nextcloudModifyRestrictions = (
        nextcloudId: string,
        restrictionsToAdd: Types.keenRobotsCommon.Restriction[] = null,
        restrictionsToUpdate: Types.keenRobotsCommon.Restriction[] = null,
        restrictionsToDelete: Types.keenRobotsCommon.Restriction[] = null,
        createEvent: boolean = false
    ) /* : q.IPromise<void> */ => {
        return this.managedApplicationClient.request('nextcloudModifyRestrictions')
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute(
                {
                    createEvent: createEvent,
                    nextcloudId: nextcloudId,
                    restrictionsToAdd: restrictionsToAdd,
                    restrictionsToDelete: restrictionsToDelete,
                    restrictionsToUpdate: restrictionsToUpdate
                }
            );
    };

    public nextcloudUserCreate = (
        user: Types.ManagedApplicationApi.NextcloudUser,
        password: string
    ) /* : q.IPromise<Types.ManagedApplicationApi.NextcloudUser> */ => {
        return this.managedApplicationClient.request('nextcloudUserCreate')
            .bustCache('managedapplication', 'nextcloudUsersFind')
            .execute(
                {
                    password: password,
                    user: user
                }
            );
    };

    public nextcloudUserUpdate = (
        user: Types.ManagedApplicationApi.NextcloudUser,
        password: string = ''
    ) /* : q.IPromise<Types.ManagedApplicationApi.NextcloudUser> */ => {
        return this.managedApplicationClient.request('nextcloudUserUpdate')
            .bustCache('managedapplication', 'nextcloudUsersFind')
            .execute(
                {
                    password: password,
                    user: user
                }
            );
    };

    public nextcloudUserDelete = (
        userId: string
    ) /* : q.IPromise<void> */ => {
        return this.managedApplicationClient.request('nextcloudUserDelete')
            .bustCache('managedapplication', 'nextcloudUsersFind')
            .execute({ userId: userId });
    };

    public nextcloudUserCheckPassword = (
        password: string
    ) /* : q.IPromise<boolean> */ => {
        return this.managedApplicationClient.request('nextcloudUserCheckPassword')
            .execute({ password: password });
    };

    public nextcloudGroupsFind = (
        filter: Types.Finding.Filter = null,
        limit: number = 25,
        page: number = 1,
        sort: Types.Finding.SortOptions = null,
        skipPagination: boolean = false
    ) => {
        return this.managedApplicationClient.request('nextcloudGroupsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .skipPagination(skipPagination)
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public nextcloudGroupUserMatrixGet = (
        nextcloudId: string
    ) => {
        return this.managedApplicationClient
            .request('nextcloudGroupUserMatrixGet')
            .useCache()
            .cacheTimeout(1000 * 60 * 15)
            .execute(
                {
                    nextcloudId: nextcloudId
                }
            );
    };

    /* Does this still exist? It's not in the current keenfiles... */
    public nextcloudGroupDelete = (
        name: string
    ) /* : q.IPromise<void> */ => {
        return this.managedApplicationClient.request('nextcloudGroupDelete')
            .bustCache('managedapplication', 'nextcloudUsersFind')
            .execute({ name: name });
    };

    public jobsFind = (
        filter: Types.Finding.Filter = null,
        limit: number = 25,
        page: number = 1,
        sort: Types.Finding.SortOptions = null,
        skipPagination: boolean = false,
        cacheTimeout?: number
    ) /* : q.IPromise<Types.ManagedApplicationApi.FindJobsResult> */ => {
        cacheTimeout = cacheTimeout || (1000 * 60 * 15);
        return this.managedApplicationClient.request('jobsFind')
            .skipPagination(skipPagination)
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public nextcloudUsersFind = (
        filter: Types.Finding.Filter = null,
        limit: number = 25,
        page: number = 1,
        sort: Types.Finding.SortOptions = null,
        skipPagination: boolean = false
    ) /* : q.IPromise<Types.ManagedApplicationApi.FindJobsResult> */ => {
        return this.managedApplicationClient.request('nextcloudUsersFind')
            .useCache()
            .cacheTimeout(1000 * 60 * 15)
            .skipPagination(skipPagination)
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public nextcloudGroupFoldersList = (nextcloudId: string) => {
        return this.managedApplicationClient.request('nextcloudGroupFoldersList')
            .useCache()
            .cacheTimeout(1000 * 60 * 15)
            .execute(
                {
                    nextcloudId: nextcloudId
                }
            );
    };

    // this method is used by the restriction helper don't remove it!
    public nextcloudModifyRestrictionsRaw = (apiObject: any, owner: string) => {
        return this.managedApplicationClient.request('nextcloudModifyRestrictions')
            .ownerAccountId(owner)
            .bustCache('managedapplication', 'nextcloudsFind')
            .execute(apiObject);
    };

    public nextcloudAppsList = (nextcloudId: string, language?: string) => {
        return this.managedApplicationClient.request('nextcloudAppsList')
            .execute({
                nextcloudId: nextcloudId,
                language: language
            });
    };

    public nextcloudAppGetConfiguration = (appId: string, nextcloudId?: string, language?: string) => {
        return this.managedApplicationClient.request('nextcloudAppGetConfiguration')
            .execute({
                appId: appId,
                nextcloudId: nextcloudId,
                language: language
            });
    };

    public nextcloudGetConfiguration = (nextcloudId?: string, language?: string) => {
        return this.managedApplicationClient.request('nextcloudGetConfiguration')
            .execute({
                nextcloudId: nextcloudId,
                language: language
            });
    };

    public nextcloudCreateFileUploadToken = (appId: string, accountId: string, fieldKey: string) => {
        return this.managedApplicationClient.request('nextcloudCreateFileUploadToken')
            .execute({
                appId: appId,
                accountId: accountId,
                fieldKey: fieldKey
            });
    };
}
