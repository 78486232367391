export const ProductCodetemplatePlaceholder = '##BILLING_CYCLE##';
export const ProductFamiliesConst = {
    bundle: ['webhosting-packages', 'email-packages'],
    database: [
        'mariadb-single-databases',
        'mariadb-single-database-storages',
        'bundle-mariadb-databases',
        'bundle-mariadb-database-storages'
    ],
    dns: [
        'ddns-hostnames'
    ],
    emailPriceList: [
        'imap-mailboxes',
        'imap-mailboxes-storages',
        'external-forwarders',
        'catchalls',
        'msexchange-mailboxes',
        'msexchange-mailboxes-storages',
        'mailing-lists'
    ],
    emailProductBox: [
        'imap-mailboxes',
        'mailing-lists',
        'catchalls',
        'external-forwarders',
        'msexchange-mailboxes',
        'smtp-forwarders'
    ],
    machines: [
        'virtual-machines',
        'managed-virtual-machines',
        'ecommerce-virtual-machines'
    ],
    nextcloud: ['managed-nextcloud-home', 'managed-nextcloud-business'],
    ssl: ['dv-certificates', 'ev-certificates', 'ov-certificates', 'email-certificates'],
    webhosting: ['webspaces', 'webspace-storages']
};
