import * as ng from 'angular';

export class MoleculeButtonDeleteController implements ng.IController {
    public static $inject: string[] = [];

    public delete: (arg: any) => void;
    public deleteParam: any;
    public modifier = '';
    public title: string;
    public disabled = false;
    public active: boolean;

    public $onInit() {
        this.active = this.active || true;
    }
}

export class MoleculeButtonDeleteComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        active: '<',
        delete: '<',
        deleteParam: '<',
        disabled: '<',
        modifier: '@?',
        title: '@?'
    };
    public controller =  MoleculeButtonDeleteController;
    public template = require('./button-delete.html');
}
