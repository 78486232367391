import ng from 'angular';
import { FormDropDownItems } from '@/atomic-components/molecules/forms/form-elements/drop-down/drop-down';
import { buttonInterface } from '@/atomic-components/molecules/panels/panel-rows/panel-edit-row-radio/panel-edit-row-radio';
import { ApiErrorModel, ProductHelperService, SslOrderModelService, SslRobotService } from '@/services';
import { CsrDecoderService } from '@/services/ssl/csr-decoder';
import { ViewTypes } from '@/types/view-types';

export class OrganSslWizardProductConfigCertificateRequestController {
    public static $inject: string[] = [
        'apiErrorModel',
        'csrDecoder',
        'sslOrderModel',
        'sslRobot',
        '$timeout',
        '$translate'
    ];

    public sslValidationObject: ViewTypes.SslValidationObject;
    public sslProductCode: string;
    public metaData: ViewTypes.ProductConfigSslObject;

    public errorCsrDomainNotWildcard: boolean;
    public errorCsrDomainNonWildcardExpected: boolean;
    public autoValidationCapable = false;
    public validationMethodList: buttonInterface[] = [];
    public approverEmailAddresses: FormDropDownItems[] = [];
    public loading = false;

    private _showEmailOriginatedFromDomainHint = false;

    public constructor(
        private apiErrorModel: ApiErrorModel,
        private csrDecoder: CsrDecoderService,
        private sslOrderModel: SslOrderModelService,
        private sslRobot: SslRobotService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit(): void {
        this.validationMethodList = [
            {
                label: this.$translate.instant('TR_280319-04edd4_TR'),
                value: 'auto'
            },
            {
                label: this.$translate.instant('TR_280319-e54f8d_TR'),
                value: 'email'
            }
        ];
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.sslProductCode !== undefined) {
            this.sslValidationObject = {
                csrRequestEncoded: null,
                apiResponse: {} as ViewTypes.CsrDecodeApiResponse,
                isWildcard: false
            };
        }
    }

    public checkErrorCsrDomainWildcardStatus = (): void => {
        this.errorCsrDomainNonWildcardExpected = false;
        this.errorCsrDomainNotWildcard = false;

        if (
            ProductHelperService.wrapSpecificationItems(
                this.metaData.settings?.productSpecification
            ).wildcardSupport?.isTrue
        ) {
            this.errorCsrDomainNotWildcard = this.sslValidationObject.isWildcard === false;
        } else {
            this.errorCsrDomainNonWildcardExpected = this.sslValidationObject.isWildcard === true;
        }
    };

    public get showApprovalEmailAddress(): boolean {
        return this.metaData.settings.validationMethod === 'email'
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedCountry(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.country?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedState(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.state?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedLocalty(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.locality?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedOrganizationUnit(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.organizationUnit?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedOrganization(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.organization?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get showValidatedEmail(): boolean {
        return this.sslValidationObject.apiResponse?.csr?.emailAddress?.length > 0
            && !this.isProductFamilyEmailCertificate;
    }

    public get isProductFamilyEmailCertificate(): boolean {
        return this.metaData.sslProductFamily === 'email-certificates';
    }

    public get showEmailOriginatedFromDomainHint(): boolean {
        return this._showEmailOriginatedFromDomainHint;
    }

    public checkCsr = async (): Promise<void> => {
        this.loading = true;
        if (this.isCertificateSyntax(this.sslValidationObject.csrRequestEncoded)) {
            await this.csrDecoder
                .decode(this.sslValidationObject.csrRequestEncoded)
                .then((result: ViewTypes.CsrDecodeApiResponse) => {
                    this.sslValidationObject.apiResponse = result;
                    this.sslValidationObject.isWildcard = result.csr?.domainName.indexOf('*.') === 0;
                    void this.$timeout(() => {
                        this.checkErrorCsrDomainWildcardStatus();
                    });
                    const domain = result.csr?.domainName.replace('*.', '');
                    this.sslRobot.checkAutoValidationCapable(
                        [domain],
                        this.metaData.account.id,
                        this.metaData.sslProductCode
                    )
                    .then(async(res) => {
                        if (!this.apiErrorModel.apiResponseHasError(res)) {
                            this.autoValidationCapable = (res.responses.length === 0);
                            if (this.autoValidationCapable) {
                                this.validationMethodChanged('auto');
                            } else {
                                this.validationMethodChanged('email');
                            }

                            if (!this.isProductFamilyEmailCertificate) {
                                await this.updateApproverEmails(domain);
                            } else {
                                this.showEmailOriginatedFromDomainHintCallback();
                            }
                        } else {
                            this.sslValidationObject.apiResponse.errors = res.errors;
                        }
                    },
                    () => {
                        this.validationMethodChanged('email');
                    });

                    this.metaData.settings.emailCheckActive = true;
                });

        } else {
            this.sslValidationObject = this.sslValidationObject || {
                csrRequestEncoded: null,
                apiResponse: {} as ViewTypes.CsrDecodeApiResponse,
                isWildcard: false
            };
            this.sslValidationObject.apiResponse = {} as ViewTypes.CsrDecodeApiResponse;
            this.sslValidationObject.isWildcard = false;
            this.errorCsrDomainNonWildcardExpected = false;
            this.errorCsrDomainNotWildcard = false;
            this.approverEmailAddresses = [];
            if (!this.isProductFamilyEmailCertificate) {
                this.metaData.settings.email = '';
                this.approverEmailAddresses = [];
            }
        }

        this.loading = false;
    };

    public isCertificateSyntax = (csrRequestEncoded: string): boolean => {
        return  (/-----BEGIN\s(NEW\s)?CERTIFICATE\sREQUEST-----/.test(csrRequestEncoded)
                && /-----END\s(NEW\s)?CERTIFICATE\sREQUEST-----/.test(csrRequestEncoded));
    };

    public validationMethodChanged = (selectedMethod: string): void => {
        this.metaData.settings.validationMethod = selectedMethod;
    };

    public showEmailOriginatedFromDomainHintCallback = (): void => {
        this._showEmailOriginatedFromDomainHint = false;
        void this.$timeout(() => {
            const emailCommonName = this.metaData.settings.email.substring(this.metaData.settings.email.indexOf('@') + 1);
            this._showEmailOriginatedFromDomainHint = this.sslValidationObject?.apiResponse?.csr?.domainName && ![null, undefined, ''].includes(this.metaData.settings.email)
                ? this.sslValidationObject.apiResponse.csr.domainName !== emailCommonName
                : false;
        });
    };

    private updateApproverEmails = async (domain: string): Promise<void> => {
        await this.sslOrderModel.getDomainApproverList(domain)
            .then((domainApproverList) => domainApproverList, () => [])
            .then((addresses: string[]) => {
            this.metaData.settings.emailCheckActive = false;
            this.approverEmailAddresses = addresses.map((address) => ({ value: address, name: address }));
            const hostmasterAddresses = addresses
                .filter((address) => address.indexOf('hostmaster@') === 0)
                .sort((a, b) => a.length - b.length);
            if (hostmasterAddresses.length > 0) {
                this.metaData.settings.email = hostmasterAddresses[0];
            } else {
                this.metaData.settings.email = addresses[0];
            }
        });
    };
}

export class OrganSslWizardProductConfigCertificateRequestComponent {
    public bindings = {
        sslValidationObject: '=',
        sslProductCode: '<',
        metaData: '<'
    };
    public template = require('./config-certificate-request.html');
    public controller = OrganSslWizardProductConfigCertificateRequestController;
}
