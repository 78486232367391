import ng from 'angular';
import { getDefaultCronjob } from '@/configuration/cronjob-default';
import { BillingCycleToContractPeriodeConst, UiRights } from '@/configuration';
import * as stateUtilityFunctions from '@/services/state-utility-functions';
import * as Types from '@/types';
import {
    AccountModelService,
    ApiObject,
    AuthContextService,
    BackupModelService,
    BundleModelService,
    BundleOverviewModel,
    CronjobOverviewModel,
    DatabaseJobOverviewModel,
    DatabaseModelService,
    DatabaseOverviewModel,
    DatabaseUserModelService,
    DnsZoneModelService,
    DomainJobOverviewModel,
    DomainModelService,
    DomainOverviewModel,
    DomainVhostOverviewModel,
    EmailJobOverviewModel,
    FilterCacheService,
    InstallerModelService,
    LegacyPhpDomainsOverviewModel,
    MailboxModelService,
    MailboxOverviewModel,
    ModelHelper,
    NominetRegistrarListService, PriceCacheService,
    RedirectOnNotFoundCallback,
    RestrictionHelperService,
    TldListService,
    UserSettingsManagerService,
    VhostModelService,
    WebhostingUserModelService,
    WebspaceModelService
} from '@/services';

export const BUNDLE_ROUTES = [

    /*
    ██████  ██    ██ ███    ██ ██████  ██      ███████
    ██   ██ ██    ██ ████   ██ ██   ██ ██      ██
    ██████  ██    ██ ██ ██  ██ ██   ██ ██      █████
    ██   ██ ██    ██ ██  ██ ██ ██   ██ ██      ██
    ██████   ██████  ██   ████ ██████  ███████ ███████
    */

    {
        name: 'bundle',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.BIL_BUNDLE_LIST
            },
            parent: 'authenticated',
            url: '/bundle'
        }
    },
    {
        name: 'bundle.new',
        state: {
            data: {
                isGrantedAll: [ UiRights.BIL_BUNDLE_LIST, UiRights.BIL_BUNDLE_CREATE ]
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-bundle-wizard></template-bundle-wizard>'
                }
            }
        }
    },
    {
        name: 'bundle.overview',
        state: {
            data: {
                isGranted: UiRights.BIL_BUNDLE_LIST
            },
            resolve: {
                extendedFilters: ($stateParams: ng.ui.IStateParamsService, filterCache: FilterCacheService) => {
                    if ($stateParams.fancyFilter !== undefined && $stateParams.fancyFilter !== '') {
                        return JSON.parse($stateParams.fancyFilter);
                    }
                    return filterCache.resolveExtendedFilters('Bundle');
                },
                overviewModel: (bundleOverviewModel: BundleOverviewModel) => bundleOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('BundleOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Bundle');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-bundle-overview-list
                        simple-filter="$resolve.simpleFilter"
                        extended-filters="$resolve.extendedFilters"
                        overview-ui-settings="$resolve.overviewUiSettings"
                        overview-model="$resolve.overviewModel"
                    ></template-bundle-overview-list>`
                }
            }
        }
    },
    {
        name: 'bundle.dashboard',
        state: {
            data: {
                isGranted: UiRights.BIL_BUNDLE_LIST
            },
            url: '/',
            // comment this part out, if only one bundle is in account, there is no way to get a other bundle.
            // At this moment we have no idea where we can display the create link on bundle dashboard
            // to register a new bundle
            // onEnter: ($timeout: ng.ITimeoutService, $state: ng.ui.IStateService, bundles) => {
            //      if (bundles.length === 1) {
            //         // $timeout to delay the redirect until after the state transition has completed
            //         $timeout(function() {
            //             $state.go('bundle.id.dashboard', { bundleId: bundles[0].id });
            //         });
            //     }
            // },
            views: {
                'content@authenticated': {
                    template: '<template-bundle-overview></template-bundle-overview-template>'
                }
            }
        }
    },
    {
        comment: 'Abstract state that does the "bundle" resolve for all bundle-specific states.',
        name: 'bundle.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.BIL_BUNDLE_LIST
            },
            resolve: {
                bundle: (
                    $stateParams: ng.ui.IStateParamsService,
                    bundleModel: BundleModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return bundleModel.findOne($stateParams.bundleId).then(null, redirectOnNotFound);
                },
                bundleAccount: (
                    accountModel: AccountModelService,
                    bundle: Types.BundleApi.Bundle,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    if (bundle.accountId === AuthContextService.account.id) {
                        return AuthContextService.account;
                    }

                    return accountModel.findOne(bundle.accountId).then(null, redirectOnNotFound);
                },
                webspace: (bundle, webspaceModel: WebspaceModelService) => {
                    if (ApiObject.bundle(bundle).hasWebspaceContingent) {
                        return webspaceModel.list(1, 1, {field: 'bundleId', value: bundle.id})
                            .then((res) => res.data.length > 0 ? res.data[0] : null);
                    }

                    return null;
                }
            },
            url: '/id/{bundleId}'
        }
    },
    {
        name: 'bundle.id.dashboard',
        state: {
                resolve: {
                    bundle: (
                        $stateParams: ng.ui.IStateParamsService,
                        bundleModel: BundleModelService,
                        redirectOnNotFound: RedirectOnNotFoundCallback
                    ) => {
                        return bundleModel.findOne($stateParams.bundleId).then(null, redirectOnNotFound);
                    },
                    showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                        return userSettingsManager.isShowId();
                    },
                    multiPartTlds: (tldList: TldListService) => tldList.getMultiPartTlds()
                },
                url: '/dashboard',
                views: {
                    'content@authenticated': {
                        template: `<template-bundle-dashboard
                                        bundle="$resolve.bundle"
                                        webspace="$resolve.webspace"
                                        show-object-id="$resolve.showObjectId"
                                        multi-part-tlds="$resolve.multiPartTlds"
                                    ></template-bundle-dashboard>`
                    }
                }
            }
    },
    {
        name: 'bundle.id.admin-options',

        state: {
            data: {
                isGrantedAny: [UiRights.WEB_ADMIN_WEBSPACE_ENABLE_DISABLE, UiRights.WEB_ADMIN_EMAIL_SENDER]
            },
            resolve: {
                webspace: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    webspaceModel: WebspaceModelService
                ) => {
                    if ($stateParams.webspaceId === null) {
                        return [];
                    }
                    return webspaceModel.findOne($stateParams.webspaceId).then(null, redirectOnNotFound);
                }
            },
            url: '/admin-options/{webspaceId}',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: '<template-webspace-admin-options webspace="$resolve.webspace"></template-webspace-admin-options>'
                }
            }
        }
    },
    {
        name: 'bundle.id.contract-details',
        state: {
            data: {
                isGranted: UiRights.BIL_BUNDLE_EDIT
            },
            url: '/contract-details',
            views: {
                'content@authenticated': {
                    template: `<template-bundle-contract-details
                                    bundle="$resolve.bundle"
                                    webspace="$resolve.webspace"
                               ></template-bundle-contract-details>`
                }
            }
        }
    },
    {
        name: 'bundle.id.rename',
        state: {
            data: {
                isGranted: UiRights.BIL_BUNDLE_EDIT
            },
            url: '/rename',
            views: {
                'content@authenticated': {
                    template: '<template-bundle-rename bundle="$resolve.bundle"></template-bundle-rename>'
                }
            }
        }
    },
    {
        name: 'bundle.id.add-domain',
        state: {
            data: {
                isGranted: UiRights.BIL_BUNDLE_EDIT
            },
            url: '/add-domain',
            views: {
                'content@authenticated': {
                    template: '<template-bundle-add-domain bundle="$resolve.bundle"></template-bundle-add-domain>'
                }
            }
        }
    },
    {
        name: 'bundle.id.delete',
        state: {
            data: {
                isGranted: UiRights.BIL_BUNDLE_DELETE
            },
            url: '/delete',
            views: {
                'content@authenticated': {
                    template: '<template-bundle-delete bundle="$resolve.bundle"></template-bundle-delete>'
                }
            }
        }
    },
    {
        name: 'bundle.id.cancel-delete',
        state: {
            data: {
                isGranted: UiRights.BIL_BUNDLE_DELETE
            },
            url: '/cancel-delete',
            views: {
                'content@authenticated': {
                    template: '<template-bundle-cancel-delete bundle="$resolve.bundle"></template-bundle-cancel-delete>'
                }
            }
        }
    },
    {
        name: 'bundle.id.restore',
        state: {
            data: {
                isGranted: UiRights.BIL_BUNDLE_RESTORE
            },
            url: '/restore',
            views: {
                'content@authenticated': {
                    template: `<template-bundle-delete
                        bundle="$resolve.bundle"
                        current-action="restore"
                    ></template-bundle-delete>`
                }
            }
        }
    },

    /*
    ███    ███  █████  ██ ██      ██████   ██████  ██   ██ ███████ ███████
    ████  ████ ██   ██ ██ ██      ██   ██ ██    ██  ██ ██  ██      ██
    ██ ████ ██ ███████ ██ ██      ██████  ██    ██   ███   █████   ███████
    ██  ██  ██ ██   ██ ██ ██      ██   ██ ██    ██  ██ ██  ██           ██
    ██      ██ ██   ██ ██ ███████ ██████   ██████  ██   ██ ███████ ███████
    */

    {
        name: 'bundle.id.mailboxes',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.EMAIL_MAILBOX_LIST
            },
            resolve: {
                comeFrom: () => 'products'
            },
            url: '/mailboxes'
        }
    },
    {
        name: 'bundle.id.mailboxes.new',
        state: {
            data: {
                isGrantedAny: [UiRights.EMAIL_IMAPMAILBOX_CREATE, UiRights.EMAIL_FORWARDER_CREATE]
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-email-wizard bundle="$resolve.bundle"></template-email-wizard>'
                }
            }
        }
    },
    {
        name: 'bundle.id.mailboxes.overview',
        state: {
            data: {
                isGranted: UiRights.EMAIL_MAILBOX_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('BundleEmail');
                },
                overviewModel: (mailboxOverviewModel: MailboxOverviewModel) => mailboxOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('EmailOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('BundleEmail');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-email-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                    bundle="$resolve.bundle"
                                ></template-email-overview>`
                }
            }
        }
    },
    {
        comment: 'unused',
        name: 'bundle.id.mailboxes.jobs',
        state: {
            data: {
                isGranted: UiRights.EMAIL_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('BundleEmailJobs');
                },
                overviewModel: (emailJobOverviewModel: EmailJobOverviewModel) => emailJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('EmailOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('BundleEmailJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-email-job-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    bundle="$resolve.bundle"
                                    overview-model="$resolve.overviewModel"
                                ></template-email-job-overview>`
                }
            }
        }
    },
    {
        name: 'bundle.id.mailboxes.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.EMAIL_MAILBOX_LIST
            },
            resolve: {
                mailbox: (
                    $stateParams: ng.ui.IStateParamsService,
                    mailboxModel: MailboxModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return mailboxModel.findOne($stateParams.mailboxId)
                        .then(stateUtilityFunctions.check404('Mailbox not found'))
                        .then(null, redirectOnNotFound);
                }
            },
            url: '/id/{mailboxId}'
        }
    },
    {
        name: 'bundle.id.mailboxes.id.edit',
        state: {
            data: {
                isGranted: UiRights.EMAIL_MAILBOX_EDIT
            },
            resolve: {
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-mailbox-edit
                                    mailbox="$resolve.mailbox"
                                    come-from="$resolve.comeFrom"
                                    show-object-id="$resolve.showObjectId"
                                ></template-mailbox-edit>`
                }
            }
        }
    },
    {
        name: 'bundle.id.mailboxes.id.domainsettings',
        state: {
            data: {
                isGrantedAll: [UiRights.ACC_SUBACCOUNT_LIST, UiRights.EMAIL_DOMAINSETTINGS_LIST]
            },
            resolve: {
                domainSettings: (mailbox, mailboxModel: MailboxModelService) => {
                    return mailboxModel.findDomainsettingsByDomainName(mailbox.domainName)
                        .then(stateUtilityFunctions.check404('Domain settings not found'));
                }
            },
            url: '/domainsettings',
            views: {
                'content@authenticated': {
                    template: `<template-mailbox-domainsettings
                                    mailbox="$resolve.mailbox"
                                    domain-settings="$resolve.domainSettings"
                                    come-from="$resolve.comeFrom"
                                ></template-mailbox-domainsettings>`
                }
            }
        }
    },
    {
        name: 'bundle.id.mailboxes.id.restrictions',
        state: {
            data: {
                isGrantedAny: [UiRights.EMAIL_MAILBOX_EDIT, UiRights.EMAIL_CATCHALL_EDIT]
            },
            resolve: {
                restrictionHelper: (
                    mailbox,
                    restrictionHelper: RestrictionHelperService,
                    mailboxModel: MailboxModelService
                ) => {
                    restrictionHelper.init(mailbox, 'email', mailboxModel, 'mailboxModifyRestrictions');
                    return restrictionHelper;
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/restrictions',
            views: {
                'content@authenticated': {
                    template: `<template-restrictions-edit
                        api-object="$resolve.mailbox"
                        restriction-helper="$resolve.restrictionHelper"
                        show-object-id="$resolve.showObjectId"
                    ></template-restrictions-edit>`
                }
            }
        }
    },

    /*
    ██████   █████  ████████  █████  ██████   █████  ███████ ███████ ███████
    ██   ██ ██   ██    ██    ██   ██ ██   ██ ██   ██ ██      ██      ██
    ██   ██ ███████    ██    ███████ ██████  ███████ ███████ █████   ███████
    ██   ██ ██   ██    ██    ██   ██ ██   ██ ██   ██      ██ ██           ██
    ██████  ██   ██    ██    ██   ██ ██████  ██   ██ ███████ ███████ ███████
    */

    {
        name: 'bundle.id.databases',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DB_DATABASE_LIST
            },
            url: '/databases'
        }
    },
    {
        name: 'bundle.id.databases.new',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-database-wizard></template-database-wizard>'
                }
            }
        }
    },
    {
        name: 'bundle.id.databases.overview',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('BundleDatabase');
                },
                overviewModel: (databaseOverviewModel: DatabaseOverviewModel) => databaseOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DatabaseOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('BundleDatabase');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-database-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    bundle="$resolve.bundle"
                                    overview-model="$resolve.overviewModel"
                                ></template-database-overview>`
                }
            }
        }
    },
    {
        comment: 'unused',
        name: 'bundle.id.databases.jobs',
        state: {
            data: {
                isGranted: UiRights.DB_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('BundleDatabaseJobs');
                },
                overviewModel: (databaseJobOverviewModel: DatabaseJobOverviewModel) => databaseJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DatabaseOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('BundleDatabaseJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-database-job-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    bundle="$resolve.bundle"
                                    overview-model="$resolve.overviewModel"
                                ></template-database-job-overview>`
                }
            }
        }
    },
    {
        name: 'bundle.id.databases.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DB_DATABASE_LIST
            },
            resolve: {
                backups: (database, backupModel: BackupModelService) => {
                    return backupModel.list('database', 'database', database.id);
                },
                database: (
                    $stateParams: ng.ui.IStateParamsService,
                    databaseModel: DatabaseModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return databaseModel.findOneById($stateParams.databaseId)
                        .then(stateUtilityFunctions.check404('Database not found'))
                        .then(null, redirectOnNotFound);
                },
                databaseUsers: (databaseUserModel: DatabaseUserModelService, database) => {
                    return databaseUserModel.list(100, null, { field: 'accountId', value: database.accountId })
                    .then((res) => res.data);
                }
            },
            url: '/id/{databaseId}'
        }
    },
    {
        name: 'bundle.id.databases.id.edit',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_EDIT
            },
            resolve: {
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-database-edit
                        database="$resolve.database"
                        database-users="$resolve.databaseUsers"
                        backups="$resolve.backups"
                        show-object-id="$resolve.showObjectId"
                    ></template-database-edit>`
                }
            }
        }
    },
    {
        name: 'bundle.id.databases.id.backups',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.BACKUP_BACKUP_LIST
            },
            resolve: {
                backups:
                    (database, backupModel: BackupModelService) => [undefined, null, ''].indexOf(database) === -1
                        ? backupModel.list('database', 'database', database.id)
                        : []
            },
            url: '/backups'
        }
    },
    {
        name: 'bundle.id.databases.id.backups.overview',
        state: {
            url: '/overview',
            views: {
                'content@authenticated': {
                    // tslint:disable-next-line: max-line-length
                    template: '<template-database-backups backups="$resolve.backups" database="$resolve.database"></template-database-backups>'
                }
            }
        }
    },
    {
        name: 'bundle.id.databases.id.restrictions',
        state: {
            data: {
                isGranted: UiRights.DB_DATABASE_EDIT
            },
            resolve: {
                restrictionHelper: (
                    database,
                    restrictionHelper: RestrictionHelperService,
                    databaseModel: DatabaseModelService
                ) => {
                    restrictionHelper.init(database, 'database', databaseModel, 'databaseModifyRestrictions');
                    return restrictionHelper;
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/restrictions',
            views: {
                'content@authenticated': {
                    template: `<template-restrictions-edit
                        api-object="$resolve.database"
                        restriction-helper="$resolve.restrictionHelper"
                        show-object-id="$resolve.showObjectId"
                    ></template-restrictions-edit>`
                }
            }
        }
    },

    /*
    ██████   █████   ██████ ██   ██ ██    ██ ██████  ███████
    ██   ██ ██   ██ ██      ██  ██  ██    ██ ██   ██ ██
    ██████  ███████ ██      █████   ██    ██ ██████  ███████
    ██   ██ ██   ██ ██      ██  ██  ██    ██ ██           ██
    ██████  ██   ██  ██████ ██   ██  ██████  ██      ███████
    */

    {
        name: 'bundle.id.backups',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.BACKUP_BACKUP_LIST
            },
            resolve: {
                webspaceBackups: (webspace, backupModel: BackupModelService) => webspace.length > 0
                ? backupModel.list('webhosting', 'webspace', webspace[0].id)
                : [],
                databaseBackups: (database, backupModel: BackupModelService) => database.length > 0
                ? backupModel.list('database', 'database', database[0].id)
                : [],
                webspace: (bundle, webspaceModel: WebspaceModelService) => {
                    return webspaceModel.list(1, 1, {field: 'bundleId', value: bundle.id})
                    .then((res) => res.data.length > 0 ? res.data : []);
                },
                database: (bundle, databaseModel: DatabaseModelService) => {
                    return databaseModel.list(1, 1, {field: 'bundleId', value: bundle.id})
                    .then((res) => res.data.length > 0 ? res.data : []);
                }
            },
            url: '/backups'
        }
    },
    {
        name: 'bundle.id.backups.overview',
        state: {
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-bundle-backups
                        database-backups="$resolve.databaseBackups"
                        webspace-backups="$resolve.webspaceBackups"
                        webspace="$resolve.webspace"
                    ></template-bundle-backups>`
                }
            }
        }
    },

    /*
    ██     ██ ███████ ██████  ███████ ██████   █████   ██████ ███████
    ██     ██ ██      ██   ██ ██      ██   ██ ██   ██ ██      ██
    ██  █  ██ █████   ██████  ███████ ██████  ███████ ██      █████
    ██ ███ ██ ██      ██   ██      ██ ██      ██   ██ ██      ██
     ███ ███  ███████ ██████  ███████ ██      ██   ██  ██████ ███████
    */

    {
        name: 'bundle.id.webspaces',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.WEB_OBJECT_LIST
            },
            resolve: {
                webspace: (bundle: Types.BundleApi.Bundle, webspaceModel: WebspaceModelService) => {
                    return webspaceModel.list(null, null, {field: 'bundleId', value: bundle.id})
                    .then((res) => res.data[0]) as ng.IPromise<Types.WebhostingApi.Webspace>;
                }
            },
            url: '/webspace'
        }
    },
    {
        name: 'bundle.id.webspaces.ftp-ssh',
        state: {
            resolve: {
                webspaceAccessUsers: (
                    webspace: Types.WebhostingApi.Webspace,
                    webhostingUserModel: WebhostingUserModelService
                ) => {
                    return webhostingUserModel.list(500, null, { field: 'UserAccessesWebspaceId', value: webspace.id })
                    .then((res) => res.data) as ng.IPromise<Types.WebhostingApi.WebspaceAccess[]>;
                },
                webspaceUsers: (bundle: Types.BundleApi.Bundle, webhostingUserModel: WebhostingUserModelService) => {
                    return webhostingUserModel.list(null, null, {field: 'accountId', value: bundle.accountId})
                    .then((res) => res.data) as ng.IPromise<Types.WebhostingApi.User[]>;
                }
            },
            url: '/user-overview',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-access-overview
                                    bundle="$resolve.bundle"
                                    webspace="$resolve.webspace"
                                    webspace-users="$resolve.webspaceUsers"
                                    webspace-access-users="$resolve.webspaceAccessUsers"
                                ></template-webspace-access-overview>`
                }
            }
        }
    },
    {
        name: 'bundle.id.webspaces.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.WEB_WEBSPACE_EDIT
            },
            url: '/id/{webspaceId}'
        }
    },
    {
        name: 'bundle.id.webspaces.id.storage',
        state: {
            data: {
                isGranted: UiRights.WEB_WEBSPACE_EDIT
            },
            resolve: {
                minimumStorage: (bundle, webspace) => {
                    const webspaceStorageUpgradeCode = webspace.productCode.replace('-webspace-', '-webspace-storage-');
                    const contingents = bundle.baseContingents
                    .filter((contingent) => contingent.productCodes.indexOf(webspaceStorageUpgradeCode) >= 0);

                    let storageUnits = 1;

                    if (contingents.length > 0) {
                        storageUnits += contingents[0].capacity;
                    }

                    return storageUnits * 1024;
                }
            },
            url: '/storage',
            views: {
                'content@authenticated': {
                    template: `<template-bundle-storage-edit
                        webspace="$resolve.webspace"
                        minimum-storage="$resolve.minimumStorage"
                    ></template-bundle-storage-edit>`
                }
            }
        }
    },
    {
        name: 'bundle.id.webspaces.id.php-boost',
        state: {
            data: {
                isGrantedAll: [
                    UiRights.BIL_LIST_PRICES_WEBHOSTING,
                    UiRights.WEB_WEBSPACE_EDIT
                ]
            },
            resolve: {
                billingCycle: (priceCache: PriceCacheService, webspace) => {
                    return priceCache.getPriceByProductCode(
                        webspace.productCode,
                        webspace.accountId
                    ).then((reply) => BillingCycleToContractPeriodeConst[reply[0].contractPeriod]);
                }
            },
            url: '/php-boost',
            views: {
                'content@authenticated': {
                    template: '<template-webspace-php-boost webspace="$resolve.webspace" billing-cycle="$resolve.billingCycle"></template-webspace-php-boost>'
                }
            }
        }
    },
    {
        name: 'bundle.id.webspaces.id.admin-options',
        state: {
            url: '/admin-options',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-admin-options
                        webspace="$resolve.webspace"
                    ></template-webspace-admin-options>`
                }
            }
        }
    },

    /*
    ██████   ██████  ███    ███  █████  ██ ███    ██ ███████
    ██   ██ ██    ██ ████  ████ ██   ██ ██ ████   ██ ██
    ██   ██ ██    ██ ██ ████ ██ ███████ ██ ██ ██  ██ ███████
    ██   ██ ██    ██ ██  ██  ██ ██   ██ ██ ██  ██ ██      ██
    ██████   ██████  ██      ██ ██   ██ ██ ██   ████ ███████

        ██     ██    ██ ██   ██  ██████  ███████ ████████ ███████
       ██      ██    ██ ██   ██ ██    ██ ██         ██    ██
      ██       ██    ██ ███████ ██    ██ ███████    ██    ███████
     ██         ██  ██  ██   ██ ██    ██      ██    ██         ██
    ██           ████   ██   ██  ██████  ███████    ██    ███████
    */

    {
        name: 'bundle.id.domains',
        state: {
            abstract: true,
            url: '/domains'
        }
    },
    {
        name: 'bundle.id.domains.new',
        state: {
            params: {
                domainSearchString: { value: null, squash: true },
                domainType: { value: null, squash: true }
            },
            resolve: {
                webspace: (bundle, webspaceModel: WebspaceModelService) => {
                    return webspaceModel.listWithoutPagination(null, 1, { field: 'bundleId', value: bundle.id})
                    .then((webspaceResult) => {
                        return webspaceResult.data.length > 0
                            ? webspaceResult.data[0]
                            : null;
                    });
                },
                ownerAccount: (
                    accountModel: AccountModelService,
                    bundle,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    if (bundle.accountId === AuthContextService.account.id) {
                        return AuthContextService.account;
                    }
                    return accountModel.findOne(bundle.accountId).then(null, redirectOnNotFound);
                }
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: `<template-domain-wizard
                        bundle="$resolve.bundle"
                        webspace="$resolve.webspace"
                        owner-account="$resolve.ownerAccount"
                    ></template-domain-wizard>`
                }
            }
        }
    },
    {
        data: {
            isGranted: UiRights.WEB_OBJECT_LIST
        },
        name: 'bundle.id.domains.legacyphp',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('domain.domain');
                },
                legacyPhpDomainsOverviewModel: (
                    legacyPhpDomainsOverviewModel: LegacyPhpDomainsOverviewModel
                ) => legacyPhpDomainsOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('domain.domain');
                }
            },
            url: '/legacyphp',
            views: {
                'content@authenticated': {
                    template: `<template-domain-overview
                                simple-filter="$resolve.simpleFilter"
                                extended-filters="$resolve.extendedFilters"
                                overview-ui-settings="$resolve.overviewUiSettings"
                                bundle="$resolve.bundle"
                                webspace="$resolve.webspace"
                                overview-model="$resolve.legacyPhpDomainsOverviewModel"
                                is-legacy-php-overview="true"
                            ></template-domain-overview>`
                }
            }
        }
    },
    {
        name: 'bundle.id.domains.overview',
        state: {
            data: {
                isGranted: UiRights.DOM_DOMAINS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('BundleDomain');
                },
                overviewModel: (
                    webspace,
                    domainOverviewModel: DomainOverviewModel,
                    domainVhostOverviewModel: DomainVhostOverviewModel
                ) => {
                    return [undefined, null].indexOf(webspace) >= 0
                    ? domainOverviewModel
                    : domainVhostOverviewModel;
                },
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return [];
                    // Ausgebaut wg: PUI-4461
                    // return userSettingsManager.getSettingsForOverview('DomainOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('BundleDomain');
                },
                webspace: (
                    authContext: AuthContextService,
                    bundle,
                    webspaceModel: WebspaceModelService
                ) => {
                    if (!authContext.isGranted(UiRights.WEB_OBJECT_LIST)) {
                        return null;
                    }
                    return webspaceModel.list(1, 1, {field: 'bundleId', value: bundle.id})
                    .then(ModelHelper.returnFindSingleData);
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-domain-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    bundle="$resolve.bundle"
                                    webspace="$resolve.webspace"
                                    overview-model="$resolve.overviewModel"
                                ></template-domain-overview>`
                }
            }
        }
    },
    {
        comment: 'unused',
        name: 'bundle.id.domains.jobs',
        state: {
            data: {
                isGranted: UiRights.DOM_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('BundleDomainJobs');
                },
                overviewModel: (domainJobOverviewModel: DomainJobOverviewModel) => domainJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('DomainOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('BundleDomainJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-domain-job-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    bundle="$resolve.bundle"
                                    overview-model="$resolve.overviewModel"
                                ></template-domain-job-overview>`
                }
            }
        }
    },
    {
        name: 'bundle.id.domains.transfer-out',
        state: {
            data: {
                isGranted: UiRights.DOM_JOBS_LIST
            },
            resolve: {
                domains: (
                    bundle: any,
                    domainModel: DomainModelService
                ) => domainModel
                .listWithoutPagination(1000, 1, { field: 'BundleId', value: bundle.id })
                .then((result) => result.data)
            },
            url: '/transfer-out',
            views: {
                'content@authenticated': {
                    template: `<template-bundle-domains-transfer
                        domains="$resolve.domains"
                    ></template-bundle-domains-transfer>`
                }
            }
        }
    },
    {
        name: 'bundle.id.domains.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.DOM_DOMAINS_EDIT
            },
            resolve: {
                domain: (
                    $stateParams: ng.ui.IStateParamsService,
                    domainModel: DomainModelService,
                    redirectOnNotFound: RedirectOnNotFoundCallback
                ) => {
                    return $stateParams.vhost === 'vhost'
                        ? null
                        : domainModel.findOne($stateParams.domainId)
                            .then(stateUtilityFunctions.check404('Domain not found'))
                            .then(null, redirectOnNotFound);
                },
                domainType: ($stateParams: ng.ui.IStateParamsService) => $stateParams.vhost === 'vhost'
                    ? 'vhost'
                    : 'domain',
                phpIni: (vHost, vhostModel: VhostModelService) => {
                    if ([undefined, null].indexOf(vHost) >= 0) {
                        return null;
                    }
                    return vhostModel.vhostPhpIniList(vHost.id);
                },
                vHost: (
                    domain,
                    webspace,
                    vhostModel: VhostModelService,
                    $stateParams: ng.ui.IStateParamsService
                ) => {
                    if ([undefined, null].indexOf(webspace) >= 0) {
                        return null;
                    }

                    let filter;
                    if ($stateParams.vhost !== undefined && $stateParams.vhost === 'vhost') {
                        filter = { field: 'vhostId', value: $stateParams.domainId };
                    } else {
                        filter = { field: 'vhostDomainNameUnicode', value: domain.nameUnicode };
                    }

                    return vhostModel.list(null, 1, filter, null, webspace.id)
                    .then(ModelHelper.returnFindSingleData);
                },
                webspace: (
                    bundle,
                    authContext: AuthContextService,
                    webspaceModel: WebspaceModelService
                ) => {
                    if (!authContext.isGranted(UiRights.WEB_OBJECT_LIST)) {
                        return null;
                    }
                    const filter = { field: 'bundleId', value: bundle.id };
                    return webspaceModel.list(null, 1, filter)
                    .then(ModelHelper.returnFindSingleData);
                },
                zone: (dnsZoneModel: DnsZoneModelService, domain) => {
                    if ([undefined, null].indexOf(domain) >= 0) {
                        return null;
                    }

                    const filters: Types.Finding.Filter = {
                        subFilter: [
                            {field: 'zoneName', value: domain.nameUnicode},
                            {field: 'zoneNameUnicode', value: domain.nameUnicode}
                        ],
                        subFilterConnective: 'OR'
                    };

                    return dnsZoneModel.findZones(filters, 1, 1, null, true);
                }
            },
            url: '/id/{domainId}/{vhost}'
        }
    },
    {
        name: 'bundle.id.domains.id.edit',
        state: {
            data: {
                isGranted: UiRights.WEB_VHOST_EDIT
            },
            resolve: {
                phpConfigurationMetaData: (vhostModel: VhostModelService) => {
                    return vhostModel.phpConfigurationMetadata();
                },
                phpIniList: (phpIni) => {
                    return phpIni;
                },
                phpVersions: (vhostModel: VhostModelService) => {
                    return vhostModel.availablePhpVersions();
                },
                profiles: (webspace, vhostModel: VhostModelService) => {
                    if ([undefined, null].indexOf(webspace) >= 0) {
                        return null;
                    }

                    return vhostModel.listProfiles(webspace.id);
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                },
                tags: (nominetRegistrarList: NominetRegistrarListService) => {
                    return nominetRegistrarList.getList();
                },
                multiPartTlds: (tldList: TldListService) => tldList.getMultiPartTlds()
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: `<template-domain-edit
                        ng-if="$resolve.domainType === 'vhost'"
                        webspace="$resolve.webspace"
                        v-host="$resolve.vHost"
                        bundle="$resolve.bundle"
                        domain="$resolve.domain"
                        domain-type="$resolve.domainType"
                        php-ini-list="$resolve.phpIniList"
                        profiles="$resolve.profiles"
                        php-versions="$resolve.phpVersions"
                        php-configuration-meta-data="$resolve.phpConfigurationMetaData"
                        show-object-id="$resolve.showObjectId"
                        multi-part-tlds="$resolve.multiPartTlds"
                    ></template-domain-edit>
                    <template-domain-product-edit
                        ng-if="$resolve.domainType === 'domain'"
                        domain="$resolve.domain"
                        zone="$resolve.zone"
                        tags="$resolve.tags"
                        show-object-id="$resolve.showObjectId"
                    ></template-domain-product-edit>`
                }
            }
        }
    },
    {
        name: 'bundle.id.domains.id.ssl',
        state: {
            resolve: {
                profiles: (bundle, vhostModel: VhostModelService, webspaceModel: WebspaceModelService) => {
                    const filter = { field: 'bundleId', value: bundle.id };
                    return webspaceModel.list(null, 1, filter)
                    .then((reply) => vhostModel.listProfiles(reply.data.id));
                }
            },
            url: '/ssl-edit',
            views: {
                'content@authenticated': {
                    template: `<template-webhosting-vhost-ssl-edit
                        webspace="$resolve.webspace"
                        v-host="$resolve.vHost"
                        php-ini-list="$resolve.phpIniList"
                        profiles="$resolve.profiles"
                    ></template-webhosting-vhost-ssl-edit>`
                }
            }
        }
    },
    {
        data: {
            isGranted: UiRights.WEB_OBJECT_LIST
        },
        name: 'bundle.id.domains.minphpversion',
        state: {
            resolve: {
                phpVersions: (vhostModel: VhostModelService) => {
                    return vhostModel.availablePhpVersions();
                }
            },
            url: '/minphpversion',
            views: {
                'content@authenticated': {
                    template: `<template-webspace-php-min-version
                                    webspace="$resolve.webspace"
                                    php-versions="$resolve.phpVersions"
                                ></template-webspace-php-min-version>`
                }
            }
        }
    },
    {
        name: 'bundle.id.domains.id.dir-protection-edit',
        state: {
            resolve: {
                phpIni: (vHost, vhostModel: VhostModelService) => {
                    return vhostModel.vhostPhpIniList(vHost.id);
                }
            },
            url: '/dir-protection-edit',
            views: {
                'content@authenticated': {
                    template: `<template-domain-dir-protection
                        v-host="$resolve.vHost"
                        domain="$resolve.domain"
                        php-ini="$resolve.phpIni"
                    ></template-domain-dir-protection>`
                }
            }
        }
    },
    {
        name: 'bundle.id.domains.id.locations-edit',
        state: {
            resolve: {
                phpIni: (vHost, vhostModel: VhostModelService) => {
                    return vhostModel.vhostPhpIniList(vHost.id).then(
                        (reply) => {
                            return reply;
                        }
                    );
                }
            },
            url: '/locations-edit',
            views: {
                'content@authenticated': {
                    template: `<template-domain-locations
                        bundle="$resolve.bundle"
                        v-host="$resolve.vHost"
                        php-ini="$resolve.phpIni"
                    ></template-domain-locations>`
                }
            }
        }
    },
    {
        name: 'bundle.id.domains.id.app-install',
        state: {
            resolve: {
                apps: (installerModel: InstallerModelService) => {
                    return installerModel.list('app', {});
                }
            },
            url: '/app-install',
            views: {
                'content@authenticated': {
                    template: `<template-domain-app-install
                        apps="$resolve.apps"
                        webspace="$resolve.webspace"
                        domain="$resolve.domain"
                        v-host="$resolve.vHost"
                        bundle="$resolve.bundle"
                    ></template-domain-app-install>`
                }
            }
        }
    },
    {
        name: 'bundle.id.domains.id.directory-protection',
        state: {
            url: '/directory-protection',
            views: {
                'content@authenticated': {
                    template: `<template-webhosting-vhost-directory-protection
                        webspace="$resolve.webspace"
                        v-host="$resolve.vHost"
                        bundle="$resolve.bundle"
                    ></template-webhosting-vhost-directory-protection>`
                }
            }
        }
    },

    /*
     ██████ ██████   ██████  ███    ██      ██  ██████  ██████  ███████
    ██      ██   ██ ██    ██ ████   ██      ██ ██    ██ ██   ██ ██
    ██      ██████  ██    ██ ██ ██  ██      ██ ██    ██ ██████  ███████
    ██      ██   ██ ██    ██ ██  ██ ██ ██   ██ ██    ██ ██   ██      ██
     ██████ ██   ██  ██████  ██   ████  █████   ██████  ██████  ███████
    */

    {
        name: 'bundle.id.cronjobs',
        state: {
            abstract: true,
            resolve: {
                webspace: (bundle: any, webspaceModel: WebspaceModelService) => {
                    return webspaceModel
                    .list(undefined, undefined, {field: 'BundleId', value: bundle.id})
                    .then((result: any) => result.data[0]);
                }
            },
            url: '/cronjobs'
        }
    },
    {
        name: 'bundle.id.cronjobs.overview',
        state: {
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('BundleCronjobs');
                },
                overviewModel: (cronjobOverviewModel: CronjobOverviewModel) => cronjobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('WebspaceOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('BundleCronjobs');
                }
            },
            url: '/overview',
            views: {
                'content@authenticated': {
                    template: `<template-cronjob-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    bundle="$resolve.bundle"
                                    overview-model="$resolve.overviewModel"
                                ></template-cronjob-overview>`
                }
            }
        }
    },
    {
        name: 'bundle.id.cronjobs.new',
        state: {
            resolve: {
                // static definition for cronjob options
                cronjob: getDefaultCronjob
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-cronjob-new cronjob="$resolve.cronjob"></template-cronjob-new>'
                }
            }
        }
    },
    {
        name: 'bundle.id.cronjobs.id',
        state: {
            abstract: true,
            resolve: {
                cronjob: ($stateParams: any, webspace: any) => {
                    return webspace.cronJobs[$stateParams.cronjobId];
                },
                cronjobId: ($stateParams: any) => $stateParams.cronjobId
            },
            url: '/id/{cronjobId}'
        }
    },
    {
        name: 'bundle.id.cronjobs.id.edit',
        state: {
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: '<template-cronjob-edit cronjob="$resolve.cronjob"></template-cronjob-edit>'
                }
            }
        }
    }
];
