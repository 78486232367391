import * as ng from 'angular';
import {
    DatabaseAccessObject,
    DatabaseUserObject
} from '../../../../../molecules/panels/panel-rows/panel-row-access/database/database-access';
import {
    WebspaceAccessObject,
    WebspaceUserObject
} from '../../../../../molecules/panels/panel-rows/panel-row-access/webhosting/webspace-access';
import { OrganismEditFormDatabaseController } from '../../../../../organisms/forms/edit-forms';
import { OrganismEditFormWebspaceUsersController } from '../../../../../organisms/forms/edit-forms';
import { MoleculeFormEditController } from '../../../../forms/form-edit/form-edit';
import { MoleculePanelEditTableController } from '../../../panel-edit/table/panel-edit-table';
import './users.scss';

export interface AccessLevels {
    type: string;
    label: string;
    shortLabel?: string;
}
export interface ServiceAccessLevels {
    database: AccessLevels[];
    webspace: AccessLevels[];
}

export class MoleculePanelEditRowTableUsersController implements ng.IController {
    public static $inject: string[] = ['$timeout'];

    public service;
    public $panelEditTable: MoleculePanelEditTableController; // speicher button aktivieren
    public $organismEditForm: OrganismEditFormDatabaseController | OrganismEditFormWebspaceUsersController;
    public $editForm: MoleculeFormEditController;
    public accessLevels: ServiceAccessLevels;
    public removeFromList: any[] = [];
    public disabledAccessLevels: any[] = [];

    private $organismEditFormDatabase: OrganismEditFormDatabaseController;
    private $organismEditFormWebspace: OrganismEditFormWebspaceUsersController;
    private getTemplateName: string;
    private serviceObjectName: string;
    private _accesses: any[];
    private _origAccesses: any[] | null;

    constructor(
        private $timeout: ng.ITimeoutService
    ) {
        this._accesses = [];
        this._origAccesses = null;
    }

    public $onInit() {
        this.setServiceParams();
        this.getRemoveFromList();
    }

    public get accesses() {
        this.$organismEditForm[this.serviceObjectName].accesses = this.getAccesses();
        return this.$organismEditForm[this.serviceObjectName].accesses;
    }

    public get newUserList() {
        return this.$organismEditForm.newUserList;
    }

    public get deleteUserList() {
        return this.$organismEditForm.deleteUserList;
    }

    public getRemoveFromList = () => {
        this.removeFromList = ng.copy(this.accesses);
        this.removeFromList = this.removeFromList.concat(this.newUserList);
    };

    public setUserEdit = (access) => {
        this.ftpLimitedAccess(access);
        const userEditIndex = this.$organismEditForm.userInList(access, this.$organismEditForm.editUserList);
        if (userEditIndex < 0) {
            this.$organismEditForm.editUserList.push(access);
        }
    };

    public inEditList = (access) => {
        return this.$organismEditForm.userInList(access, this.$organismEditForm.editUserList) >= 0;
    };

    public toggleUserDelete(access) {
        const userDeleteIndex = this.$organismEditForm.userInList(access, this.deleteUserList);
        if (userDeleteIndex >= 0) {
            this.deleteUserList.splice(userDeleteIndex, 1);
        } else {
            this.deleteUserList.push(access);
        }
    }

    public deleteButtonActiv(access) {
        return this.$organismEditForm.userInList(access, this.deleteUserList) < 0;
    }

    public inDeleteList(access) {
        return this.$organismEditForm.userInList(access, this.deleteUserList) >= 0;
    }

    public addNewAccess = (returnObject) => {
        (this.$organismEditForm.newAccessList as (DatabaseAccessObject | WebspaceAccessObject)[])
            .push(ng.copy(returnObject.access));
        (this.$organismEditForm.newUserList as (DatabaseUserObject | WebspaceUserObject)[])
            .push(ng.copy(returnObject.user));
        this.getRemoveFromList();
    };

    public newAccessDelete(index) {
        this.$organismEditForm.newAccessList.splice(index, 1);
        this.$organismEditForm.newUserList.splice(index, 1);
        this.getRemoveFromList();
    }

    public getTemplate = () => {
        return require('./' + this.getTemplateName + '.html?external');
    };

    public ftpLimitedAccess = (userObject) => {
        this.$timeout(() => {
            this.disabledAccessLevels = [];
            if (userObject.ftpAccess === true) {
                this.disabledAccessLevels.push('ftpLimited');
                userObject.ftpLimited = false;
            }
            if (userObject.ftpLimited === true) {
                this.disabledAccessLevels.push('ftpAccess');
                this.disabledAccessLevels.push('sshAccess');
                userObject.ftpAccess = false;
                userObject.sshAccess = false;
            }
            if (userObject.sshAccess === true) {
                this.disabledAccessLevels.push('ftpLimited');
                userObject.ftpLimited = false;
            }
        });
    };

    public isDisabledAccessType = (accessType) => {
        return this.disabledAccessLevels.indexOf(accessType) >= 0;
    };

    public isChangeUserPassword = (access) => {
        if (!access || !access.password) {
            return false;
        }

        return access.password?.length > 0;
    };

    private getAccesses = () => {
        if (this.$panelEditTable.showEditableContent && !this.$panelEditTable.resetDataFromOriginalData) {
            // on edit mode, return _accesses
            return this._accesses;
        } else if (this._origAccesses !== null && !this.$panelEditTable.resetDataFromOriginalData) {
            // on readonly mode, are original accesses already set
            return this._origAccesses;
        } else if (this.$organismEditForm[this.getServiceOriginalObjectName()] === undefined) {
            return [];
        }

        // on init or on reset, set data of saved original data and copy to private _accesses
        this._origAccesses = [];
        this.$organismEditForm[this.getServiceOriginalObjectName()].accesses.map((access) => {
            const tmpAccess = this.$organismEditForm.setAccess(access);
            if (tmpAccess !== null) {
                this._origAccesses.push(ng.copy(tmpAccess));
            }
        });

        // Reset panel edit component flag
        this.$panelEditTable.resetDataFromOriginalData = false;
        // Reset _accesses with original data
        this._accesses = ng.copy(this._origAccesses);

        this.getRemoveFromList();
        return this._origAccesses;
    };

    private setServiceParams() {
        switch (this.service) {
            case ('database'):
                this.$organismEditForm = this.$organismEditFormDatabase;
                this.serviceObjectName = 'database';
                this.getTemplateName = 'database-accesses';
                break;
            case ('webspace'):
                this.$organismEditForm = this.$organismEditFormWebspace;
                this.serviceObjectName = 'webspace';
                this.getTemplateName = 'webspace-users';
                break;
            default:
                break;
        }
    }

    private getServiceOriginalObjectName() {
        return 'original' + this.serviceObjectName.charAt(0).toUpperCase() + this.serviceObjectName.slice(1);
    }
}

export class MoleculePanelEditRowTableUsersComponent implements ng.IComponentOptions {
    public require = {
        $editForm: '^moleculeFormEdit',
        $organismEditFormDatabase: '?^organismEditFormDatabase',
        $organismEditFormWebspace: '?^organismEditFormWebspaceUsers',
        $panelEditTable: '^moleculePanelEditTable'
    };
    public bindings = {
        service: '@',
        validationInstructions: '<'
    };
    public controller = MoleculePanelEditRowTableUsersController;
    public controllerAs = '$PanelEditRowTableUsers';
    public template = require('./users.html');
}
