import * as ng from 'angular';
import { PriceObject, SelectBoxContentCreator, SelectedProduct } from '../../../../services';
import { WizardObject } from '../wizard-supervisor/wizard-supervisor';

export class MoleculeWizardProductSelectController implements ng.IController {
    public static $inject: string[] = ['$q', '$state', 'selectBoxContentCreator'];

    public serviceObject: WizardObject;
    public selectBoxContents: any;
    public selectedBillingCycle: PriceObject;
    public accountId = '';
    public loadingData = true;

    constructor(
        private $q: ng.IQService,
        private $state: ng.ui.IStateService,
        private selectBoxContentCreator: SelectBoxContentCreator
    ) {}

    public $onInit() {
        this.loadingData = true;
        this._createSelectBoxes();
    }

    public $onChanges(changes: any) {
        if (changes.accountId !== undefined && typeof(changes.accountId.previousValue) === 'string') {
            this._createSelectBoxes();
        }
    }

    public showProductSelection = () => {
        return ['domain', 'redirection', 'vhost'].indexOf(this.serviceObject.service) === -1 && !this.loadingData;
    };

    public get showBillingCycleSelect() {
        return !([undefined, null].indexOf(this.serviceObject.selectedProduct) >= 0
            || [undefined, null].indexOf(this.serviceObject.selectedProduct.prices) >= 0
            || this.serviceObject.selectedProduct.prices.length === 1
            || !this.showProductSelection()
            || (this.serviceObject.display.contingentAvailable
                && this.serviceObject.settings.selectedContingent === undefined));
    }

    public checkPoolBundleAvailability = () => {
        if ([undefined, null, false].indexOf(this.serviceObject.display.inMachine) >= 0) {
            return [undefined, null].indexOf(this.serviceObject.selectedProduct) === -1
                && [undefined, null].indexOf(this.serviceObject.selectedProduct.prices) === -1;
        }
        return false;
    };

    public get showBillingCycleSelection() {
        return [undefined, null].indexOf(this.serviceObject.display) >= 0
            || [undefined, null, false].indexOf(this.serviceObject.display.hideBillingCycleSelection) >= 0;
    }

    private _createSelectBoxes = () => {
        this.selectBoxContents = [];
        switch (this.serviceObject.service) {
            case 'bundle':
                this.selectBoxContentCreator.createBundleSelectBoxes(
                    this.serviceObject.settings.createData.accountId
                ).then(
                    (reply) => {
                        this.selectBoxContents = reply;
                        this.serviceObject.display.factSheetItems = reply;
                        this.loadingData = false;
                    }
                );
                break;

            case 'managedapplication':
                this.selectBoxContentCreator.createSelectBoxes(
                    this.serviceObject.service,
                    this.serviceObject.productFamilies,
                    this.serviceObject.display.showFamiliesWithSingleProduct,
                    this.serviceObject.settings.createData.nextcloud.accountId
                ).then((reply) => {
                    this.selectBoxContents = reply;
                    this.loadingData = false;
                });
                break;

            default:
                this.selectBoxContentCreator.createSelectBoxes(
                    this.serviceObject.service,
                    this.serviceObject.productFamilies,
                    this.serviceObject.display.showFamiliesWithSingleProduct,
                    this.serviceObject.settings.accountId
                ).then((reply) => {
                    this.selectBoxContents = reply;
                    this.loadingData = false;
                });
                break;
        }
    };
}

export class MoleculeWizardProductSelectComponent implements ng.IComponentOptions {
    public bindings = {
        accountId: '<',
        productFamilies: '<',
        reloadSelectbox: '<',
        serviceObject: '='
    };
    public template = require('./wizard-product-select.html');
    public controller =  MoleculeWizardProductSelectController;
}
