import ng from 'angular';

import { AccountModelService, AuthContextService } from '@/services';
import { AccountApi } from '@/types';
import { UiRights } from '@/configuration/rights';

export class OrganismWizardAccountController {
    public static $inject: string[] = ['$timeout', '$translate', 'accountModel'];

    public selectedAccount: AccountApi.Account | AccountApi.Subaccount;
    public accountId: string;
    public ownerAccount: AccountApi.Account | AccountApi.Subaccount;
    public hasSubAccounts = false;
    public onlySubaccountsFirstLevel: boolean;
    public titleOverlay: string;
    public panelTitle: string;
    public callbackOnAccountSelect: (acc: AccountApi.Subaccount) => void;

    private _reloadAccountObject = false;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService
    ) {}

    public $onInit(): void {
        this.panelTitle = this.titleOverlay ? this.titleOverlay : this.$translate.instant('TR_100119-c9e705_TR');
        this.onlySubaccountsFirstLevel = this.onlySubaccountsFirstLevel || true;

        if (this.ownerAccountProduct) {
            this.selectedAccount = this.ownerAccount;
            this.accountId = this.ownerAccount.id;
        } else {
            this.selectedAccount = [undefined, null].indexOf(this.selectedAccount) < 0
                ? this.selectedAccount
                : AuthContextService.account;
            this.accountId = this.selectedAccount.id;
        }

        if (this.hasAccountRights) {
            void this.accountModel.listSubaccounts(1).then((reply) => {
                const accounts: AccountApi.Account[] = reply?.data;
                this.hasSubAccounts = accounts.length > 0;
            });
        }
    }

    public get hasAccountRights(): boolean {
        return AuthContextService.isGranted(UiRights.ACC_SUBACCOUNT_LIST);
    }

    public onAccountSelect = (accountId: string): void => {
        if (this._reloadAccountObject || this.selectedAccount === undefined || this.selectedAccount.id === accountId) {
            /**
             *  The Account Selection component set the account during initialization.
             *  This triggers the callback function in the lower component and executes
             *  the onAccountSelct method. However, this should not be executed when
             *  initializing the component, since the Account Object is already set.
             */
            return;
        }

        if (accountId === AuthContextService.account.id) {
            void this.$timeout(() => {
                this.selectedAccount = AuthContextService.account;
            });
        } else {
            this._reloadAccountObject = true;
            this.selectedAccount = null;
            void this.accountModel.findOne(accountId).then((res) => {
                if (this.callbackOnAccountSelect) {
                    this.callbackOnAccountSelect(res);
                }
                void this.$timeout(() => {
                    this.selectedAccount = res;
                    this._reloadAccountObject = false;
                });
            });
        }
    };

    public get showAccountSelection(): boolean {
        return this.hasAccountRights && this.hasSubAccounts;
    }

    public set ownerAccountProduct(value) {
        this.ownerAccount = value;
    }
    public get ownerAccountProduct(): AccountApi.Account | AccountApi.Subaccount {
        return this.ownerAccount;
    }
}

export class OrganismWizardAccountComponent implements ng.IComponentOptions {
    public bindings = {
        onlySubaccountsFirstLevel: '=?',
        selectedAccount: '=',
        titleOverlay: '@?',
        callbackOnAccountSelect: '<?',
        ownerAccount: '<?'
    };
    public template = require('./wizard-account-selection.html');
    public controller = OrganismWizardAccountController;
}
