import * as ng from 'angular';

import * as Types from '@/types';
import { UiLanguagesConst, UiRights } from '../../configuration';
import {
    AuthContextService,
    FilterCacheService,
    ManagedApplicationRobotService,
    ModelHelper,
    NavigationService,
    RedirectOnNotFoundCallback,
    RestrictionHelperService,
    StorageJobModelService,
    StorageJobOverviewModel,
    StorageOverviewModel,
    BackupModelService,
    UserSettingsManagerService
} from '../../services';
import * as stateUtilityFunctions from '../../services/state-utility-functions';

export const STORAGE_ROUTES = [
    {
        name: 'storage',
        state: {
            abstract: true,
            parent: 'authenticated',
            url: '/storage'
        }
    },
    {
        name: 'storage.dashboard',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST,
                    UiRights.MANAGED_APPLICATION_JOBS_LIST
                ]
            },
            onEnter: (navigation: NavigationService, userSettingsManager: UserSettingsManagerService) => {
                userSettingsManager.getUiSettings().then(
                    (result) => {
                        if (result?.overviewSettings?.StorageOverviewCtrl?.expertView) {
                            navigation.go('storage.storage-products.overview');
                        }
                    }
                );
            },
            url: '/',
            views: {
                'content@authenticated': {
                    template: '<template-storage-dashboard></template-storage-dashboard>'
                }
            }
        }
    },
    {
        name: 'storage.storage-products',
        state: {
            abstract: true,
            url: '/storage-products'
        }
    },
    {
        name: 'storage.storage-products.new',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_CREATE
            },
            url: '/new',
            views: {
                'content@authenticated': {
                    template: '<template-nextcloud-wizard></template-nextcloud-wizard>'
                }
            }
        }
    },
    {
        name: 'storage.storage-products.overview',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST
            },
            resolve: {
                extendedFilters: ($stateParams: ng.ui.IStateParamsService, filterCache: FilterCacheService) => {
                    if (ng.isDefined($stateParams.fancyFilter) && $stateParams.fancyFilter !== '') {
                        return JSON.parse($stateParams.fancyFilter);
                    }
                    return filterCache.resolveExtendedFilters('Storage');
                },
                overviewModel: (storageOverviewModel: StorageOverviewModel) => storageOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('StorageOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('Storage');
                }
            },
            url: '/overview/{fancyFilter}',
            views: {
                'content@authenticated': {
                    template: `<template-storage-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                               ></template-storage-overview>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id',
        state: {
            abstract: true,
            resolve: {
                storageProduct: ($stateParams, managedApplicationRobot: ManagedApplicationRobotService) => {
                    return managedApplicationRobot.nextcloudsList(
                        { field: 'NextcloudId', value: $stateParams.storageProductId },
                        1,
                        1,
                        undefined
                    )
                        .then(stateUtilityFunctions.check404('Storage Product not found!', 'storage'))
                        .then(ModelHelper.returnFindOneResponse);
                },
                storageGroupFolders: ($stateParams, managedApplicationRobot: ManagedApplicationRobotService) => {
                    return managedApplicationRobot.nextcloudGroupFoldersList($stateParams.storageProductId)
                        .then((response) => {
                            return response.response.groupfolders;
                        });
                },
                appList: (
                    $stateParams: ng.ui.IStateParamsService,
                    managedApplicationRobot: ManagedApplicationRobotService
                ) => managedApplicationRobot.nextcloudAppsList($stateParams.storageProductId).then(
                    (response) => response.response.data
                )
            },
            url: '/id/{storageProductId}'
        }
    },
    {
        name: 'storage.storage-products.id.admin-access',
        state: {
            data: {
                isGrantedAny: [UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT, UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN]
            },
            url: '/admin-access',
            views: {
                'content@authenticated': {
                    template:
                        `<template-nextcloud-admin-access
                            nextcloud="$resolve.storageProduct"
                        ></template-nextcloud-admin-access>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.config',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
            },
            resolve: {
                storageConfiguration: (
                    $stateParams: ng.ui.IStateParamsService,
                    managedApplicationRobot: ManagedApplicationRobotService
                ) => {
                    return managedApplicationRobot
                        .nextcloudGetConfiguration(
                            $stateParams.storageProductId,
                            UiLanguagesConst[AuthContextService.user.language]
                        )
                        .then((response) => response.response);
                }
            },
            url: '/config',
            views: {
                'content@authenticated': {
                    template: `<template-storage-product-config
                                    storage-product="$resolve.storageProduct"
                                    nextcloud-configuration="$resolve.storageConfiguration"
                               ></template-storage-product-config>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.edit',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
            },
            url: '/edit',
            views: {
                'content@authenticated': {
                    template: '<template-storage-product-edit></template-storage-product-edit>'
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.app-center',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
            },
            url: '/app-center',
            views: {
                'content@authenticated': {
                    template: `<template-storage-product-app-center
                        storage-product="$resolve.storageProduct"
                    ></template-storage-product-app-center>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.app',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
            },
            resolve: {
                appConfiguration: (
                    $stateParams: ng.ui.IStateParamsService,
                    managedApplicationRobot: ManagedApplicationRobotService,
                    storageProduct: Types.ManagedApplicationApi.Nextcloud
                ) => {
                    if (storageProduct.enabledApps?.includes($stateParams.appId)) {
                        return managedApplicationRobot
                            .nextcloudAppGetConfiguration(
                                $stateParams.appId,
                                storageProduct.id,
                                UiLanguagesConst[AuthContextService.user.language]
                            )
                            .then((response) => response.response);
                    }
                    return managedApplicationRobot
                        .nextcloudAppGetConfiguration(
                            $stateParams.appId,
                            undefined,
                            UiLanguagesConst[AuthContextService.user.language]
                        )
                        .then((response) => response.response);
                },
                storageProduct: (
                    $stateParams: ng.ui.IStateParamsService,
                    managedApplicationRobot: ManagedApplicationRobotService
                ) => managedApplicationRobot
                        .nextcloudsList(
                            { field: 'NextcloudId', value: $stateParams.storageProductId },
                            1,
                            1,
                            undefined
                        )
                        .then(stateUtilityFunctions.check404('Storage Product not found!', 'storage'))
                        .then(ModelHelper.returnFindOneResponse)
            },
            url: '/app/{appId}',
            views: {
                'content@authenticated': {
                    template: `<template-storage-product-app-config
                        storage-product="$resolve.storageProduct"
                        app-configuration="$resolve.appConfiguration"
                    ></template-storage-product-app-config>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.app-test',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
            },
            resolve: {
                appConfiguration: () => {
                    const appConfig = require('./appConfig.json');
                    return {
                        appConfigSchema: JSON.stringify(appConfig),
                        appCurrentConfig: JSON.stringify({})
                    };
                },
                storageProduct: ($stateParams, managedApplicationRobot: ManagedApplicationRobotService) => {
                    return managedApplicationRobot
                        .nextcloudsList(
                            { field: 'NextcloudId', value: $stateParams.storageProductId },
                            1,
                            1,
                            undefined
                        )
                        .then(stateUtilityFunctions.check404('Storage Product not found!', 'storage'))
                        .then(ModelHelper.returnFindOneResponse);
                }
            },
            url: '/app-test',
            views: {
                'content@authenticated': {
                    template: '<template-storage-product-app-config storage-product="$resolve.storageProduct" app-configuration="$resolve.appConfiguration"></template-storage-product-app-config>'
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.addons',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
            },
            url: '/addons',
            views: {
                'content@authenticated': {
                    template: `<template-nextcloud-addons
                                   app-list="$resolve.appList"
                                   nextcloud="$resolve.storageProduct"
                               ></template-nextcloud-addons>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.upgrade',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
            },
            url: '/upgrade',
            views: {
                'content@authenticated': {
                    template: `<template-nextcloud-upgrade
                                   nextcloud="$resolve.storageProduct"
                               ></template-nextcloud-upgrade>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.use-own-domain',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
            },
            url: '/use-own-domain',
            views: {
                'content@authenticated': {
                    template: `<template-nextcloud-use-own-domain
                                    nextcloud="$resolve.storageProduct"
                               ></template-nextcloud-use-own-domain>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.rename',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT
            },
            url: '/rename',
            views: {
                'content@authenticated': {
                    template: `<template-nextcloud-rename
                                    nextcloud="$resolve.storageProduct"
                               ></template-nextcloud-rename>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.cancel',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_DELETE
            },
            url: '/cancel',
            views: {
                'content@authenticated': {
                    template:
                        `<template-nextcloud-delete
                            nextcloud="$resolve.storageProduct"
                        ></template-nextcloud-delete>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.restore',
        state: {
            data: {
                isGrantedAny: [
                    UiRights.MANAGED_APPLICATION_NEXTCLOUD_DELETE,
                    UiRights.MANAGED_APPLICATION_NEXTCLOUD_RESTORE
                ]
            },
            url: '/restore',
            views: {
                'content@authenticated': {
                    template:
                        `<template-nextcloud-restore
                            nextcloud="$resolve.storageProduct"
                        ></template-nextcloud-restore>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.restrictions',
        state: {
            data: {
                isGrantedAll: [UiRights.MANAGED_APPLICATION_NEXTCLOUD_EDIT, UiRights.ADMIN_SYSTEM_SUPER_USER_READ]
            },
            resolve: {
                restrictionHelper: (
                    storageProduct,
                    restrictionHelper: RestrictionHelperService,
                    managedApplicationRobot: ManagedApplicationRobotService
                ) => {
                    restrictionHelper.init(
                        storageProduct,
                        'nextcloud',
                        managedApplicationRobot,
                        'nextcloudModifyRestrictionsRaw'
                    );
                    return restrictionHelper;
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/restrictions',
            views: {
                'content@authenticated': {
                    template: `<template-restrictions-edit
                        api-object="$resolve.storageProduct"
                        restriction-helper="$resolve.restrictionHelper"
                        show-object-id="$resolve.showObjectId"
                    ></template-restrictions-edit>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.id.backup',
        state: {
            data: {
                isGrantedAny: [ UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT, UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN ]
            },
            resolve: {
                backups:
                (storageProduct, backupModel: BackupModelService) => [undefined, null, ''].indexOf(storageProduct) === -1
                ? backupModel.list('managedapplication', 'nextcloud', storageProduct.id)
                : [],
                restrictionHelper: (
                    storageProduct,
                    restrictionHelper: RestrictionHelperService,
                    managedApplicationRobot: ManagedApplicationRobotService
                ) => {
                    restrictionHelper.init(
                        storageProduct,
                        'nextcloud',
                        managedApplicationRobot,
                        'nextcloudModifyRestrictionsRaw'
                    );
                    return restrictionHelper;
                },
                showObjectId: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.isShowId();
                }
            },
            url: '/backup',
            views: {
                'content@authenticated': {
                    template: `<template-storage-backup
                    storage-product="$resolve.storageProduct"
                    backups="$resolve.backups"
                    ></template-storage-backup>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.jobs',
        state: {
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_JOBS_LIST
            },
            resolve: {
                extendedFilters: (filterCache: FilterCacheService) => {
                    return filterCache.resolveExtendedFilters('StorageJobs');
                },
                overviewModel: (storageJobOverviewModel: StorageJobOverviewModel) => storageJobOverviewModel,
                overviewUiSettings: (userSettingsManager: UserSettingsManagerService) => {
                    return userSettingsManager.getSettingsForOverview('StorageOverviewCtrl');
                },
                simpleFilter: (filterCache: FilterCacheService) => {
                    return filterCache.resolveSimpleFilter('StorageJobs');
                }
            },
            url: '/jobs',
            views: {
                'content@authenticated': {
                    template: `<template-storage-job-overview
                                    simple-filter="$resolve.simpleFilter"
                                    extended-filters="$resolve.extendedFilters"
                                    overview-ui-settings="$resolve.overviewUiSettings"
                                    overview-model="$resolve.overviewModel"
                                ></template-storage-job-overview>`
                }
            }
        }
    },
    {
        name: 'storage.storage-products.jobs.id',
        state: {
            abstract: true,
            data: {
                isGranted: UiRights.MANAGED_APPLICATION_JOBS_LIST
            },
            resolve: {
                job: (
                    $stateParams: ng.ui.IStateParamsService,
                    redirectOnNotFound: RedirectOnNotFoundCallback,
                    storageJobModel: StorageJobModelService
                ) => {
                    return storageJobModel.findOne($stateParams.jobId).then(null, redirectOnNotFound);
                }
            },
            url: '/id/{jobId}'
        }
    },
    {
        name: 'storage.storage-products.jobs.id.details',
        state: {
            url: '/details',
            views: {
                'content@authenticated': {
                    template: `<template-storage-job-details
                                    job="$resolve.job"
                                ></template-storage-job-details>`
                }
            }
        }
    }
];
