import ng from 'angular';

import { CalculatePriceFilter, ConvertAmountFilter } from '@/filters';
import { AuthContextService, PricelistHelperService } from '@/services';

import { DisableFilter } from '../overview';
import { BillingApi } from '@/types';

export interface OverviewPricelistDataObject {
    filters: {
        simpleFilter: any;
        extendedFilters: any;
    };
    serviceItemList: any;
    additionalFilters?: any[];
}

export interface IDomainCalcPrices extends BillingApi.DomainPrice {
    trusteePrice: string;
    createPrice: string;
    ownerChangePrice: string;
    renewPrice: string;
    restorePrice: string;
    transferPrice: string;
    updatePrice: string;
}

export interface IProductOverviewUiPrice extends BillingApi.ArticlePurchasePrice {
    uiPrice: number| string; // could be 'N/A' ... not nice i know
}

export interface IProductOverviewData {
    prices: IProductOverviewUiPrice[];
    productCode: string;
    productCodeTemplate: string;
    productName: string;
    productType: string;
}

export class OrganismPricelistOverviewController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'authContext',
        'calculatePriceFilter',
        'convertAmountFilter',
        'pricelistHelper'
    ];

    public expandWrapperValue: boolean;
    public disableFilter: DisableFilter;
    public overviewData: OverviewPricelistDataObject;
    public overviewServiceData;
    public overviewLoaded = false;
    public listType: string;
    public isCommercialCustomer: boolean;
    // dummy variable for compatibility with fancy-filter-bar.ts (do not remove)
    public dataNotLoadedOnInit = false;

    private origItems: any[] = [];

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private calculatePriceFilter: CalculatePriceFilter,
        private convertAmountFilter: ConvertAmountFilter,
        private pricelistHelper: PricelistHelperService
    ) {
        this.overviewLoaded = false;
    }

    public $onInit = (): void => {
        this.isCommercialCustomer = AuthContextService.account.isCommercialCustomer;
        this.expandWrapperValue = true;
        this.disableFilter = this.disableFilter || {
            fancyFilter: false,
            searchFilter: false
        };
        this._setListType();

        this.overviewData = {
            additionalFilters: [],
            filters: {
                extendedFilters: this.overviewServiceData.extendedFilters,
                simpleFilter: this.overviewServiceData.simpleFilter
            },
            serviceItemList: []
        };
        this.onLoad();

    };

    public overviewLoad = (): void => {
        window.scrollTo(0, 0);
        this.filterItems();
    };

    public onLoad = (): void => {
        if (this.authContext.isRootOrCompanyAccount) {
            void this.$timeout(() => this.overviewLoaded = true);
            return;
        }
        void this._getPricelist();
    };

    public showUiPrice = (product: IProductOverviewData, billingCycle: number): string => {
        if ([undefined, null].indexOf(product) >= 0
            || typeof billingCycle !== 'string'
            || product.prices === undefined
            || product.prices[billingCycle] === undefined
            || product.prices[billingCycle].uiPrice === 0
            || [undefined, null].indexOf(product.prices[billingCycle].uiPrice) >= 0
        ) {
            return '-';
        } else if (product.prices[billingCycle].uiPrice === 'N/A') {
            return product.prices[billingCycle].uiPrice as string;
        } else {
            return `${this.convertAmountFilter(product.prices[billingCycle].uiPrice as number)}
                ${(product.prices[billingCycle].currency ).toUpperCase()}`;
        }
    };

    public getDomainPriceByType = (product: BillingApi.DomainPrice, priceType: string): string => {
        const domainPrice = this._getDomainPriceByParamKey(product, priceType);

        if ([-1, null, undefined].includes(domainPrice)) {
            return '-';
        }

        const price = this.calculatePriceFilter(domainPrice);

        return `${this.convertAmountFilter(price)} ${product.currency.toUpperCase()}`;
    };

    public getDomainPriceByNetValue = (product: BillingApi.DomainPrice, netValue: number): string => {
        // if the api value is null then the capability does not exist for this tld
        if ([null, undefined].includes(netValue)) {
            return '-';
        }

        // if the api value is -1 then the capability is request based
        if ([-1].includes(netValue)){
            return this.$translate.instant('TR_220520-c522c8_TR');
        }

        const price = this.calculatePriceFilter(netValue);
        return this.convertAmountFilter(price) + ' ' + product.currency.toUpperCase();
    };

    public showDiscountPercent = (orgPrice: number, promoPrice: number): string => {
        const org = this.calculatePriceFilter(orgPrice);
        const promo = this.calculatePriceFilter(promoPrice);
        const discount = this.convertAmountFilter(100 - ((promo / org) * 100));
        const flatDiscount = discount.slice(0, discount.indexOf(','));

        return `- ${flatDiscount} %`;
    };

    private _getDomainPriceByParamKey = (product: BillingApi.DomainPrice, priceType: string): number => {
        let domainPrice;
        Object.entries(product)
            .some(
                ([key, value]) => {
                    if (key === priceType) {
                        domainPrice = value;
                        return true;
                    }

                    return false;
                }
            );
        return domainPrice;
    };

    private filterItems = (): void => {
        void this.$timeout(() => this.overviewLoaded = false);

        const simpleFilterName = this.overviewData.filters.simpleFilter.name;
        const simpleFilterValue = this.overviewData.filters.simpleFilter.value;

        const newItemList = this.origItems.filter((product) => {
            if (
                (product[simpleFilterName] !== undefined
                    && (product[simpleFilterName] as string)
                        .toLowerCase()
                        .indexOf((simpleFilterValue as string).toLowerCase()) >= 0)
            ) {
                return product;
            }
        });

        const durationFactor = 4;
        let itemLenghtDuration = (this.overviewData.serviceItemList.length * durationFactor);
        const newItmeLengthDuration = (newItemList.length * durationFactor);
        if (itemLenghtDuration > 0  && newItmeLengthDuration > itemLenghtDuration) {
            itemLenghtDuration = newItmeLengthDuration;
        }

        this.overviewData.serviceItemList = newItemList;
        void this.$timeout(() => this.overviewLoaded = true);
    };

    private calculateDomainPrices(domainList: BillingApi.DomainPrice[]): IDomainCalcPrices[] {
        return domainList.map((domain: IDomainCalcPrices) => {
            domain.renewPrice = this.getDomainPriceByNetValue(domain, domain.renew);
            domain.restorePrice = this.getDomainPriceByNetValue(domain, domain.restore);
            domain.trusteePrice = this.getDomainPriceByNetValue(domain, domain.trusteeService);
            domain.transferPrice = this.getDomainPriceByNetValue(domain, domain.transfer);
            domain.updatePrice = this.getDomainPriceByNetValue(domain, domain.update);
            domain.createPrice = this.getDomainPriceByNetValue(domain, domain.create);
            domain.ownerChangePrice = this.getDomainPriceByNetValue(domain, domain.ownerChange);
            return domain;
        });
    }

    private _getPricelist = (): PromiseLike<void> => {
        switch (this.listType) {
            case 'dns':
                return this.pricelistHelper.getDnsPriceDetails()
                    .then((dnsProducts) => {
                        this.origItems = dnsProducts;
                        this.overviewLoad();
                    });

            case 'domains':
                return this.pricelistHelper.getDomainsPriceDetails()
                    .then((domainProducts: BillingApi.DomainPrice[]) => {
                        this.origItems = this.calculateDomainPrices(domainProducts);
                        this.overviewLoad();
                        void this.$timeout(() => this.overviewLoaded = true);
                    });

            case 'ssl':
                return this.pricelistHelper.getSslPriceDetails()
                    .then((sslProducts) => {
                        this.origItems = sslProducts;
                        this.overviewLoad();
                    });

            case 'mailboxes':
                return this.pricelistHelper.getEmailPriceDetails()
                    .then((emailProducts) => {
                        this.origItems = emailProducts;
                        this.overviewLoad();
                    });

            case 'webhosting':
                return this.pricelistHelper.getWebhostingPriceDetails()
                    .then((webhostingProducts) => {
                        this.origItems = webhostingProducts;
                        this.overviewLoad();
                    });

            case 'databases':
                return this.pricelistHelper.getDatabasesPriceDetails()
                    .then((databasesProducts) => {
                        this.origItems = databasesProducts;
                        this.overviewLoad();
                    });

            case 'machines':
                return this.pricelistHelper.getMachinePriceDetails()
                    .then((machineProducts) => {
                        this.origItems = machineProducts;
                        this.overviewLoad();
                    });

            case 'promotions':
                return this.pricelistHelper.getPromoPriceDetails()
                    .then((promotionPrices: BillingApi.ArticlePurchasePrice[]) => {
                        this.origItems = promotionPrices;
                        this.overviewLoad();
                    });

            case 'storage-product':
                return this.pricelistHelper.getStorageProductPriceDetails()
                    .then((storageProducts) => {
                        this.origItems = storageProducts;
                        this.overviewLoad();
                    });

            case 'changes':
                return this.pricelistHelper.getChangedPriceDetails()
                    .then((changedPrices) => {
                        this.origItems = changedPrices;
                        this.overviewLoad();
                    });

            default: return;
        }
    };

    private _setListType = (): void => {
        const stateName = this.$state.current.name;
        this.listType = stateName.split('.').pop();
    };
}

export class OrganismPricelistOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        disableFilter: '<',
        overviewServiceData: '<'
    };
    public controller =  OrganismPricelistOverviewController;
    public controllerAs = 'OverviewPricelistCtrl';
    public template = require('./pricelist-overview.html');
}
