import * as ng from 'angular';

export type JobStateFilter = (value: string) => string;
export type JobActionFilter = (value: string) => string;
export type ObjectStatusFilter = (value: string) => string;
export type ObjectTypeFilter = (value: string) => string;

export class JobState {
    public static $inject: string[] = ['$translate'];

    public static Factory($translate: ng.translate.ITranslateService) {
        const jobStates: { [key: string]: string } = {
            canceled:                    /* translationID */ 'GENERAL.JOB.STATUS.CANCELED',
            deferred:                    /* translationID */ 'GENERAL.JOB.STATUS.DEFERRED',
            done:                        /* translationID */ 'GENERAL.JOB.STATUS.DONE',
            failed:                      /* translationID */ 'GENERAL.JOB.STATUS.FAILED',
            inProgress:                  /* translationID */ 'GENERAL.JOB.STATUS.IN-PROGRESS',
            new:                         /* translationID */ 'GENERAL.JOB.STATUS.NEW',
            onHold:                      /* translationID */ 'GENERAL.JOB.STATUS.ON-HOLD',
            restart:                     /* translationID */ 'TR_291220-55e674_TR',
            running:                     /* translationID */ 'GENERAL.JOB.STATUS.IN-PROGRESS',
            scheduled:                   /* translationID */ 'GENERAL.JOB.STATUS.SCHEDULED',
            successful:                  /* translationID */ 'GENERAL.JOB.STATUS.SUCCESSFUL',
            support:                     /* translationID */ 'GENERAL.JOB.STATUS.SUPPORT',
            timed:                       /* translationID */ 'GENERAL.JOB.STATUS.TIMED',
            transferDoneButUpdateFailed: /* translationID */ 'GENERAL.JOB.STATUS.TRANSFER-DONE-BUT-UPDATE-FAILED',
            waiting:                     /* translationID */ 'GENERAL.JOB.STATUS.WAITING'
        };
        return (value: string) => {
            if ([undefined, null, ''].indexOf(jobStates[value]) >= 0) {
                return value;
            }
            return $translate.instant(jobStates[value]);
        };
    }
}

export class JobAction {
    public static $inject: string[] = ['$translate'];

    public static Factory($translate: ng.translate.ITranslateService) {
        const jobActions = {
            addIndividualDomainName:            /* translationID */ 'TR_221220-fb6a14_TR',
            appInstall:                         /* translationID */ 'GENERAL.JOB.ACTION.APPINSTALL',
            authorizeName:                      /* translationID */ 'GENERAL.JOB.ACTION.AUTHORIZE-NAME',
            applyChangeContentChanges:          /* translationID */ 'TR_070621-b220a0_TR',
            applyTemplateChanges:               /* translationID */ 'TR_260220-d39038_TR',
            blockWriteAccess:                   /* translationID */ 'GENERAL.JOB.ACTION.BLOCK-WRITE-ACCESS',
            cancel:                             /* translationID */ 'GENERAL.JOB.ACTION.CANCEL',
            changeContent:                      /* translationID */ 'GENERAL.JOB.ACTION.CHANGE-CONTENT',
            changeProduct:                      /* translationID */ 'TR_221220-d2dd85_TR',
            contactCreate:                      /* translationID */ 'GENERAL.JOB.ACTION.CONTACTCREATE',
            contactDelete:                      /* translationID */ 'GENERAL.JOB.ACTION.CONTACTDELETE',
            contactOwnerVerification:           /* translationID */ 'GENERAL.JOB.ACTION.CONTACTOWNERVERIFICATION',
            contactSync:                        /* translationID */ 'GENERAL.JOB.ACTION.CONTACTSYNC',
            contactUpdate:                      /* translationID */ 'GENERAL.JOB.ACTION.CONTACTUPDATE',
            create:                             /* translationID */ 'TR_140119-50d91c_TR',
            delete:                             /* translationID */ 'GENERAL.JOB.ACTION.DELETE',
            deleteManagedSslIrreversible:       /* translationID */ 'GENERAL.JOB.ACTION.DELETE-MANAGED-SSL-IRREVERIBLE',
            deleteManagedSslRestorable:         /* translationID */ 'GENERAL.JOB.ACTION.DELETE-MANAGED-SSL-RESTORABLE',
            deletePermanently:                  /* translationID */ 'GENERAL.JOB.ACTION.DELETE-PERMANENTLY',
            deleteScheduled:                    /* translationID */ 'TR_140119-d46abf_TR',
            deleteScheduledPermanently:         /* translationID */ 'GENERAL.JOB.ACTION.DELETE-PERMANENTLY',
            deletionCancel:                     /* translationID */ 'GENERAL.JOB.ACTION.DELETION-CANCEL',
            disable:                            /* translationID */ 'GENERAL.JOB.ACTION.DISABLE',
            disableNextcloud:                   /* translationID */ 'TR_221220-5abb91_TR',
            disableRescue:                      /* translationID */ 'GENERAL.JOB.ACTION.DISABLE-RESCUE',
            domainAddOwnerChangeBlock:          /* translationID */ 'GENERAL.JOB.ACTION.DOMAINADDOWNERCHANGEBLOCK',
            domainCreate:                       /* translationID */ 'GENERAL.JOB.ACTION.DOMAINCREATE',
            domainCreateAuthInfo2:              /* translationID */ 'GENERAL.JOB.ACTION.DOMAINCREATEAUTHINFO2',
            domainDeactivateRenew:              /* translationID */ 'GENERAL.JOB.ACTION.DOMAINDEACTIVATERENEW',
            domainDelete:                       /* translationID */ 'GENERAL.JOB.ACTION.DOMAINDELETE',
            domainDeleteCanceled:               /* translationID */ 'GENERAL.JOB.ACTION.DOMAINDELETECANCELED',
            domainDeleteScheduled:              /* translationID */ 'TR_140119-d46abf_TR',
            domainMovedIn:                      /* translationID */ 'GENERAL.JOB.ACTION.DOMAINMOVEDIN',
            domainMovedOut:                     /* translationID */ 'GENERAL.JOB.ACTION.DOMAINMOVEDOUT',
            domainReactivateRenew:              /* translationID */ 'GENERAL.JOB.ACTION.DOMAINREACTIVATERENEW',
            domainRegistryDelete:               /* translationID */ 'TR_200721-86c153_TR',
            domainRemoveOwnerChangeBlock:       /* translationID */ 'GENERAL.JOB.ACTION.DOMAINREMOVEOWNERCHANGEBLOCK',
            domainRestore:                      /* translationID */ 'GENERAL.JOB.ACTION.DOMAINRESTORE',
            domainSupplierChangeIncoming:       /* translationID */ 'GENERAL.JOB.ACTION.DOMAINSUPPLIERCHANGEINCOMING',
            domainTransferIn:                   /* translationID */ 'GENERAL.JOB.ACTION.DOMAINTRANSFERIN',
            domainTransferInPushAccept:         /* translationID */ 'GENERAL.JOB.ACTION.DOMAINTRANSFERINPUSHACCEPT',
            domainTransferInPushReject:         /* translationID */ 'GENERAL.JOB.ACTION.DOMAINTRANSFERINPUSHREJECT',
            domainTransferOut:                  /* translationID */ 'GENERAL.JOB.ACTION.DOMAINTRANSFEROUT',
            domainTransferOutAck:               /* translationID */ 'GENERAL.JOB.ACTION.DOMAINTRANSFEROUTACK',
            domainTransferOutDone:              /* translationID */ 'GENERAL.JOB.ACTION.DOMAINTRANSFEROUTDONE',
            domainTransferOutFoa:               /* translationID */ 'GENERAL.JOB.ACTION.DOMAINTRANSFEROUTFOA',
            domainTransferOutNack:              /* translationID */ 'GENERAL.JOB.ACTION.DOMAINTRANSFEROUTNACK',
            domainTransferOutPush:              /* translationID */ 'GENERAL.JOB.ACTION.DOMAINTRANSFEROUTPUSH',
            domainTransferOutRequest:           /* translationID */ 'GENERAL.JOB.ACTION.DOMAINTRANSFEROUTREQUEST',
            domainUpdate:                       /* translationID */ 'GENERAL.JOB.ACTION.DOMAINUPDATE',
            domainWithdraw:                     /* translationID */ 'GENERAL.JOB.ACTION.DOMAINWITHDRAW',
            domainWithdrawCanceled:             /* translationID */ 'GENERAL.JOB.ACTION.DOMAINWITHDRAWCANCELED',
            domainWithdrawScheduled:            /* translationID */ 'GENERAL.JOB.ACTION.DOMAINWITHDRAWSCHEDULED',
            enable:                             /* translationID */ 'dc2fb62f-0cde-41d6-9015-2e5a170b4da0',
            enableNextcloud:                    /* translationID */ 'TR_221220-b52c48_TR',
            enableRescue:                       /* translationID */ 'GENERAL.JOB.ACTION.ENABLE-RESCUE',
            enableRescueRollback:               /* translationID */ 'GENERAL.JOB.ACTION.ENABLE-RESCUE-ROLLBACK',
            grantAdminAccess:                   /* translationID */ 'TR_221220-580235_TR',
            infrastructureRecordsUpdate:        /* translationID */ 'GENERAL.JOB.ACTION.INFRASTRUCTURE-RECORDS-UPDATE',
            install:                            /* translationID */ 'GENERAL.JOB.ACTION.INSTALL',
            installRollback:                    /* translationID */ 'GENERAL.JOB.ACTION.INSTALL-ROLLBACK',
            managedServerConfiguration:         /* translationID */ 'GENERAL.JOB.ACTION.MANAGED-SERVER-CONFIGURATION',
            managedServerConfigurationRollback: /* translationID */ 'GENERAL.JOB.ACTION.MANAGED-SERVER-CONFIGURATION-ROLLBACK',
            manualJob:                          /* translationID */ 'GENERAL.JOB.ACTION.MANUALJOB',
            modifyNextcloudGroupFolders:        /* translationID */ 'TR_221220-36b7a9_TR',
            modifyNextcloudUsers:               /* translationID */ 'TR_101219-1b649a_TR',
            move:                               /* translationID */ 'GENERAL.JOB.ACTION.MOVE',
            nextcloudCliUpdate:                 /* translationID */ 'TR_221220-582b89_TR',
            nextcloudUpgrade:                   /* translationID */ 'TR_221220-c3b6c2_TR',
            order:                              /* translationID */ 'GENERAL.JOB.ACTION.ORDER',
            orderManagedSsl:                    /* translationID */ 'GENERAL.JOB.ACTION.ORDER-MANAGED-SSL',
            powerOff:                           /* translationID */ 'GENERAL.JOB.ACTION.POWER-OFF',
            powerOn:                            /* translationID */ 'GENERAL.JOB.ACTION.POWER-ON',
            powerOnRollback:                    /* translationID */ 'GENERAL.JOB.ACTION.POWER-ON-ROLLBACK',
            publishKsk:                         /* translationID */ 'GENERAL.JOB.ACTION.PUBLISH-KSK',
            purgeRestorable:                    /* translationID */ 'GENERAL.JOB.ACTION.PURGE-RESTORABLE',
            reboot:                             /* translationID */ 'GENERAL.JOB.ACTION.REBOOT',
            recreate:                           /* translationID */ 'GENERAL.JOB.ACTION.RECREATE',
            reissue:                            /* translationID */ 'GENERAL.JOB.ACTION.REISSUE',
            remove:                             /* translationID */ 'TR_210119-1850b1_TR',
            removeIndividualDomainName:         /* translationID */ 'TR_221220-4f59ae_TR',
            removePermanently:                  /* translationID */ 'TR_210119-823a4b_TR',
            removeScheduled:                    /* translationID */ 'TR_210119-f4ea9d_TR',
            removeScheduledPermanently:         /* translationID */ 'TR_210119-bf6730_TR',
            renew:                              /* translationID */ 'GENERAL.JOB.ACTION.RENEW',
            renewManagedSsl:                    /* translationID */ 'GENERAL.JOB.ACTION.RENEW-MANAGED-SSL',
            reset:                              /* translationID */ 'GENERAL.JOB.ACTION.RESET',
            resourceRecordSetUpdate:            /* translationID */ 'GENERAL.JOB.ACTION.RESOURCE-RECORD-SET-UPDATE',
            restore:                            /* translationID */ 'TR_140119-d1b7a4_TR',
            restoreManagedSsl:                  /* translationID */ 'GENERAL.JOB.ACTION.RESTORE-MANAGED-SSL',
            revoke:                             /* translationID */ 'GENERAL.JOB.ACTION.REVOKE',
            revokeAdminAccess:                  /* translationID */ 'TR_221220-6d4572_TR',
            setupIndividualDomainName:          /* translationID */ 'TR_120819-f55f41_TR',
            shutdown:                           /* translationID */ 'GENERAL.JOB.ACTION.SHUTDOWN',
            sign:                               /* translationID */ 'GENERAL.JOB.ACTION.SIGN',
            startMaintenance:                   /* translationID */ 'TR_221220-145347_TR',
            StorageUsageSync:                   /* translationID */ 'TR_210119-c4e98b_TR',
            storageUsageSync:                   /* translationID */ 'TR_210119-c4e98b_TR',
            tieToTemplate:                      /* translationID */ 'GENERAL.JOB.ACTION.TIE-TO-TEMPLATE',
            unblockWriteAccess:                 /* translationID */ 'GENERAL.JOB.ACTION.UNBLOCK-WRITE-ACCESS',
            untieFromTemplate:                  /* translationID */ 'GENERAL.JOB.ACTION.UNTIE-FROM-TEMPLATE',
            update:                             /* translationID */ 'TR_140119-a1847d_TR',
            updateAcmeAuthorization:            /* translationID */ 'GENERAL.JOB.ACTION.UPDATE-ACME-AUTHORIZATION',
            updateIndividualDomainName:         /* translationID */ 'TR_221220-b360a2_TR',
            updateManagedSsl:                   /* translationID */ 'GENERAL.JOB.ACTION.UPDATE-MANAGED-SSL',
            updateSslAuthorization:             /* translationID */ 'GENERAL.JOB.ACTION.UPDATE-SSL-AUTHORIZATION',
            webspaceDisable:                    /* translationID */ 'TR_210119-089115_TR',
            webspaceEnable:                     /* translationID */ 'TR_210119-93ac52_TR',
            webspaceEmailSenderDisable:         /* translationID */ 'TR_210119-f56b57_TR',
            webspaceEmailSenderDefer:           /* translationID */ 'TR_210119-5f2ce8_TR',
            webspaceEmailSenderActivate:        /* translationID */ 'TR_210119-7e450d_TR',
            wipe:                               /* translationID */ 'GENERAL.JOB.ACTION.WIPE',
            restoreBackup:                      /* translationID */ 'GENERAL.JOB.ACTION.BACKUP.RESTORE',
            startManualBackup:                  /* translationID */ 'GENERAL.JOB.ACTION.BACKUP.START'
        };

        return (value: string | number) => {
            if ([undefined, null, ''].indexOf((jobActions as any)[value]) >= 0) {
                return value;
            }
            return $translate.instant((jobActions as any)[value]);
        };
    }
}

export class ObjectStatus {
    public static $inject: string[] = ['$translate'];

    public static Factory($translate: ng.translate.ITranslateService) {
        const objectStates = {
            active:                             /* translationID */ '35b2882a-2c6b-4291-b7de-963835933109',
            approvalFailed:                     /* translationID */ 'GENERAL.OBJECT.STATUS.APPROVAL-FAILED',
            blocked:                            /* translationID */ 'GENERAL.OBJECT.STATUS.BLOCKED',
            canceled:                           /* translationID */ 'GENERAL.OBJECT.STATUS.CANCELED',
            completed:                          /* translationID */ 'GENERAL.OBJECT.STATUS.COMPLETED',
            creating:                           /* translationID */ 'GENERAL.OBJECT.STATUS.CREATING',
            deleting:                           /* translationID */ 'GENERAL.OBJECT.STATUS.DELETING',
            disabled:                           /* translationID */ 'GENERAL.OBJECT.STATUS.DISABLED',
            expired:                            /* translationID */ 'GENERAL.OBJECT.STATUS.EXPIRED',
            inProgress:                         /* translationID */ 'GENERAL.OBJECT.STATUS.IN-PROCESSING',
            inactive:                           /* translationID */ 'GENERAL.OBJECT.STATUS.INACTIVE',
            initiated:                          /* translationID */ 'GENERAL.OBJECT.STATUS.INITIATED',
            manualSecurityReview:               /* translationID */ 'GENERAL.OBJECT.STATUS.MANUAL-SECURITY-REVIEW',
            new:                                /* translationID */ 'GENERAL.OBJECT.STATUS.NEW',
            none:                               /* translationID */ 'GENERAL.OBJECT.STATUS.NONE',
            other:                              /* translationID */ 'GENERAL.OBJECT.STATUS.OTHER',
            overQuota:                          /* translationID */ 'GENERAL.OBJECT.STATUS.OVERQUOTA',
            overquota:                          /* translationID */ 'GENERAL.OBJECT.STATUS.OVERQUOTA',
            pendingReissue:                     /* translationID */ 'GENERAL.OBJECT.STATUS.PENDING-REISSUE',
            pendingRevoke:                      /* translationID */ 'GENERAL.OBJECT.STATUS.PENDING-REVOKE',
            processing:                         /* translationID */ 'GENERAL.OBJECT.STATUS.IN-PROCESSING',
            processingFailed:                   /* translationID */ 'GENERAL.OBJECT.STATUS.PROCESSING-FAILED',
            restorable:                         /* translationID */ 'GENERAL.OBJECT.STATUS.RESTORABLE',
            restoring:                          /* translationID */ 'GENERAL.OBJECT.STATUS.RESTORING',
            restricted:                         /* translationID */ 'GENERAL.OBJECT.STATUS.RESTRICTED',
            revoked:                            /* translationID */ 'GENERAL.OBJECT.STATUS.REVOKED',
            unknown:                            /* translationID */ 'GENERAL.OBJECT.STATUS.UNKNOWN',
            waitForApproval:                    /* translationID */ 'GENERAL.OBJECT.STATUS.WAIT-FOR-APPROVAL',
            webspaceDeleted:                    /* translationID */ 'GENERAL.OBJECT.STATUS.WEBSPACE-DELETED'
        };

        return (apiObject: any) => {
            let status: string;
            if (ng.isObject(apiObject)) {
                status = apiObject.status;
                if (apiObject.restrictions !== undefined && apiObject.restrictions.length > 0) {
                    const userAccessibleRestrictionExists = apiObject.restrictions.some(
                        (restriction: any) => restriction.accountId !== null
                    );
                    if (apiObject.restrictions.length > 1) {
                        return $translate.instant('TR_010719-b4006f_TR');
                    } else if (!userAccessibleRestrictionExists) {
                        return $translate.instant('TR_280619-fc2564_TR');
                    }
                }
            } else {
                status = apiObject;
            }
            if ([undefined, null, ''].indexOf((objectStates as any)[status]) >= 0) {
                return status;
            }
            return $translate.instant((objectStates as any)[status]);
        };
    }
}

export class ObjectType {
    public static $inject: string[] = ['$translate'];

    public static Factory($translate: ng.translate.ITranslateService) {
        const objectTypes: { [key: string]: string } = {
            apptemp:                            /* translationID */ 'GENERAL.OBJECT.TYPE.WH.APPTEMP',
            catchall:                           /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.CATCHALL',
            Catchall:                           /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.CATCHALL',
            certificate:                        /* translationID */ 'GENERAL.OBJECT.TYPE.SSL.CERTIFICATE',
            cluster:                            /* translationID */ 'GENERAL.OBJECT.TYPE.DB.CLUSTER',
            creditnote:                         /* translationID */ 'TR_300119-67d886_TR',
            Database:                           /* translationID */ 'GENERAL.OBJECT.TYPE.DB.DATABASE',
            dbUser:                             /* translationID */ 'TR_130319-0cb933_TR',
            exchangeMailbox:                    /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.EXCHANGE',
            ExchangeMailbox:                    /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.EXCHANGE',
            forwarder:                          /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.FORWARDER',
            Forwarder:                          /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.FORWARDER',
            imapMailbox:                        /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.IMAP',
            ImapMailbox:                        /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.IMAP',
            invoice:                            /* translationID */ 'TR_300119-887e33_TR',
            mailingList:                        /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.MAILING-LIST',
            MailingList:                        /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.MAILING-LIST',
            NameserverSet:                      /* translationID */ 'GENERAL.OBJECT.TYPE.DNS.NAMESERVER-SET',
            org:                                /* translationID */ 'TR_100119-77ee69_TR',
            Organization:                       /* translationID */ 'GENERAL.OBJECT.TYPE.EMAIL.ORGANIZATION',
            person:                             /* translationID */ 'TR_100119-74543b_TR',
            Redirection:                        /* translationID */ 'GENERAL.OBJECT.TYPE.WH.REDIRECTION',
            returndebitnote:                    /* translationID */ 'TR_300119-5878fa_TR',
            role:                               /* transactionID */ 'DOMAIN.HANDLE.TYPE.OPTIONS.ROLE',
            single:                             /* translationID */ 'GENERAL.OBJECT.TYPE.DB.SINGLE',
            smtpForwarder:                      /* translationID */ 'TR_210119-d8df7f_TR',
            SmtpForwarder:                      /* translationID */ 'TR_210119-d8df7f_TR',
            sslfake:                            /* translationID */ 'GENERAL.OBJECT.TYPE.WH.FAKESSL',
            Template:                           /* translationID */ 'GENERAL.OBJECT.TYPE.DNS.TEMPLATE',
            User:                               /* translationID */ 'TR_130319-0cb933_TR',
            VHost:                              /* translationID */ 'TR_140119-3834d8_TR',
            Webspace:                           /* translationID */ 'TR_210319-eeec82_TR',
            Zone:                               /* translationID */ 'GENERAL.OBJECT.TYPE.DNS.ZONE'
        };

        return (value: string) => {
            if ([undefined, null, ''].indexOf(objectTypes[value]) >= 0) {
                return $translate.instant(value);
            }
            return $translate.instant(objectTypes[value]);
        };
    }
}

export class ContractPeriodTranslation {
    public static $inject: string[] = ['$translate'];

    public static Factory($translate: ng.translate.ITranslateService) {
        const objectTypes: { [key: string]: string } = {
            monthly:                            /* translationID */ 'GENERAL.MONTHLY',
            quarterly:                          /* translationID */ 'GENERAL.QUARTERLY',
            semiannually:                       /* translationID */ 'GENERAL.SEMIANNUAL',
            annually:                           /* translationID */ 'GENERAL.ANNUALLY'
        };

        return (value: string) => {
            if ([undefined, null, ''].indexOf(objectTypes[value]) >= 0) {
                return value;
            }
            return $translate.instant(objectTypes[value]);
        };
    }
}
