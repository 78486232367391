import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService,
    ApiErrorModel,
    AuthContextService,
    BundleModelService,
    DateWrapper,
    EmailJobModelService,
    MailboxModelService,
    NavigationService
} from '../../../../../services';
import { EditPanelStatus } from '../../../../molecules/forms';

export class OrganismEditFormMailboxController {
    public static $inject: string[] = [
        '$q',
        '$state',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'bundleModel',
        'emailJobModel',
        'mailboxModel',
        'navigation'
    ];

    public originalMailbox: any;
    public mailbox: any;
    public password: string|string = null;
    public userPanelRight: any;
    public deleteDate: DateWrapper;
    public hasAgreedToDeleteMails = false;
    public additionalStorageItems = 0;
    public maxMailingListMembers: number;
    public mailboxTypes: any = [
        { name: 'Postfach', value: 'ImapMailbox' },
        { name: 'Weiterleitung', value: 'Forwarder' }
    ];
    public bundle: { status: string } = undefined;
    public restorePanelRight: any = {};
    public selectedRestoreAction = '';
    public restorePossibleUntil = '';
    public showObjectId: boolean;
    public mailboxHasUnfinishedJob = false;
    public disableGeneralSaveButton = false;
    public deletionInitalStatus: EditPanelStatus;

    private _isPaidUntilExceeded: boolean;
    private _targetsAllInternal = false;

    constructor(
        private $q: ng.IQService,
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private bundleModel: BundleModelService,
        private emailJobModel: EmailJobModelService,
        private mailboxModel: MailboxModelService,
        private navigation: NavigationService
    ) {
        Object.defineProperty(
            this.restorePanelRight,
            'editPanelButton',
            {
                get: () => this.userPanelRight.restore.editPanelButton && !this.bundleIsDeleted
            }
        );
    }

    public $onInit = () => {
        // Sort forwarder targets alphabetically.
        if (this.originalMailbox.forwarderTargets) {
            this.originalMailbox.forwarderTargets = this.originalMailbox.forwarderTargets.sort();
        }

        this.mailbox = JSON.parse(JSON.stringify(this.originalMailbox));

        this.deletionInitalStatus = this.mailbox.deletionScheduledFor === null
            ? EditPanelStatus.READONLY
            : EditPanelStatus.DELETIONSCHEDULED;

        if ([undefined, null, ''].indexOf(this.mailbox.bundleId) < 0) {
            this.bundleModel.findOneById(this.mailbox.bundleId)
            .then((bundles) => this.bundle = bundles[0]);
        }

        if (AuthContextService.isGranted(UiRights.EMAIL_JOBS_LIST)) {
            this.emailJobModel.findRunningJobsForMailbox(this.originalMailbox)
            .then(
                (result) => {
                    this.mailboxHasUnfinishedJob = result.status === 'success'
                    && result.response.data.length > 0;
                }
            );
        }
    };

    public set isPaidUntilExceeded(value: boolean) {
        this._isPaidUntilExceeded = value;
    }

    public get isPaidUntilExceeded(): boolean {
        return this._isPaidUntilExceeded;
    }

    public get showBundleHint() {
        return [undefined, null, '']
            .indexOf(this.mailbox?.bundleId) < 0 && this.$state.current.name
            .split('.')[0] !== 'bundle';
    }

    public get bundleIsDeleted() {
        return [undefined, null].indexOf(this.bundle) < 0 && this.bundle.status === 'restorable';
    }

    public get targetsAllInternal() {
        return this._targetsAllInternal;
    }

    public set targetsAllInternal(value) {
        this._targetsAllInternal = value;
        if (this.originalMailbox.type.toLowerCase() !== 'forwarder') {
            this.disableGeneralSaveButton = false;
            return;
        }
        if (this.originalMailbox.forwarderType !== undefined
            && this.originalMailbox.forwarderType === 'internalForwarder'
            && this._targetsAllInternal
        ) {
            this.disableGeneralSaveButton = false;
        } else {
            this.disableGeneralSaveButton = this.mailbox.forwarderTargets.length === 0;
        }
    }

    public save = () => {
        if (this.additionalStorageItems > 0 || this.additionalStorageItems < 0) {
            this.mailbox.storageQuota = this.mailbox.storageQuota + (1024 * this.additionalStorageItems);
        }
        return this.mailboxModel.update(this.mailbox, this.password)
        .then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.originalMailbox = JSON.parse(JSON.stringify(this.mailbox));
                    this.alertManager.success(this.$translate.instant('TR_100119-73a436_TR'));
                    return res;
                }
                return this.$q.reject(false);
            },
            (error) => {
                return this.$q.reject(error);
            }
        );
    };

    public delete = () => {
        this.apiErrorModel.destroyErrorList();
        this.mailboxModel.delete(
            [this.mailbox], false, (this.deleteDate.isToday() ? null : this.deleteDate.dateObj)
        ).then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                this.navigation.go('email.mailboxes.overview', {}, { reload: true });
            },
            (error) => {
                return this.$q.reject(error);
            }
        );
    };

    public restoreOrPurge = () => {
        this.apiErrorModel.destroyErrorList();
        if (this.selectedRestoreAction === 'restore') {
            this.mailboxModel.restore([this.mailbox]).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-6a9a06_TR'));
                    this.navigation.go('email.mailboxes.overview', {}, { reload: true });
                },
                (error) => {
                    return this.$q.reject(error);
                }
            );
        } else if (this.selectedRestoreAction === 'purge') {
            this.mailboxModel.purgeRestorable(this.mailbox.id).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                    this.navigation.go('email.mailboxes.overview', {}, { reload: true });
                },
                (error) => {
                    return this.$q.reject(error);
                }
            );
        }
    };

    public cancelGeneral = () => {
        this.mailbox.type = this.originalMailbox.type;
        this.password = null;
        this.mailbox.forwarderTargets = ng.copy(this.originalMailbox.forwarderTargets);

        // Sort the forwarder targets alphabetically.
        if (this.originalMailbox.forwarderTargets) {
            this.originalMailbox.forwarderTargets = this.originalMailbox.forwarderTargets.sort();
        }
    };

    public cancelStorage = () => {
        this.additionalStorageItems = 0;
        this.mailbox.storageQuota = this.originalMailbox.storageQuota;
    };

    public cancelPermissions = () => {
        this.mailbox.isAdmin = this.originalMailbox.isAdmin;
    };

    public cancelSpam = () => {
        this.mailbox.spamFilter = ng.copy(this.originalMailbox.spamFilter);
    };

    public cancelAutoresponder = () => {
        this.mailbox.autoResponder = ng.copy(this.originalMailbox.autoResponder);
    };

    public cancelTerminate = () => {
        this.hasAgreedToDeleteMails = false;
    };

    public cancelDeletion = () => {
        this.mailboxModel.mailboxDeletionCancel(this.mailbox.id).then(
            (apiResponse) => {
                this.alertManager.success(this.$translate.instant('TR_200319-993fd2_TR'));
                this.navigation.reloadCurrentState();
            },
            (apiResponse) => {
                return apiResponse;
            }
        );
    };

    public cancelRestore = () => {
        this.selectedRestoreAction = '';
    };

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }

    public get enableDeletion() {
        return this.hasAgreedToDeleteMails && this.deleteDate !== undefined;
    }
}

export class OrganismEditFormMailboxComponent implements ng.IComponentOptions {
    public bindings = {
        maxMailingListMembers: '<',
        originalMailbox: '<mailbox',
        showObjectId: '<',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormMailboxController;
    public template = require('./mailbox-edit.html');
}
