import ng from 'angular';

import { knownManagedSslProductCodesConst, UiRights } from '@/configuration';
import { CalculatePriceFilter, ConvertAmountFilter } from '@/filters';
import { AuthContextService, DepositModelService, PriceModelService, SslCertificateModelService } from '@/services';
import { BillingApi, SslApi } from '@/types';
import { PanelType } from '@/atomic-components/molecules/forms/form-edit/form-edit';
import { FormDropDownItems } from '@/atomic-components/molecules/forms/form-elements/drop-down/drop-down';
import { OrganismEditFormDomainController } from '@/atomic-components/organisms';
import { EditPanelRight } from '@/atomic-components/molecules';

export interface SslOptions {
    type: string;
    isReadOnly: boolean;
    types: FormDropDownItems[];
    sslIndividualRoute: string;
    sslIndividualRouteParams: {
        bundleId: string;
        domainId: string;
        webspaceId: string;
        vHostId: string;
    };
    profiles: {name: string; value: string}[];
}

export class OrganEditPanelDomainSslController {
    public static $inject: string[] = [
        '$translate',
        'calculatePriceFilter',
        'convertAmountFilter',
        'depositModel',
        'priceModel',
        'sslCertificateModel'
    ];

    public $editFormOrganism: OrganismEditFormDomainController;
    public panelRight: EditPanelRight;
    public userConfirmedPayment = false;
    public panelTypeOverride: PanelType = PanelType.MISCELLANEOUS_ACTION;
    public rapidSslType = 'rapid-ssl';
    public initalSslType: string;
    public displayInsufficientCreditWarning = false;
    public vhostSslCreating = false;

    private rapidSslPrice: BillingApi.ArticlePurchasePrice;
    private rapidSslProductCode = 'ssl-geotrust-rapidssl-12m';

    constructor(
        private $translate: ng.translate.ITranslateService,
        private calculatePriceFilter: CalculatePriceFilter,
        private convertAmountFilter: ConvertAmountFilter,
        private depositModel: DepositModelService,
        private priceModel: PriceModelService,
        private sslCertificateModel: SslCertificateModelService
    ) {}

    public $onInit = (): void => {
        this.initalSslType = this.$editFormOrganism.sslOptions.type;
        if (this.initalSslType === this.rapidSslType) {
            this.getPriceInfo();
            this.userConfirmedPayment = true;
        }
        void this.certificatesPending();
    };

    public certificatesPending = async (): Promise<void> => {
        const certificatesFind = await this.sslCertificateModel.listWithoutPagination();
        // eslint-disable-next-line @typescript-eslint/no-unsafe-call
        this.vhostSslCreating = certificatesFind.data.some((data: SslApi.Certificate) =>
            data.commonName === this.$editFormOrganism.vHost.domainNameUnicode
            && data.status === 'creating');
    };

    public cancelCallback: () => void = () => {/* */};

    public get profileName(): string {
        if ([null, undefined].indexOf(this.$editFormOrganism.vHost.sslSettings) !== -1) {
            return '';
        }

        const profileObject = this.$editFormOrganism.sslOptions.profiles
        .filter((profile) => profile.value === this.$editFormOrganism.vHost.sslSettings.profile)[0];

        return profileObject.name;
    }

    public get isUnknownManagedSslType(): boolean {
        return [null, undefined].indexOf(this.$editFormOrganism.vHost.sslSettings) < 0
            && [null, undefined, ''].concat(knownManagedSslProductCodesConst)
                .indexOf(this.$editFormOrganism.vHost.sslSettings.managedSslProductCode) < 0;
    }

    public get panelDisabled(): boolean {
        return this.isUnknownManagedSslType
            || this.$editFormOrganism.sslOptions.isReadOnly
            || this.vhostSslCreating;
    }

    public get saveButtonDisabled(): boolean { // used in template
        if (this.$editFormOrganism.autoValidationErrors.length > 0) {
            return true;
        }
        return this.$editFormOrganism.sslOptions.type === 'rapid-ssl'
            && (this.userConfirmedPayment === false || this.displayInsufficientCreditWarning);
    }

    public errorDetail = (error, detailKey: string): boolean => {
        return error.details.filter((detail) => detail.key === detailKey)[0].value as boolean;
    };

    public save = (): Promise<void> => {
        return void (this.$editFormOrganism.save())
            .then(() => this.certificatesPending);
    };

    public cancel = (): void => {
        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };

    private sslTypeChanged = (newType: string): void => {
        if (newType === this.rapidSslType && this.rapidSslPrice === undefined) {
            this.getPriceInfo();
        }
        this.$editFormOrganism.sslTypeChanged();
    };

    public get showMissingCertWarning(): boolean {
        // checks if cert is custom
        // and if there is additionally either no ssl settings or no cert
        if (
            this.$editFormOrganism.sslOptions.type === 'custom'
            && ( this.$editFormOrganism.vHost.sslSettings === null
            || this.$editFormOrganism.vHost.sslSettings.certificates === '')
            ){
            return true;
        }

        return false;
    }

    public get priceInfoText(): string {
        const net = this.$translate.instant('BILLING.LABEL.NET');
        const gross = this.$translate.instant('BILLING.LABEL.GROSS');
        const annually = this.$translate.instant('GENERAL.ANNUALLY');

        if (this.rapidSslPrice === undefined || this.rapidSslPrice.netAmount === undefined) {
            return '';
        }
        const netPrice = this.calculatePriceFilter(
            this.rapidSslPrice.netAmount,
            this.rapidSslPrice.amounts[0].vatRate,
            1,
            'net',
            undefined,
            false,
            this.rapidSslPrice.productCode,
            12
        );
        const grossPrice = this.calculatePriceFilter(
            this.rapidSslPrice.netAmount,
            this.rapidSslPrice.amounts[0].vatRate,
            1,
            'gross',
            undefined,
            false,
            this.rapidSslPrice.productCode,
            12
        );
        if (
            this.initalSslType !== this.rapidSslType
            && AuthContextService.isGranted(UiRights.BIL_GET_DEPOSIT)
            && this.depositModel.sufficientCreditForOrder(grossPrice * 10000) < 0
        ) {
            this.displayInsufficientCreditWarning = true;
        }
        if (AuthContextService.account.isCommercialCustomer) {
            return `${this.convertAmountFilter(netPrice)} ${this.rapidSslPrice.currency.toUpperCase()}
                ${net} (${this.convertAmountFilter(grossPrice)} ${this.rapidSslPrice.currency.toUpperCase()}
                ${gross} ${annually}`;
        } else {
            return `${this.convertAmountFilter(grossPrice)} ${this.rapidSslPrice.currency}
                (${gross}) ${annually}`;
        }
    }

    private getPriceInfo = (): void => {
        void this.priceModel.getPriceByProductCode(this.rapidSslProductCode).then(
            (price) => {
                this.rapidSslPrice = price;
            }
        );
    };
}

export class OrganEditPanelDomainSslComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<cancel',
        panelRight: '<'
    };

    public require = {
        $editFormOrganism: '^organismEditFormDomain'
    };

    public controllerAs = '$editPanelOrgan';
    public template = require('./domain-ssl.html');
    public controller = OrganEditPanelDomainSslController;
}
