import * as ng from 'angular';
import { UI } from '../../../../../types';
import { UiLanguagesConst } from '@/configuration';
import { AuthContextService, PriceCacheService, PriceHelperService, ProductsModelService } from '@/services';

export class OrganEditPanelWebspacePhpLegacyController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'dateFilter',
        'productsModel',
        'priceCache',
        'priceHelper'
    ];

    public cancel;
    public $editFormOrganism;
    public $editForm;
    public panelRight;
    public panelTitle: string;
    public currentProduct = null;
    public initialMinPHPVer: string|null = null;
    public productList;
    public legacyPhpProductBillingPeriode = '';
    public legacyGracePeriodEndsAt = null;
    public legacyPHPDropdown: { name: string; value: string }[] = [];
    private registrationIndex: number;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private dateFilter,
        private productsModel: ProductsModelService,
        private priceCache: PriceCacheService,
        public priceHelper: PriceHelperService,
    ) {}

    public legacyPHPDropdownSelected = () => {
        this.$timeout(
            () => {
                if (this.currentProduct === null || this.$editFormOrganism.selectedLegacyPHPProduct !== this.currentProduct.productCode) {
                    this.$editFormOrganism.legacyProductPriceAccepted = false;
                }

                if (this.$editFormOrganism.selectedLegacyPHPProduct) {
                    this.currentProduct = this.productList.filter((product) => product.productCode === this.$editFormOrganism.selectedLegacyPHPProduct)[0];
                } else {
                    this.currentProduct = null;
                }

                this.setValidationStatus();
            }
        );
    }
    public cancelEdit = (): void => {
        if (this.$editFormOrganism.webspace.legacyPhpAddonProductCode !== null) {
            this.currentProduct = this.productList.filter((product) => product.productCode === this.$editFormOrganism.webspace.legacyPhpAddonProductCode)[0];
            this.$editFormOrganism.selectedLegacyPHPProduct = this.currentProduct.productCode;
        } else {
            this.currentProduct = null;
            this.$editFormOrganism.selectedLegacyPHPProduct = null;
        }
        this.cancel();
    }
    public $onInit = async (): Promise<void> => {
        this.initialMinPHPVer = null;
        this.legacyGracePeriodEndsAt = this.$editFormOrganism.webspace.legacyPhpMigrationPeriod === null ? null : this.dateFilter(this.$editFormOrganism.webspace.legacyPhpMigrationPeriod, 'dd.MM.yyyy');
        this.registrationIndex = this.$editForm.registerValidator(this);
        this.legacyPhpProductBillingPeriode = this.priceHelper.getBillingPeriod(String(this.$editFormOrganism.billingCycle));
        this.legacyPHPDropdown = [{ name: this.$translate.instant('TR_090123-e3436f_TR'), value: null }];
        const language = UiLanguagesConst[AuthContextService.user.language];
        this.productList = await this.productsModel.findProducts('webhosting', 'webspace-php-legacy-addons', language).then(
            (reply) => {
                return reply.response.data.map((product) => {
                    product.productCode = product.productCodeTemplate.replace('##BILLING_CYCLE##', this.$editFormOrganism.billingCycle);
                    product.includedVersions = product.specificationItems.filter((spec) => spec.name === 'phpVersions')[0].value;
                    product.minVersion = parseFloat(product.includedVersions[0]);
                    this.legacyPHPDropdown.push({ name: product.name; value: product.productCode });
                    return product;
                });
            }
        );

        if (this.$editFormOrganism.webspace.legacyPhpAddonProductCode !== null) {
            this.currentProduct = this.productList.filter((product) => product.productCode === this.$editFormOrganism.webspace.legacyPhpAddonProductCode)[0];
            this.initialMinPHPVer = this.currentProduct.minVersion;
            this.$editFormOrganism.selectedLegacyPHPProduct = this.currentProduct.productCode;
        }

        this.panelTitle = this.$translate.instant('TR_090123-b8df3a_TR');
    }

    public get displayVhostUpdateWarning() {
        return (this.$editFormOrganism.selectedLegacyPHPProduct === null && this.initialMinPHPVer !== null) // there was a addon booked and the user is about to cancel it
            || (this.currentProduct && parseFloat(this.currentProduct.minVersion) > parseFloat(this.initialMinPHPVer)) // user is downgrading a support package
        ;
    }

    public get displayDowngradeWarning() {
        if (this.$editFormOrganism.selectedLegacyPHPProduct === null
            && this.$editFormOrganism.webspace.legacyPhpAddonProductCode !== null
            && this.$editFormOrganism.webspace.legacyPhpMigrationPeriod !== null
        ) {
            return true;
        }

        if (
            this.$editFormOrganism.selectedLegacyPHPProduct === null
            || this.$editFormOrganism.webspace.legacyPhpAddonProductCode === null
            || this.$editFormOrganism.webspace.legacyPhpMigrationPeriod === null
        ) {
            return false;
        }

        if (this.currentProduct === null) {
            return true;
        }

        if (this.$editFormOrganism.webspace.legacyPhpAddonProductCode === this.currentProduct.productCode) {
            return false;
        }

        const migrationProduct = this.productList.filter(
            (product) => product.productCode === this.$editFormOrganism.webspace.legacyPhpAddonProductCode
        )[0];

        return this.currentProduct.includedVersions.every(
            (version: string) => migrationProduct.includedVersions.includes(version)
        );
    }

    public $onDestroy = () => {
        this.$editForm.unregisterValidator(this.registrationIndex);
        this.$editForm.validatorStatus[this.registrationIndex] = true;
    };

    public get orderConfirmed() {
        return this.$editFormOrganism.legacyProductPriceAccepted;
    }

    public set orderConfirmed(value: boolean) {
        this.$editFormOrganism.legacyProductPriceAccepted = value;
        this.setValidationStatus();
    }

    private setValidationStatus = () => {
        this.$timeout(
            () => {
                this.$editForm.validatorStatus[this.registrationIndex] = this.$editFormOrganism.legacyProductPriceAccepted
                    || this.$editFormOrganism.webspace.legacyPhpAddonProductCode === this.$editFormOrganism.selectedLegacyPHPProduct
                ;
            }
        );
    };
    public $onDestroy = () => {
        this.$editForm.unregisterValidator(this.registrationIndex);
        this.$editForm.validatorStatus[this.registrationIndex] = true;
    };

    public toggle = () => {
        this.orderConfirmed = !this.orderConfirmed;
    };
}

export class OrganEditPanelWebspacePhpLegacyComponent implements ng.IComponentOptions {
    public bindings = {
        cancel: '<',
        panelRight: '<'
    };
    public require = {
        $editFormOrganism: '^organismEditFormWebspacePhpBoost',
        $editForm: '^moleculeFormEdit'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./webspace-php-legacy.html');
    public controller = OrganEditPanelWebspacePhpLegacyController;
}
