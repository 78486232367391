
import ng from 'angular';
import {
    DomainWizardConfirmation
} from '@/atomic-components/organisms/wizards/domain/confirm/domain-wizard-confirmation';
import { WizardCreateObject } from '@/atomic-components/organisms/wizards/general';
import { DomainTypes } from '@/atomic-components/organs/create';
import { UiRights } from '@/configuration';
import {
    ApiExpectedErrorList,
    AuthContextService,
    DnsHelperService,
    DnsZoneModelService,
    DomainModelService,
    WebspaceModelService,
    WizardNewHelperService,
    VhostModelService
} from '@/services';
import {
    DomainApi,
    ViewTypes,
    WebhostingApi
} from '@/types';

export class OrganismDomainWizardConfirmViewController {
    public static $inject: string[] = [
        '$translate',
        'dnsHelper',
        'dnsZoneModel',
        'domainModel',
        'domainWizardConfirmation',
        'vhostModel',
        'webspaceModel',
        'wizardNewHelper'
    ];

    public bundleId = '';
    public wizardCreateObjectList: WizardCreateObject[];
    public expectedErrorList: ApiExpectedErrorList[] = [];

    // Required information for the products that need to be ordered.
    private _apiProductObject: { apiObject: ViewTypes.DomainWizardApiObject };
    private _vhostRedirectionUrl: string;
    private _startRequests = false;

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private dnsHelper: DnsHelperService,
        private dnsZoneModel: DnsZoneModelService,
        private domainModel: DomainModelService,
        public domainWizardConfirmation: DomainWizardConfirmation, // also used in template!
        private vhostModel: VhostModelService,
        private webspaceModel: WebspaceModelService,
        private wizardNewHelper: WizardNewHelperService
    ) {} // tslint:disable-line:no-empty

    public $onInit(): void {
        this.wizardCreateObjectList = [];
        switch (true) {
            case [DomainTypes.REGISTER]
                .includes(this._apiProductObject.apiObject.domainType):
                this.wizardCreateObjectList = this._orderDomain(this._apiProductObject.apiObject);
                break;
            case [DomainTypes.EXTERNAL, DomainTypes.SUBDOMAIN]
                .includes(this._apiProductObject.apiObject.domainType):
                /* eslint-disable no-case-declarations */
                this.wizardCreateObjectList = this._orderVhostExternal(this._apiProductObject.apiObject);
                break;
        }
        this._startRequests = true;
    }

    public set startRequests(_) {/* */}
    public get startRequests(): boolean {
        return this._startRequests;
    }

    private _orderDomain = (apiObject: ViewTypes.DomainWizardApiObject): WizardCreateObject[] => {
        let firstDomain = true;
        const requestObjectList = [];
        const mainData = ng.copy(apiObject);
        if ([undefined, null].includes(apiObject.domains) || mainData.domains?.length < 1) {
            return [];
        }
        for (const domain of mainData.domains) {
            if (firstDomain) {
                this._vhostRedirectionUrl = 'https://' + domain.domainOrderObject.name;
            }
            requestObjectList.push(this._createDomainCreateObject(mainData, domain, firstDomain));
            firstDomain = false;
        }

        if (mainData.createWebspace) {
            // Create new webspace reference instance
            requestObjectList.push({
                callback: () => this.webspaceModel.create(
                    mainData.webspace,
                    [],
                    mainData.account.id,
                    mainData.webspace.poolId,
                    mainData.webspace.webserverId
                ),
                children: requestObjectList,
                labelText: this.$translate.instant('TR_210319-eeec82_TR'),
                objectType: 'Webspace'
            });
        }
        return requestObjectList;
    };

    private _orderVhostExternal = (apiObject: ViewTypes.DomainWizardApiObject): WizardCreateObject[] => {
        const vhostChildrenCallbacks = [];
        const vhost = apiObject.vhost;
        if (apiObject.dnsZone && AuthContextService.isGranted(UiRights.DNS_ZONES_CREATE)) {
            vhostChildrenCallbacks.push({
                callback: () => this.dnsZoneModel.updateZone(
                    apiObject.dnsZone,
                    apiObject.zoneRecordsToUpdate.addRecords,
                    apiObject.zoneRecordsToUpdate.deleteRecords,
                    null,
                    apiObject.zoneRecordsToUpdate.modifyRecords
                ),
                children: [],
                labelText: this.$translate.instant(
                    /* translationId */ 'TR_080420-700487_TR',
                    { zoneName: apiObject.dnsZone.nameUnicode }
                ),
                objectType: 'Zone'
            });
        }

        return [{
            callback:
                (parentArgs: any) => {
                    if (parentArgs?.newWebspaceId) {
                        vhost.webspaceId = parentArgs.newWebspaceId;
                    }

                    return this.vhostModel.create(
                        vhost,
                        apiObject.phpIniDefaults,
                        '',
                        [],
                        apiObject.accountId
                    );
                },
            children: vhostChildrenCallbacks,
            labelText: this.$translate.instant('TR_240620-ac68bc_TR') + ': ' + vhost.domainNameUnicode,
            objectType: 'Vhost'
        }];
    };

    private _orderVhost = (
        vhostApiObject: VHostApiObject
    ): WizardCreateObject[] => {
        const requestObjectList = [];
        const vhost = ng.copy(vhostApiObject.vhost);

        const vhostChildrenCallbacks = [];
        if (this._apiProductObject.apiObject.dnsZone && AuthContextService.isGranted(UiRights.DNS_ZONES_CREATE)) {
            vhostChildrenCallbacks.push({
                callback: () => this.dnsZoneModel.updateZone(
                    this._apiProductObject.apiObject.dnsZone,
                    this._apiProductObject.apiObject.zoneRecordsToUpdate.addRecords,
                    this._apiProductObject.apiObject.zoneRecordsToUpdate.deleteRecords,
                    null,
                    this._apiProductObject.apiObject.zoneRecordsToUpdate.modifyRecords
                ),
                children: [],
                labelText: this.$translate.instant(
                    /* translationId */ 'TR_080420-700487_TR',
                    { zoneName: this._apiProductObject.apiObject.dnsZone.nameUnicode }
                ),
                objectType: 'Zone'
            });
        }
        requestObjectList.push({
            callback: (parentArgs: any) => {
                if (parentArgs?.newWebspaceId) {
                    vhost.webspaceId = parentArgs.newWebspaceId;
                }
                return this.vhostModel.create(
                    vhost,
                    vhostApiObject.phpIniDefaults,
                    '',
                    [],
                    vhostApiObject.accountId
                );
            },
            children: vhostChildrenCallbacks,
            labelText: `${this.$translate.instant('TR_240620-ac68bc_TR')}: ${vhost.domainNameUnicode}`,
            objectType: 'Vhost'
        });

        return requestObjectList;
    };

    private _createDomainCreateObject = (
        mainData: ViewTypes.DomainWizardApiObject,
        domainApiObject: ViewTypes.DomainCreateData,
        firstDomain: boolean
    ): WizardCreateObject => {
        const domain = domainApiObject.domainOrderObject;
        const accountOwner = mainData.account.id;
        let domainChildrenCallbacks: WizardCreateObject[] = [];

        // vhost request
        if ((mainData.createWebspace && domainApiObject.vhost)
            || (!mainData.createWebspace && mainData.webspace)
        ) {
            if (
                domainApiObject.vhostExists
                && domainApiObject.domainOrderObject.name === domainApiObject.vhost.domainNameUnicode
            ) {
                domainChildrenCallbacks = domainChildrenCallbacks.concat(
                    this._updateVhostCallback(mainData, true));
            } else {
                domainChildrenCallbacks = domainChildrenCallbacks.concat(
                    this._createVhostCallback(domainApiObject, firstDomain));
            }
        }

        // zone request
        if (domainApiObject.createZone
            && !domainApiObject.dnsZone
            && domainApiObject.domainOrderObject.nameserverType !== 'external'
            && AuthContextService.isGranted(UiRights.DNS_ZONES_CREATE)
        ) {
            domainChildrenCallbacks.push(this._createZoneCallback(mainData, domainApiObject));
        }
        if (domainApiObject.updateRecords
            && domainApiObject.dnsZone
            && AuthContextService.isGranted(UiRights.DNS_ZONES_EDIT)
        ) {
            domainChildrenCallbacks.push({
                callback: () => this.dnsZoneModel.updateZone(
                    domainApiObject.dnsZone,
                    domainApiObject.zoneRecordsToUpdate.addRecords,
                    domainApiObject.zoneRecordsToUpdate.deleteRecords,
                    null,
                    domainApiObject.zoneRecordsToUpdate.modifyRecords
                ),
                children: [],
                labelText: this.$translate.instant(
                    /* translationId */ 'TR_080420-700487_TR',
                    { zoneName: domainApiObject.domainOrderObject.name }
                ),
                objectType: 'Zone'
            });
        }

        if (['available', 'canNotCheck'].includes(domainApiObject.orderType)) {
            return {
                callback: (parentArgs: any) => this._registerDomain(
                    parentArgs,
                    domain,
                    accountOwner
                ),
                children: domainChildrenCallbacks,
                labelText: this.$translate.instant('TR_060420-138b4b_TR', { domainName: domain.name }),
                objectType: 'Domain'
            };
        } else if (domainApiObject.orderType === 'registered') {
            // zone might already exist in different account
            this.expectedErrorList.push({
                lookup: {
                    code: 40001, // Zone already exists error
                    value: domain.name
                },
                userNotice: {
                    type: 'error',
                    message: this.$translate.instant('TR_241120-8f415b_TR')
                }
            });
            this.domainWizardConfirmation.expectedErrorList = this.expectedErrorList;

            return {
                callback: () => this.domainModel.transfer(
                    domain,
                    { authInfo: domainApiObject.authCode },
                    accountOwner
                ),
                children: domainChildrenCallbacks,
                labelText: this.$translate.instant('TR_060420-5ff374_TR', { domainName: domain.name }),
                objectType: 'Domain'
            };
        }

        // through error - wrong domain order type isset
        // todo: sentry
        console.error('Domain order type is unknown/undefined', domainApiObject.orderType);
    };

    private _registerDomain = (
        parentArgs: any,
        domain: DomainApi.Domain,
        accountOwner: string
    ): Promise<{ newWebspaceId: string | null; response: any }> => {
        return this.domainModel.register(
            domain,
            accountOwner
        )
            .then((res) => {
                // Special things here, we need webspaceId to set webspace id in vhostObject
                return {
                    newWebspaceId: parentArgs ? parentArgs.id : null,
                    response: res
                };
            }) as Promise<{ newWebspaceId: string | null; response: any }>;
    };

    private _updateVhostCallback = (
        mainData: ViewTypes.DomainWizardApiObject,
        asWebsite?: boolean
    ): WizardCreateObject[] => {
        const response: WizardCreateObject[] = [];
        if (!mainData.domains) {
            return response;
        }
        for (const domain of mainData.domains) {
            if (!domain.vhost) {
                continue;
            }
            const vhost = ng.copy(domain.vhost);
            asWebsite = asWebsite || false;
            vhost.enableAlias = true;
            if (asWebsite) {
                vhost.locations = vhost.locations.map((location) => {
                    if (location.matchType === 'default' && location.locationType === 'redirect') {
                        location.locationType = 'generic';
                        location.phpEnabled = true;
                        location.redirectionStatus = '';
                        location.redirectionUrl = '';
                    }
                    return location;
                });
            } else {
                vhost.additionalDomainNames = null;
                vhost.additionalDomainNamesUnicode = null;
            }
            response.push({
                callback: () => this.vhostModel.update(vhost),
                children: [],
                labelText: this.$translate.instant('TR_060420-218c62_TR'),
                objectType: 'Vhost'
            });
        }

        return response;
    };

    private _createVhostCallback = (
        domain: ViewTypes.DomainCreateData,
        firstDomain: boolean
    ): WizardCreateObject[] => {
        let response: WizardCreateObject[] = [];
        const vhost = domain.vhost;
        const defaultLocation: WebhostingApi.Location = this.wizardNewHelper.getDefaultLocation(vhost);

        if (!firstDomain) {
            // all other vhost create as redirection
            defaultLocation.locationType = 'redirect';
            defaultLocation.redirectionStatus = '302';
            defaultLocation.phpEnabled = false;
            defaultLocation.redirectionUrl = this._vhostRedirectionUrl;

            vhost.profile = null;
            vhost.additionalDomainNames = null;
            vhost.additionalDomainNamesUnicode = null;
        }

        vhost.locations = vhost.locations.map(
            (location) => location.matchType === 'default' ? defaultLocation : location
        );
        vhost.domainNameUnicode = `${domain.domainOrderObject.name}`;
        vhost.domainName = `${domain.domainOrderObject.name}`;
        vhost.webRoot = vhost.webRoot?.length === 0
            ? vhost.domainNameUnicode
            : vhost.webRoot;
        vhost.webspaceId = this._apiProductObject.apiObject.webspace
            ? (this._apiProductObject.apiObject.webspace as WebhostingApi.Webspace).id
            : null;
        const vhostApiObject: VHostApiObject = {
            vhost: vhost,
            phpIniDefaults: this._apiProductObject.apiObject.phpIniDefaults,
            accountId: this._apiProductObject.apiObject.account.id,
            domainType: DomainTypes.EXTERNAL // Type external for domain vhost
        };
        response = response.concat(
            this._orderVhost(vhostApiObject));

        return response;
    };

    private _createZoneCallback = (
        mainData: ViewTypes.DomainWizardApiObject,
        domainApiObject: ViewTypes.DomainCreateData
    ): WizardCreateObject => {
        const dns: ViewTypes.DomainWizardDnsSettingsObject = mainData.dns.values;
        const domain = domainApiObject.domainOrderObject;
        const accountOwner = mainData.account.id;
        const labelText = this.$translate.instant('TR_060420-0f6bc4_TR', { zoneName: domain.name});
        const nameserverSetId: string | number = (domain).nameserverSetId || null;
        if (mainData.dns.type === 'slave') {
            return {
                callback: () => this.dnsZoneModel.createSlave(
                    domain.name,
                    dns.masterIp,
                    {},
                    accountOwner
                ),
                children: [],
                labelText: labelText,
                objectType: 'Zone'
            };
        } else if (dns.templateId) {
            return {
                callback: () => this.dnsZoneModel.createWithTemplate(
                    domain.name,
                    dns.templateId,
                    dns.templateChain as boolean,
                    dns.replacements,
                    (nameserverSetId as string | -1 | 0)  === 0
                        ? null
                        : (nameserverSetId as string | -1),
                    dns.dnsSecOptions,
                    accountOwner
                ),
                children: [],
                labelText: labelText,
                objectType: 'Zone'
            };
        } else {
            return {
                callback: () => this.dnsZoneModel.createNative(
                    domain.name,
                    mainData.dns.type === 'empty'
                        ? []
                        : this._createDomainRecords(domain, dns),
                    {},
                    (nameserverSetId as string | -1),
                    'NATIVE',
                    dns.dnsSecOptions,
                    accountOwner
                ),
                children: [],
                labelText: labelText,
                objectType: 'Zone'
            };
        }
    };

    private  _createDomainRecords(
        domain: ViewTypes.DomainOrderObject,
        dns: ViewTypes.DomainWizardDnsSettingsObject
    ): ViewTypes.DomainRecord[] {
        const ttl = 86400;
        const records = [] as {name: string; type: string; content: string; ttl: number; priority?: number }[];
        const hasGlueRecord = this._checkDomainHasGlueRecord(domain.nameservers, [domain]);

        if (hasGlueRecord) {
            domain.nameservers.map((nameserver) => {
                if (domain.glueRecord) {
                    nameserver.ips.map((ip) => records.push({name: nameserver.name, type: 'A', content: ip, ttl: ttl}));
                }
            });
            return records;
        } else if (dns.ip !== '') {
            // Dns ip Address is given
            records.push({name: domain.name, type: 'A', content: dns.ip, ttl: ttl});
            records.push({name: 'www.' + domain.name, type: 'A', content: dns.ip, ttl: ttl});
        } else  if (!dns.addCompanyMx) {
            // Wenn keine IP-Adresse gesetzt ist, wird eine leere Zone angelegt.
            return [];
        }

        // MX Server
        const hasMxServer = dns.mxServer && Array.isArray(dns.mxServer)
            ? dns.mxServer.some((server) => server.name !== '')
            : false;

        if (!hasMxServer) {
            if (dns.addCompanyMx) {
                // todo: accountDefaults von eingeloggten Account??
                AuthContextService.accountDefaults.mxRecords.forEach((record) => {
                    records.push({
                        content: record.content,
                        name: domain.name,
                        priority: record.priority,
                        ttl: 86400,
                        type: 'MX'
                    });
                });
            } else {
                records.push({name: 'mail.' + domain.name, type: 'A', content: dns.ip, ttl: 86400});
                records.push({name: domain.name, type: 'MX', content: 'mail.' + domain.name, ttl: 86400, priority: 10});
            }
        } else {
            dns.mxServer.forEach((mxServer) => {
                if (mxServer.name === undefined || mxServer.name === '') {
                    return;
                }
                records.push({name: domain.name, type: 'MX', content: mxServer.name, ttl: 86400, priority: 10});
            });
        }

        return records;
    }

    private _checkDomainHasGlueRecord = (
        selectedNameservers: DomainApi.Nameserver[],
        domains: (DomainApi.Domain & { glueRecord?: boolean; domainName?: string })[]
    ): boolean => {
        return selectedNameservers.some((nameserver) => {
            return domains.some((domain) => {
                const domainName = (domain.name !== undefined) ? domain.name : domain.domainName;
                if (nameserver.name.indexOf(domainName) >= 0) {
                    domain.glueRecord = true;
                    return true;
                }
                return false;
            });
        });
    };
}

export interface VHostApiObject {
    vhost: WebhostingApi.VHost;
    phpIniDefaults: WebhostingApi.vhostPhpIniDefault;
    accountId: string;
    domainType: number;
}

export class OrganismDomainWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        _apiProductObject: '<apiProductObject',
        wizardCreateObjectList: '<'
    };
    public template = require('./domain-wizard-confirm-view.html');
    public controller = OrganismDomainWizardConfirmViewController;
}
