import * as q from 'q';
import { UiRights } from '../../configuration';
import * as Types from '../../types';
import { BackupApi, UI } from '../../types';
import { AuthContextService } from '../auth-context';
import { DatabaseModelService } from '../database/database-model';
import {
    RpcClient,
    RpcClientService
} from '../rpc-client';
import { WebspaceModelService } from '../webhosting/webspace-model';

export const BackupClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/backup/v1/json/', 'backup')
];

export class BackupRobotService {
    public static $inject: string[] = ['backupClient'];

    constructor(private backupClient: RpcClient) {}

    public backupObjectCreate = (backupObject) => {
        return this.backupClient.request('backupObjectCreate').execute({ backupObject: backupObject });
    };

    public backupObjectsFind: Types.UI.ListMethod<
    UI.APIResponse<BackupApi.BackupExtendedObject>|
    UI.APIResponse<BackupApi.BackupObject>> =
    (filter?, limit?, page?, sort?) => {
        return this.backupClient.request('backupObjectsFind')
        .skipPagination()
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public backupsFind = (
        service: string,
        objectType: string,
        objectId: string,
        limit: number,
        page: number,
        sort?: Types.Finding.SortOptions
    ) => {
        return this.backupClient.request('backupsFind')
        .skipPagination()
        .execute(
            {
                limit: limit,
                objectId: objectId,
                objectType: objectType,
                page: page,
                service: service,
                sort: sort
            }
        );
    };

    public backupStartManualBackup = (
        service: string,
        objectType: string,
        objectId: string,
        backupName: string,
        backupToReplaceId?: string
    ) => {
        return this.backupClient.request('startManualBackup')
        .execute(
            {
                backupName: backupName,
                backupToReplaceId: backupToReplaceId,
                objectId: objectId,
                objectType: objectType,
                service: service
            }
        );
    };

    public restoreBackup = (
        service: string,
        objectType: string,
        objectId: string,
        backupId: string
    ) => {
        return this.backupClient.request('restoreBackup')
        .execute(
            {
                backupId: backupId,
                objectId: objectId,
                objectType: objectType,
                service: service
            }
        );
    };

    public deleteBackup = (
        backupId: string
    ) => {
        return this.backupClient.request('backupDelete')
        .execute(
            {
                backupId: backupId
            }
        );
    };

    public jobsFind: Types.UI.ListMethod = (filter, limit, page, sort) => {
        return this.backupClient.request('jobsFind').execute({ filter: filter, limit: limit, page: page, sort: sort });
    };
}

export class BackupModelService {
    public static $inject: string[] = ['backupRobot', 'databaseModel', 'webspaceModel'];

    constructor(
        private backupRobot: BackupRobotService,
        private databaseModel: DatabaseModelService,
        private webspaceModel: WebspaceModelService
    ) {}

    public list = (service: string, type: string, id: string, limit?, page?, sort?) => {
        if (!AuthContextService.isGranted(UiRights.BACKUP_BACKUP_LIST)) {
            return [];
        }

        limit = limit || 1000;
        page = page || 1;
        sort = sort || {
            field: 'backupStart',
            order: 'DESC'
        };

        return this.backupRobot.backupsFind(service, type, id, limit, page, sort)
        .then(
            (result) => result.response.data,
            () => false
        );
    };

    public start = (
        service: string,
        objectType: string,
        objectId: string,
        backupName: string,
        oldBackupId: string = null
    ) => {
        backupName = backupName || '';
        if (oldBackupId !== null) {
            return this.backupRobot.backupStartManualBackup(service, objectType, objectId, backupName, oldBackupId);
        } else {
            return this.backupRobot.backupStartManualBackup(service, objectType, objectId, backupName);
        }
    };

    public replace = (
        service: string,
        objectType: string,
        objectId: string,
        backupName: string,
        backupToReplaceId: string
    ) => {
        backupName = backupName || '';
        return this.backupRobot.backupStartManualBackup(service, objectType, objectId, backupName, backupToReplaceId);
    };

    public restore = (service: string, objectType: string, objectId: string, backupId: string) => {
        return this.backupRobot.restoreBackup(service, objectType, objectId, backupId);
    };

    public delete = (backupId: string) => {
        return this.backupRobot.deleteBackup(backupId);
    };

    public findJobs: Types.UI.ListMethod = (filter?, limit?, page?, sort?) => {
        sort = {
            field: 'JobAddDate',
            order: 'DESC'
        };

        const callback = {
            Database: this.databaseModel.findOneById,
            Webspace: this.webspaceModel.findOne
        };

        return this.backupRobot.jobsFind(filter, limit, page, sort)
        .then(
            (result) => {
                const response = result.response;

                return q.all(
                    response.data.map(
                        (job, index) => {
                            return callback[job.objectType](job.objectId)
                            .then(
                                (res) => {
                                    response.data[index].displayName = res.name;
                                }
                            );
                        }
                    )
                )
                .then(() => response);
            }
        );
    };
}
