import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService,
    ApiErrorModel,
    AuthContextService,
    DateWrapper,
    MachineModelService,
    NavigationService
} from '../../../../../services';
import * as Types from '../../../../../types';
import { EditPanel, EditPanelStatus } from '../../../../molecules/forms';

export class OrganismEditFormMachineController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'machineModel',
        'navigation'
    ];

    public callbackCancelMachineChanges: () => void;
    public callbackUpdateMachineStatus: () => void;
    public checkingMachineStatus: boolean;
    public deleteDate: DateWrapper;
    public editPanels: { [name: string]: EditPanel };
    public hasAgreedToDeleteMachine = false;
    public initialStatus: any;
    public machine: Types.MachineApi.VirtualMachine;
    public machineHasUnfinishedJob: boolean;
    public machineStatusSupport: boolean;
    public reinstallTitle = '';
    public selectedRestoreAction = '';
    public showObjectId: boolean;
    public userPanelRight: any;

    private _isPaidUntilExceeded: boolean;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private machineModel: MachineModelService,
        private navigation: NavigationService
    ) {}

    public $onInit = () => {
        if (this.isManagedServer() && this.machine.managementType === 'platform') {
            this.reinstallTitle = this.$translate.instant('TR_310119-3c498c_TR');
        } else {
            this.reinstallTitle = this.$translate.instant('TR_100119-5c028e_TR');
        }

        this.initialStatus = this.machine.deletionScheduledFor !== null
            ? EditPanelStatus.DELETIONSCHEDULED
            : EditPanelStatus.READONLY;
    };

    public set isPaidUntilExceeded(value: boolean) {
        this._isPaidUntilExceeded = value;
    }

    public get isPaidUntilExceeded(): boolean {
        return this._isPaidUntilExceeded;
    }

    public get errorList() {
        return this.apiErrorModel.errorObjectList;
    }

    public get enableDeletion() {
        return this.hasAgreedToDeleteMachine && this.deleteDate !== undefined;
    }

    public get disableOsButton() {
        if ([undefined, null].indexOf(this.editPanels) >= 0) {
            return false;
        }

        return Object.keys(this.editPanels)
            .map((panelName) => this.editPanels[panelName])
            .some((panel) => panel.status === EditPanelStatus.ACTIVE);
    }

    public get disableNetworkConfiguration() {
        if ([undefined, null].indexOf(this.editPanels) >= 0) {
            return false;
        }

        return Object.keys(this.editPanels)
            .map((panelName) => this.editPanels[panelName])
            .some((panel) => panel.status === EditPanelStatus.ACTIVE);
    }

    public cancelGeneral = () => {
        this.callbackCancelMachineChanges();
    };

    public cancelNetwork = () => {
        this.callbackCancelMachineChanges();
    };

    public cancelTerminate = () => {
        this.hasAgreedToDeleteMachine = false;
        this.callbackCancelMachineChanges();
    };

    public delete = () => {
        this.apiErrorModel.destroyErrorList();
        this.machineModel.delete(this.machine, (this.deleteDate.isToday() ? null : this.deleteDate.dateObj))
            .then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                    this.$timeout(
                        () => {
                            this.navigation.reloadCurrentState();
                        },
                        300
                    );
                }
            );
    };

    public cancelDeletion = () => {
        if (this.machine.deletionScheduledFor !== null) {
            this.machineModel.cancelDeletion(this.machine.id).then (
                (reply) => {
                    this.alertManager.success(this.$translate.instant('TR_230119-105bfd_TR'));

                    return reply;
                }
            );

            this.callbackCancelMachineChanges();
        }
    };

    public restoreOrPurge = () => {
        this.apiErrorModel.destroyErrorList();

        if (this.selectedRestoreAction === 'restore') {
            this.machineModel.restore([this.machine]).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-6a9a06_TR'));
                    this.navigation.reloadCurrentState();
                }
            );
        } else if (this.selectedRestoreAction === 'purge') {
            this.machineModel.purgeRestorable(this.machine.id).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                    this.navigation.go('machine.virtualmachines.overview', {}, { reload: true });
                }
            );
        }
    };

    public save = () => {
        this.machineModel.updateMachine(this.machine)
            .then(
                (res) => {
                    if (!this.apiErrorModel.apiResponseHasError(res)) {
                        this.alertManager.success(this.$translate.instant('TR_100119-5f0c4f_TR'));
                        return res;
                    }

                    return Promise.reject(false);
                }
            );
        this.updateMachineSettings();
    };

    public updateMachineSettings =  () => {
        if (this.callbackUpdateMachineStatus) {
            this.callbackUpdateMachineStatus();
        }
    };

    public goToOsInstall = () => {
        this.navigation.go('machine.virtualmachines.id.edit.os');
    };

    public isManagedServer = () => {
        if ([undefined].indexOf(this.machine.managementType) === 0) {
            return false;
        }

        return this.machine.managementType !== 'none';
    };

    public showSuperUserOptions = () => {
        if (this.isManagedServer()) {
            return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);
        } else {
            return true;
        }
    };

    public showInstallPanel = () => {
        switch (this.machine.managementType) {
            case 'individual': return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);
            case 'platform': return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);
            default: return true;
        }
    };
}

export class OrganismEditFormMachineComponent implements ng.IComponentOptions {
    public bindings = {
        callbackCancelMachineChanges: '<',
        callbackUpdateMachineStatus: '<',
        checkingMachineStatus: '<',
        machine: '<machine',
        machineHasUnfinishedJob: '<',
        machineStatusSupport: '<',
        showObjectId: '<',
        userPanelRight: '='
    };
    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormMachineController;
    public template = require('./machine-edit.html');
}
