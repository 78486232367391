import ng from 'angular';

import { DropdownElementData, FormDropDownItems } from '@/atomic-components';
import { ConvertAmountFilter } from '@/filters/convert-amount';
import { AuthContextService, ResourceModelService, ScrollToValidationError } from '@/services';
import { ViewTypes } from '@/types';

const specsOptions = {
    virtualMachine: {
        name: ''
    },
    individualProperties: {
        price: {
            min: 0,
            max: 100000
        },
        managementTypes: [
            {
                value: 'none'
            },
            {
                value: 'individual'
            },
            {
                value: 'platform'
            }
        ],
        virtualMachineHostIds: [
            {
                name: 'auto',
                value: 'auto'
            }
        ],
        architectures: [
            {
                value: 'x86_64'
            }
        ],
        diskControllerTypes: [
            {
                value: 'virtio-scsi'
            },
            {
                value: 'virtio'
            }
        ],
        cpu: {
            cpuCount: Array.apply(null, Array(8))
                .map((x, i) => i + 1)
                .map((v) => ({ value: v })),
            // cpuPeriod: Array.apply(null, Array(32)).map((x, i) => i).map((v) => ({ value: v * 1000})),
            cpuQuota:  Array.apply(null, Array(121))
                .map((x, i) => i)
                .map((v) => ({ value: v * 1000 + 11000 }))
        },
        memory: {
            capacity: Array.apply(null, Array(32))
                .map((x, i) => i + 1)
                .map((v) => ({ value: v, name: v }))
        },
        disk: {
            capacity: [10, 20, 40].map((v) => ({ value: v, name: `${v}` })),
            // capacity: Array.apply(null, Array(32)).map((x, i) => i + 1).map((v) => ({ value: v * 1024 })),
            type: [
                {
                    value: 'local'
                }
            ],
            Bps: [10, 20, 40, 100].map((v) => ({ value: v, name: `${v}` })),
            // Bps: Array.apply(null, Array(32)).map((x, i) => i + 1).map((v) => ({ value: v * 1024 })),
            Iops: [100, 200, 300, 600, 900].map((v) => ({ value: v, name: `${v}` }))
            // Iops: Array.apply(null, Array(32)).map((x, i) => i + 1).map((v) => ({ value: v * 1024 }))
        },
        networkBandwidth: [10, 20, 50, 100].map((v) => ({ value: v, name: `${v}` }))
    }

};

export class OrganismMachineWizardProductConfigIndividualVirtualMachineController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        'convertAmountFilter',
        'resourceModel'
    ];

    public sufficientCreditAvailable = true;
    public metaData: ViewTypes.ProductConfigMachineIndividualObject;
    public selectedProductCode: string;

    public formCompleted = {
        /* eslint-disable max-len */
        accountid:          () => [null, undefined, ''].indexOf(this.metaData?.virtualMachine?.account?.id) < 0,
        name:               () => [null, undefined, ''].indexOf(this.metaData?.virtualMachine?.name) < 0,
        price:              () => [null, undefined, ''].indexOf(this.metaData?.individualProperties.price) < 0,
        management:         () => [null, undefined, ''].indexOf(this.metaData?.individualProperties.managementType) < 0,
        machineHostId:      () => [null, undefined, ''].indexOf(this.metaData?.individualProperties.virtualMachineHostId) < 0,
        cpuCount:           () => [null, undefined, 0 ].indexOf(this.metaData?.individualProperties.cpu.cpuCount) < 0,
        ram:                () => [null, undefined, 0 ].indexOf(this.metaData?.individualProperties.memory.capacity) < 0,
        disk:               () => [null, undefined, 0 ].indexOf(this.metaData?.individualProperties.disk.capacity) < 0,
        cpuQuota:           () => [null, undefined, 0 ].indexOf(this.metaData?.individualProperties.cpu.cpuQuota) < 0,
        diskController:     () => [null, undefined, ''].indexOf(this.metaData?.individualProperties.diskControllerType) < 0,
        diskType:           () => [null, undefined, ''].indexOf(this.metaData?.individualProperties.disk.type) < 0,
        diskRW:             () => [null, undefined, 0 ].indexOf(this.metaData?.individualProperties.disk.Bps) < 0,
        DiskIOPS:           () => [null, undefined, 0 ].indexOf(this.metaData?.individualProperties.disk.Iops) < 0,
        arch:               () => [null, undefined, ''].indexOf(this.metaData?.individualProperties.architecture) < 0,
        network:            () => [null, undefined, 0 ].indexOf(this.metaData?.individualProperties.networkBandwidth) < 0
        /* eslint-enable max-len */
    };

    private pricePlaceholder: string;

    private periodItems: FormDropDownItems[];
    private managementTypeItems: FormDropDownItems[];
    private virtualMachineHostIdItems: FormDropDownItems[];
    private architectureItems: FormDropDownItems[];
    private diskControllerTypeItems: FormDropDownItems[];

    private cpuCountOptions: DropdownElementData[];
    private cpuQuotaOptions: DropdownElementData[];

    private memoryCapacityOptions: DropdownElementData[];

    private diskCapacityOptions: DropdownElementData[];
    private diskTypeItems: FormDropDownItems[];
    private diskBpsOptions: DropdownElementData[];
    private diskIopsOptions: DropdownElementData[];

    private networkBandwidthOptions: DropdownElementData[];

    public constructor(
        private $timeout: ng.ITimeoutService,
        private convertAmountFilter: ConvertAmountFilter,
        private resourceModel: ResourceModelService
    ) {}

    public $onInit = (): void => {
        switch (AuthContextService.user.language.toUpperCase()) {
            case 'DE':
                this.pricePlaceholder = '150,00';
                break;
            case 'EN':
                this.pricePlaceholder = '150.00';
                break;
            default:
                this.pricePlaceholder = '150.00';
                break;
        }

        this.managementTypeItems = specsOptions.individualProperties.managementTypes;

        this.architectureItems = specsOptions.individualProperties.architectures;
        this.diskControllerTypeItems = specsOptions.individualProperties.diskControllerTypes;

        this.cpuCountOptions = specsOptions.individualProperties.cpu.cpuCount;
        this.cpuQuotaOptions = specsOptions.individualProperties.cpu.cpuQuota;

        this.memoryCapacityOptions = specsOptions.individualProperties.memory.capacity;

        this.diskCapacityOptions = specsOptions.individualProperties.disk.capacity;
        this.diskTypeItems = specsOptions.individualProperties.disk.type;
        this.diskBpsOptions = specsOptions.individualProperties.disk.Bps;
        this.diskIopsOptions = specsOptions.individualProperties.disk.Iops;

        this.networkBandwidthOptions = specsOptions.individualProperties.networkBandwidth;
        this.metaData = {
            productFamily: 'individual-virtual-machines',
            virtualMachine: {
                account: AuthContextService.account,
                name: ''
            },
            individualProperties: {
                price: '0',
                managementType: 'none',
                virtualMachineHostId: 'auto',
                architecture: 'x86_64',
                diskControllerType: 'virtio-scsi',
                cpu: {
                    cpuCount: 1,
                    cpuPeriod: 1000000,
                    cpuQuota: 80000
                },
                memory: {
                    capacity: 1
                },
                disk: {
                    capacity: 10,
                    type: 'local',
                    Bps: 100,
                    Iops: 300
                },
                networkBandwidth: 100
            }
        };
    };

    public set selectedBillingCycle(selectedBillingCycle: string) {
        if (this.metaData) {
            this.metaData.selectedBillingCycle = selectedBillingCycle;
        }
    }
    public get selectedBillingCycle(): string {
        return this.metaData?.selectedBillingCycle;
    }

    public set visiblePrice(_) {}
    public get visiblePrice(): string {
        return this.convertAmountFilter(parseFloat(this.metaData.individualProperties?.price || '0'));
    }

    public set configCompleted(_) {} // used by parent
    public get configCompleted() { // used by parent
        for (const part in this.formCompleted) {
            if (Object.prototype.hasOwnProperty.call(this.formCompleted, part)) {
                if (!this.formCompleted[part]()) {
                    return false;
                }
            }
        }

        return true;
    }

   public scrollToError = () => {
       ScrollToValidationError.scrollToError(this.$timeout);
    };

    public findVirtualMachineHostIds = (limit?: number, page?: number, filters?, sort?, cancel?) => {
        return this.resourceModel.vmHostList(limit, page, filters, sort, cancel).then(res => {
            return res.data.map(item => {
                return {
                    value: item.id,
                    name: item.hostName
                };
            }).concat(specsOptions.individualProperties.virtualMachineHostIds);
        });
    };

}

export class OrganismMachineWizardProductConfigIndividualVirtualMachineComponent implements ng.IComponentOptions {
    public bindings = {
        configCompleted: '=',
        metaData: '=',
        selectedProductCode: '=',
        selectedBillingCycle: '<?selectedBillingCycle'
    };

    public template = require('./machine-wizard-product-config-individual-virtual-machine.html');
    public controller = OrganismMachineWizardProductConfigIndividualVirtualMachineController;
}
