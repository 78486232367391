import * as ng from 'angular';

import { Contingent, ContingentType } from '@/atomic-components/organs';
import { AuthContextService, ScrollToValidationError } from '@/services';
import { EmailProductFamilies, UiRights } from '../../../../../configuration';
import { ProductFamiliesConst } from '../../../../../configuration/billing';
import * as Types from '../../../../../types';
import { ViewTypes } from '../../../../../types/view-types';
import { AsteriskNoteService } from './../../../../../services/billing/asterisk-note-service';

export class OrganismEmailWizardConfigViewController {
    public static $inject: string[] = ['$state', '$stateParams', '$timeout', 'asteriskNote'];

    public callback: (metaData) => {};
    public outerAccount: Types.AccountApi.Account;
    public ownerAccount: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public outerProductCode: string; // todo: has to be implemented (if set product has to be preselected)
    public productFamilies: string[] = ProductFamiliesConst.emailProductBox;
    public service = 'email';
    public sufficientCreditAvailable = true;
    public productCodeList: string[] = [];
    public initializeDefault = false;

    public preselectedItemObject: { productFamily: string; productCode: string };
    public productSelectionOverlay = [];

    private _metaData: ViewTypes.ProductConfigEmailObjectGeneric;
    private _productFamily: string;
    private _additionalStorageList: string[] = [];

    public constructor(
        private $state: ng.ui.IStateService,
        private $stateParams: ng.ui.IStateParamsService,
        private $timeout: ng.ITimeoutService,
        private asteriskNote: AsteriskNoteService
    ) {}

    public $onInit() {
        this.asteriskNote.resetNodes();

        this.metaData = {
            mailbox: {
                emailAddress: ''
            },
            autoconfigureDns: false,
            skipDnsCheck: false,
            spamProfile: {
                spamLevel: 'medium'
            }
        } as ViewTypes.ProductConfigEmailObjectGeneric;

        if ([undefined, null].indexOf(this.outerProductCode) < 0) {
            this.selectedProductCode = this.outerProductCode;
        }

        if ([undefined, null].indexOf(this.outerAccount) < 0) {
            this.account = this.outerAccount;
        }
        if (this._hasContingentParams()) {
            this.initializeDefault = true;
            this._preselectProduct();
            this._preselectContingent();
            this.initializeDefault = false;
        }
        const productFamilyRightsGranted = [
            {
                family: 'imap-mailboxes',
                granted: AuthContextService.isGranted(UiRights.EMAIL_IMAPMAILBOX_CREATE)
            },
            {
                family: 'mailing-lists',
                granted: AuthContextService.isGranted(UiRights.EMAIL_MAILINGLIST_CREATE)
            },
            {
                family: 'catchalls',
                granted: AuthContextService.isGranted(UiRights.EMAIL_CATCHALL_CREATE)
            },
            {
                family: 'external-forwarders',
                granted: AuthContextService.isGranted(UiRights.EMAIL_FORWARDER_CREATE)
            },
            {
                family: 'msexchange-mailboxes',
                granted: AuthContextService.isGranted(UiRights.EMAIL_EXCHANGE_CREATE)
            },
            {
                family: 'smtp-forwarders',
                granted: AuthContextService.isGranted(UiRights.EMAIL_SMTP_FORWARDER_CREATE)
            }
        ];

        for (const userRight of productFamilyRightsGranted) {
            if (!userRight.granted) {
                this.productSelectionOverlay.push({
                    action: {
                        type: 'family',
                        mode: 'remove'
                    },
                    target: {
                        field: 'family',
                        value: userRight.family
                    }
                });
            }
        }
    }

    public scrollToError = () => {
        ScrollToValidationError.scrollToError(this.$timeout);
    };

    public get additionalStorageList() { return this._additionalStorageList; }
    public set additionalStorageList(value) {
        this._additionalStorageList = value;
        this.productCodeList = [];
        this.productCodeList.push(this.selectedProductCode);
        this.productCodeList.push(...value);
    }

    public get selectedProductFamily(): string {
        return this._productFamily;
    }
    public set selectedProductFamily(value: string) {
        this._productFamily = value;
        this.metaData.productFamily = (value as any);
    }

    public get metaData() { return this._metaData; }
    public set metaData(metaData) {
        this._metaData = metaData;
    }

    public get account() { return this.metaData?.account; }
    public set account(value: Types.AccountApi.Account | Types.AccountApi.Subaccount) {
        if (!value || !this.metaData) {
            return;
        }
        if (value?.id !== this.metaData?.account?.id) {
            this.metaData.account = value;
            if (this._hasContingentParams()) {
                switch (true) {
                    case Object.keys(this.$state.$current.locals.globals).includes('bundleAccount'):
                        this.ownerAccount = this.$state.$current.locals.globals.bundleAccount;
                        this.metaData.account = this.ownerAccount;
                        break;
                    case Object.keys(this.$state.$current.locals.globals).includes('machineAccount'):
                        this.ownerAccount = this.$state.$current.locals.globals.machineAccount;
                        this.metaData.account = this.ownerAccount;
                        break;
                    default:
                        break;
                }
            }
        }
    }

    public get selectedContingent() { return this.metaData.contingent; }
    public set selectedContingent(value) {
        this.metaData.contingent = value;
    }

    public get selectedProductCode() { return this.metaData.productCode; }
    public set selectedProductCode(selectedProductCode: string) {
        this.productCodeList.splice(this.productCodeList.indexOf(this.metaData.productCode), 1);
        this.productCodeList.push(selectedProductCode);
        this.metaData.productCode = selectedProductCode;
        this._additionalStorageList = [];
    }

    public get productType(): 'imapMailbox' | 'externalForwarder' | 'catchall' | 'exchangeMailbox' | 'mailingList' | 'smtpForwarder' | string { // used in template
        if ([undefined, null].indexOf(this.selectedProductCode) >= 0) {
            return '';
        }
        const productType = EmailProductFamilies.filter((type) => type.productFamily === this.metaData.productFamily);
        if (productType.length > 0) {
            return productType[0].type;
        } else {
            return '';
        }
    }

    // tslint:disable-next-line: cyclomatic-complexity
    public unsavedChangesExist = () => {
        switch (this.productType) {
            case 'imapMailbox':
                const imapMailbox = (this.metaData as ViewTypes.ProductConfigEmailObject);
                if (imapMailbox?.mailbox?.emailAddress?.length > 0
                || imapMailbox?.mailbox?.forwarderTargets?.length > 0
                || imapMailbox?.password?.length > 0) {
                    return true;
                }
                break;
            case 'externalForwarder':
                const externalForwarder = (this.metaData as ViewTypes.ProductConfigExternalForwarderObject);
                if (externalForwarder?.mailbox?.emailAddress?.length > 0
                || externalForwarder?.mailbox?.forwarderTargets?.length > 0) {
                    return true;
                }
                break;
            case 'catchall':
                const catchall = (this.metaData as ViewTypes.ProductConfigCatchallObject);
                if (catchall?.mailbox?.emailAddress?.length > 0
                || catchall?.mailbox?.forwarderTarget?.length > 0) {
                    return true;
                }
                break;
            case 'exchangeMailbox':
                const exchangeMailbox = (this.metaData as ViewTypes.ProductConfigExchangeMailboxObject);
                if (exchangeMailbox?.mailbox?.emailAddress?.length > 0
                || exchangeMailbox?.mailbox?.firstName?.length > 0
                || exchangeMailbox?.mailbox?.lastName?.length > 0
                || exchangeMailbox?.password?.length > 0
                || exchangeMailbox?.organization?.name?.length > 0
                || exchangeMailbox?.organization?.comment?.length > 0) {
                    return true;
                }
                break;
            case 'mailingList':
                const mailingList = (this.metaData as ViewTypes.ProductConfigMailingListObject);
                if (mailingList?.mailbox?.emailAddress?.length > 0
                || mailingList?.mailbox?.members?.length > 0
                || mailingList?.mailbox?.owners?.length > 0
                || mailingList?.mailbox?.replyToEmailAddress?.length > 0
                || mailingList?.mailbox?.replyToMode?.length > 0
                || mailingList?.mailbox?.subjectPrefix?.length > 0) {
                    return true;
                }
                break;
            case 'smtpForwarder':
                const smtpForwarder = (this.metaData as ViewTypes.ProductConfigSmtpForwarderObject);
                if (smtpForwarder?.mailbox?.emailAddress?.length > 0
                || ((smtpForwarder?.mailbox?.forwarderPort) as string)?.length > 0
                || smtpForwarder?.mailbox?.forwarderServer?.length > 0) {
                    return true;
                }
                break;
        }
        return false;
    };

    public finishConfiguration = () => {
        if (this.callback !== undefined) {
            this.metaData.productCode = this.selectedProductCode;
            this.callback(this.metaData);
        }
    };

    private _preselectProduct = () => {
        this.preselectedItemObject = {
            productCode: 'email-imap-mailbox-12m',
            productFamily: 'imap-mailboxes'
        };
    };

    private _preselectContingent = () => {
        if (this.$stateParams.bundleId) {
            this.selectedContingent = {
                id: this.$stateParams.bundleId,
                type: ContingentType.bundle
            } as Contingent;
        } else if (this.$stateParams.poolId) {
            this.selectedContingent = {
                id: this.$stateParams.poolId,
                type: ContingentType.pool
            } as Contingent;
        } else if (this.$stateParams.machineId) {
            this.selectedContingent = {
                id: this.$stateParams.machineId,
                type: ContingentType.webserver
            } as Contingent;
        }
    };

    private _hasContingentParams = () => {
        const contingentIds = ['bundleId', 'poolId', 'machineId'];
        for (const id of contingentIds) {
            if ([undefined, null, ''].indexOf(this.$stateParams[id]) < 0) {
                return true;
            }
        }
        return false;
    };
}

export class OrganismEmailWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<',
        _productFamily: '=productFamily',
        outerAccount: '<',
        outerProductCode: '<'
    };

    public template = require('./email-wizard-config-view.html');
    public controller = OrganismEmailWizardConfigViewController;
}
