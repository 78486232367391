import { NavigationService } from '@/services/navigation';
import * as ng from 'angular';
import * as q from 'q';
import { UiRights } from '../../../../configuration';
import { AuthContextService } from '../../../../services';
import { GlobalSearchSources, SearchSource } from './global-search-sources';

import './global-search.scss';

export class OrganismGlobalSearchController {
    public static $inject: string[] = [
        '$document',
        '$timeout',
        'authContext',
        'globalSearchSources',
        'navigation'
    ];

    public results: any = {};
    public query = '';
    public loading: any = {};
    public totals: any = {};
    public noSearchCommitted = true;
    public searchResultsFound = false;
    public searchInProgress = false;
    public menuOpen: boolean;

    private internal: any = {
        lastAccount: null,
        show: false,
        sources: []
    };

    constructor(
        private $document: ng.IDocumentService,
        private $timeout: ng.ITimeoutService,
        private authContext: AuthContextService,
        private globalSearchSources: GlobalSearchSources,
        private navigation: NavigationService,
    ) {
        this.internal.sources = this.globalSearchSources.sources;
        this.internal.lastAccount = this.authContext.account.id;
    }

    public $onInit = () => {
        this.$document.bind(
            'keydown',
            (event) => {
                const ctrlAndShiftPressed = (event.ctrlKey && event.shiftKey);
                const spacePressed = ((event.key !== undefined && event.key === ' ') || event.keyCode === 32);

                if (ctrlAndShiftPressed && spacePressed) {
                    this.$timeout(
                        () => {
                            this.internal.show = !this.internal.show;

                            if (this.internal.show) {
                                document.getElementById('globalSearchInput').focus();
                            }
                        }
                    );
                }
            }
        );
    };

    public get sources(): SearchSource[] {
        if (this.internal.lastAccount !== this.authContext.account.id) {
            this.internal.sources = this.globalSearchSources.sources;
            this.internal.lastAccount = this.authContext.account.id;
        }

        return this.internal.sources;
    }

    public get show() {
        if (!this.authContext.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_READ)) {
            return false;
        }

        return this.internal.show;
    }

    public set show(value: boolean) {
        this.internal.show = value;
    }

    public get stillLoading(): boolean {
        return Object.keys(this.loading)
        .reduce(
            (total, next) => total || this.loading[next],
            false
        );
    }

    private _filterType = (source: SearchSource) => {
        const parsedQuery = this._filterRegex(this.query);
        if ([undefined, null, ''].includes(parsedQuery['type'])) {
            return true;
        }
        return source.type === parsedQuery['type'];
    }

    private _filterRegex = (query: string): Record<'type'|'field'|'search', string> => {
        const typeFieldRegex = /^(type:"\w+"\s*)?(field:"\w+"\s*)?(.*)/g;
        let typeField = typeFieldRegex.exec(query);
        const result: Record<string, string> = {};
        if (![undefined, null].includes(typeField)) {
            for (const match of typeField) {
                if ([undefined, null].includes(match) || match === query) {
                    continue;
                }
                if (match.includes(':') && match.split(':').length === 2) {
                    const [name, value] = match.split(':');
                    result[name] = value.trim().replace(/\"/g, '');
                } else {
                    result['search'] = match;
                }
            }
        } else {
            result['search'] = query;
        }
        return result;
    }

    public search = async (query: string) => {
        if (!query) {
            return;
        }

        this.searchInProgress = true;
        this.searchResultsFound = false;
        this.query = query;
        document.getElementById('globalSearchInput').focus();

        const searchResponses = await q.all(
            this.sources.filter(this._filterType).map(
                (source: SearchSource) => {
                    this.results[source.type] = [];
                    this.totals[source.type] = 0;
                    this.loading[source.type] = true;

                    let _query = this.query;
                    const parsedQuery = this._filterRegex(_query);
                    let filter = source.filter(_query);
                    if (![undefined, null, ''].includes(parsedQuery['field']) && ![undefined, null, ''].includes(parsedQuery['search'])) {
                        filter = source.filterProperty(parsedQuery['field'], parsedQuery['search']);
                    } else if (![undefined, null, ''].includes(parsedQuery['search']) && parsedQuery['search'].startsWith('#')) {
                        filter = source.filterId(parsedQuery['search'].slice(1));
                    } else if (![undefined, null, ''].includes(parsedQuery['search'])) {
                        filter = source.filter(parsedQuery['search']);
                    } else if ([undefined, null, ''].includes(parsedQuery['search']) && _query.startsWith('#')) {
                        filter = source.filter(_query.slice(1));
                    }

                    return source.model.list(5, 1, filter, null)
                        .then((result) => [source, result]);
                }
            )
        );

        this.searchInProgress = false;
        this.noSearchCommitted = false;
        this.$timeout(() => {
            searchResponses.forEach(
                (resultWrapper) => {
                    let source: SearchSource;
                    let result: any;

                    [source, result] = resultWrapper;

                    // Making API responses uniform.
                    if (result.response) {
                        result = result.response;
                    }

                    if (result.data.length > 0) {
                        this.searchResultsFound = true;
                    }
                    result.data.map(
                        (product: any) => {
                            product._type = source.type;
                            this.results[source.type].push(product);
                            return product;
                        }
                    );

                    this.loading[source.type] = false;
                    this.totals[source.type] = result?.pagination?.entries || result?.totalEntries;
                }
            );
        });
    };

    public hide(): void {
        this.internal.show = false;
    }

    public openHelpMenu(): void {
        this.menuOpen = !this.menuOpen;
    }
}

export class OrganismGlobalSearchComponent {
    public controller = OrganismGlobalSearchController;
    public controllerAs = '$globalSearch';

    public template = require('./global-search.html');
}
