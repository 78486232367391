import * as ng from 'angular';
import { DataObject } from '@/services';

export class MoleculePanelEditRowTextareaController implements ng.IController {
    public static $inject: string[] = [];

    public value: string;
    public placeholder: string;
    public readonly: boolean;
    public disabled: boolean;
    public callbackOnChange: (arg0: unknown) => unknown;
    public callbackOnChangeParams: unknown;
    public labelLeft = false;
    public validationInstructions: DataObject[];   // Used to pass custom validators if type 'custom' is used
    public validationInstructionsOperator: string; // && ||
    public rows: string;
    public submit: () => void;
    public catchFocus: boolean;
    public callbackOnClear: (arg0: unknown) => unknown;
    public callbackOnClearParams: unknown;
    public clearable: boolean;

    public $onInit(): void {
        this.value = this.value || '';
    }
}

export class MoleculePanelEditRowTextareaComponent implements ng.IComponentOptions {
    public transclude = {
        label: 'rowLabel'
    };
    public bindings = {
        callbackOnChange: '<',
        callbackOnChangeParams: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        catchFocus: '<',
        clearable: '<',
        disabled: '<',
        labelLeft: '<?',
        placeholder: '@',
        readonly: '<',
        rows: '<',
        submit: '<',
        validationInstructions: '<',
        validationInstructionsOperator: '@?',
        value: '='
    };
    public controller = MoleculePanelEditRowTextareaController;
    public controllerAs = '$panelRow';
    public template = require('./panel-edit-row-textarea.html');
}
