export type DatabaseAccessLevelsAllRightsType = 'read' | 'write' | 'schema';

export const DatabaseAccessLevelsAllRightsConst = [
    'read',
    'write',
    'schema'
];

export const DatabaseAccessObjectConst = {
    accessLevel: DatabaseAccessLevelsAllRightsConst,
    databaseId: '',
    userId: ''
};

export const DatabaseUserObjectConst = {
    accountId: '',
    comments: '',
    id: '',
    name: '',
    password: null
};

export const DatabaseObjectConst = {
    accesses: [],
    accountId: '',
    name: '',
    productCode: '',
    storageQuota: 0
};
