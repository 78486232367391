import * as ng from 'angular';

import { OrganSslWizardProductConfigCertificateContactsController } from '.';
import { ViewTypes } from '../../../../../../types/view-types';

export class OrganSslWizardProductConfigCertificateContactOrganizationController {
    public static $inject: string[] = [
        '$translate'
    ];

    public organizationContact: ViewTypes.SslContact<ViewTypes.OrganizationContactFields>;
    public metaData: ViewTypes.ProductConfigSslObject;
    public salutationsDropdown: { name: string; value: string }[] = [];

    public $onInit() {
        this.organizationContact.filledOut = this.filledOut;
    }

    public filledOut = () => {
        return OrganSslWizardProductConfigCertificateContactsController.filledOut(this.organizationContact);
    };

    public set organizationCountry(country: string) {
        this.organizationContact.fields.country.value = country.toUpperCase();
    }
    public get organizationCountry(): string {
        return this.organizationContact.fields.country.value;
    }
}

export class OrganSslWizardProductConfigCertificateContactOrganizationComponent implements ng.IComponentOptions {
    public bindings = {
        metaData: '<',
        organizationContact: '=',
        salutationsDropdown: '<'
    };
    public template = require('./config-certificate-contact-organization.html');
    public controller = OrganSslWizardProductConfigCertificateContactOrganizationController;
}
