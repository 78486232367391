import * as ng from 'angular';

import { BundleProductFamilies } from '../../../../../configuration';
import {
    BundleModelService,
    DnsZoneModelService,
    DomainModelService,
    OrchestratorRobotService
} from '../../../../../services';
import { WizardCreateObject } from '../../general';
import { BundleWizardConfirmation } from './bundle-wizard-confirmation';

export class OrganismBundleWizardConfirmViewController {
    public static $inject: string[] = [
        '$translate',
        'bundleModel',
        'dnsZoneModel',
        'domainModel',
        'orchestratorRobot',
        'bundleWizardConfirmation'
    ];

    public bundleId = '';
    public wizardCreateObjectList: WizardCreateObject[];
    public nextSteps: any[] = [];

    private _apiProductObject: any; // Required information for the products that need to be ordered.

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private bundleModel: BundleModelService,
        private dnsZoneModel: DnsZoneModelService,
        private domainModel: DomainModelService,
        private orchestratorRobot: OrchestratorRobotService,
        public bundleWizardConfirmation: BundleWizardConfirmation // also used in template!
    ) {} // tslint:disable-line:no-empty

    public $onInit() {
        if ([undefined, null].indexOf(this.bundleWizardConfirmation.orchestrationJobsGroups) > -1) {
            this.bundleWizardConfirmation.orchestrationJobsGroups = [];
        }

        this.wizardCreateObjectList = [];
        this.bundleWizardConfirmation.selectedProductFamily = this._apiProductObject.productFamily;

        if (this._apiProductObject.productFamily === BundleProductFamilies.email) {
            this.bundleWizardConfirmation.useOrchestrationService = false;
            const createJob: WizardCreateObject = {
                callback:
                    () => {
                        return this.bundleModel.create(
                            this._apiProductObject.apiObject.bundle,
                            this._apiProductObject.apiObject.bundle.accountId
                        ).then((bundle) => {
                            // used in template! For "create new mail account"-route params
                            this.bundleId = bundle.id;
                            return bundle;
                        });
                    },
                children: [],
                labelText: this.$translate.instant('TR_171219-919b5c_TR'),
                objectType: 'Bundle'
            };

            if ([undefined, null].indexOf(this._apiProductObject.apiObject.domain) < 0) {
                this.bundleWizardConfirmation.useOrchestrationService = false;
                createJob.children.push({
                    callback: (bundleResult)  => {
                        if (this._apiProductObject.apiObject.domain.registrationMode === 'bundle') {
                            this._apiProductObject.apiObject.domain.bundleId = bundleResult.id;
                        }

                        if (
                            [null, undefined].indexOf(this._apiProductObject.apiObject.domain.authCode) < 0
                            && this._apiProductObject.apiObject.domain.authCode.length > 0
                        ) {
                            return this.domainModel.transfer(
                                this._apiProductObject.apiObject.domain,
                                { authInfo: this._apiProductObject.apiObject.domain.authCode },
                                this._apiProductObject.apiObject.bundle.accountId
                            );
                        } else {
                            return this.domainModel.register(
                                this._apiProductObject.apiObject.domain,
                                this._apiProductObject.apiObject.bundle.accountId
                            );
                        }
                    },
                    children: [],
                    labelText: this.$translate.instant('TR_171219-072231_TR'),
                    objectType: 'Domain'
                });
                this.wizardCreateObjectList.push(createJob);
                this.wizardCreateObjectList.push({
                    callback: () => {
                        return this.dnsZoneModel.createNative(
                            this._apiProductObject.apiObject.domain.name,
                            [],
                            {},
                            null,
                            'NATIVE',
                            undefined,
                            this._apiProductObject.apiObject.bundle.accountId
                        );
                    },
                    children: [],
                    labelText: this.$translate.instant('TR_171219-b1141e_TR'),
                    objectType: 'Zone'
                });
            } else {
                this.wizardCreateObjectList.push(createJob);
            }
        } else if (this._apiProductObject.productFamily === BundleProductFamilies.webspace) {
            this.bundleWizardConfirmation.useOrchestrationService = true;
            this.wizardCreateObjectList.push({
                callback: () => {
                    return this.orchestratorRobot.order(
                        this._apiProductObject.apiObject.orderItem,
                        this._apiProductObject.apiObject.ownerAccountId
                    );
                },
                labelText: this.$translate.instant('TR_210319-726ddf_TR'),
                objectType: 'Webspace'
            });
        }
    }
}

export class OrganismBundleWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        _apiProductObject: '<apiProductObject',
        wizardCreateObjectList: '<'
    };

    public template = require('./bundle-wizard-confirm-view.html');
    public controller = OrganismBundleWizardConfirmViewController;
}
