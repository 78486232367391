import * as ng from 'angular';
import { OverviewDataObject } from '../../../../../../organisms';

export class MoleculeOverviewPricelistRowPanelPromotionsController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
}

export class MoleculeOverviewPricelistRowPanelPromotionsComponent implements ng.IComponentOptions {
    public require = {
        organismPricelistOverview: '^organismPricelistOverview'
    };
    public bindings = {
        overviewData: '<'
    };
    public controller =  MoleculeOverviewPricelistRowPanelPromotionsController;
    public controllerAs = 'OverviewPricelistCtrl';
    public template = require('./pricelist-promotions-row.html');
}
