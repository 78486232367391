import ng from 'angular';
import { NextcloudFreeProductCodeConst, UiRights } from '../../../../configuration';
import { AuthContextService } from '../../../../services';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbStorageTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat([]);

    public readonly topicName: string = 'storage';
    public readonly specialTopicCases: TopicCases;

    /**
     * List of route parts, which are excluded from main class generalRoutes method
     */
    public readonly excludeGeneralRouteParts = ['overview', 'new', 'edit'];

    private storageActionDropdownItems: BreadcrumbItem[] = [];
    private nextcloudActionDropdownItems: BreadcrumbItem[] = [];

    constructor(protected $state: ng.ui.IStateService, protected $translate: ng.translate.ITranslateService) {
        super($state, $translate);

        this.storageActionDropdownItems = [
            {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST,
                part: 'overview',
                route: 'storage.storage-products.overview'
            },
            {
                isGranted: UiRights.MANAGED_APPLICATION_JOBS_LIST,
                part: 'jobs',
                route: 'storage.storage-products.jobs'
            },
            {
                isGranted: UiRights.MANAGED_APPLICATION_NEXTCLOUD_CREATE,
                part: 'new',
                route: 'storage.storage-products.new'
            }
        ];

        this.nextcloudActionDropdownItems = [
            {
                part: 'overview',
                route: 'storage.storage-products.overview'
            },
            {
                name: this.$translate.instant('TR_100119-4669d2_TR'),
                part: 'edit',
                route: 'storage.storage-products.id.edit'
            },
            {
                name: this.$translate.instant('TR_230621-29daf9_TR'),
                part: 'config',
                route: 'storage.storage-products.id.config'
            },
            {
                name: this.$translate.instant('TR_030919-a153f2_TR'),
                part: 'upgrade',
                route: 'storage.storage-products.id.upgrade'
            },
            {
                name: this.$translate.instant('TR_180221-2542cc_TR'),
                part: 'addons',
                route: 'storage.storage-products.id.addons'
            },
            {
                name: this.$translate.instant('TR_121020-0e5df1_TR'),
                part: 'app-center',
                route: 'storage.storage-products.id.app-center'
            },
            {
                name: this.$translate.instant('TR_250519-7f279b_TR'),
                part: 'restrictions',
                route: 'storage.storage-products.id.restrictions'
            }
        ];

        if (
            AuthContextService.isGrantedAny([
                UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT,
                UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN
            ])
        ) {
            this.nextcloudActionDropdownItems.push({
                name: this.$translate.instant('TR_070720-f5a487_TR'),
                part: 'admin-access',
                route: 'storage.storage-products.id.admin-access'
            });
        }

        this.specialTopicCases = {
            'app-center': this.getStorageEditRoute,
            'addons': this.getStorageEditRoute,
            'cancel': this.getCancelRoute,
            'config': this.getStorageEditRoute,
            'details': this.jobDetailsRoute,
            'edit': this.getStorageEditRoute,
            'id': this.getNextcloudName,
            'jobs': () => this.getProductActionDropdown(this.storageActionDropdownItems),
            'new': () => this.getProductActionDropdown(this.storageActionDropdownItems),
            'overview': () => this.getProductActionDropdown(this.storageActionDropdownItems),
            'rename': this.getStorageEditRoute,
            'restrictions': this.getStorageEditRoute,
            'upgrade': this.getStorageEditRoute,
            'use-own-domain': this.getStorageEditRoute
        };
    }

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            type: 'text',
            value: this.$translate.instant('MENU.MAIN.PRODUCTS.LABEL')
        });

        if (AuthContextService.isGranted(UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST)) {
            this.breadcrumbs = this.breadcrumbs.concat(
                this.getServiceListDropdownBox({
                    active: this.steps.length === 2,
                    isGrantedAny: [UiRights.MANAGED_APPLICATION_NEXTCLOUD_LIST, UiRights.MANAGED_APPLICATION_JOBS_LIST],
                    name: this.$translate.instant('TR_140519-4f0af6_TR'), // storage: TR_140519-4ff911_TR
                    route: 'storage.dashboard',
                    routeParams: {},
                    step: 'storage',
                    title: this.$translate.instant('TR_150519-73b99f_TR') // storage: TR_150519-96ae60_TR
                })
            );
        }

        return this.breadcrumbs;
    };

    public getCancelRoute = (): Breadcrumb[] => {
        if (
            this.$state.$current.locals.globals.storageProduct !== null
            && this.$state.$current.locals.globals.storageProduct.productCode !== NextcloudFreeProductCodeConst
        ) {
            return this.getTerminateRoute();
        }

        return this.getDeleteRoute();
    };

    public getStorageEditRoute = (): Breadcrumb[] =>
        this.getProductActionDropdown(this.nextcloudActionDropdownItems, {}, this.step);

    public getNextcloudName = (): Breadcrumb[] => [
        {
            active: this.steps.length === 4 && this.steps[this.steps.length - 1] === 'edit',
            route: 'storage.storage-products.id.edit',
            routeParams: { storageProductId: this.$state.params.storageProductId },
            type: 'text',
            value: this._getNextcludNameFromState()
        }
    ];

    private _getNextcludNameFromState = (): string => {
        if (this.globals === undefined) {
            return '';
        }

        switch (true) {
            case this.globals.storageProduct !== undefined:
                return this.globals.storageProduct.name;
            case this.globals.job !== undefined:
                return this.globals.job.displayName;
            default:
                return '';
        }
    };
}
