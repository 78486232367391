import { ApiExpectedErrorList } from '@/services';
import * as ng from 'angular';
import {
    BundleWizardConfirmation,
    DnsWizardConfirmation,
    DomainWizardConfirmation,
    EmailWizardConfirmation,
    ManagedServerProjectWizardConfirmation,
    NextcloudWizardConfirmation,
    WizardConfirmationBase
} from '../../';
import { WizardCreateObject } from '../../general';
import { SslWizardConfirmation } from './../../ssl/confirm/ssl-wizard-confirmation';

export type ServiceWizardConfirm =  BundleWizardConfirmation
                                |   DnsWizardConfirmation
                                |   DomainWizardConfirmation
                                |   EmailWizardConfirmation
                                |   ManagedServerProjectWizardConfirmation
                                |   NextcloudWizardConfirmation
                                |   WizardConfirmationBase
                                |   SslWizardConfirmation;

export class OrganismWizardConfirmViewController {
    public static $inject: string[] = ['wizardConfirmationBase'];

    public expectedErrorList: ApiExpectedErrorList[] = [];
    public wizardCreateObjectList: WizardCreateObject[] = [];
    public serviceWizardConfirm: ServiceWizardConfirm;

    public constructor(
        private wizardConfirmationBase: WizardConfirmationBase
    ) {}

    public $onDestroy() {
        this.serviceWizardConfirm.cancelJobInterval();
    }

    public $onInit() {
        if (this.serviceWizardConfirm === undefined) {
            this.serviceWizardConfirm = this.wizardConfirmationBase;
        }

        if ([undefined, null].indexOf(this.serviceWizardConfirm.orchestrationJobsGroups) > -1) {
            this.serviceWizardConfirm.orchestrationJobsGroups = [];
        }

        // Start wizard order process
        this.serviceWizardConfirm.startOrderProcess(this.wizardCreateObjectList);
    }

    public jobFailed = (job) => {
        return this.serviceWizardConfirm.jobFailed(job.result)
            && !this.serviceWizardConfirm.hasExpectedError(job);
    };

    public jobAborted = (job) => {
        return this.serviceWizardConfirm.jobAborted(job.result)
            && !this.serviceWizardConfirm.hasExpectedError(job);
    };

    public hasUserNotification = (job) => {
        return this.serviceWizardConfirm.hasExpectedError(job, true) !== false;
    };

    public getUserNotification = (job) => {
        return this.serviceWizardConfirm.hasExpectedError(job, true);
    };
}

export class OrganismWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        expectedErrorList: '<?',
        finishCreateProcess: '=',
        serviceWizardConfirm: '<',
        wizardCreateObjectList: '='
    };

    public template = require('./wizard-confirm-view.html');
    public controller = OrganismWizardConfirmViewController;
}
