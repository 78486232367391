import * as ng from 'angular';
import {
    AlertManagerService,
    AuthContextService,
    DnsRobotService,
    NavigationService
} from '../../../../../services';

export class OrganEditPanelDomainZoneEntriesController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'authContext',
        'dnsRobot',
        'navigation'
    ];

    public domain;
    public zoneData;
    public checkDomainZone = true;
    private zoneStatus: string;
    private accountSystemNameserver: any[];
    private defaultNameservers;

    private _nameserverSetId = '';
    private _defaultNameserver = false;

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private authContext: AuthContextService,
        private dnsRobot: DnsRobotService,
        private navigation: NavigationService
    ) {}

    public $onInit() {
        this.checkDomainZone = true;
        this.zoneData = this.zoneData || null;
        this.zoneStatus = 'zoneEditable';
        let isSystemNameserver = false;

        if (this.zoneData === null) {
            if ([undefined, null].indexOf(this.domain.nameservers) !== -1) {
                isSystemNameserver = false;
            } else {
                isSystemNameserver = this.domain.nameservers.every((domainNameserver) => {
                    if ([undefined, null].indexOf(this.accountSystemNameserver) !== -1) {
                        return false;
                    }
                    // check domain nameservers in accountSystemNamerservers. If true, than set nameserverSetId
                    return this.accountSystemNameserver.some((nsSet) => {
                        const inSet = nsSet.nameservers.every((ns) => {
                            return ns === domainNameserver.name;
                        });

                        if (inSet) {
                            this._nameserverSetId = nsSet.id;
                            return true;
                        }

                        return false;
                    });
                });
                if (!isSystemNameserver) {
                    if ([undefined, null].indexOf(this.defaultNameservers) !== -1) {
                        isSystemNameserver = false;
                    } else {
                        isSystemNameserver = this._defaultNameserver = this.domain.nameservers.every((ns, index) => {
                            return ns.name === this.defaultNameservers.nameservers[index];
                        });
                    }
                }
            }

            this.zoneStatus = isSystemNameserver ? 'zoneCreateRequired' : 'zoneExternal';
        }

        this.checkDomainZone = false;
    }

    public goToRecordsEdit = () => {
        this.navigation.go('domain.domains.id.records', {domainId: this.domain.id});
    };

    public createZone = () => {
        this.dnsRobot.createZone({name: this.domain.name, type: 'NATIVE'}, [],
            this._nameserverSetId, this._defaultNameserver, this.authContext.account.id)
        .then(
            (res) => {
                if (res.status !== 'error') {
                    this.alertManager.success(this.$translate.instant('TR_110119-7a5cf9_TR'));
                    this.navigation.go('domain.domains.id.records', {domainId: this.domain.id});
                }
            }
        );
    };
}

export class OrganEditPanelDomainZoneEntriesComponent implements ng.IComponentOptions {
    public bindings = {
        accountSystemNameserver: '<',
        defaultNameservers: '<',
        domain: '<',
        zoneData: '<'
    };
    public controller = OrganEditPanelDomainZoneEntriesController;
    public template = require('./domain-edit-zone-entries.html');
}
