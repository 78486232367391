import * as q from 'q';
import {
    OverviewModelUtilityChart,
    UtilityStatusObject
} from '../../atomic-components/molecules/charts/utility-status/utility-status';
import { LinkListOverviewItem } from '../../atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField,
    ApiSelectFilterField,
    SelectFilterField,
    TextFilterField
} from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';
import { DatabaseUserModelService } from './database-user-model';

export class DatabaseOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$state', '$translate', 'databaseModel', 'databaseUserModel', 'objectStatusFilter']
    );

    public readonly service = 'database';
    public readonly objectType = 'Database';
    public readonly idField = 'databaseId';
    public readonly defaultFilterFields = ['DatabaseDbName', 'DatabaseName'];

    public activeMassAction: string | boolean = false;
    public selected = {};
    public selectedAll = false;
    public actionLinksAvailabilities = {};

    protected listCallbacks = [];
    private _cachedAvailableFilters = null;
    private _cachedFiltersIncludeUserFilter = false;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private databaseModel: any,
        private databaseUserModel: DatabaseUserModelService,
        private objectStatusFilter
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            deletableDatabases: this.deletableDatabases,
            editableDatabases: this.editableDatabases,
            restorableDatabases: this.restorableDatabases
        };

        this.listCallbacks = [{
            callback: this.listDatabases
        }];
    }

    public listDatabases = (limit?, page?, filters?, sort?, cancel?) => {
        if (this.$state.current.name.split('.').indexOf('dashboard') >= 0) {
            return this.databaseModel.listWithoutPagination(limit, page, filters, sort, cancel);
        } else {
            return this.databaseModel.list(limit, page, filters, sort, cancel);
        }
    };

    public actionLinks = (params: { [param: string]: any }): LinkListOverviewItem[] => {
        let createRoute = 'database.databases.new';
        let createRouteParams = null;
        let inManagedServerState = false;
        switch (this.$state.current.name.split('.')[0]) {
            case 'bundle':
                createRoute = 'bundle.id.databases.new';
                createRouteParams = { bundleId: this.$state.$current.locals.globals.bundle.id };
                break;
            case 'managed-servers':
                createRoute = 'managed-servers.id.databases.new';
                createRouteParams = { machineId: this.$state.$current.locals.globals.machine.id };
                inManagedServerState = true;
                break;
            default:
                // nothing to do here
        }
        const actionLinks = [
            {
                isClickable: 'deletableDatabases',
                isGranted: AuthContextService.isGrantedAny([UiRights.DB_DATABASE_DELETE]),
                linkList: [
                    {
                        group: 'delete',
                        groupName: this.$translate.instant('TR_140119-aee71e_TR'),
                        isClickable: 'deletableDatabases',
                        isGranted: AuthContextService.isGranted(UiRights.DB_DATABASE_DELETE),
                        onclickMethode: () => this.activeMassAction = 'delete',
                        route: '',
                        text: this.$translate.instant('TR_140119-2e6d83_TR')
                    },
                    {
                        group: 'delete',
                        isClickable: 'restorableDatabases',
                        isGranted: AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_RESTORE),
                        onclickMethode: () => this.activeMassAction = 'restore',
                        route: '',
                        text: this.$translate.instant('TR_140119-1f0f46_TR')
                    },
                    {
                        group: 'delete',
                        isClickable: 'restorableDatabases',
                        isGranted: AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_DELETE),
                        onclickMethode: () => this.activeMassAction = 'deleteRestorable',
                        route: '',
                        text: this.$translate.instant('TR_140119-e9ccd7_TR')
                    },
                    {
                        group: 'others',
                        groupName: this.$translate.instant('TR_140119-75ba67_TR'),
                        isClickable: 'editableDatabases',
                        isGranted: AuthContextService.isGranted(UiRights.EMAIL_MAILBOX_DELETE),
                        onclickMethode: () => this.activeMassAction = 'wipe',
                        route: '',
                        text: this.$translate.instant('TR_140119-d7c4d6_TR')
                    }
                ],
                onlyOnExperView: true,
                route: '',
                text: this.$translate.instant('TR_140119-080106_TR'),
                type: 'multi'
            },
            {
                route: createRoute,
                routeParams: createRouteParams,
                text: this.$translate.instant('TR_140119-614f1b_TR')
            }
        ] as LinkListOverviewItem[];

        if (!inManagedServerState) {
            actionLinks.unshift({
                isGranted: AuthContextService.isGrantedAny([
                    UiRights.DB_USER_LIST,
                    UiRights.DB_JOBS_LIST
                ]),
                linkList: [
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.DB_USER_LIST),
                        route: 'database.users.overview',
                        text: this.$translate.instant('TR_100119-3176b5_TR')
                    },
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.DB_JOBS_LIST),
                        route: 'database.jobs',
                        text: this.$translate.instant('MENU.CONTEXT.JOBS')
                    }
                ],
                route: '',
                text: this.$translate.instant('TR_100119-a22157_TR'),
                type: 'multi'
            });
        }

        return actionLinks;
    };

    /**
     * Database in Bundle, set utilityChart for status chartValue
     */

    public bundleUtilityChart = (): OverviewModelUtilityChart => {
        return {
            inUse: true,
            infoText: this.$translate.instant('TR_090119-f0ccfb_TR'),
            maxUse: true,
            productcodes: ['database-bundle-mariadb-single', 'database-mariadb-single'],
            showAbsolute: true
        };
    };

    /**
     *  Submit methods
     */
    public deleteSubmit = (databases, actionObject) => {
        const deleteDatabases = Object.keys(this.selected).map((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            return this.getObjectFromId(databases, elem);
        });

        return q.all(deleteDatabases.map((database) => {
            this.databaseModel.deleteOne(database, this.getDeletionDate(database, actionObject));
        }));
    };

    public restoreSubmit(databases) {
        const restoreDatabases = Object.keys(this.selected).map((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            return this.getObjectFromId(databases, elem);
        });

        return this.databaseModel.restore(restoreDatabases);
    }

    public deleteRestoreSubmit(databases) {
        const deleteDatabases = Object.keys(this.selected).map((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            return this.getObjectFromId(databases, elem);
        });

        return q.all(deleteDatabases.map(this.getObjectId).map(this.databaseModel.purgeRestorable));
    }

    public wipeSubmit(databases) {
        const wipeDatabases = Object.keys(this.selected).map((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            return this.getObjectFromId(databases, elem);
        });

        return q.all(wipeDatabases.map(this.getObjectId).map(this.databaseModel.wipeDatabase));
    }

    /**
     *  Action availabbility definitions
     */
    public deletableDatabases = (databases) => {
        if (databases.length === 0) {
            return false;
        }

        let selected = 0;
        const deleteable = Object.keys(this.selected).filter((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            selected += 1;

            return ['active', 'overQuota', 'restricted'].indexOf(this.getObjectFromId(databases, elem).status) >= 0;
        });

        return deleteable.length > 0 && deleteable.length === selected;
    };

    public restorableDatabases = (databases) => {
        if (databases.length === 0) {
            return false;
        }

        let selected = 0;
        const restorable = Object.keys(this.selected).filter((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            selected += 1;

            return ['restorable'].indexOf(this.getObjectFromId(databases, elem).status) >= 0;
        });

        return restorable.length > 0 && restorable.length === selected;
    };

    public editableDatabases = (databases) => {
        if (databases.length === 0) {
            return false;
        }

        let selected = 0;
        const restorable = Object.keys(this.selected).filter((elem) => {
            if (!this.selected[elem]) {
                return false;
            }
            selected += 1;

            return ['active'].indexOf(this.getObjectFromId(databases, elem).status) >= 0;
        });

        return restorable.length > 0 && restorable.length === selected;
    };

    private getDeletionDate = (database: any, settings: any) => {
        if (settings.actiontype === 'sofort') {
            return null;
        }
        if (settings.actiontype === 'endOfContract') {
            return new Date(database.paidUntil);
        }
        if (settings.actiontype === 'date') {
            return settings.deleteDate.dateObj;
        }
    };

    private getObjectFromId = (objects, objectId) => {
        return objects.filter((object) => {
            return object.id === objectId;
        })[0];
    };

    private getObjectId = (object) => {
        return object.id;
    };

    private getExecutionDate(database) { // Used?
        return undefined;
        /* switch (deleteType) {
            case '3':
                return this.$scope.form.delDate;
            case '2':
                return new Date(machine.paidUntil);
            default:
                return undefined;
        }*/
    }

    public get availableFilters() {
        if (this._cachedFiltersIncludeUserFilter !== this.$state.current.name.indexOf('.users.id') < 0) {
            this._cachedAvailableFilters = null;
        }

        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('DatabaseName', this.$translate.instant('TR_140119-03bd29_TR')),
                new TextFilterField('DatabaseDbName', this.$translate.instant('TR_140119-49cec9_TR')),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField(
                    'DatabaseStatus',
                    this.$translate.instant('TR_140119-8e4b60_TR'),
                    [
                        {
                            name: this.objectStatusFilter('active'),
                            value: 'active'
                        },
                        {
                            name: this.objectStatusFilter('creating'),
                            value: 'creating'
                        },
                        {
                            name: this.objectStatusFilter('restorable'),
                            value: 'restorable'
                        },
                        {
                            name: this.objectStatusFilter('restricted'),
                            value: 'restricted'
                        }
                    ]
                )
            ];

            if (this.$state.current.name.indexOf('.users.id') < 0) {
                this._cachedAvailableFilters.push(
                    new ApiSelectFilterField(
                        'DatabaseAccessesUserId',
                        this.$translate.instant('TR_180419-5581ae_TR'),
                        ['UserName', 'UserDBName'],
                        this.databaseUserModel.findOneById,
                        this.databaseUserModel.listWithoutPagination,
                        'UserId',
                        {
                            id: 'UserId',
                            name: 'name',
                            value: 'id'
                        }
                    )
                );
            }

            this._cachedFiltersIncludeUserFilter = this.$state.current.name.indexOf('.users.id') < 0;
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }
}
