import * as ng from 'angular';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService,
    AuthContextService,
    MachineModelService,
    NavigationService
} from '../../../../../services';
import { InstallerModelService } from '../../../../../services';

export class OrganismEditFormMachineOsInstallController {
    public static $inject: string[] = [
        '$translate',
        'alertManager',
        'installerModel',
        'machineModel',
        'navigation'
    ];

    public machine: any;
    public operatingSystems: any;
    public selectedOs: any;
    public password: string;
    public sshKey: string;
    public extraUserName: string;
    public extraUserPass: string;
    public extraUserKeys: string;
    public showOverlay = false;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private installerModel: InstallerModelService,
        private MachineModel: MachineModelService,
        private navigation: NavigationService
    ) {}

    public installOs = () => {
        if (this.machine.managementType === 'platform') {
            if (this.isSuperUser === false) {
                return false;
            }

            this.MachineModel.reinstallManaged(this.machine.id)
                .then(
                    (res) => {
                        if (res.status !== 'error') {
                            this.alertManager.success(this.$translate.instant('TR_310119-59e784_TR'));
                            this.navigation.go('machine.virtualmachines.id.edit');
                        }

                        return res;
                    },
                    () => {
                        this.showOverlay = false;

                        return Promise.reject(false);
                    }
                );
        } else {
            this.showOverlay = true;
            let data;

            if ([undefined].indexOf(this.extraUserName) >= 0 || this.extraUserName !== '') {
                data = {
                    extraUserKeys: this.extraUserKeys,
                    extraUserName: this.extraUserName,
                    extraUserPass: this.extraUserPass,
                    rootUserKeys: this.sshKey,
                    rootUserPass: this.password
                };
            } else {
                data = {
                    rootUserKeys: this.sshKey,
                    rootUserPass: this.password
                };
            }

            this.installerModel.installOs(
                this.machine.id, this.selectedOs.general.pkgName, data, AuthContextService.account.id
            ).then(
                () => {
                    this.showOverlay = false;
                    this.alertManager.success(this.$translate.instant('TR_310119-59e784_TR'));
                    this.navigation.go('machine.virtualmachines.id.edit', {}, {reload: true});
                },
                (apiResponse) => {
                    this.showOverlay = false;

                    return apiResponse;
                }
            );
        }
    };

    public get isManagedServer() {
        if ([undefined].indexOf(this.machine.managementType) === 0) {
            return false;
        }

        return this.machine.managementType !== 'none';
    }

    public get isSuperUser() {
        return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN);
    }

    public get showOsInstallerPanel() {
        return !this.isManagedServer
            || (
                this.isSuperUser
                && this.isManagedServer
                && this.machine.managementType === 'individual'
            );
    }

    public get showReMachineResetPanel() {
        return this.isSuperUser
            && this.isManagedServer
            && this.machine.managementType === 'platform';
    }
}

export class OrganismEditFormMachineOsInstallComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<',
        operatingSystems: '<'
    };
    public controller = OrganismEditFormMachineOsInstallController;
    public template = require('./machine-os-install.html');
}
