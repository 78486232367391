export const ManagedMachineConst = {
    contingentProductCodes: {
        database: 'database-mariadb-single-v1-1m',
        webspace: 'webhosting-webspace-v1-1m'
    }
};

export const ManagedServerProjectProductCodes = {
    database: 'database-mariadb-single-v1-1m',
    webspace: 'webhosting-webspace-v1-1m'
};

export const MachineProductFamilies = {
    cloudServer: 'virtual-machines',
    managedCloudServer: 'managed-virtual-machines',
    ecommerceServer: 'ecommerce-virtual-machines',
    individualServer: 'individual-virtual-machines'
};
