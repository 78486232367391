import * as ng from 'angular';
import { UiLanguagesConst } from '../../../../../configuration';
import {
    AuthContextService,
    DatabaseModelService,
    DomainInfoHelperService,
    ValidateAllowedCharacters,
    ValidateDns,
    ValidateEmail,
    ValidateIllegalCharacters,
    ValidateMaxLength,
    ValidateMinLength,
    ValidateNotEmpty,
    ValidateRegularExpression
} from '../../../../../services';

export class MoleculePanelEditRowAppController implements ng.IController {
    public static $inject: string[] = ['$translate', 'languageSettingFilter', 'databaseModel', 'domainInfoHelper'];

    public serviceObject: any;
    public optionData: any;
    public validationSettings: any[];
    public validationErrorList: any[] = [];
    public passwordIsValid = true;
    public dropdownItems: any[];
    public language: string;
    public bundleId: string;
    public loadingDropdownItems: boolean;
    public databaseRessource;

    private _accountId: string;
    private _passwordValidationOptions = {};

    constructor(
        private $translate: ng.translate.ITranslateService,
        private languageSettingFilter,
        private databaseModel: DatabaseModelService,
        private domainInfoHelper: DomainInfoHelperService
    ) {}

    public $onInit() {
        this.passwordIsValid = true;
        this.validationErrorList = [];
        this.loadingDropdownItems = true;
        if ([undefined, null].indexOf(this.language) >= 0) {
            this.language = UiLanguagesConst[AuthContextService.user.language];
        }

        switch (this.optionData.type) {
            case 'string':
                this.setValidation();
                if (this.optionData.validation.minLength === null
                    || this.optionData.validation.minLength === undefined
                ) {
                    this.serviceObject.appSettings[this.optionData.key] = '';
                }
                break;
            case 'bool':
                this.serviceObject.appSettings[this.optionData.key] = JSON.parse(this.optionData.default);
                break;
            case 'select':
                this.setDropdownItems();
                this.serviceObject.appSettings[this.optionData.key] = this.optionData.default;
                break;
            case 'database':
                this.setDropdownItems();
                this.serviceObject.database[this.optionData.key] = this.optionData.default;
                break;
            default:
                // nothing to dd here...
        }
        if (this.optionData.validation !== undefined) {
            this._passwordValidationOptions = {
                allowOtherCharacters: this.optionData.validation.otherCharacters,
                characterPools: this.optionData.validation.characterClasses,
                minCharacterPoolsUsed: this.optionData.validation.minClasses,
                minLength: this.optionData.validation.minLength
            };
        }
    }

    // tslint:disable-next-line:no-empty
    public set isValid({}) {}
    public get isValid() {
        if (this.optionData.type === 'password') {
            return this.passwordIsValid;
        }
        return this.validationErrorList.length === 0;
    }

    private setValidation = () => {
        this.validationSettings = [];
        let isEmailAdressField = false;
        if (this.optionData.validation.extendedType !== undefined && this.optionData.validation.extendedType !== null) {
            if (this.optionData.validation.extendedType === 'emailAddress') {
                isEmailAdressField = true;
                this.validationSettings.push({
                    instructions: {},
                    validator: new ValidateEmail(true, this.$translate, this.domainInfoHelper, false)
                });
            }
            if (this.optionData.validation.extendedType === 'fqdn') {
                this.validationSettings.push({
                    instructions: {},
                    validator: new ValidateDns(this.$translate)
                });
            }
        }
        if (this.optionData.validation.minLength !== undefined && this.optionData.validation.minLength !== null) {
            this.validationSettings.push({
                instructions: {},
                validator: isEmailAdressField
                    ? new ValidateNotEmpty(this.$translate)
                    : new ValidateMinLength(this.$translate, this.optionData.validation.minLength)
            });
        }
        if (this.optionData.validation.maxLength !== undefined && this.optionData.validation.maxLength !== null) {
            this.validationSettings.push({
                instructions: {},
                validator: new ValidateMaxLength(this.$translate, this.optionData.validation.maxLength)
            });
        }
        if (this.optionData.validation.invalidChars !== undefined && this.optionData.validation.invalidChars !== null) {
            this.validationSettings.push({
                instructions: {},
                validator: new ValidateIllegalCharacters(this.optionData.validation.invalidChars, this.$translate)
            });
        }
        if (this.optionData.validation.validChars !== undefined && this.optionData.validation.validChars !== null) {
            this.validationSettings.push({
                instructions: {},
                validator: new ValidateAllowedCharacters(this.optionData.validation.validChars, this.$translate)
            });
        }
        if (this.optionData.validation.regex !== undefined && this.optionData.validation.regex !== null) {
            this.validationSettings.push({
                instructions: {},
                validator: new ValidateRegularExpression(this.$translate, this.optionData.validation.regex)
            });
        }
    };

    private setDropdownItems = () => {
        this.dropdownItems = [];

        switch (true) {
            case this.optionData.type === 'database':
                this.loadingDropdownItems = true;
                this._getDatabaseDropdownItems();
                break;
            case [undefined, null].indexOf(this.optionData.ui.options) === -1
                && Array.isArray(this.optionData.ui.options):
                this.dropdownItems = this._getDropdownItemsfromUiOptions();
                break;
            default:
                // nothing to do here...
        }
    };

    public get passwordValidationOptions() {
        return this._passwordValidationOptions;
    }

    public get numberValidationOptions() {
        return {
            maximum: this.optionData.validation.maxvalue,
            minimum: this.optionData.validation.minValue
        };
    }

    public get optionName() {
        if (this.optionData.ui.label.translations[this.languageSettingFilter(this.language, 'short')] !== undefined) {
            return this.optionData.ui.label.translations[this.languageSettingFilter(this.language, 'short')];
        } else {
            return this.optionData.ui.label.translations.de;
        }
    }

    private _getDatabaseDropdownItems = () => {
        const filters = {
            subFilter: [
                { field: 'AccountId', value: this._accountId },
                { field: 'DatabaseDbEngine', value: 'MariaDB' },
                {
                    subFilter: [
                        { field: 'DatabaseStatus', value: 'active' },
                        { field: 'DatabaseStatus', value: 'creating' }
                    ],
                    subFilterConnective: 'OR'
                }
            ],
            subFilterConnective: 'AND'
        };
        if ([undefined, null].indexOf(this.databaseRessource) < 0) {
            filters.subFilter.push({ field: 'DatabaseServerId', value: this.databaseRessource.id });
        }

        this.databaseModel.list(100, 1, filters)
            .then(
                (res) => {
                    this.loadingDropdownItems = false;
                    this.dropdownItems = res.data
                        .filter(
                            (database) => [undefined, null, ''].indexOf(this.bundleId) >= 0
                                || [undefined, null, ''].indexOf(database.bundleId) >= 0
                                || database.bundleId === this.bundleId
                        )
                        .map(
                            (database) => ({
                                name: database.name
                                    + (database.deletionScheduledFor !== null
                                            ? ( ' ' + this.$translate.instant(
                                                    /* translationId */ 'TR_280720-99a6e2_TR',
                                                    { deletionScheduledFor: database.deletionScheduledFor }
                                                )
                                            )
                                            : ''
                                    ),
                                value: database.id
                            })
                        );
                }
            );
    };

    private _getDropdownItemsfromUiOptions = () => {
        const dropdownItems = [];
        for (const option of this.optionData.ui.options) {
            let optionLabel: string;
            if (option.translations[this.languageSettingFilter(this.language, 'short')] !== undefined) {
                optionLabel = option.translations[this.languageSettingFilter(this.language, 'short')];
            } else {
                optionLabel = option.translations.de;
            }
            dropdownItems.push({
                name: optionLabel,
                value: option.optionName
            });
        }
        return dropdownItems;
    };
}

export class MoleculePanelEditRowAppComponent implements ng.IComponentOptions {
    public bindings = {
        _accountId: '<accountId',
        bundleId: '<',
        databaseRessource: '<?',
        isValid: '=?',
        language: '<',
        optionData: '<',
        serviceObject: '='
    };
    public controller = MoleculePanelEditRowAppController;
    public template = require('./panel-edit-row-app.html');
}
