import { BillingApi } from '@/types';
import { BillingRobotService } from './robot';

export class PriceModelService {
    public static $inject: string[] = ['billingRobot'];

    constructor(private billingRobot: BillingRobotService) {}

    public getDnsPrices() {
        return this.billingRobot.listServicePrices('dns')
            .then(this.returnResponses)
            .then((prices) => {
                prices = prices.map((price) => {
                    if ([undefined, null].indexOf(price.currency) < 0) {
                        price.currency = price.currency.toUpperCase();
                    }
                    return price;
                });

                return prices;
            });
    }

    public getDomainPrices() {
        return this.billingRobot.priceListDomains()
            .then(this.returnResponses)
            .then((prices) => {
                prices = prices.map((price) => {
                    if ([undefined, null].indexOf(price.currency) < 0) {
                        price.currency = price.currency.toUpperCase();
                    }
                    if ([undefined, null].indexOf(price.domainSuffix) < 0) {
                        price.domainSuffix = '.' + price.domainSuffix;
                    }
                    return price;
                });

                return prices;
            });
    }

    public getSSLPrices() {
        return this.billingRobot.listServicePrices('ssl')
            .then(this.returnResponses)
            .then((prices) => {
                prices = prices.map((price) => {
                    if ([undefined, null].indexOf(price.currency) < 0) {
                        price.currency = price.currency.toUpperCase();
                    }
                    return price;
                });

                return prices;
            });
    }

    public getEmailPrices() {
        return this.billingRobot.listServicePrices('email')
            .then(this.returnResponses)
            .then((prices) => {
                prices = prices.map((price) => {
                    if ([undefined, null].indexOf(price.currency) < 0) {
                        price.currency = price.currency.toUpperCase();
                    }
                    return price;
                });

                return prices;
            });
    }

    public getWebhostingPrices() {
        return this.billingRobot.listServicePrices('webhosting')
            .then(this.returnResponses)
            .then((prices) => {
                prices = prices.map((price) => {
                    if ([undefined, null].indexOf(price.currency) < 0) {
                        price.currency = price.currency.toUpperCase();
                    }
                    return price;
                });

                return prices;
            });
    }

    public getDatabasePrices() {
        return this.billingRobot.listServicePrices('database')
            .then(this.returnResponses)
            .then((prices) => {
                prices = prices.map((price) => {
                    if ([undefined, null].indexOf(price.currency) < 0) {
                        price.currency = price.currency.toUpperCase();
                    }
                    return price;
                });

                return prices;
            });
    }

    public getMachinePrices() {
        return this.billingRobot.listServicePrices('machine')
            .then(this.returnResponses)
            .then((prices) => {
                prices = prices
                    .map((price) => {
                        if ([undefined, null].indexOf(price.currency) < 0) {
                            price.currency = price.currency.toUpperCase();
                        }
                        return price;
                    })
                    .filter((price) => {
                        return !price.productCode.split('-').includes('individual');
                    });

                if ([undefined, null].indexOf(prices) >= 0) {
                    return [];
                }

                return prices;
            });
    }

    public getManagedApplicationPrices() {
        return this.billingRobot.listServicePrices('managedapplication')
            .then(this.returnResponses)
            .then((prices) => {
                prices = prices
                    .map((price) => {
                        if ([undefined, null].indexOf(price.currency) < 0) {
                            price.currency = price.currency.toUpperCase();
                        }
                        return price;
                    });

                if ([undefined, null].indexOf(prices) >= 0) {
                    return [];
                }

                return prices;
            });
    }

    public articlePurchasePriceList(filters, limit, page?, sort?, owner?) {
        page = page || 1;

        return this.billingRobot.articlePurchasePriceList(filters, limit, page, sort, owner);
    }

    public getPriceByProductCode(productCode: string, owner?: string) {
        const filter = { field: 'productCode', value: productCode };
        return this.articlePurchasePriceList(filter, 1, undefined, undefined, owner)
            .then((apiResponse) => apiResponse.response.data)
            .then((prices): BillingApi.ArticlePurchasePrice => {
                const price = prices[0];
                if ([undefined, null].indexOf(price?.currency) < 0) {
                    price.currency = price.currency.toUpperCase();
                }
                return price;
            });
    }

    private returnResponses(result) {
        return result.responses;
    }
}
