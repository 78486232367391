import * as ng from 'angular';

import { VhostModelService } from '@/services';
import * as Sentry from '@sentry/browser';

export class OrganViewPanelJobDetailsController {
    public static $inject: string[] = ['vhostModel'];

    public job: any;
    public createEqualsLastChange: boolean;
    public isStaff: boolean;
    public showErrorDetails = false;
    public productLinkDisplayName: string;
    public productLinkRoute: string;
    public productLinkRouteParams: any;

    constructor(
        private vhostModel: VhostModelService
    ){}

    public async $onInit() {
        // Only compare until minutes (2019-02-21T07:31:21Z - :21Z)
        this.createEqualsLastChange = this.job.lastChangeDate.slice(0, 16) === this.job.addDate.slice(0, 16);

        // Build link to the actual product edit-page.
        this.productLinkDisplayName = this.job.displayName;
        switch (this.job.objectType.toLowerCase()) {
            case 'domain':
                this.productLinkDisplayName = this.job.domainNameUnicode;
                this.productLinkRoute = 'domain.domains.id.edit';
                this.productLinkRouteParams = { domainId: this.job.objectId };
                break;
            case 'zone':
                this.productLinkRoute = 'dns.zones.id.edit';
                this.productLinkRouteParams = { zoneId: this.job.objectId };
            break;
            case 'imapmailbox':
            case 'catchall':
            case 'mailinglist':
            case 'smtpforwarder':
            case 'externalforwarder':
            case 'exchangemailbox':
                this.productLinkDisplayName = this.job.emailAddress;
                this.productLinkRoute = 'email.mailboxes.id.edit';
                this.productLinkRouteParams = { mailboxId: this.job.objectId };
            break;
            case 'vhost':
                // Need vhost for the corresponding webspace-id.
                const vHost = await this.vhostModel.findOne(this.job.objectId);
                this.productLinkRoute = 'webhosting.webspaces.id.vhosts.id.edit';
                this.productLinkRouteParams = { vHostId: this.job.objectId, webspaceId: vHost.webspaceId };
            break;
            case 'user':
                this.productLinkRoute = 'webhosting.users.id.edit';
                this.productLinkRouteParams = { userId: this.job.objectId };
            break;
            case 'webspace':
                this.productLinkRoute = 'webhosting.webspaces.id.edit';
                this.productLinkRouteParams = { webspaceId: this.job.objectId };
            break;
            case 'database':
                this.productLinkRoute = 'database.databases.id.edit';
                this.productLinkRouteParams = { databaseId: this.job.objectId };
            break;
            case 'virtualmachine':
                this.productLinkRoute = 'machine.virtualmachines.id.edit';
                this.productLinkRouteParams = { machineId: this.job.objectId };
            break;
            case 'nextcloud':
                this.productLinkRoute = 'nextcloud.id.edit';
                this.productLinkRouteParams = { storageProductId: this.job.objectId };
            break;
            case 'certificate':
                this.productLinkRoute = 'ssl.certificates.id.edit';
                this.productLinkRouteParams = { certificateId: this.job.objectId };
            break;
            default:
                Sentry.captureMessage(
                    'Unknown object-type returned in admin-search!',
                    { extra: { objectType: this.job.objectType }}
                );
                break;
        }
    }
}

export class OrganViewPanelJobDetailsComponent implements ng.IComponentOptions {
    public bindings = {
        job: '<'
    };
    public controller = OrganViewPanelJobDetailsController;
    public template = require('./view-panel-job-details-organs.html');
}
