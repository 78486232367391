import * as ng from 'angular';
import { DomainInfoHelperService, MailboxModelService, ValidateEmail } from '../../../../../services';

import './email-address-list.scss';

export interface EmailForwarderItem {
    forwarderAddress: string;
    tmpStatus: 'registered' | '';
    newStatus: '' | 'delete' | 'new' | 'registered';
    type?: 'internal' | 'external';
}

export class MoleculePanelEditRowEmailAddressListController implements ng.IController {
    public static $inject: string[] = ['$translate', 'domainInfoHelper', 'mailboxModel'];

    public addresses: string[];
    public newForwarderValue = '';
    public forwarderList: EmailForwarderItem[] = [];
    public errorMessage = '';
    public validationInstructions: any[];
    public placeholder: string;
    public max: number;
    public isEmailForwardTarget = false;
    public allowTextArea = false;
    public forwarderType: string;
    public editMode = false;
    public showRequired: boolean;
    public emailAddressErrorList: string[] = [];
    public combinedErrorList: string[] = [];

    private platformMailValidation: boolean;
    private onChangeList: (addresses: string[]) => {};

    constructor(
        protected $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        private mailboxModel: MailboxModelService
    ) {}

    public $onInit() {
        if ([undefined].indexOf(this.platformMailValidation) >= 0) {
            this.platformMailValidation = false;
        }

        if ([undefined, null, ''].indexOf(this.placeholder) >= 0) {
            this.placeholder = this.$translate.instant('TR_110119-594d9a_TR');
        }

        // Add the forwarders and check if they are internal.
        this.addAllAndCheckInternal();

        this.validationInstructions = [{
            instructions: null,
            validator: new ValidateEmail(
                true,
                this.$translate,
                this.domainInfoHelper,
                this.platformMailValidation
            )
        }];
    }

    public textInputValueChanged = (newValue) => {
        if (newValue === '') {
            this.errorMessage = '';
        }
        this._updateCombinedErrorList();
    };

    public addAllAndCheckInternal = async () => {
        const mailboxFilter = {
            subFilter: this.addresses.map(
                (address) => ({
                    field: 'mailboxEmailAddressUnicode',
                    value: address
                })
            ),
            subFilterConnective: 'OR'
        };

        const response = await this.mailboxModel.listWithoutPagination(null, 1, mailboxFilter);

        if (!response) {
            return;
        }

        // Checking for internal/external.
        this.addresses.forEach((target) => {
            const foundInternalMailbox = response.data.find(
                (mailbox) => mailbox.emailAddressUnicode === target
            );

            const newEmailForwarderItem: EmailForwarderItem = {
                forwarderAddress: target,
                newStatus: 'new',
                tmpStatus: '',
                type: foundInternalMailbox !== undefined
                    ? 'internal'
                    : 'external'
            };

            this.forwarderList.unshift(newEmailForwarderItem);
            this.newForwarderValue = '';
            this._checkTargetError();
        });

        // Sort forwarder alphabetically.
        this.forwarderList = this.forwarderList.sort(
            (a, b) => (a.forwarderAddress > b.forwarderAddress) ? 1 : -1
        );
    };

    public addToList = (newForwarder: { value: string }, onlyCheck?: boolean) => {
        this.errorMessage = '';
        onlyCheck = onlyCheck !== undefined ? onlyCheck : false;

        if ([undefined, ''].indexOf(newForwarder.value) >= 0) {
            // forwarder is empty
            this.errorMessage = this.$translate.instant('TR_110119-b5a951_TR');
        } else if (this.forwarderExistsInList(newForwarder.value)) {
            // forwarder already in list
            this.errorMessage = this.$translate.instant('TR_110119-02ca40_TR');
        }

        this._updateCombinedErrorList();

        if (this.errorMessage !== '') {
            return;
        }

        this.mailboxModel.list(1, 1, { field: 'mailboxEmailAddressUnicode', value: newForwarder.value}).then(
            (res: any) => {
                const newEmailForwarderItem: EmailForwarderItem = {
                    forwarderAddress: newForwarder.value,
                    newStatus: 'new',
                    tmpStatus: '',
                    type: res.data.length > 0
                        ? 'internal'
                        : 'external'
                };

                this.forwarderList.unshift(newEmailForwarderItem);
                this.newForwarderValue = '';
                if (!onlyCheck) {
                    this.addresses.push(newForwarder.value);
                    this._checkTargetError();
                }
            }
        );
    };

    public buttonItemModifier(item: EmailForwarderItem) {
        return item.newStatus === 'delete' ? 'active' : 'inactive';
    }

    public toggleStatus(item: EmailForwarderItem) {
        if (item.newStatus === 'delete') {
            item.newStatus = item.tmpStatus === 'registered' ? item.tmpStatus : 'new';
            this.addresses.push(item.forwarderAddress);
        } else {
            item.newStatus = 'delete';
            const index = this.addresses.indexOf(item.forwarderAddress);
            this.addresses.splice(index, 1);
        }
        this._checkTargetError();
    }

    public get disableAddButton() {
        if (this.max === undefined) {
            return false;
        }

        const toAdd = this.newForwarderValue.split('\n')
        .map((address) => address.trim())
        .filter((address) => [undefined, null, ''].indexOf(address) < 0);

        return this.addresses.length + toAdd.length > this.max;
    }
    public get targetsAllInternal() {
        const allNewTargets = this.forwarderList.filter(
            (forwarder: EmailForwarderItem) => forwarder.newStatus === 'new'
        );
        const allInternalTargets = allNewTargets.filter(
            (target: EmailForwarderItem) => target.type === 'internal'
        );

        return  allInternalTargets.length > 0
            && allNewTargets.length === allInternalTargets.length;
    }

    public set targetsAllInternal({}) {} // tslint:disable-line:no-empty

    private forwarderExistsInList = (forwarder: string) => {
        return this.forwarderList.some((forwarderInList: EmailForwarderItem) => {
            return forwarderInList.forwarderAddress === forwarder;
        });
    };

    public get showQuotaMessage() {
        return this.max !== undefined;
    }

    public get quotaMessage() {
        return this.addresses.length + '/' + this.max;
    }

    private _checkTargetError = () => {
        if (this.editMode && this.isEmailForwardTarget) {
            if (this.addresses.length === 0) {
                this.errorMessage = this.forwarderType === 'internalForwarder'
                    ? this.$translate.instant('TR_220819-b7a6da_TR')
                    : this.$translate.instant('TR_270819-2772ba_TR');
            } else if (this.forwarderType === 'internalForwarder' && !this.targetsAllInternal) {
                this.errorMessage = this.$translate.instant('TR_220819-61c2c4_TR');
            } else {
                this.errorMessage = '';
            }
        }
        this._updateCombinedErrorList();
    };

    private _updateCombinedErrorList = () => {
        const errorList = ng.copy(this.emailAddressErrorList);

        if (this.errorMessage !== '') {
            errorList.push(this.errorMessage);
        }

        this.combinedErrorList = errorList;
    };
}

export class MoleculePanelEditRowEmailAddressListComponent implements ng.IComponentOptions {
    public bindings = {
        addresses: '=',
        allowTextArea: '<?',
        editMode: '<?',
        combinedErrorList: '=?',
        errorMessage: '=?',
        forwarderType: '<?',
        isEmailForwardTarget: '<?',
        max: '<',
        newForwarderValue: '=?',
        placeholder: '@?',
        platformMailValidation: '<?',
        showRequired: '<?',
        targetsAllInternal: '=?'
    };

    public transclude = {
        label: '?rowLabel'
    };

    /* tslint:disable-next-line:max-line-length */
    public template = require('./email-address-list.html');
    public controller = MoleculePanelEditRowEmailAddressListController;
}
