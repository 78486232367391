import { RpcClient } from '../rpc-client';

import * as ng from 'angular';

export class AppInstallerRobotService {
    public static $inject: string[] = ['webhostingClient'];

    constructor(private webhostingClient: RpcClient) {}

    public listAppInstallers(vhostId) {
        return this.webhostingClient.request('appInstallerList')
        .execute({vhostId: vhostId});
    }

    public installApp(vhostId, appId, data, owner?) {
        const settings = ng.copy(data.appSettings);
        settings.database = data.database;

        return this.webhostingClient.request('appInstallerInstall')
        .ownerAccountId(owner)
        .execute({vhostId: vhostId, appId: appId, json: JSON.stringify(settings)});
    }
}

export class OsInstallerRobotService {
    public static $inject: string[] = ['machineClient'];

    constructor(private machineClient: RpcClient) {}

    public listOsInstallers(virtualMachineId, productCode) {
        return this.machineClient.request('osInstallerList')
        .execute({ virtualMachineId: virtualMachineId, productCode: productCode });
    }

    public installOs(virtualMachineId, osId, data, owner?) {
        const dataString = JSON.stringify(data);

        if ([undefined, null, '', '[]', '{}'].indexOf(dataString) >= 0) {
            return this.machineClient.request('virtualMachineInstall')
            .ownerAccountId(owner)
            .execute({ virtualMachineId: virtualMachineId, osId: osId, json: undefined });
        } else {
            return this.machineClient.request('virtualMachineInstall')
            .ownerAccountId(owner)
            .execute({ virtualMachineId: virtualMachineId, osId: osId, json: JSON.stringify(data) });
        }
    }

    public installVm(virtualMachine, poolId, virtualMachineHostId, osId, data, owner?) {
        if ([undefined, null, 0, ''].indexOf(virtualMachineHostId) < 0) {
            poolId = undefined;
        }

        let dataToSend;

        if ([undefined, null].indexOf(data) < 0) {
            dataToSend = JSON.parse(JSON.stringify(data));

            ['poolId', 'poolIdForCreatedResources', 'virtualMachineHostId']
            .forEach(
                (key) => {
                    if (dataToSend[key] !== undefined) {
                        delete(dataToSend[key]);
                    }
                }
            );
        }

        virtualMachine.accountId = [undefined, null, ''].indexOf(owner) < 0 ? owner : undefined;

        if ([undefined, null, ''].indexOf(osId) >= 0) {
            return this.machineClient.request('virtualMachineCreate')
            .execute(
                {
                    poolId: [undefined, null, ''].indexOf(poolId) < 0 ? poolId : undefined,
                    virtualMachine: virtualMachine,
                    virtualMachineHostId: [undefined, null, ''].indexOf(virtualMachineHostId) < 0
                    ? virtualMachineHostId
                    : undefined
                }
            );
        } else {
            return this.machineClient.request('virtualMachineCreate')
            .execute(
                {
                    json: JSON.stringify(dataToSend),
                    osId: osId,
                    poolId: [undefined, null, ''].indexOf(poolId) < 0 ? poolId : undefined,
                    virtualMachine: virtualMachine,
                    virtualMachineHostId: [undefined, null, ''].indexOf(virtualMachineHostId) < 0
                    ? virtualMachineHostId
                    : undefined
                }
            );
        }
    }

    public managedServerCreate(virtualMachine, poolIdForCreatedResources, poolId, virtualMachineHostId, owner?) {
        if ([undefined, null, 0, ''].indexOf(virtualMachineHostId) < 0) {
            poolId = undefined;
        }

        virtualMachine.accountId = [undefined, null, ''].indexOf(owner) < 0 ? owner : undefined;

        return this.machineClient.request('managedServerCreate')
        .execute(
            {
                poolId: [undefined, null, ''].indexOf(poolId) < 0 ? poolId : undefined,
                poolIdForCreatedResources: [undefined, null, ''].indexOf(poolIdForCreatedResources) < 0
                ? poolIdForCreatedResources
                : undefined,

                virtualMachine: virtualMachine,
                virtualMachineHostId: [undefined, null, ''].indexOf(virtualMachineHostId) < 0
                ? virtualMachineHostId
                : undefined
            }
        );
    }
}
