import * as ng from 'angular';
import { PanelHeaderData } from '@/atomic-components/molecules/panels/panel-header/panel-header';

export interface PricelistOverviewOptions {
    panelTitleTranslationId: string;
    simpleFilterField: string;
}

export abstract class TemplateBillingPricelistAbstractOverviewController {
    public static $inject: string[] = ['$translate'];

    public availableFilters: any[] = [];
    public pageHeaderData: PanelHeaderData;
    public overviewServiceData: { availableFilters: any[]; extendedFilters: any; fancyFilterExpand: boolean; overviewUiSettings: any; simpleFilter: { name: string; value: string; }; };
    public expertView: boolean;
    public priceList: any;

    protected abstract options: PricelistOverviewOptions;

    private extendedFilters: any;
    private overviewUiSettings: any;

    constructor(private $translate: ng.translate.ITranslateService) {}

    $onInit() {
        this.expertView = true;

        this.pageHeaderData = {
            backwardLink: 'billing.pricelists',
            backwardText: this.$translate.instant('TR_090119-ebc8d5_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: null,
            panelIcon: 'funnel-dollar',
            panelTitle: this.$translate.instant(this.options.panelTitleTranslationId)
        };
        this.overviewServiceData = {
            availableFilters: this.availableFilters,
            extendedFilters: this.extendedFilters,
            fancyFilterExpand: this.expertView,
            overviewUiSettings: this.overviewUiSettings,
            simpleFilter: {name: this.options.simpleFilterField, value: ''}
        };
    }
}

export abstract class TemplateBillingPricelistAbstractOverviewComponent implements ng.IComponentOptions {
    public bindings = {
        extendedFilters: '<',
        overviewUiSettings: '<',
        simpleFilter: '<'
    };
    public abstract controller: any;
    public controllerAs = 'TemplateBillingPricelistOverviewCtrl';
    public abstract template?: string;

}
