import * as ng from 'angular';
import * as q from 'q';
import { VhostDefaultObjectConst, VhostPhpDefaultVersionConst } from '../../configuration/webhosting/vhost';
import * as Types from '../../types';
import { WebhostingApi } from '../../types';
import { ModelHelper } from '../model-helper';
import { RequestStatus } from '../rpc-client';
import { SslRobotService } from '../ssl/';
import { WebhostingRobotService } from './robot';

export class VhostModelService {
    public static $inject: string[] = ['sslRobot', 'webhostingRobot', '$rootScope'];

    constructor(
        private sslRobot: SslRobotService,
        private webhostingRobot: WebhostingRobotService,
        private $rootScope: ng.IRootScopeService
    ) {}

    public list = (limit?, page?, filters?, sort?, webspaceId?) => {
        sort = sort || {
            field: 'vhostDomainNameUnicode',
            order: 'ASC'
        };
        page = page || 1;

        if (typeof webspaceId === 'string') {
            const filter = {field: 'webspaceId', value: webspaceId};

            if ([undefined, null].indexOf(filters) >= 0) {
                filters = filter;
            } else {
                filters = {
                    subFilter: [filters, filter],
                    subFilterConnective: 'AND'
                };
            }
        }

        return this.webhostingRobot.listVHosts(filters, limit, page, sort)
        .then(value => ModelHelper.returnListResponse<WebhostingApi.VHost>(value));
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?, webspaceId?) => {
        sort = sort || {
            field: 'vhostDomainNameUnicode',
            order: 'ASC'
        };
        page = page || 1;

        if (typeof webspaceId === 'string') {
            const filter = {field: 'webspaceId', value: webspaceId};
            if (filters) {
                filters = {
                    subFilter: [filters, filter],
                    subFilterConnective: 'AND'
                };
            } else {
                filters = filter;
            }
        }
        return this.webhostingRobot.listVHostsWithoutPagination(filters, limit, page, sort)
        .then(value => ModelHelper.returnListResponse<WebhostingApi.VHost>(value));
    };

    public findOne = (id) => {
        return this.webhostingRobot.listVHostsWithoutPagination({field: 'vhostId', value: id}, 1, 1)
        .then(ModelHelper.returnFindOneResponse);
    };

    public findById = (ids) => {
        const filter = {subFilterConnective: 'OR', subFilter: ids.map(this.idFilter)};
        return this.webhostingRobot.listVHostsWithoutPagination(filter, 0, 1)
        .then(ModelHelper.returnFindResponse);
    };

    public findByNames = (names: string[]) => {
        const filter: Types.Finding.Filter = { subFilterConnective: 'OR', subFilter: names.map(this.nameFilter) };

        return this.webhostingRobot.listVHostsWithoutPagination(filter, 0, 1)
        .then(this.returnResponseData);
    };

    public create = (vhost, phpIni?, sslPrivateKey?, httpUsers?, owner?) => {
        httpUsers = httpUsers || null;
        return this.webhostingRobot.createVHost(vhost, phpIni, sslPrivateKey, httpUsers, owner)
        .then(this.returnResponse)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public update = (vhost, phpIni?, sslPrivateKey?, httpUsers?, expectedCosts?) => {
        httpUsers = httpUsers || null;
        return this.webhostingRobot.updateVHost(vhost, phpIni, sslPrivateKey, httpUsers, expectedCosts)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public delete = (vhost) => {
        return this.webhostingRobot.deleteVHost(vhost.id)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public restoreOne = (vhost) => {
        return this.webhostingRobot.restoreVHost(vhost.id)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public restore = (vhosts) => {
        const promises = vhosts.map(this.restoreOne);
        return q.all(promises)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public purgeRestorable = (vhostId: string) => {
        return this.webhostingRobot.purgeRestorableVHost(vhostId)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public vhostPhpIniDefault = (webspaceId, phpVersion): q.IPromise<any> => {
        return this.webhostingRobot.phpIniDefaultVHost(webspaceId, phpVersion)
        .then(this.returnResponse);
    };

    public vhostPhpIniList = (vhostId) => {
        return this.webhostingRobot.phpIniListVHost(vhostId)
        .then(this.returnResponse);
    };

    public phpConfigurationMetadata = () => {
        return this.webhostingRobot.phpConfigurationMetadata()
        .then(this.returnResponses);
    };

    public listProfiles = (webspaceId, productCode?) => {
        return this.webhostingRobot.listProfiles(webspaceId, productCode)
        .then(this.returnResponses);
    };

    public checkAutoValidationCapable = (
        names: string[],
        ownerAccountId: string,
        productCode: string
    ) => {
        return this.sslRobot.checkAutoValidationCapable(
            names,
            ownerAccountId,
            productCode
        );
    };

    public availablePhpVersions = (): q.IPromise<Types.WebhostingApi.PhpVersion[]> => {
        return this.webhostingRobot.availablePhpVersions().then(this.returnResponses);
    };

    public defaultPhpVersion = async (): Promise<string> => {
        try {
            const availablePhpVersions = await this.availablePhpVersions();

            for (const version of availablePhpVersions) {
                if (version.isDefault) {
                    return version.version;
                }
            }
        }
        // eslint-disable-next-line no-empty
        catch (error){}

        // Fallback because I'm not sure what to do in case there is no default
        return VhostPhpDefaultVersionConst;
    };

    public defaultVhostObject = async (): Promise<Types.WebhostingApi.VHost> => {
        const vHost: Types.WebhostingApi.VHost = JSON.parse(JSON.stringify(VhostDefaultObjectConst));

        vHost.phpVersion = await this.defaultPhpVersion();

        return vHost;
    };

    public vhostNormalize = (
        vhost: Types.WebhostingApi.VHost,
        phpIni?: Types.WebhostingApi.PhpIni,
        changePhpVersion?: string
    ) => {
        return this.webhostingRobot.vhostNormalize(vhost, phpIni, changePhpVersion);
    };

    private refresh = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.$rootScope.$broadcast('webhosting.vhost.refresh');
        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return q.reject(result);
        }
    };

    private idFilter = (id) => {
        return {field: 'vhostId', value: id};
    };

    private returnResponse = (result) => {
        return result.response;
    };

    private returnResponses = (result) => {
        return result.responses;
    };

    private returnResponseData = (result) => {
        return result.response.data;
    };

    private nameFilter: (name: string) => Types.Finding.Filter = (name) => {
        return {field: 'VHostDomainNameUnicode', value: name};
    };
}
