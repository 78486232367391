/* eslint-disable no-shadow */
import * as ng from 'angular';
import { AlertManagerService, ApiErrorModel, NavigationService } from '@/services';

type Validator = () => true|unknown;

export interface EditFormMessage<T> {
    type: string;
    message: T;
}

export class EditFormSavingStatusUpdate implements EditFormMessage<EditFormSavingStatus> {
    public type = 'statusUpdate';

    constructor(private _message: EditFormSavingStatus) { }

    public get message(): EditFormSavingStatus {
        return this._message;
    }
}

export enum EditFormStatus {
    EDITABLE,
    ERROR,
    READONLY,
    SAVING
}

export enum EditFormSavingStatus {
    READY,
    SAVING,
    ERROR
}

export enum EditPanelStatus {
    ACTIVE,
    ERROR,
    READONLY,
    DELETIONSCHEDULED,
    CREATE
}

export enum PanelType {
    COST_EFFECTIVE,
    COST_NEUTRAL,
    DATABASE_WIPE,
    DELETE,
    RESTORE,
    USER_DATA,
    USER_LOGIN,
    USER_PASSWORD,
    USER_TWO_FA,
    MISCELLANEOUS_ACTION
}

export interface EditPanel {
    type: PanelType;
    status: EditPanelStatus;
    name: string;
    onMessage?: (message: EditFormMessage<unknown>) => void;
}

export class MoleculeFormEditController implements ng.IController {
    /* tslint:enable:no-empty */

    public get $status(): EditFormStatus  {
        return this.status;
    }

    public set $status(setStatus) {
        this.status = setStatus;
    }

    public get $valid(): boolean {
        return this.validatorStatus.every(this.isTrue);
    }

    public get $invalid(): boolean {
        return !this.$valid;
    }

    public get panelCopies(): { [name: string]: EditPanel } {
        if (this.externalPanelCopiesBinding) {
            return undefined;
        }

        const key = Object.keys(this.panels)
            .filter((panelName) => [undefined, null].indexOf(this.panels[panelName]) < 0)
            .map((panelName) => `${panelName}:${this.panels[panelName].type}:${this.panels[panelName].status}`)
            .join('|');

        if ([undefined, null].indexOf(this.panelCopiesCache[key]) < 0) {
            return this.panelCopiesCache[key];
        }

        const copies: { [name: string]: EditPanel } = {};

        if ([undefined, null].indexOf(this.panels) < 0) {
            Object.keys(this.panels)
                .filter((panelName) => [undefined, null].indexOf(this.panels[panelName]) < 0)
                .forEach(
                    (panelName) => copies[panelName] = {
                        status: this.panels[panelName].status,
                        type: this.panels[panelName].type
                    }
                );
        }

        this.panelCopiesCache[key] = copies;

        return copies;
    }

    public set panelCopies(value) {
        this.externalPanelCopiesBinding = value;
    }

    public static $inject: string[] = ['$translate', 'apiErrorModel', 'navigation', '$window', 'alertManager'];

    public validators: { [key: string]: Validator }[] = [];
    public validatorStatus: boolean[] = [];
    public formName: string;
    public processApiCallbackSent = false;
    private panels: { [name: string]: EditPanel } = {};
    private status: EditFormStatus = EditFormStatus.EDITABLE;
    private panelCopiesCache: { [hash: string]: { [name: string]: EditPanel } } = {};
    private externalPanelCopiesBinding: { [name: string]: EditPanel };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private apiErrorModel: ApiErrorModel,
        private navigation: NavigationService,
        private $window: ng.IWindowService,
        private alertManager: AlertManagerService
    ) {
    }

    public unsavedChangesExist: () => boolean = () => false;

    public $onInit(): void {
        /* eslint-disable max-len */
        // safe to call addEventListener multiple times see link
        // tslint:disable-next-line: max-line-length
        // https://developer.mozilla.org/en-US/docs/Web/API/EventTarget/addEventListener#Multiple_identical_event_listeners
        // remove event in onDestroy otherwise the event still triggers
        // after form-edit is removed from the DOM
        this.$window.addEventListener('beforeunload', this.leaveFormPopUp, true);
        /* eslint-enable max-len */
    }

    /* tslint:disable:no-empty */
    public deleteCallback: () => Promise<void> = () => undefined;
    public restoreCallback: () => Promise<void> = () => undefined;
    public reissueCallback: () => Promise<void> = () => undefined;
    public revokeCallback: () => Promise<void> = () => undefined;
    public saveCallback: () => Promise<void> = () => undefined;
    public cancelDeletionCallback: () => Promise<void> = () => undefined;
    public accountChangeCallback: () => Promise<void> = () => undefined;
    public toggleStatusCallback: () => Promise<void> = () => undefined;
    public cancelCallback: () => Promise<void> = () => undefined;

    public resetValidations = (): void => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        this.validatorStatus.forEach(status => status = true);
    };

    public validate = (validatorIndex: number): void => {
        if ([undefined, null].indexOf(this.validators) === -1
        && [undefined, null].indexOf(this.validators[validatorIndex]) === -1
        ) {
            this.validatorStatus[validatorIndex] = this.validators[validatorIndex].validate() as boolean;
        }
    };

    public validateAll = (): boolean => {
        this.validators.forEach((validator, i) => {
            if ([undefined, null].indexOf(validator) === -1
                && [undefined, null].indexOf(validator.validate) === -1
            ) {
                this.validatorStatus[i] = validator.validate() as boolean;
            } else {
                this.validatorStatus[i] = true;
            }
        });

        return this.validatorStatus.every(this.isTrue);
    };

    public panel: (panelName: string) => EditPanel
        = (panelName: string) => this.panels[panelName];

    public canEnablePanel(panelName: string): boolean {
        const allPanels = Object.keys(this.panels).map((panelKey: string) => this.panels[panelKey]);

        if (this.panels[panelName].type === PanelType.COST_EFFECTIVE) {
            const anyOfOtherPanelsIsActive = allPanels.some((panel: EditPanel) => {
                if (panel.name === panelName) {
                   return false;
                }

                return panel.status === EditPanelStatus.ACTIVE;
            });

            return !anyOfOtherPanelsIsActive;
        }
        return Object.keys(this.panels)
            .map((panelKey: string) => this.panels[panelKey])
            .every(
                (otherPanel: EditPanel) => {
                    switch (this.panels[panelName].type) {
                        case PanelType.MISCELLANEOUS_ACTION:
                            // return false even if the other panel's type is MISCELLANEOUS_ACTION,
                            // too, since they might be different actions.
                            return otherPanel.status !== EditPanelStatus.ACTIVE;
                        default:
                            return (this.panels[panelName].type === otherPanel.type)
                                || (otherPanel.status !== EditPanelStatus.ACTIVE);
                    }
                }
            );
    }

    public register = (panel: any): void => {
        this.panels[panel.name as string] = panel;
    };

    public updateRegisteredPanel = (panel: any): void => {
        this.panels[panel.name as string] = panel;
    };

    public unregister = (panel: any): void => {
        this.panels[panel.name] = undefined;
        delete (this.panels[panel.name]);
    };

    public registerValidator = (validator: { [key: string]: Validator }): number => {
        this.validators.push(validator);
        this.validatorStatus.push(true);
        return this.validators.length - 1;
    };

    public unregisterValidator = (validatorIndex: number): void => {
        this.validators[validatorIndex] = null;
        this.validatorStatus[validatorIndex] = true;
    };

    public accountChanged: () => void = () => {
        if (this.accountChangeCallback) {
            void this.accountChangeCallback();
        }
    };

    /* eslint-disable max-len */
    public save: () => Promise<void> = (keepErrors?: boolean) => this.processApiCallback(this.saveCallback, keepErrors);

    public delete: () => Promise<void> = (keepErrors?: boolean) => this.processApiCallback(this.deleteCallback, keepErrors);

    public restore: () => Promise<void> = (keepErrors?: boolean) => this.processApiCallback(this.restoreCallback, keepErrors);

    public revoke: () => Promise<void> = (keepErrors?: boolean) => this.processApiCallback(this.revokeCallback, keepErrors);

    public reissue: () => Promise<void> = (keepErrors?: boolean) => this.processApiCallback(this.reissueCallback, keepErrors);

    public cancelDeletion: () => Promise<void> = (keepErrors?: boolean) => this.processApiCallback(this.cancelDeletionCallback, keepErrors);

    public toggleStatus: () => Promise<void> = (keepErrors?: boolean) => this.processApiCallback(this.toggleStatusCallback, keepErrors);
    /* eslint-enable max-len */

    // keepErrors can be used to prevent the form from deleting any errors currently displayed
    public processApiCallback = (apiCallback: () => Promise<void>, keepErrors?: boolean): Promise<void> => {
        if (!this.validateAll()) {
            return;
        }

        try {
            if (!keepErrors) {
                this.apiErrorModel.destroyErrorList();
            }
            this.processApiCallbackSent = true;
            const result = apiCallback();
            if (result !== undefined && result.then) {
                this.notify(new EditFormSavingStatusUpdate(EditFormSavingStatus.SAVING));
                this.status = EditFormStatus.SAVING;
                result.then(
                    () => {
                        this.notify(new EditFormSavingStatusUpdate(EditFormSavingStatus.READY));
                        this.status = EditFormStatus.EDITABLE;
                        this.navigation.reloadCurrentState();
                    },
                    () => {
                        this.notify(new EditFormSavingStatusUpdate(EditFormSavingStatus.ERROR));
                        this.status = EditFormStatus.EDITABLE;
                    }
                );
            } else {
                this.notify(new EditFormSavingStatusUpdate(EditFormSavingStatus.READY));

                this.status = EditFormStatus.EDITABLE;
            }
        } catch (error) {
            this.notify(new EditFormSavingStatusUpdate(EditFormSavingStatus.ERROR));
            this.processApiCallbackSent = false;
            this.status = EditFormStatus.EDITABLE;
        }
    };

    public $onDestroy(): void {
        this.$window.removeEventListener('beforeunload', this.leaveFormPopUp, true);
    }

    public leaveFormPopUp = (ev: BeforeUnloadEvent): void | string => {
        const userAgent = this.$window.navigator.userAgent.toLowerCase();
        const alertMessage = this.$translate.instant('TR_301019-8f54c9_TR');
        if (this.unsavedChangesExist()) {
            ev.preventDefault();
            this.alertManager.warning(alertMessage);

            if (userAgent.indexOf('firefox') > -1) {
                ev.preventDefault();
                // "Mozilla/5.0 (X11; Ubuntu; Linux x86_64; rv:61.0) Gecko/20100101 Firefox/61.0"
            } else if (userAgent.indexOf('vivaldi') > -1) {
                this.$window.event.returnValue = false;
                // mozilla/5.0 (x11; linux x86_64) applewebkit/537.36 (khtml, like gecko)
                // chrome/77.0.3865.93 safari/537.36 vivaldi/2.8.1664.40
            } else if (userAgent.indexOf('samsungbrowser') > -1) {
                ev.preventDefault();
                // "Mozilla/5.0 (Linux; Android 9; SAMSUNG SM-G955F Build/PPR1.180610.011) AppleWebKit/537.36
                // (KHTML, like Gecko) SamsungBrowser/9.4 Chrome/67.0.3396.87 Mobile Safari/537.36
            } else if (userAgent.indexOf('opera') > -1 || userAgent.indexOf('opr') > -1) {
                return alertMessage;
                // "Mozilla/5.0 (Macintosh; Intel Mac OS X 10_14_0) AppleWebKit/537.36
                // (KHTML, like Gecko) Chrome/70.0.3538.102 Safari/537.36 OPR/57.0.3098.106"
            } else if (userAgent.indexOf('trident') > -1) {
                ev.returnValue = alertMessage;
                // "Mozilla/5.0 (Windows NT 10.0; WOW64; Trident/7.0; .NET4.0C;
                // .NET4.0E; Zoom 3.6.0; wbx 1.0.0; rv:11.0) like Gecko"
            } else if (userAgent.indexOf('edge') > -1) {
                return alertMessage;
                // "Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36
                // (KHTML, like Gecko) Chrome/58.0.3029.110 Safari/537.36 Edge/16.16299"
            } else if (userAgent.indexOf('chrome') > -1) {
                ev.returnValue = alertMessage;
                // "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko)
                // Ubuntu Chromium/66.0.3359.181 Chrome/66.0.3359.181 Safari/537.36"
            } else if (userAgent.indexOf('safari') > -1) {
                ev.preventDefault();
                // "Mozilla/5.0 (iPhone; CPU iPhone OS 11_4 like Mac OS X) AppleWebKit/605.1.15
                // (KHTML, like Gecko) Version/11.0 Mobile/15E148 Safari/604.1 980x1306"
            } else {
                ev.preventDefault();
            }
        }
    };

    private isTrue = (item: boolean): boolean => {
        return item;
    };

    private notify = (data: EditFormMessage<unknown>): void => {
        Object.keys(this.panels).map((name) => {
            if (this.panels[name].onMessage !== undefined) {
                this.panels[name].onMessage(data);
            }
        });
    };
}

export class MoleculeFormEditComponent implements ng.IComponentOptions {
    public bindings = {
        accountChangeCallback: '<accountChange',
        cancelDeletionCallback: '<cancelDeletion',
        deleteCallback: '<delete',
        formName: '@?',
        panelCopies: '=?panels',
        reissueCallback: '<reissue',
        revokeCallback: '<revoke',
        saveCallback: '<save',
        toggleStatusCallback: '<toggleStatus',
        unsavedChangesExist: '<?'
    };
    public transclude = true;
    public controller = MoleculeFormEditController;
    public controllerAs = '$editForm';
    public template = require('./form-edit.html');
}
