import * as ng from 'angular';
import * as q from 'q';
import * as Types from '../../../../../types';
import { UiRights } from './../../../../../configuration/rights';
import { UiLanguagesConst } from './../../../../../configuration/system';
import {
    AuthContextService,
    BundleModelService,
    MachineModelService,
    ProductsModelService,
    ResourceModelService,
    WebspaceModelService
} from './../../../../../services';

export enum ContingentType {
    bundle = 'Bundle',
    databaseServer = 'DatabaseServer',
    pool = 'Pool',
    standalone = 'standalone',
    webserver = 'Webserver',
    webspace = 'Webspace'
}

export enum ContingentUsage {
    database = 'database',
    domain = 'domain',
    email_forwarder = 'email_forwarder',
    mailbox_exchange = 'mailbox_exchange',
    mailbox_imap = 'mailbox_imap',
    managed_server = 'managed_server',
    vhost = 'vhost',
    webspace = 'webspace',
    chargeable = '---STANDALONE---'
}

export class Contingent {
    public readonly id: string;
    public misc: any;
    public readonly name: string;
    public readonly type: ContingentType;
    public usableFor: ContingentUsage[] = [];
    public usableContingents: Contingent[];

    public static filterByUsability(usage: ContingentUsage): (contingent: Contingent) => boolean {
        return (contingent) => contingent.isUsableFor(usage);
    }

    public static filterByType(type: ContingentType): (contingent: Contingent) => boolean {
        return (contingent) => contingent.type === type;
    }

    public static filterForProducts(
        products: Types.ProductApi.Product[] | Types.ProductApi.TemplateProduct[]
    ): (contingent: Contingent) => boolean {
        return (contingent) => {
            switch (contingent.type) {
                default: return true;

                case ContingentType.bundle:
                    return (contingent.misc.bundle as Types.BundleApi.Bundle).effectiveContingentUsage
                    .some(
                        (bundleContingentUsage) => {
                            return products.some((product) => {
                                if ((product as Types.ProductApi.Product).productCode) {
                                    return bundleContingentUsage.productCodes.indexOf(
                                        (product as Types.ProductApi.Product).productCode
                                    ) >= 0;
                                }

                                return (product as Types.ProductApi.TemplateProduct).billingCycles
                                .map(
                                    (billingCycle) => (product as Types.ProductApi.TemplateProduct).productCodeTemplate
                                    .replace(
                                        '##BILLING_CYCLE##',
                                        `${billingCycle}`
                                    )
                                )
                                .some((productCode) => bundleContingentUsage.productCodes.indexOf(productCode) >= 0);
                            });
                        }
                    );
            }
        };
    }

    constructor(id: string, name: string, type: ContingentType, usableContingents?: any[]) {
        this.id = id;
        this.name = name;
        this.type = type;
        this.usableContingents = usableContingents || [];

        switch (type) {
            default:
            case ContingentType.standalone:
                this.usableFor = [
                    ContingentUsage.database,
                    ContingentUsage.domain,
                    ContingentUsage.mailbox_exchange,
                    ContingentUsage.mailbox_imap,
                    ContingentUsage.managed_server,
                    ContingentUsage.webspace
                ];
                return;

            case ContingentType.bundle: return; // Contingent usbility depends on bundle contingents

            case ContingentType.databaseServer:
                this.usableFor = [ContingentUsage.database];
                return;

            case ContingentType.pool:
                this.usableFor = [
                    ContingentUsage.email_forwarder,
                    ContingentUsage.mailbox_exchange,
                    ContingentUsage.mailbox_imap,
                    ContingentUsage.managed_server
                ];
                return;

            case ContingentType.webserver:
                this.usableFor = [ContingentUsage.webspace];
                return;

            case ContingentType.webspace:
                this.usableFor = [ContingentUsage.vhost];
                return;
        }
    }

    public isUsableFor(usage: ContingentUsage): boolean {
        return this.usableFor.indexOf(usage) >= 0;
    }

    public addMisc = (miscKey: string, miscValue: Record<string, any>): void => {
        if (!this.misc) {
            this.misc = {};
        }
        this.misc[miscKey] = miscValue;
    };
}

export class OrganCreateConfigurationGeneralContingentsController implements ng.IController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'bundleModel',
        'machineModel',
        'productsModel',
        'resourceModel',
        'webspaceModel'
    ];

    public defaultContingent = new Contingent(
        ContingentUsage.chargeable,
        this.$translate.instant('TR_100119-b13e52_TR'),
        ContingentType.standalone
    );

    public disabled = false;
    public usableContingents: Contingent[] = [];
    public contingentUpdateInProgress = false;

    private _accountId: string;
    private _availableContingents: Contingent[] = []; // [this.defaultContingent];
    private _products: Types.ProductApi.Product[] |   Types.ProductApi.TemplateProduct[];
    private _delimiter = '|||';
    private _externalModel: Contingent;
    private _internalModel: string;
    private _outerContingent: Contingent;
    private _tmpLastSelectedContingentId: string;
    private _defaultPool: Types.ResourceApi.Pool;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private bundleModel: BundleModelService,
        private machineModel: MachineModelService,
        private productsModel: ProductsModelService,
        private resourceModel: ResourceModelService,
        private webspaceModel: WebspaceModelService
    ) {
        this.model = this.defaultContingent;
    }

    public $onInit(): void {
        if (this._outerContingent) {
            this.accountId = this._accountId;
        }
    }

    private _isSubAccount = (accountId: string): boolean => {
        return accountId !== AuthContextService.account.id;
    };

    public get internalModel(): string {
        return this._internalModel;
    }

    public set internalModel(internalModel: string) {
        if (internalModel === undefined) {
            return;
        }
        this._internalModel = internalModel;
        if (internalModel) {
            this._externalModel = this._availableContingents
                .filter(Contingent.filterByType(internalModel.split(this._delimiter)[0] as ContingentType))
                .filter((contingent) => contingent.id === internalModel
                    .split(this._delimiter).slice(1).join(this._delimiter))[0];

            this._externalModel.usableContingents = this.usableContingents;
        } else {
            this._externalModel = internalModel as undefined;
        }
    }

    public get model(): Contingent {
        return this._externalModel;
    }

    public set model(model: Contingent) {
        if (model?.usableContingents?.length > 0) {
            model.usableContingents = model.usableContingents
            .filter(
                (contingent) => contingent.id !== ContingentUsage.chargeable
            );
        }

        this._externalModel = model;

        if (model) {
            this._internalModel = this._setModelId(model);
        } else {
            this._internalModel = model as undefined;
        }
    }

    public get accountId(): string {
        return this._accountId;
    }

    public set accountId(accountId) {
        if (!accountId || accountId === this._accountId) {
            return;
        } else {
            this._accountId = accountId;
        }

        const _accountId = this._isSubAccount(this._accountId) ? AuthContextService.account.id : this._accountId;

        this.defaultContingent = new Contingent(
            ContingentUsage.chargeable,
            this.$translate.instant('TR_100119-b13e52_TR'),
            ContingentType.standalone
        );

        /*
         * DO NOT REMOVE THE NEXT LINE!
         * It's there to ensure the usable contingents only get set once both the product code
         * AND the available contingents are completely available!
         */
        this._availableContingents = [];
        let availableContingents = [this.defaultContingent];
        let webserverPromise = Promise.resolve({ data: [] });
        let databaseServerPromise = Promise.resolve({ data: [] });

        if (AuthContextService.isGrantedAll([UiRights.RES_POOL_LIST, UiRights.MACHINE_VM_LIST])) {
            webserverPromise = this.resourceModel.webserversFind({
                field: 'AccountId',
                value: _accountId
            }) as unknown as Promise<{ data: [] }>;
            databaseServerPromise = this.resourceModel.databaseServersFind({
                field: 'AccountId',
                value:  _accountId
            }) as unknown as Promise<{ data: [] }>;
        }

        let poolsPromise = Promise.resolve({ data: [] });
        if (this._accountHasRessourceRight()) {
            poolsPromise = this.resourceModel.poolsList(100, 1, {
                    field: 'AccountId',
                    value: _accountId
                }) as unknown as Promise<{ data: [] }>;
        }

        let bundlePromise = Promise.resolve({ data: [] });
        if (AuthContextService.isGranted(UiRights.BIL_BUNDLE_LIST)) {
            bundlePromise = this._getBundleList(this._accountId) as unknown as Promise<{ data: [] }>;
        }

        let webspacePromise = Promise.resolve({ data: [] });
        if (AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST)) {
            webspacePromise = this._getWebspaceList(_accountId) as unknown as Promise<{ data: [] }>;
        }

        void poolsPromise.then(
            (result: { data: Types.ResourceApi.Pool[] }) => {
                let poolContingents: Contingent[] = [];

                if (this._isSubAccount(this._accountId)) {
                    for (const pool of result.data) {
                        if (pool.defaultPool) {
                            this._defaultPool = pool;

                            // If there is a default pool, certain products can no longer be created as standalone.
                            this._filterBlockedUsages(pool);
                            poolContingents = [new Contingent(
                                pool.id,
                                this.$translate.instant('TR_220721-5e7c40_TR'),
                                ContingentType.pool
                            )];
                        }
                    }
                } else {
                    for (const pool of result.data) {
                        if (pool.defaultPool) {
                            this._defaultPool = pool;

                            // If there is a default pool, certain products can no longer be created as standalone.
                            this._filterBlockedUsages(pool);
                        }

                        const poolContingent = new Contingent(
                            pool.id,
                            pool.name,
                            ContingentType.pool
                        );

                        if (pool.forwarderMailboxesAllocated >= pool.forwarderMailboxesLimit) {
                            poolContingent.usableFor = poolContingent.usableFor.filter(
                                (usage: ContingentUsage) => usage !== ContingentUsage.email_forwarder
                            );
                        }

                        if (pool.exchangeMailboxesAllocated >= pool.exchangeMailboxesLimit) {
                            poolContingent.usableFor = poolContingent.usableFor.filter(
                                (usage: ContingentUsage) => usage !== ContingentUsage.mailbox_exchange
                            );
                        }

                        if (pool.imapMailboxesAllocated >= pool.imapMailboxesLimit) {
                            poolContingent.usableFor = poolContingent.usableFor.filter(
                                (usage: ContingentUsage) => usage !== ContingentUsage.mailbox_imap
                            );
                        }

                        if (pool.defaultPool) {
                            poolContingents.unshift(poolContingent);
                        } else {
                            poolContingents.push(poolContingent);
                        }
                    }
                }

                availableContingents = availableContingents.concat(poolContingents);

                return webserverPromise;
            }
        )
        .then(
            (result: { data: Types.ResourceApi.Webserver[] }) => {
                const promises = [];
                for (const webserverResource of result.data) {
                    promises.push(
                        this.machineModel.findOneById(webserverResource.virtualMachineId)
                        .then(
                            async (virtualMachine: Types.MachineApi.VirtualMachine) => {
                                if (virtualMachine.status !== 'active') {
                                    return;
                                }

                                const webserverContingent = new Contingent(
                                    webserverResource.id,
                                    virtualMachine.name,
                                    ContingentType.webserver
                                );

                                const productResult = await this.productsModel.findOne(
                                    virtualMachine.productCode,
                                    UiLanguagesConst[AuthContextService.user.language],
                                    true
                                );

                                webserverContingent.misc = {
                                    virtualMachine: virtualMachine,
                                    virtualMachineProduct: productResult?.response
                                };

                                availableContingents.push(webserverContingent);
                            }
                        )
                    );
                }

                return Promise.all(promises)
                    .then(() => databaseServerPromise)
                    .catch(() => databaseServerPromise);
            }
        )
        .then(
            (result: { data: Types.ResourceApi.DatabaseServer[] }) => {
                const promises: Promise<any>[] = [];
                for (const databaseResource of result.data) {
                    promises.push(
                        this.machineModel.findOneById(databaseResource.virtualMachineId)
                        .then(
                            async (virtualMachine: Types.MachineApi.VirtualMachine) => {
                                if (virtualMachine.status !== 'active') {
                                    return;
                                }

                                const databaseServerContingent = new Contingent(
                                    databaseResource.id,
                                    virtualMachine.name,
                                    ContingentType.databaseServer
                                );

                                const productResult = await this.productsModel.findOne(
                                    virtualMachine.productCode,
                                    UiLanguagesConst[AuthContextService.user.language],
                                    true
                                );

                                databaseServerContingent.misc = {
                                    virtualMachine: virtualMachine,
                                    virtualMachineProduct: productResult?.response
                                };

                                availableContingents.push(databaseServerContingent);
                            }
                        )
                    );
                }

                return Promise.all(promises)
                    .then(() => webspacePromise)
                    .catch(() => webspacePromise);
            }
        )
        .then(
            (result: { data: Types.WebhostingApi.Webspace[] }) => {
                for (const webspace of result.data) {
                    availableContingents.push(
                        new Contingent(
                            webspace.id,
                            webspace.name,
                            ContingentType.webspace
                        )
                    );
                }

                return bundlePromise;
            }
        )
        .then(
            (result: { data: Types.BundleApi.Bundle[] }) => {
                const promises = [];
                for (const bundle of result.data) {
                    const selectedBundleId = this.$state.$current.locals?.globals?.bundle?.id;

                    if (selectedBundleId !== undefined && bundle.id !== selectedBundleId) {
                        continue;
                    }

                    const bundleContingent = new Contingent(
                        bundle.id,
                        bundle.name,
                        ContingentType.bundle
                    );

                    bundleContingent.misc = {
                        bundle: bundle,
                        webspace: null
                    };
                    const usableForDatabase = bundle.effectiveContingentUsage
                    .some(
                        (contingent) => contingent.availableCapacity > 0
                        && contingent.productCodes.some((productCode) => productCode.indexOf('database') >= 0)
                    );

                    const usableForDomain = bundle.effectiveContingentUsage
                        .some(
                            (contingent) => {

                                if (contingent.productCodes.some(
                                    (productCode) => productCode.indexOf('domain') >= 0)
                                ) {
                                    return contingent.availableCapacity > 0;
                                }
                            }
                        );

                    const usableForEmail = bundle.effectiveContingentUsage
                        .some(
                            (contingent) => contingent.availableCapacity > 0
                            && contingent.productCodes.some((productCode) => productCode.indexOf('email') >= 0)
                        );

                    if (usableForDatabase) {
                        bundleContingent.usableFor.push(ContingentUsage.database);
                    }

                    if (usableForDomain) {
                        bundleContingent.usableFor.push(ContingentUsage.domain);
                    }

                    if (usableForEmail) {
                        bundleContingent.usableFor.push(ContingentUsage.email_forwarder);
                        bundleContingent.usableFor.push(ContingentUsage.mailbox_imap);
                    }

                    bundleContingent.usableFor.push(ContingentUsage.vhost);

                    const hasWebspaceContingent = bundleContingent.misc?.bundle?.effectiveContingentUsage?.some(
                        (contingent) => contingent.productCodes?.some(
                            (productCode) => productCode.startsWith('webhosting-')
                        )
                    );

                    if (hasWebspaceContingent && AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST)) {
                        promises.push(
                            this.webspaceModel.findByBundleId(bundleContingent.misc.bundle.id)
                                .then((webspaceResult) => {
                                        bundleContingent.misc.webspace = webspaceResult;
                                        return bundleContingent;
                                    }
                                )
                        );
                    } else {
                        promises.push(Promise.resolve(bundleContingent));
                    }
                }

                void Promise.all(promises).then((bundleContingents) => {
                    for (const bundleContingent of bundleContingents) {
                        availableContingents.push(bundleContingent);
                    }
                    this._availableContingents = availableContingents;
                    if (this._products) {
                        this._setUsableContingents();
                    }
                });
            }
        );
    }

    public set showContingentSelection({}) {} // tslint:disable-line:no-empty
    public get showContingentSelection(): boolean {
        return this.usableContingents?.length > 1;
    }

    public set contingentAvailable({}) {} // tslint:disable-line:no-empty
    public get contingentAvailable(): boolean {
        return this.products?.length > 0
            ? this.usableContingents?.length > 1    // > 1 because of default standalone selection
            : false;
    }

    public get products(): Types.ProductApi.Product[] | Types.ProductApi.TemplateProduct[] {
        return this._products;
    }

    public set products(products) {
        this._products = products;
        this._setUsableContingents();
    }

    private _filterBlockedUsages(pool: Types.ResourceApi.Pool): void {
        const blockedUsages = [ContingentUsage.managed_server];

        if (pool.availableResources.indexOf('Database') >= 0) {
            blockedUsages.push(ContingentUsage.database);
        }

        if (pool.availableResources.indexOf('Webserver') >= 0) {
            blockedUsages.push(ContingentUsage.webspace);
        }

        if (pool.forwarderMailboxesAllocated < pool.forwarderMailboxesLimit) {
            blockedUsages.push(ContingentUsage.email_forwarder);
        }

        if (pool.exchangeMailboxesAllocated < pool.exchangeMailboxesLimit) {
            blockedUsages.push(ContingentUsage.mailbox_exchange);
        }

        if (pool.imapMailboxesAllocated < pool.imapMailboxesLimit) {
            blockedUsages.push(ContingentUsage.mailbox_imap);
        }

        this.defaultContingent.usableFor = this.defaultContingent.usableFor
            .filter((usage: ContingentUsage) => blockedUsages.indexOf(usage) < 0);
    }

    /**
     * Sets the usable contingents using available contingents filtered by product.
     *
     * EVIL PRODUCT CODE PARSING VOODOO - PROBABLY WRONG!!!!!!!
     */
    private _setUsableContingents = (): void => {
        this.contingentUpdateInProgress = true;
        let usableContingents: Contingent[] = [];

        if (
            this.products?.length === 0
            || this._availableContingents.length === 0
        ) {
            // Reset usableContingents list and model -> no products or no available contingents set
            this.usableContingents = usableContingents;
            this._tmpLastSelectedContingentId = this.model?.id;
            this.model = this._outerContingent || this.defaultContingent;
            return;
        }

        for (const product of this._products) {
            const productCodeOrTemplate = this._getProductCodeOrTemplate(product);
            switch (productCodeOrTemplate.split('-')[0]) {
                case 'domain':
                    usableContingents = this._availableContingents
                    .filter(Contingent.filterByUsability(ContingentUsage.domain))
                    .filter(Contingent.filterForProducts(this._products));
                    break;

                case 'database':
                    usableContingents = this._availableContingents
                    .filter(Contingent.filterByUsability(ContingentUsage.database))
                    .filter(Contingent.filterForProducts(this._products));
                    break;

                case 'machine':
                    const isManaged = product.factSheetItems.some(
                        (factSheetItem) => factSheetItem.category === 'management'
                    );

                    if (isManaged) {
                        usableContingents = this._availableContingents
                            .filter(Contingent.filterByUsability(ContingentUsage.managed_server));
                    }

                    break;

                case 'webhosting':
                    usableContingents = this._availableContingents
                    .filter(Contingent.filterByUsability(ContingentUsage.webspace));
                    break;

                case 'email':
                    if (productCodeOrTemplate.indexOf('forwarder') >= 0) {
                        usableContingents = this._availableContingents
                        .filter(Contingent.filterByUsability(ContingentUsage.email_forwarder))
                        .filter(Contingent.filterForProducts(this._products));
                    } else if (productCodeOrTemplate.indexOf('exchange') >= 0) {
                        usableContingents = this._availableContingents
                        .filter(Contingent.filterByUsability(ContingentUsage.mailbox_exchange));
                    } else if (productCodeOrTemplate.indexOf('imap') >= 0) {
                        usableContingents = this._availableContingents
                            .filter(Contingent.filterByUsability(ContingentUsage.mailbox_imap))
                            .filter(Contingent.filterForProducts(this._products));
                    }
                    break;

                default:
                    usableContingents = [];
                    break;
            }
            this.model = this._setModelFromUsableContingents(usableContingents);
            this.usableContingents = usableContingents.map(
                (contingent) => {
                    return {
                        id: contingent.id,
                        name: contingent.name,
                        value: this._setModelId(contingent)
                    };
                }
            );

            this.model.usableContingents = this.usableContingents
            .filter(
                (contingent) => contingent.id !== ContingentUsage.chargeable
            );
        }

        void this.$timeout(() => this.contingentUpdateInProgress = false );
    };

    private _setModelFromUsableContingents = (usableContingents: Contingent[]): Contingent => {
        if (this._outerContingent && this._outerContingent.type !== ContingentType.standalone) {
            // If a contingent was given from outside, I set the model accordingly
            let outerContingent;
            usableContingents.some(
                (contingent) => {
                    if (contingent.type === this._outerContingent.type
                        && contingent.id === this._outerContingent.id) {
                            outerContingent = contingent;
                            return true;
                        }

                        return false;
                    }
            );

            return outerContingent
                ? outerContingent
                : this.model;
        }

        // Select Contingent
        let newUsableContingent;
        const usableContingentSet = usableContingents.some(
            (contingent) => {
                if (contingent.id === ContingentUsage.chargeable) {
                    // Disregard chargeable option (default)
                    return false;
                }
                if (contingent.id === this._tmpLastSelectedContingentId) {
                    // If the last contingent selection is still possible after adding more products, select them again
                    newUsableContingent = contingent;
                    return true;
                }
                if (!this._tmpLastSelectedContingentId
                    && contingent.type === this.model?.type
                    && contingent.id === this.model?.id
                ) {
                    newUsableContingent = this.model;
                    return true;
                }

                return false;
            }
        );

        if (!usableContingentSet) {
            if (usableContingents.length === 1) { // exactly one contingent is available ... select it.
                newUsableContingent = usableContingents[0];
            } else if (
                usableContingents.length > 1
                && this._internalModel !== this._setModelId(this.defaultContingent)
            ) {
                // if more then one contingent is available:
                // select the first contingent that is NOT the standalone contingent
                newUsableContingent = usableContingents[0].type !== ContingentType.standalone
                    ? usableContingents[0]
                    : usableContingents[1];
            } else { // no contingents available use standalone as default
                newUsableContingent = this.defaultContingent;

                if (this._defaultPool) {
                    const availableDefaultPool = usableContingents.filter(
                        (contingent) => contingent.id === this._defaultPool.id
                    );
                    if (availableDefaultPool.length > 0) {
                        newUsableContingent = availableDefaultPool[0];
                    }
                }
            }
        }

        return newUsableContingent;
    };

    private _getProductCodeOrTemplate = (
        product:  Types.ProductApi.Product | Types.ProductApi.TemplateProduct
    ): string => {
        return [undefined, null, ''].indexOf((product as Types.ProductApi.Product).productCode) < 0
            ? (product as Types.ProductApi.Product).productCode
            : (product as Types.ProductApi.TemplateProduct).productCodeTemplate;

    };

    private _setModelId = (model: Contingent): string => {
        return `${model.type}${this._delimiter}${model.id}`;
    };

    private _accountHasRessourceRight = (): boolean => {
        return (
            AuthContextService.isGranted(UiRights.RES_POOL_LIST)
            && !AuthContextService.isRootOrCompanyAccount
        );
    };

    private _getBundleList = (accountId: string): PromiseLike<any> => {

        const filters = {
            subFilterConnective: 'AND',
            subFilter: [
                {
                    field: 'AccountId',
                    value: accountId
                },
                {
                    field: 'BundleStatus',
                    value: 'active'
                }
            ]
        };

        return this.bundleModel.list(100, 1, filters);
    };

    private _getWebspaceList = (accountId: string): PromiseLike<any> => {
        const filters = {
            subFilterConnective: 'AND',
            subFilter: [
                {
                    field: 'AccountId',
                    value: accountId
                },
                {
                    field: 'WebspaceStatus',
                    value: 'active'
                }
            ]
        };

        return this.webspaceModel.list(100, 1, filters);
    };
}

export class OrganCreateConfigurationGeneralContingentsComponent implements ng.IComponentOptions {
    public bindings = {
        // Contingent from outer component - create product within an bundle/webspace/machine
        _outerContingent: '<?outerContingent',
        accountId: '<',                 // Account ID used to fetch available bundles / resources
        contingentAvailable: '=?',       // Communicator to the outside world whether contingents are available
        disabled: '<',                  // Boolean to disable the dropdown
        model: '=',                     // Selected contingent object
        products: '<'                  // Product objects to show available contingents for
    };

    public template = require('./contingents.html');
    public controller =  OrganCreateConfigurationGeneralContingentsController;
}
