import {
    RpcClient,
    RpcClientService
} from '../rpc-client';

import * as ng from 'angular';
import * as Types from '../../types';
import { DnsApi } from '../../types';

export const DnsClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient
    .Factory('/api/dns/v1/json/', 'dns')
];

export class DnsRobotService {
    public static $inject: string[] = ['dnsClient'];

    constructor(private dnsClient: RpcClient) {}

    public listZones: Types.UI.ListMethod<unknown>
    = (filter?, limit?, page?, sort?, timeout?) => {
        return this.dnsClient.request('zoneConfigsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 5)
        .timeout(timeout)
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listZonesWithoutPagination: Types.UI.ListMethod<unknown>
    = (filter?, limit?, page?, sort?, timeout?, owner?) => {
        return this.dnsClient.request('zoneConfigsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 5)
        .timeout(timeout)
        .ownerAccountId(owner)
        .skipPagination()
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public createZone(
        zoneConfig: Types.DnsApi.ZoneConfig,
        records: Types.DnsApi.Record[],
        nameserverSetId: string,
        useDefaultNameserverSet: boolean,
        owner: string,
        dnsSecOptions?: Types.DnsApi.DnsSecOptions
    ) {
        if ([undefined, null].indexOf(dnsSecOptions) >= 0 || ng.equals({}, dnsSecOptions)) {
            dnsSecOptions = undefined;
        }

        return this.dnsClient.request('zoneCreate')
        .bustCache('dns', 'zoneConfigsFind')
        .ownerAccountId(owner)
        .execute(
            {
                dnsSecOptions: dnsSecOptions,
                nameserverSetId: nameserverSetId,
                records: records,
                useDefaultNameserverSet: useDefaultNameserverSet,
                zoneConfig: zoneConfig
            }
        );
    }

    public checkZone(
        zoneConfig: Types.DnsApi.ZoneConfig,
        records: Types.DnsApi.Record[],
        nameserverSetId: string,
        useDefaultNameserverSet: boolean,
        owner: string,
        dnsSecOptions?: Types.DnsApi.DnsSecOptions,
        isReCreate: boolean = false
    ) {
        if ([undefined, null].indexOf(dnsSecOptions) >= 0 || ng.equals({}, dnsSecOptions)) {
            dnsSecOptions = undefined;
        }

        return this.dnsClient
            .request(isReCreate ? 'zoneRecreateCheck' : 'zoneCreateCheck')
            .ownerAccountId(owner)
            .execute(
                {
                    dnsSecOptions: dnsSecOptions,
                    nameserverSetId: nameserverSetId,
                    records: records,
                    useDefaultNameserverSet: useDefaultNameserverSet,
                    zoneConfig: zoneConfig
                }
            );
    }

    public zoneModifyRestrictions = (apiObject, owner) => {
        return this.dnsClient.request('zoneModifyRestrictions')
            .ownerAccountId(owner)
            .bustCache('dns', 'zoneConfigsFind')
            .execute(apiObject);
    };

    public recreateZone(
        zoneConfig: Types.DnsApi.ZoneConfig,
        records: Types.DnsApi.Record[],
        nameserverSetId?: string,
        useDefaultNameserverSet?: boolean,
        dnsSecOptions?: Types.DnsApi.DnsSecOptions
    ) {
        nameserverSetId = nameserverSetId || '0';
        useDefaultNameserverSet = useDefaultNameserverSet || false;

        if ([undefined].indexOf(dnsSecOptions) < 0 || ng.equals({}, dnsSecOptions)) {
            dnsSecOptions = undefined;
        }

        return this.dnsClient.request('zoneRecreate')
        .bustCache('dns', 'zoneConfigsFind')
        .execute(
            {
                dnsSecOptions: dnsSecOptions,
                nameserverSetId: nameserverSetId,
                records: records,
                useDefaultNameserverSet: useDefaultNameserverSet,
                zoneConfig: zoneConfig
            }
        );
    }

    public findZones = (
        filter: Types.Finding.Filter,
        limit: number,
        page: number,
        sort: Types.Finding.SortOptions,
        skipPagination: boolean
    ) => {
        return this.dnsClient.request('zonesFind')
        .skipPagination(skipPagination)
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public updateZone = (
        zoneConfig: Types.DnsApi.ZoneConfig,
        addRecordList: Types.DnsApi.Record[],
        deleteRecordList: Types.DnsApi.Record[],
        modifiedRecordList: Types.DnsApi.Record[],
        dnsSecOptions: Types.DnsApi.DnsSecOptions
    ) => {
        if ([undefined, null].indexOf(dnsSecOptions) >= 0 || ng.equals({}, dnsSecOptions)) {
            dnsSecOptions = undefined;
        }

        return this.dnsClient.request('zoneUpdate')
            .bustCache('dns', 'zoneConfigsFind')
            .execute(
                {
                    dnsSecOptions: dnsSecOptions,
                    recordsToAdd: addRecordList,
                    recordsToDelete: deleteRecordList,
                    recordsToModify: modifiedRecordList,
                    zoneConfig: zoneConfig
                }
            );
    };

    public deleteZone(zoneId: string) {
        return this.dnsClient.request('zoneDelete')
        .bustCache('dns', 'zoneConfigsFind')
        .execute({zoneConfigId: zoneId});
    }

    public blockZones(zoneIds: string[]) {
        return this.dnsClient.request('zonesDisable')
        .bustCache('dns', 'zoneConfigsFind')
        .execute({zoneConfigIds: zoneIds});
    }

    public unblockZones(zoneIds: string[]) {
        return this.dnsClient.request('zonesEnable')
        .bustCache('dns', 'zoneConfigsFind')
        .execute({zoneConfigIds: zoneIds});
    }

    public listTemplates: Types.UI.ListMethod<unknown> = (filter?, limit?, page?, sort?, timeout?) => {
        return this.dnsClient.request('templatesFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .timeout(timeout)
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listTemplatesWithoutPagination: Types.UI.ListMethod<unknown> = (filter?, limit?, page?, sort?) => {
        return this.dnsClient.request('templatesFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .skipPagination()
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public createTemplate(template, records, owner: string) {
        return this.dnsClient.request('templateCreate')
        .bustCache('dns', 'templatesFind')
        .ownerAccountId(owner)
        .execute({ dnsTemplate: template, recordTemplates: records });
    }

    public recreateTemplate(template, records, replacements) {
        return this.dnsClient.request('templateRecreate')
        .bustCache('dns', 'templatesFind')
        .execute(
            {
                dnsTemplate: template,
                recordTemplates: records,
                replacements: replacements
            }
        );
    }

    public updateTemplate(
        dnsTemplate: Types.DnsApi.Template,
        recordTemplatesToAdd: Types.DnsApi.RecordTemplate[] = null,
        recordTemplatesToDelete: Types.DnsApi.RecordTemplate[] = null,
        replacements: Types.DnsApi.TemplateReplacements = null
    ) {
        return this.dnsClient.request('templateUpdate')
        .bustCache('dns', 'templatesFind')
        .execute(
            {
                dnsTemplate: dnsTemplate,
                recordTemplatesToAdd: recordTemplatesToAdd,
                recordTemplatesToDelete: recordTemplatesToDelete,
                replacements: replacements
            }
        );
    }

    public deleteTemplate(templateId: string) {
        return this.dnsClient.request('templateDelete')
        .bustCache('dns', 'templatesFind')
        .execute({ templateId: templateId });
    }

    public listJobs: Types.UI.ListMethod<unknown> = (filter?, limit?, page?, sort?) => {
        return this.dnsClient.request('jobsFind')
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listNameserverSet = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions,
        owner?: string
    ) => {
        return this.dnsClient.request('nameserverSetsFind')
        .ownerAccountId(owner)
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listNameserverSetWithoutPagination = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions,
        owner?: string
    ) => {
        return this.dnsClient.request('nameserverSetsFind')
        .ownerAccountId(owner)
        .skipPagination()
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listTemplateRecords: Types.UI.ListMethod<unknown> = (filter, limit, page, sort) => {
        return this.dnsClient.request('recordTemplatesFind')
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listTemplateRecordsWithoutPagination: Types.UI.ListMethod<unknown> = (filter, limit, page, sort) => {
        return this.dnsClient.request('recordTemplatesFind')
        .skipPagination()
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listRecords = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions,
        owner?: string
    ) => {
        return this.dnsClient.request('recordsFind')
        .ownerAccountId(owner)
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public listRecordsWithoutPagination = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions,
        owner?: string
    ) => {
        return this.dnsClient.request('recordsFind')
        .ownerAccountId(owner)
        .skipPagination()
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public untieZoneFromTemplate = (zoneId: string) => {
        return this.dnsClient.request('zonesUntieFromTemplates')
        .bustCache('dns', 'zoneConfigsFind')
        .execute({ zoneConfigIds: [zoneId] });
    };

    public nameserverSetGetDefault = (owner: string) => {
        return this.dnsClient.request('nameserverSetGetDefault')
        .ownerAccountId(owner)
        .execute({});
    };

    public createNameserverSet = (nameserverSet, owner: string) => {
        return this.dnsClient.request('nameserverSetCreate')
        .ownerAccountId(owner)
        .execute({ nameserverSet: nameserverSet });
    };

    public updateNameserverSet = (nameserverSet) => {
        return this.dnsClient.request('nameserverSetUpdate').execute({ nameserverSet: nameserverSet });
    };

    public deleteNameserverSet = (nameserverSetId: string) => {
        return this.dnsClient.request('nameserverSetDelete').execute({ nameserverSetId: nameserverSetId });
    };

    public contentChanger = (content) => {
        return this.dnsClient.request('changeContent')
        .execute(
            {
                includeSubAccounts: content.includeSubAccounts,
                includeTemplates: content.includeTemplates,
                newContent: content.newValue,
                oldContent: content.oldValue,
                ownerAccountId: content.accountId,
                recordType: content.type
            }
        );
    };

    public zoneMove = (zoneConfigIds: string[], toAccountId: string) => {
        return this.dnsClient.request('zonesMove')
        .bustCache('dns', 'zoneConfigsFind')
        .execute({ zoneConfigIds: zoneConfigIds, toAccountId: toAccountId });
    };

    public zoneMoveByName = (zoneNames: string[], toAccountId: string) => {
        return this.dnsClient.request('zonesMove')
        .bustCache('dns', 'zoneConfigsFind')
        .execute({ zoneNames: zoneNames, toAccountId: toAccountId });
    };

    public listDnsOptions = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions,
        owner?: string
    ) => {
        return this.dnsClient.request('dnsSecOptionsFind')
        .ownerAccountId(owner)
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public purgeRestorableDnsZone = (zoneId: string) => {
        return this.dnsClient.request('zonePurgeRestorable')
            .bustCache('dns', 'zoneConfigsFind')
            .execute(
                {
                    zoneConfigId: zoneId
                }
            );
    };

    public restoreDnsZone = (zoneId: string) => {
        return this.dnsClient.request('zoneRestore')
            .bustCache('dns', 'zoneConfigsFind')
            .execute(
                {
                    zoneConfigId: zoneId
                }
            );
    };

    /* =================
     * == Dynamic DNS ==
     * =================
     */
    public listDdnsHostnames: Types.UI.ListMethod<unknown> = (filter?, limit?, page?, sort?) => {
        return this.dnsClient.request('dynamicDnsHostnamesFind')
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public listDdnsCredentials: Types.UI.ListMethod<unknown> = (filter?, limit?, page?, sort?) => {
        return this.dnsClient.request('dynamicDnsCredentialsFind')
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public listDdnsCredentialsWithoutPagination = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions,
        owner?: string
    ) => {
        return this.dnsClient.request('dynamicDnsCredentialsFind')
            .ownerAccountId(owner)
            .skipPagination()
            .useCache(true)
            .execute(
                {
                    filter: filter,
                    limit: limit,
                    page: page,
                    sort: sort
                }
            );
    };

    public createDdnsCredentials = (newUser: Types.DnsApi.DynamicDnsCredentials, password: string) => {
        return this.dnsClient.request('dynamicDnsCredentialsCreate')
            .bustCache('dns', 'dynamicDnsCredentialsFind')
            .execute(
                {
                    dynamicDnsCredentials: newUser,
                    password: password
                }
            );
    };

    public deleteDdnsCredentials = (credentialsId: string) => {
        return this.dnsClient.request('dynamicDnsCredentialsDelete')
            .bustCache('dns', 'dynamicDnsCredentialsFind')
            .execute(
                {
                    dynamicDnsCredentialsId: credentialsId
                }
            );
    };

    public updateDdnsCredentials = (user: Types.DnsApi.DynamicDnsCredentials, password: string) => {
        return this.dnsClient.request('dynamicDnsCredentialsUpdate')
            .bustCache('dns', 'dynamicDnsCredentialsFind')
            .execute(
                {
                    dynamicDnsCredentials: user,
                    newPassword: password
                }
            );
    };

    public dynamicDnsHostnameCreate = (
        credentialsId: string,
        hostname: string,
        comment: string,
        productCode: string,
        accountId: string
    ) => {
        return this.dnsClient.request('dynamicDnsHostnameCreate')
            .execute(
                {
                    dynamicDnsHostname: {
                        accountId: accountId,
                        comments: comment,
                        credentialsId: credentialsId,
                        hostname: hostname,
                        productCode: productCode
                    }
                }
            );
    };

    public dynamicDnsHostnameUpdate = (hostname: DnsApi.DynamicDnsHostname) => {
        return this.dnsClient.request('dynamicDnsHostnameUpdate')
            .execute(
                {
                    dynamicDnsHostname: hostname
                }
            );
    };

    public dynamicDnsHostnameDelete = (hostnameId: string, execDate) => {
        if ([undefined, null].indexOf(execDate) < 0) {
            if (execDate.isToday()) {
                execDate = null;
            }
        }
        return this.dnsClient.request('dynamicDnsHostnameDelete')
            .execute(
                {
                    dynamicDnsHostnameId: hostnameId,
                    execDate: (execDate === null) ? null : execDate.dateObj
                }
            );
    };

    public dynamicDnsHostnameCancelDeletion = (hostnameId: string) => {
        return this.dnsClient.request('dynamicDnsHostnameDeletionCancel')
            .execute(
                {
                    dynamicDnsHostnameId: hostnameId
                }
            );
    };

    public purgeRestorableDdnsHost = (hostnameId: string) => {
        return this.dnsClient.request('dynamicDnsHostnamePurgeRestorable')
            .execute(
                {
                    dynamicDnsHostnameId: hostnameId
                }
            );
    };

    public restoreDdnsHost = (hostnameId: string) => {
        return this.dnsClient.request('dynamicDnsHostnameRestore')
            .execute(
                {
                    dynamicDnsHostnameId: hostnameId
                }
            );
    };
}
