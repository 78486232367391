export const knownManagedSslProductCodesConst = [
    'ssl-letsencrypt-dv-3m',
    'ssl-geotrust-rapidssl-12m'
];

export const ManagedMachineProductFamilies = [
    'dv-certificates',
    'ov-certificates',
    'ev-certificates'
];
