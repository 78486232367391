import * as ng from 'angular';
import './panel-edit-row-add-text-to-list.scss';

export class MoleculePanelEditRowAddTextToListController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public list: (string | number)[];
    public newItemValue: string;
    public itemList: any[] = [];
    public errorMessage = '';
    public validationInstructions: any[];
    public placeholder: string;
    public isNumberList = false;
    public max: number;
    public onChangeCallback: () => any;

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.list = this.list || [];
        this.list.forEach((target) => {
            this.itemList.push({value: target, tmpStatus: 'registered', newStatus: ''});
        });
    }

    public addToList = (newItem) => {
        this.errorMessage = '';

        // Convert newItem to number if `isNumberList` property is set.
        if (this.isNumberList) {
            newItem.value = parseInt(newItem.value, 10);
        }

        if (this.newItemValue === '' || this.newItemValue === undefined) {
            this.errorMessage = this.$translate.instant('TR_100119-97498a_TR');
            return;
        }
        if (this.itemExistsInList(newItem.value)) {
            this.errorMessage = this.$translate.instant('TR_100119-97498a_TR');
            return;
        }

        const newListItem: any = {value: newItem.value, tmpStatus: '', newStatus: 'new'};
        this.itemList.unshift(newListItem);
        this.newItemValue = '';
        this.list.push(newItem.value);
        if (this.onChangeCallback !== undefined) {
            this.onChangeCallback();
        }
    };

    public buttonItemModifier(item) {
        return item.newStatus === 'delete' ? 'active' : 'inactive';
    }

    public toggleStatus = (item) => {
        if (item.newStatus === 'delete') {
            item.newStatus = item.tmpStatus === 'registered' ? item.tmpStatus : 'new';
            this.list.push(item.value);
        } else {
            item.newStatus = 'delete';
            const index = this.list.indexOf(item.value);
            this.list.splice(index, 1);
        }
        if (this.onChangeCallback !== undefined) {
            this.onChangeCallback();
        }
    };

    private itemExistsInList = (item) => {
        return this.itemList.some((itemInList: any) => {
            return itemInList.value === item;
        });
    };

    public get disableAddButton() {
        if (this.max === undefined) {
            return false;
        }
        return this.list.length >= this.max;
    }

    public get showQuotaMessage() {
        return this.max !== undefined;
    }

    public get quotaMessage() {
        return this.list.length + '/' + this.max;
    }

}

export class MoleculePanelEditRowAddTextToListComponent implements ng.IComponentOptions {
    public bindings = {
        list: '=',
        onChangeCallback: '<',
        placeholder: '@?',
        isNumberList: '<?',
        validationInstructions: '<'
    };
    public transclude = {
        label: '?rowLabel'
    };
    public controller = MoleculePanelEditRowAddTextToListController;
    public template = require('./panel-edit-row-add-text-to-list.html');
}
