import ng from 'angular';

import { Contingent, ContingentType } from '@/atomic-components';
import { BillingCycleToContractPeriodeConst } from '@/configuration';
import {
    AccountHelperService, AsteriskNoteService, AsteriskNoteType, AuthContextService,
    BillingHelperService, PriceHelperService, ResourceModelService
} from '@/services/';
import { AccountApi, BillingApi } from '@/types';

export type TotalPriceTaxObject = {
    tax: number;
    vatRate: number;
};

export type TotalPriceObject = {
    currency: string;
    exchangeRatio: BillingApi.ExchangeRatio;
    index: string;
    rawPrice: number;
    rawBasisConverted: number;
    rawTax: null | TotalPriceTaxObject;
    amount: number;
};
export class MoleculeFormProductPriceController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        'accountHelper',
        'asteriskNote',
        'billingHelper',
        'resourceModel',
        'priceHelper'
    ];

    public account: AccountApi.Account | AccountApi.Subaccount;
    public contingent: Contingent;
    public noSpaceLeft = false;
    public priceInNewLine = false;
    public pricePrefixText: string = null;
    public vatAsteriskNoteType: AsteriskNoteType;
    public pricePostfixText: string;
    public priceStroke: boolean;

    public annualBillingAsteriskNoteType = AsteriskNoteType.ANNUALLY_CONTRACT_PERIOD;

    private _loadingPrice = true;
    private _price: BillingApi.ArticlePurchasePrice;
    private _priceMultiplicator: number;
    private _productCode: string;
    private _totalPriceId: string;
    private _totalPriceObject: TotalPriceObject[] = [];
    private _priceText: string;
    private _rawPrice: number;

    constructor(
        private $timeout: ng.ITimeoutService,
        public accountHelper: AccountHelperService,
        private asteriskNote: AsteriskNoteService,
        private billingHelper: BillingHelperService,
        private resourceModel: ResourceModelService,
        private priceHelper: PriceHelperService
    ) {}

    public $onInit(): void {
        this.account = this.account || AuthContextService.account;
        if ([undefined, null].indexOf(this._totalPriceId) >= 0) {
            this._totalPriceId = this.priceHelper.setTotalPriceIndex();
        }

        if (this.showVatAsteriskNote) {
            this.vatAsteriskNoteType = this.account.isCommercialCustomer
                ? AsteriskNoteType.VAT_EXCLUDED
                : AsteriskNoteType.VAT_INCLUDED;
        }

        this._getProductPrice();
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        if (
            (changes._productCode !== undefined && !changes._productCode.isFirstChange())
            || (
                changes._priceMultiplicator?.currentValue !== changes._priceMultiplicator?.previousValue
                && !changes._priceMultiplicator.isFirstChange()
                && !changes._priceMultiplicator !== undefined
            )
        ) {
            // Q: ToDo: BENÖTIGEN WIR DAS HIER? Eigentlich sollte sich der productCode doch nicht verändern, oder?
            // Q: Falls doch, haben wir das Problem, dass das totalPriceObjekt erneut dem
            // Q: totalPriceObject Array hinzugefügt wird !!!
            // A: Wird für's wechseln von Vertragslaufzeit gebraucht
            // A: @fixme anders machen (PUI-6968)
            this._getProductPrice();
        }
    }

    public get showAnnualBillingAsteriskNote(): boolean {
        return this.billingHelper.getBillingCycle(this._price) === BillingCycleToContractPeriodeConst.annually;
    }

    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    public set loadingPrice(_) {}
    public get loadingPrice(): boolean {
        return this._loadingPrice;
    }

    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    public set prefixTextIsset(_) {}
    public get prefixTextIsset(): boolean {
        if (this._price?.netAmount === -1) {
            return false;
        }

        return [undefined, null, ''].indexOf(this.pricePrefixText) < 0;
    }

    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    public set priceText(_) {}
    public get priceText(): string {
        return this._priceText;
    }

    public get showVatAsteriskNote(): boolean {
        if (this._price?.netAmount === -1) {
            return false;
        }

        return !this.freeOfChargeProduct
            && !AuthContextService.isRootOrCompanyAccount;
    }

    /* eslint-disable-next-line @typescript-eslint/no-empty-function */
    public set freeOfChargeProduct(_) {}
    public get freeOfChargeProduct(): boolean {
        return this._rawPrice === 0;
    }

    private setPrice = (priceObject: BillingApi.ArticlePurchasePrice): boolean | void => {
        const totalPriceObject: TotalPriceObject = {
            currency: priceObject.currency,
            exchangeRatio: priceObject.exchangeRatio,
            index: this._totalPriceId,
            rawPrice: 0,
            rawBasisConverted: 0,
            rawTax: null,
            amount: this._priceMultiplicator ? this._priceMultiplicator : 1
        };

        if ([undefined, null].indexOf(this._price) >= 0 // Price is not set.
            || this.loadingPrice                        // Price is currently being determined or loaded.
        ) {
            this._priceText = '';
            this._totalPriceObject.push(totalPriceObject);
            return false;
        }

        if (!this.freeOfChargeProduct) {
            this.asteriskNote.addNote(this.vatAsteriskNoteType);
        }

        const ignoreCommercial = false;
        const showPromotionPrice = [undefined, null].indexOf(priceObject.promotionalNetAmount) < 0;
        const showBasicCurrency = [undefined, null].indexOf(priceObject.exchangeRatio) < 0;

        this._priceText = this.priceHelper.calculateAndShowPrice(
            this._price,
            this.account,
            ignoreCommercial,
            showPromotionPrice,
            true,
            [undefined, null].indexOf(priceObject.exchangeRatio) < 0,
            this._priceMultiplicator
        );

        this._rawPrice = totalPriceObject.rawPrice = this.priceHelper.calculatePrice(
            this._price,
            this.account.isCommercialCustomer,
            ignoreCommercial,
            showPromotionPrice,
            showBasicCurrency
                ? priceObject.exchangeRatio.exchangeRatio
                : null,
            showBasicCurrency
        );

        totalPriceObject.rawBasisConverted = showBasicCurrency
            ? this.priceHelper.calculatePrice(
                this._price,
                this.account.isCommercialCustomer,
                ignoreCommercial,
                showPromotionPrice,
                showBasicCurrency
            )
            : 0;

        totalPriceObject.rawTax = ng.copy(this.priceHelper.calculatePriceTax(
            this._price,
            this.account
        ));

        this._totalPriceObject.push(totalPriceObject);
    };

    public get currency(): string {
        if ([undefined, null].indexOf(this._price) >= 0
            || this.loadingPrice
        ) {
            return '';
        }
        return this.priceHelper.getProductCurrency(this._price);
    }

    private _getProductPrice = (): void => {
        this._loadingPrice = true;
        const subAccount = AuthContextService?.account?.id !== this.account?.id
            ? this.account?.id
            : null
        ;

        const promises = [];
        promises.push(this.billingHelper.getPriceByProductCode(this._productCode, subAccount));
        if (this.contingent?.type === ContingentType.pool) {
            promises.push(this.resourceModel.findOne(this.contingent.type, this.contingent.id));
        }

        void Promise.all(promises).then((promiseResolve) => {
            let isInContingent = false;
            const priceObject: BillingApi.ArticlePurchasePrice = promiseResolve[0];
            const poolObject = promiseResolve[1];

            if (this.contingent?.type === ContingentType.bundle) {
                const effectiveContingent = this.contingent.misc.bundle.effectiveContingentUsage.filter(
                    (contingent) => contingent.productCodes.some(
                        (includedProductCode) => includedProductCode === this._productCode
                    )
                );

                isInContingent = effectiveContingent.length > 0 && effectiveContingent[0].availableCapacity > 0;
            } else if (
                [
                    ContingentType.databaseServer,
                    ContingentType.webserver
                ].indexOf(this.contingent?.type) >= 0
            ) {
                isInContingent = true;
            } else if (poolObject) {
                if (this._productCode.indexOf('email-forwarder') >= 0) {
                    isInContingent = (poolObject.forwarderMailboxesLimit - poolObject.forwarderMailboxesAllocated) > 0;
                } else if (this._productCode.indexOf('exchange') >= 0) {
                    isInContingent = (poolObject.exchangeMailboxesLimit - poolObject.exchangeMailboxesAllocated) > 0;
                } else if (this._productCode.indexOf('imap') >= 0) {
                    isInContingent = (poolObject.imapMailboxesLimit - poolObject.imapMailboxesAllocated) > 0;
                }
            }

            if (isInContingent === true && !!priceObject?.netAmount) {
                priceObject.netAmount = 0;
            }

            // it has a reason, which is not known to man kind (lost hour timer: 3)
            void this.$timeout(() => {
                if (priceObject === -1) {
                    this._price = {
                        netAmount: priceObject
                    };
                } else {
                    this._price = priceObject;
                }

                this._loadingPrice = false;
                this.setPrice(priceObject);
            });
        });
    };
}

export class MoleculeFormProductPriceComponent implements ng.IComponentOptions {
    public bindings = {
        _priceMultiplicator: '<?priceMultiplicator',
        _productCode: '<productCode',
        _totalPriceId: '<?totalPriceId',
        _totalPriceObject: '=?totalPriceObject',
        account: '<?',
        contingent: '<?',
        noSpaceLeft: '<?',
        priceStroke: '<?',
        priceInNewLine: '<?',
        pricePostfixText: '@?',
        pricePrefixText: '@?'
    };
    public template = require('./product-price.html');
    public controller =  MoleculeFormProductPriceController;
}
