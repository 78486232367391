import * as ng from 'angular';

import {
    WizardServiceHelperDomain
} from '../../../../molecules/wizard/wizard-service-object-container/service-helpers';

import './domain-nameservers.scss';

export class OrganEditPanelDomainNameserversController {
    public get showZoneInfo() {
        return [undefined, null].indexOf(this.zone) === -1;
    }
    public static $inject: string[] = ['$timeout', 'wizardServiceHelperDomain'];
    public domain: any;
    public nameservers: any;
    public selectedNameservers: any;
    public panelRightSettings: any;
    public nameserverDropdownItems: any[];
    public ownNameServers: any[];
    public zone: any;
    public isNameserversetAndhasGlueRecord = false;

    private _origDomainNamservers: any;

    constructor(
        private $timeout: ng.ITimeoutService,
        private wizardServiceHelperDomain: WizardServiceHelperDomain
    ) {}

    $onInit() {
        this._origDomainNamservers = ng.copy(this.domain.nameservers);
        this._setNameserverSets();
    }

    public onNameserverOptionChange = (selection) => {
        this.$timeout(() => {
            this._getSelectedNameserver(selection);
            this._checkGlueRecord();
            return this.isNameserversetAndhasGlueRecord;
        });
    };

    public cancel = () => {
        this.domain.nameservers = ng.copy(this._origDomainNamservers);
        this._getSelectedNameserver();
    };

    private _setNameserverSets = () => {
        return this.wizardServiceHelperDomain.getNameserverSets(this.domain.accountId)
            .then(nameserverSets => {
                this.nameservers = this.wizardServiceHelperDomain.objectCache.nameserSets[0].items[0].value;
                this.nameserverDropdownItems = nameserverSets;
                this._getSelectedNameserver();
                this._checkGlueRecord();
            });
    };

    private _checkGlueRecord = () => {
        this.isNameserversetAndhasGlueRecord = this.ownNameServers.length === 0
            && this.wizardServiceHelperDomain.checkDomainHasGlueRecord(
                this.selectedNameservers.nameservers,
                [this.domain]
            );
    };

    private _getSelectedNameserver = (selection?) => {
        const selectedNameserver = selection || this.domain;
        let noCustomeNameservers = false;
        this.ownNameServers = [];

        if (selectedNameserver.nameservers !== null) {
            noCustomeNameservers = this.nameserverDropdownItems.some(dropdownOption => {
                return dropdownOption.items.some(item => {
                    if ([undefined, null].indexOf(item.value.nameservers) >= 0
                        || selectedNameserver.nameservers?.length !== item.value.nameservers.length
                    ) {
                        return false;
                    }
                    const nameserverMatch = item.value.nameservers.every(nameserver => {
                        return selectedNameserver.nameservers.some(domainNs => {
                            return nameserver.name === domainNs.name;
                        });
                    });

                    if (nameserverMatch) {
                        this.selectedNameservers = item.value;
                        this.domain.nameservers.map(ns => {
                            this.selectedNameservers.nameservers.some(sns => {
                                if (ns.name === sns.name) {
                                    sns.ips = ng.copy(ns.ips);
                                }
                            });
                        });
                        return true;
                    }
                    return false;
                });
            });
        }

        if ([undefined, null].indexOf(this.selectedNameservers) >= 0) {
            // own-custome nameservers
            this.selectedNameservers = {
                default: false,
                id: null,
                nameservers: ng.copy(this.domain.nameservers)
            };
        }

        if (selectedNameserver.nameservers === null || !noCustomeNameservers) {
            this.ownNameServers = ng.copy(this.domain.nameservers);
        }
    };
}

export class OrganEditPanelDomainNameserversComponent implements ng.IComponentOptions {
    public bindings = {
        panelRightSettings: '<panelRight',
        domain: '=',
        selectedNameservers: '=',
        ownNameServers: '=',
        zone: '<'
    };
    public controller = OrganEditPanelDomainNameserversController;
    public template = require('./domain-nameservers.html');
}
