import {
    RpcClient,
    RpcClientService
} from '../rpc-client';

import * as Types from '../../types';
// import * as q from 'q';

export const DomainClientService = [
    'rpcClient',
    (platformRpcClient: RpcClientService) => platformRpcClient.Factory('/api/domain/v1/json/', 'domain')
];

export class DomainRobotService {
    public static $inject: string[] = ['domainClient'];

    constructor(private domainClient: RpcClient) {}

    public listDomains(
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions,
        cancel?,
        owner?
    ) {
        return this.domainClient.request('domainsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .timeout(cancel)
        .ownerAccountId(owner)
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    }

    public listDomainsWithoutPagination = (
        filter?: Types.Finding.Filter,
        limit?: number,
        page?: number,
        sort?: Types.Finding.SortOptions,
        cancel?,
        owner?
    ) => {
        return this.domainClient.request('domainsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .timeout(cancel)
        .ownerAccountId(owner)
        .skipPagination()
        .execute(
            {
                filter: filter,
                limit: limit,
                page: page,
                sort: sort
            }
        );
    };

    public createDomain(domain, owner) {
        return this.domainClient.request('domainCreate')
        .bustCache('domain', 'domainsFind')
        .bustCache('billing', 'depositGet')
        .bustCache('bundle', 'bundlesFind')
        .ownerAccountId(owner)
        .execute({ domain: domain, registrationPeriod: 1, execDate: null });
    }

    public transferDomain(domain, transferData, owner) {
        return this.domainClient.request('domainTransfer')
        .bustCache('domain', 'domainsFind')
        .bustCache('billing', 'depositGet')
        .ownerAccountId(owner)
        .execute({ domain: domain, transferData: transferData });
    }

    public updateDomain(domain, actingAs, dnsSecKeyAdd, dnsSecKeyRemove) {
        return this.domainClient.request('domainUpdate')
        .bustCache('domain', 'domainsFind')
        .execute(
            {
                actingAs: actingAs,
                dnsSecKeyAdd: dnsSecKeyAdd,
                dnsSecKeyRemove: dnsSecKeyRemove,
                domain: domain
            }
        );
    }

    public listContacts = (filter?, limit?, page?, sort?, config?, owner?) => {
        return this.domainClient.request('contactsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .timeout(config)
        .ownerAccountId(owner)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listContactsWithoutPagination = (filter?, limit?, page?, sort?, config?, owner?) => {
        return this.domainClient.request('contactsFind')
        .useCache()
        .cacheTimeout(1000 * 60 * 15)
        .timeout(config)
        .ownerAccountId(owner)
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listContactVerfications = (filter?, limit?, page?, sort?, config?) => {
        return this.domainClient.request('contactVerificationsFind')
        .timeout(config)
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public listContactVerficationsWithoutPagination = (filter?, limit?, page?, sort?, config?) => {
        return this.domainClient.request('contactVerificationsFind')
        .timeout(config)
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    };

    public domainStatus(domains, accountId?) {
        return this.domainClient.request('domainStatus')
        .useCache()
        .cacheTimeout(1000 * 60 * 5)
        .ownerAccountId(accountId)
        .skipPagination()
        .execute({ domainNames: domains });
    }

    public domainDetachFromBundle = (domainName: string) => {
        return this.domainClient.request('domainDetachFromBundle')
        .bustCache('domain', 'domainsFind')
        .execute({ domainName: domainName });
    };

    public createContact(handle, owner) {
        return this.domainClient.request('contactCreate')
        .bustCache('domain', 'contactsFind')
        .ownerAccountId(owner)
        .execute({ contact: handle });
    }

    public updateContact(handle, actingAs) {
        return this.domainClient.request('contactUpdate')
        .bustCache('domain', 'domainsFind')
        .bustCache('domain', 'contactsFind')
        .bustCache('domain', 'contactUsableFor')
        .bustCache('domain', 'contactSetUsableFor')
        .execute({ contact: handle, actingAs: actingAs });
    }

    public contactUsableFor(contactId, allocation, domainSuffixes, owner?) {
        return this.domainClient.request('contactUsableFor')
        .useCache()
        .cacheTimeout(1000 * 60 * 5)
        .ownerAccountId(owner)
        .execute({ contactId: contactId, allocation: allocation, domainSuffixes: domainSuffixes });
    }

    public contactSetUsableFor(contacts, domainSuffixes, useTrustee, owner) {
        return this.domainClient.request('contactSetUsableFor')
        .useCache()
        .cacheTimeout(1000 * 60 * 5)
        .ownerAccountId(owner)
        .execute({ contacts: contacts, domainSuffixes: domainSuffixes, useTrustee: useTrustee });
    }

    public deleteDomain(domainName, execDate) {
        return this.domainClient.request('domainDelete')
        .bustCache('domain', 'domainsFind')
        .execute(
            {
                domainName: domainName,
                execDate: (execDate === null) ? undefined : execDate
            }
        );
    }

    public cancelDelete(domainName) {
        return this.domainClient.request('domainDeletionCancel')
        .bustCache('domain', 'domainsFind')
        .execute({ domainName: domainName });
    }

    public withdrawDomain(domainName, disconnect, execDate) {
        return this.domainClient.request('domainWithdraw')
        .bustCache('domain', 'domainsFind')
        .execute({ domainName: domainName, disconnect: disconnect, execDate: execDate });
    }

    public restoreDomain(domainName) {
        return this.domainClient.request('domainRestore')
        .bustCache('domain', 'domainsFind')
        .bustCache('billing', 'depositGet')
        .execute({ domainName: domainName });
    }

    public listJobs(filter, limit, page, sort?) {
        return this.domainClient.request('jobsFind')
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public listJobsWithoutPagination(filter, limit, page, sort?) {
        return this.domainClient.request('jobsFind')
        .skipPagination()
        .execute({ filter: filter, limit: limit, page: page, sort: sort });
    }

    public domainCreateAuthInfo2(domainName, owner) {
        return this.domainClient.request('domainCreateAuthInfo2')
        .bustCache('billing', 'depositGet')
        .ownerAccountId(owner)
        .execute({ domainName: domainName });
    }

    public setTransferAck(domainName) {
        return this.domainClient.request('domainTransferOutAck')
        .bustCache('domain', 'domainsFind')
        .execute({ domainName: domainName });
    }

    public setTransferNack(domainName, reason) {
        return this.domainClient.request('domainTransferOutNack')
        .bustCache('domain', 'domainsFind')
        .execute({ domainName: domainName, reason: reason });
    }

    public statisticDomainCount(withSubAccounts, owner) {
        withSubAccounts = withSubAccounts || false;
        return this.domainClient.request('statisticDomainCount')
        .ownerAccountId(owner)
        .execute({ withSubAccounts: withSubAccounts });
    }

    public statisticDomainTurnover(start, end, withSubAccounts, owner) {
        withSubAccounts = withSubAccounts || false;
        return this.domainClient.request('statisticDomainTurnover')
        .ownerAccountId(owner)
        .execute({ start: start, end: end, withSubAccounts: withSubAccounts });
    }

    public statisticDomainPortfolio(withSubAccounts, owner) {
        withSubAccounts = withSubAccounts || false;
        return this.domainClient.request('statisticDomainPortfolio')
        .ownerAccountId(owner)
        .execute({ withSubAccounts: withSubAccounts });
    }

    public statisticJobs(withSubAccounts, start, end, owner) {
        withSubAccounts = withSubAccounts || false;
        return this.domainClient.request('statisticJobs')
        .ownerAccountId(owner)
        .execute({ withSubAccounts: withSubAccounts, start: start, end: end });
    }

    public statisticSubAccountDomainTurnover(start, end, minInflow, minOutflow, owner) {
        return this.domainClient.request('statisticSubAccountDomainTurnover')
        .ownerAccountId(owner)
        .execute({ start: start, end: end, minInflow: minInflow, minOutflow: minOutflow });
    }

    public whois(domain) {
        return this.domainClient.request('domainMemberWhois')
        .execute({ domainName: domain });
    }

    public domainChangeTag(domainName, tag) {
        return this.domainClient.request('domainChangeTag')
        .bustCache('domain', 'domainsFind')
        .execute({ domainName: domainName, tag: tag });
    }

    public domainMove = (domains, destinationAccountId, contactOperations) => {
        return this.domainClient.request('domainMove')
        .bustCache('domain', 'domainsFind')
        .execute(
            {
                contactOperations: contactOperations,
                destinationAccountId: destinationAccountId,
                domains: domains
            }
        );
    };

    public domainMoveQuery = (domains, destinationAccountId) => {
        return this.domainClient.request('domainMoveQuery')
        .bustCache('domain', 'domainsFind')
        .execute({ domains: domains, destinationAccountId: destinationAccountId });
    };

    public dnsSecKeysList(domainName: string) {
        return this.domainClient.request('dnsSecKeysList')
        .execute({ domainName: domainName });
    }

    public dnsSecKeyUpdate(
        domainName: string,
        add: Types.DomainApi.DnsSecData[],
        remove: Types.DomainApi.DnsSecData[]
    ) {
        return this.domainClient.request('dnsSecKeyModify')
        .execute({ domainName: domainName, add: add, remove: remove });
    }

    public jobResendEmail(jobId) {
        return this.domainClient.request('jobResendEmail')
        .execute({ jobId: jobId });
    }

    public resendVerificationEmail(contactVerificationId) {
        return this.domainClient.request('contactVerificationResendEmail')
        .execute({ contactVerificationId: contactVerificationId });
    }

    public domainUpdateIsIcannOwnerChange(domain) {
        return this.domainClient.request('domainUpdateIsIcannOwnerChange')
        .execute({ domain: domain });
    }

    public contactUpdateIsIcannOwnerChange(contact) {
        return this.domainClient.request('contactUpdateIsIcannOwnerChange')
        .execute({ contact: contact });
    }

    public contactUpdateIsRestrictedOwnerChange(contact) {
        return this.domainClient.request('contactUpdateIsRestrictedOwnerChange')
        .execute({ contact: contact });
    }

    public domainAttachToBundle(bundleId: string, domainName: string) {
        return this.domainClient.request('domainAttachToBundle')
        .bustCache('domain', 'domainsFind')
        .bustCache('bundle', 'bundlesFind')
        .execute({
            bundleId: bundleId,
            domainName: domainName
        });
    }

    public domainChangeBundle(domainName, newBundleMode, newBundleId) {
        return this.domainClient.request('domainChangeBundle')
        .bustCache('domain', 'domainsFind')
        .bustCache('billing', 'depositGet')
        .execute({ domainName: domainName, newBundleMode: newBundleMode, newBundleId: newBundleId });
    }
}
