import * as ng from 'angular';
import './input-2fa-separated.scss';

export class MoleculeInput2faSeparatedController implements ng.IController {
    public static $inject: string[] = ['$element', '$timeout'];

    public activeFieldIndex: number;
    public allowPassword = true;
    public componentInitialized = false;
    public isWaitingForDebounce = false;
    public length: number;
    public model: unknown[] = [];
    public usePassword = false;
    public value: string;

    private _keyEventDetected = false;
    private _realModel: string[] = [];
    private _domWatcherTimer: ng.IPromise<void> | ng.IPromise<any>;
    private _password = '';

    constructor(
        public $element: ng.IAugmentedJQuery,
        public $timeout: ng.ITimeoutService
    ) {}

    public $onInit(): void {
        /* Diese Stelle nicht verändern dies ist ein work-around damit tools
         * wie 1Password oder Lastpass die OTP Felder automatisch ausfüllen können
         * und wir die änderungen mitbekommen! #doNotTouch
         */
        for (let i = 0; i < this.length; i++) {
            this._realModel.push('');
            this.model.push({/* ignore empty object */});
            Object.defineProperty(
                this.model,
                i,
                {
                    get: () => this._realModel[i],
                    set: (newValue) => {
                        if (newValue.length === this.length) {
                            for (let index = 0; index < this.length; index++) {
                                this._realModel[index] = newValue;
                            }
                        } else {
                            this._realModel[i] = newValue;
                            // work-around for mobile browsers not triggering the key-events
                            void this.$timeout(() => {
                                if (newValue.length === 1 && !this._keyEventDetected && this.activeFieldIndex <= i) {
                                    this.increaseFieldIndex();
                                }
                            });
                        }
                        if (i === (this.length - 1)) {
                            this.updateModelValue();
                        }
                    }
                }
            );
        }

        // das Form darf erst angezeigt werden wenn das model initialisiert wurde!
        this.componentInitialized = true;
        this.activeFieldIndex = 0;

        // watcher to look out for DOM changes tools like 1Password use DOM manipulations to enter the code #doNotTouch
        this.lookoutForDomManipulations();
        // Autofocus (wrapped in timeout because it must wait for model)
        void this.$timeout(() => {
            this.$element[0]?.querySelector('input')?.focus();
        });
    }

    public $onDestroy() {
        // stop the DOM watcher when component is unloaded #doNotTouch
        this.$timeout.cancel(this._domWatcherTimer);
    }

    public lookoutForDomManipulations = (): void => {
        /* Diese Stelle nicht verändern dies ist ein work-around damit tools
         * wie 1Password oder Lastpass die OTP Felder automatisch ausfüllen können
         * und wir die änderungen mitbekommen! #doNotTouch
         */
        this._domWatcherTimer = this.$timeout(
            () => {
                for (let i = 0; i < this.length; i++) {
                    // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                    const currentElement = this.$element[0]?.querySelector(
                        `#securitytoken${i + 1}`
                    ) as HTMLInputElement;
                    if (this.model[i] !== currentElement?.value) {
                        this.model[i] = currentElement?.value;
                    }
                }
                this.lookoutForDomManipulations();
            },
            1000
        );
    };

    get password(): string {
        return this._password;
    }

    set password(value: string) {
        if (value.length === 12) {
            this.value = value;
        }

        this._password = value;
    }
    // Set the index.
    public setActiveFieldIndex = (index: number, focus = false): void => {
        this.activeFieldIndex = index;

        if (focus) {
            void this.$timeout(() => {
                this.focusElementNumber(this.activeFieldIndex);
            });
        }
    };

    // Select next field.
    public increaseFieldIndex = (): void => {
        if (this.activeFieldIndex < this.length) {
            ++this.activeFieldIndex;
            this.focusElementNumber(this.activeFieldIndex);
        }
    };

    // Select previous field.
    public decreaseFieldIndex = (): void => {
        if (this.activeFieldIndex > 0) {
            --this.activeFieldIndex;
            this.focusElementNumber(this.activeFieldIndex);
        }
    };

    public toggleUsePassword = (): void => {
        this.usePassword = !this.usePassword;
    };

    public focusElementNumber = (index: number): void => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        const elementToFocus = this.$element[0]?.querySelector(
            `input:nth-of-type(${index + 1})`
        ) as HTMLInputElement;
        elementToFocus?.focus();
    };

    // Clear the actively selected field.
    public clearFocusedField = (): void => {
        this.fillFieldWithChar('');
    };

    // Paste event.
    public onPaste = ($event: ClipboardEvent): void => {
        const pastedText = $event.clipboardData.getData('text/plain');

        if (/[0-9]+/.test(pastedText)) {
            this.resetForm(0, false);
            const newModel = pastedText.split('');

            while (newModel.length < this.length) {
                newModel.push('');
            }

            this.model = newModel.slice(0, this.length);
            this.isWaitingForDebounce = true;
            void this.$timeout(() => { this.isWaitingForDebounce = false; }, 300);
            this.updateModelValue();
        } else {
            this.resetForm(this.activeFieldIndex, true);
        }
    };

    public fillFieldWithChar = (char: string): void => {
        if (this.activeFieldIndex < this.length) {
            this.model[this.activeFieldIndex] = char;
        }
    };

    // Key event.
    public onKeyUp = ($event: KeyboardEvent): void => {
        // Skip key event while paste-debounce is active.
        if (this.isWaitingForDebounce === true) {
            return;
        }

        if (!['Return', 'Enter'].includes($event.key)) {
            $event.stopImmediatePropagation();
        }
        switch ($event.key) {
            case 'Backspace':
                this.clearFocusedField();
                this.decreaseFieldIndex();
                break;

            case 'Delete':
                this.clearFocusedField();
                this.increaseFieldIndex();
                break;

            case 'ArrowRight':
                this.increaseFieldIndex();
                break;

            case 'ArrowLeft':
                this.decreaseFieldIndex();
                break;

            case 'Esc': // (Edge) specific value
            case 'Escape': // "normal" browsers
                this.resetForm(0);
                break;

            default:
                if (
                    $event.key.length === 1 // Skip special keys
                    && $event.key !== ' ' // Skip space
                    && $event.ctrlKey === false // Skip ctrl-key (because of pasting)
                ) {
                    if (/[0-9]/.test($event.key)) {
                        this.fillFieldWithChar($event.key);
                        this.increaseFieldIndex();
                        this._keyEventDetected = true;
                    } else {
                        this.resetForm(this.activeFieldIndex, true);
                    }
                }
                this.updateModelValue();
        }
    };

    public resetForm = (focusElement: number, keepCurrentContent = false): void => {
        const backupModel = keepCurrentContent
            ? ng.copy(this.model)
            : new Array(this.length).fill('');

        this.model = [];

        void this.$timeout(() => {
            this.model = backupModel;
            this.setActiveFieldIndex(focusElement, true);
        });
    };

    public onChange = (): void => {
        this.updateModelValue();
    };

    private updateModelValue = (): void => {
        this.value = this.model.join('');
    };
}

export class MoleculeInput2faSeparatedComponent implements ng.IComponentOptions {
    public bindings = {
        allowPassword: '<?',
        usePassword: '=?',
        value: '=',
        length: '<'
    };
    public controller = MoleculeInput2faSeparatedController;
    public template = require('./input-2fa-separated.html');
}
