import ng from 'angular';
import { ProductHelperService } from '@/services/helpers';
import * as Types from '@/types';

export class SslComposerService {
    public static $inject: string[] = [
        '$translate',
        'productHelper'
    ];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private productHelper: ProductHelperService
    ) {}

    public buildSslApiObject = (
        metaData: Types.ViewTypes.ProductConfigSslObject
    ): PromiseLike<Types.ViewTypes.WizardComposerObject> => {
        if (metaData === undefined) {
            return Promise.reject({
                error: 'metaData is not defined'
            });
        }

        const composerObject: Types.ViewTypes.WizardComposerObject = {
            apiObject: {
                orderItem: {
                    items: undefined,
                    name: undefined,
                    productCode: undefined,
                    type: undefined
                },
                ownerAccountId: undefined
            },
            summaryObject: []
        };

        return this.productHelper.getProductFromProductCode(metaData.sslProductCode).then(
            () => {
                composerObject.productFamily = metaData.sslProductFamily;

                this._getSummaryObject(composerObject, metaData);
                this._getApiObject(composerObject, metaData);

                return composerObject;
            });
    };

    private _getSummaryObject(
        composerObject: Types.ViewTypes.WizardComposerObject,
        metaData: Types.ViewTypes.ProductConfigSslObject
    ): void {

        const sslProductName = metaData.sslProductCode;
        const mainFactSheets =  [];

        mainFactSheets.push({
            description: '',
            labelType: 'productCode',
            label: sslProductName,
            type: 'string',
            value: '1x'
        });

        if ([undefined, null, ''].indexOf(metaData.settings?.email) < 0) {
            mainFactSheets.push({
                description: '',
                labelType: 'translation',
                label: this.$translate.instant('bf139ed6-d397-4348-889a-90363f11dae0'),
                type: 'string',
                value: metaData.settings.email
            });
        }

        if (metaData.sslBillingCycle) {
            mainFactSheets.push({
                description: '',
                labelType: 'translation',
                label: this.$translate.instant('3ae628f1-02c5-4564-bfe5-06174b184462'),
                type: 'string',
                value: metaData.sslBillingCycle
            });
        }

        composerObject.summaryObject.push({
            account: metaData.account,
            factSheets: mainFactSheets,
            product: {
                name: metaData.sslProductCode,
                nameType: 'productCode',
                shortDescription: metaData.sslProductFamily
            },
            productCode: metaData.sslProductCode
        });

        // level1SanLimit
        metaData.additionalDomains = (metaData.additionalDomains || []).filter(
            (additionalDomain) => additionalDomain.length > 0
        );
        const productSpecification = ProductHelperService.wrapSpecificationItems(
            metaData.settings.productSpecification
        );
        const level1SanLimit = productSpecification.level1SanLimit;

        if (
            level1SanLimit !== undefined
            && metaData.additionalDomains.length > 0
            && metaData.additionalDomains[0].length > 0
        ) {
            const level1SanProductCode = productSpecification.level1SanProductCode;
            const level1SanProductCodeCycle = (level1SanProductCode.value as string).replace(
                '##BILLING_CYCLE##',
                metaData.sslBillingCycle
            );

            const level1SanProductName = (level1SanProductCode.value as string).replace(
                '##BILLING_CYCLE##',
                metaData.sslBillingCycle
            );

            composerObject.summaryObject.push({
                account: metaData.account,
                factSheets: [{
                    description: '',
                    labelType: 'productCode',
                    label: level1SanProductName,
                    type: 'string',
                    value: '1x'
                }],
                product: {
                    name: level1SanProductName,
                    nameType: 'productCode',
                    shortDescription: metaData.sslProductFamily
                },
                productCode: level1SanProductCodeCycle
            });
        }

        // level2SanLimit
        const maxAdditionalDomainNames = productSpecification.maxAdditionalDomainNames;
        const level2SanLimit = productSpecification.level2SanLimit;

        if (maxAdditionalDomainNames !== undefined
            && metaData.additionalDomains.length > level2SanLimit.intValue
            && metaData.additionalDomains.length <= maxAdditionalDomainNames.intValue
        ) {

            const level2SanLimitMultiplier = metaData.additionalDomains.length - level2SanLimit.intValue;
            const level2SanProductCode = productSpecification.level2SanProductCode;
            const level2SanProductCodeCycle = (level2SanProductCode.value as string).replace(
                '##BILLING_CYCLE##',
                metaData.sslBillingCycle
            );

            const level2SanProductName = (level2SanProductCode.value as string).replace(
                '##BILLING_CYCLE##',
                metaData.sslBillingCycle
            );

            composerObject.summaryObject.push({
                account: metaData.account,
                factSheets: [{
                    description: metaData.additionalDomains,
                    labelType: 'productCode',
                    label: level2SanProductName,
                    type: 'integer',
                    value: `${level2SanLimitMultiplier}x`
                }],
                product: {
                    name: level2SanProductName,
                    nameType: 'productCode',
                    shortDescription: metaData.sslProductFamily
                },
                productCode: level2SanProductCodeCycle,
                priceMultiplication: level2SanLimitMultiplier
            });
        }
    }

    private _getApiObject(
        composerObject: Types.ViewTypes.WizardComposerObject,
        metaData: Types.ViewTypes.ProductConfigSslObject
    ): void {
        // Das ist nicht das korrekte composer apiObject (Types.ViewTypes.WizardComposerObject) !!!
        // Wird von mir erst einmal nicht angepackt!
        composerObject.apiObject = {
            ssl: {
                order: {
                    additionalDomainNames: metaData.additionalDomains,
                    adminContact: {
                        emailAddress: metaData.contacts.administrationContact.fields.emailAddress.value,
                        firstName: metaData.contacts.administrationContact.fields.firstName.value,
                        lastName: metaData.contacts.administrationContact.fields.lastName.value,
                        phoneNumber: metaData.contacts.administrationContact.fields.phoneNumber.value,
                        title: metaData.contacts.administrationContact.fields.title.value
                    },
                    approverEmailAddress: metaData.settings.email,
                    autoRenew: false,
                    csr: metaData.sslValidationObject.csrRequestEncoded,
                    organization: {
                        organizationName: metaData.contacts.organizationContact.fields.organizationName.value,
                        city: metaData.contacts.organizationContact.fields.city.value,
                        region: metaData.contacts.organizationContact.fields.region.value,
                        country: metaData.contacts.organizationContact.fields.country.value,
                        addressLine1: metaData.contacts.organizationContact.fields.addressLine1.value,
                        addressLine2: metaData.contacts.organizationContact.fields.addressLine2.value,
                        postalCode: metaData.contacts.organizationContact.fields.postalCode.value,
                        phoneNumber: metaData.contacts.organizationContact.fields.phoneNumber.value,
                        faxNumber: metaData.contacts.organizationContact.fields.faxNumber.value
                    },
                    productCode: metaData.sslProductCode,
                    techContact: {
                        emailAddress: metaData.contacts.technicalContact.fields.emailAddress.value,
                        firstName: metaData.contacts.technicalContact.fields.firstName.value,
                        lastName: metaData.contacts.technicalContact.fields.lastName.value,
                        phoneNumber: metaData.contacts.technicalContact.fields.phoneNumber.value,
                        title: metaData.contacts.technicalContact.fields.title.value
                    },
                    type: (
                        metaData.sslProductFamily === 'dv-certificates'
                            ? 'DomainValidatedCertificateOrder'
                            : 'OrganizationValidatedCertificateOrder'
                    ),
                    validationType: (metaData.settings.validationMethod === 'auto' ? 'auto' : 'validateViaEmail')
                },
                ownerAccountId: metaData.account.id
            }
        };
    }
}
// tslint:enable: max-line-length
