import * as ng from 'angular';
import { LinkListItem } from '../../links/link-list/link-list';

export class MoleculePanelMachineDashboardController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public machineItem;
    public machineStatus = 'creating';
    public serverRessorce = null;
    public linkListFooter: LinkListItem[] = [];
    public OrganismDashboardOverview;
    public panelHeader = {
        includeShowDetailsLink: false,
        panelHeaderRoute: 'machine.virtualmachines.id.edit',
        panelHeaderRouteParams: {}
    };

    constructor(
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this.panelHeader.includeShowDetailsLink = true;
        this.panelHeader.panelHeaderRouteParams = {
            machineId: this.machineItem.id
        };
        this.linkListFooter = [];
    }
}

export class MoleculePanelMachineDashboardComponent implements ng.IComponentOptions {
    public bindings = {
        linkListBottom: '<',
        linkListTop: '<',
        machineItem: '<'
    };
    public controller =  MoleculePanelMachineDashboardController;
    public template = require('./panel-machine-dashboard.html');
}
