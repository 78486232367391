import * as ng from 'angular';
import { MachineComposerService } from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganismMachineWizardWrapperController {
    public static $inject: string[] = ['$timeout', 'machineComposer'];

    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string; // used in template
    public productFamily: string;
    public account: Types.AccountApi.Account;
    public productCode: string;

    public wizardComposerObject: any = {
        apiObject: {},
        summaryObject: []
    };
    public viewType = 'config';

    constructor(
        private $timeout: ng.ITimeoutService,
        private machineComposer: MachineComposerService
    ) {}

    public loadSummaryView = (metadata: Types.ViewTypes.ProductConfigMachineObjectGeneric) => { // used in template
        this.wizardComposerObject.summaryObject = [];
        switch (metadata.productFamily) {
            case 'individual-virtual-machines':
                this.machineComposer
                    .buildIndividualMachineApiObject(metadata as Types.ViewTypes.ProductConfigMachineIndividualObject)
                    .then((composerObject) => {
                        this.$timeout(() => {
                            this.wizardComposerObject = composerObject;
                            this.viewType = 'summary';
                        });
                    });
                break;
            default:
                this.machineComposer
                    .buildMachineApiObject(metadata as Types.ViewTypes.ProductConfigMachineObject)
                    .then((composerObject) => {
                        this.$timeout(() => {
                            this.wizardComposerObject = composerObject;
                            this.viewType = 'summary';
                        });
                    });
                break;
        }
    };

    public loadConfirmView = () => { // used in template
        this.viewType = 'confirm';
    };
}

export class OrganismMachineWizardWrapperComponent implements ng.IComponentOptions {
    public bindings = {
        outerAccount: '<?account',
        outerProductCode: '<?productCode'
    };
    public template = require('./machine-wizard-wrapper.html');
    public controller = OrganismMachineWizardWrapperController;
}
