import ng from 'angular';

import q from 'q';
import { WizardHelperService, WizardObject } from '../';
import { MoleculeMenuMainController } from '../../';
import {
    DatabaseAccessLevelsAllRightsConst,
    VhostDefaultLocationsObjectConst
} from '../../../../configuration';
import {
    AccountModelService,
    AlertManagerService,
    ApiObject,
    AuthContextService,
    BundleModelService,
    DatabaseModelService,
    DatabaseUserModelService,
    DnsZoneModelService,
    DomainRegistrationHelperService,
    InstallerModelService,
    MailboxModelService,
    OrchestratorModelService,
    OrganizationModelService,
    SslOrderModelService,
    VhostModelService,
    WebhostingHelperService,
    WebhostingUserModelService,
    WebspaceModelService
} from '../../../../services';
import * as Types from '../../../../types';
import {
    WizardServiceHelperDomain,
    WizardServiceHelperRedirection,
    WizardServiceHelperWebhosting
} from './service-helpers';

export class MoleculeWizardServiceObjectContainerController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'accountModel',
        'alertManager',
        'bundleModel',
        'databaseModel',
        'databaseUserModel',
        'dnsZoneModel',
        'domainRegistrationHelper',
        'installerModel',
        'mailboxModel',
        'orchestratorModel',
        'organizationModel',
        'sslOrderModel',
        'vhostModel',
        'webhostingHelper',
        'webhostingUserModel',
        'webspaceModel',
        'wizardHelper',
        'wizardServiceHelperDomain',
        'wizardServiceHelperRedirection',
        'wizardServiceHelperWebhosting'
    ];

    public serviceObject: WizardObject;
    public subAccountDropdownItems: any[] = [];
    public disableAccountSelection = false;

    private $formMolecule: ng.IController;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private accountModel: AccountModelService,
        private alertManager: AlertManagerService,
        private bundleModel: BundleModelService,
        private databaseModel: DatabaseModelService,
        private databaseUserModel: DatabaseUserModelService,
        private dnsZoneModel: DnsZoneModelService,
        private domainRegistrationHelper: DomainRegistrationHelperService,
        private installerModel: InstallerModelService,
        private mailboxModel: MailboxModelService,
        private orchestratorModel: OrchestratorModelService,
        private organizationModel: OrganizationModelService,
        private sslOrderModel: SslOrderModelService,
        private vhostModel: VhostModelService,
        private webhostingHelper: WebhostingHelperService,
        private webhostingUserModel: WebhostingUserModelService,
        private webspaceModel: WebspaceModelService,
        private wizardHelper: WizardHelperService,
        private wizardServiceHelperDomain: WizardServiceHelperDomain,
        private wizardServiceHelperRedirection: WizardServiceHelperRedirection,
        private wizardServiceHelperWebhosting: WizardServiceHelperWebhosting
    ) {}

    public $onInit() {
        if ([undefined, null].indexOf(this.serviceObject.children) >= 0) {
            this.serviceObject.children = [];
        }

        if ([undefined, null].indexOf(this.serviceObject.display) >= 0) {
            this.serviceObject.display = {};
        }

        if ([undefined, null].indexOf(this.serviceObject.display.inBundle) >= 0) {
            this.serviceObject.display.inBundle = false;
        }

        if ([undefined, null].indexOf(this.serviceObject.display.sufficientCreditAvailable) >= 0) {
            this.serviceObject.display.sufficientCreditAvailable = false;
        }

        if ([undefined, null].indexOf(this.serviceObject.display.contactDataComplete) >= 0) {
            this.serviceObject.display.contactDataComplete = false;
        }

        if ([undefined, null].indexOf(this.serviceObject.settings) >= 0) {
            this.serviceObject.settings = {};
            this.wizardHelper.resetSelectedProductObject(this.serviceObject);
        }
        this.serviceObject.settings.ownAccountId = AuthContextService.account.id;
        if ([undefined, null, ''].indexOf(this.serviceObject.settings.accountId) >= 0) {
            this.serviceObject.settings.accountId = ng.copy(this.serviceObject.settings.ownAccountId);
        }
        this.onAccountChange();

        if (this.serviceObject.settings.inWebspace) {
            this.disableAccountSelection = true;
        }
        if (this.serviceObject.display.inBundle !== false) {
            this.serviceObject.settings.accountId = this.serviceObject.display.inBundle.accountId;
            this.disableAccountSelection = true;
        }
        this.$formMolecule.saveCallback = this.completeServiceObject;

        if ([undefined, null].indexOf(this.serviceObject.settings.bundle) === -1) {
            this.serviceObject.settings.bundleHandler = ApiObject.bundle(this.serviceObject.settings.bundle);
        }
    }

    public get showAccountSelection() {
        if (this.serviceObject.display !== undefined
            && this.serviceObject.display.createInWebspace
        ) {
            return false;
        }

        return this.subAccountDropdownItems.length > 0;
    }

    public get isValid() {
        return this.$formMolecule.$valid;
    }

    public completeServiceObject = () => {
        this.serviceObject.installFunction = this.installObject;
        this.serviceObject.active = false;
        this.serviceObject.completed = true;
    };

    public onAccountChange = () => {
        this.$timeout(() => {
            this.wizardHelper.resetSelectedProductObject(this.serviceObject);
            if (this.serviceObject.settings.accountId === AuthContextService.account.id) {
                this.serviceObject.settings.account = AuthContextService.account;
                this.$formMolecule.accountChanged();
            } else {
                this.accountModel.findOne(this.serviceObject.settings.accountId).then((result) => {
                    this.serviceObject.settings.account = result;
                    this.$formMolecule.accountChanged();
                });
            }
        });
    };

    public installObject = () => {
        switch (this.serviceObject.service) {
            case 'bundle': return this.orderBundle();
            case 'domain': return this.orderDomain();
            case 'email': return this.installMailbox();
            case 'machine': return this.installVirtualMachine();
            case 'redirection': return this.wizardServiceHelperRedirection.orderRedirection(
                this.serviceObject.settings
            );
            case 'ssl': return this.orderCertificate();
            case 'database': return this.orderDatabase();
            case 'vhost': return this.orderVhost();
            case 'webhosting': return this.orderWebHosting();

            default: throw new Error(`Callback for creating new objects in service
                '${this.serviceObject.service}' not yet implemented.`);
        }
    };

    public get showProductSelectBox() {
        return ['domain', 'dns', 'vhost', 'redirection'].indexOf(this.serviceObject.service) === -1;
    }

    public get showProductSettingsDialog() {
        // If not enought credit is available
        if (this.serviceObject.service !== 'domain' && !this.serviceObject.display.sufficientCreditAvailable) {
            return false;
        }

        // On the following services, show product settings ever - not product selection is required
        if (!this.showProductSelectBox) {
            return true;
        }
        // if new product (inc. price , cycle billing) is selected, do not show product settings
        if ([undefined, null].indexOf(this.serviceObject.selectedProduct) >= 0
            || [undefined, null].indexOf(this.serviceObject.selectedProduct.selectedPrice) >= 0) {
            return false;
        }

        // if no contingents are available show product settings
        if ([undefined, null].indexOf(this.serviceObject.display.availableContingentItems) === -1
            && this.serviceObject.display.availableContingentItems.length === 0) {
            return true;
        }

        // if order in machine and contingent is selected
        if (this.serviceObject.display.inMachine) {
            return true;
        }

        // if contingents are available, user must select a contingent, before product settings is viewable
        if ([undefined, null].indexOf(this.serviceObject.display.availableContingentItems) === -1
            && this.serviceObject.display.availableContingentItems.length > 0) {
            return [undefined].indexOf(this.serviceObject.settings.selectedContingent) === -1;
        }
    }

    // wird die Methode eigentlich irgentwo verwendet???
    public createZone = (domain: string) => {
        const records = this.serviceObject.settings.nameserverType === 'quick' ? this.getRecords(domain) : [];

        return this.dnsZoneModel.createNative(
            domain,
            records,
            {},
            this.serviceObject.settings.nameservers.id,
            'NATIVE',
            {},
            this.serviceObject.settings.account
        )
        .then(
            (res) => {
                if (res.status === 'error') {
                    return {status: 'error', response: res.err};
                }

                return {status: 'success', response: res};
            },
            (err) => ({status: 'error', response: err})
        );
    };

    private orderBundle = () => {
        const webspace = {
            accountId: this.serviceObject.settings.accountId,
            bundleId: null,
            name: this.serviceObject.settings.name,
            productCode: this._getBundleWebspaceProductCode(),
            storageQuota: this._getBundleWebspaceStorageQuota(),
            type: ''
        };

        const userInfo = {
            ftpAccess: this.serviceObject.display.newAccessObject.ftpAccess
                || this.serviceObject.display.newAccessObject.ftpLimited,
            homeDir: this.serviceObject.display.newAccessObject.ftpLimited
                ? (
                    this.serviceObject.display.newAccessObject.homeDirPrefix
                    + this.serviceObject.display.newAccessObject.homeDir
                )
                : undefined,
            sshAccess: this.serviceObject.display.newAccessObject.sshAccess,
            statsAccess: this.serviceObject.display.newAccessObject.statsAccess,
            userId: (this.serviceObject.display.accessType === '1')
                ? this.serviceObject.settings.choosenAccessId
                : null
        };

        return this.bundleModel.create(
            {
                accountId: this.serviceObject.settings.accountId,
                name: this.serviceObject.settings.name,
                productCode: this.serviceObject.selectedProduct.selectedPrice.productCode,
                purchasedContingentExtensions: []
            },
            this.serviceObject.settings.accountId
        )
        .then(
            (bundle): any => {
                webspace.bundleId = bundle.id;

                if (this.serviceObject.display.accessType === '1') {
                    return {
                        id: this.serviceObject.settings.choosenAccessId,
                        status: 'success'
                    };
                } else {
                    const newUser: Types.WebhostingApi.User = {
                        accountId: this.serviceObject.settings.accountId,
                        name: this.serviceObject.display.newWebspaceUserObject.name,
                        sshKey: this.serviceObject.display.newWebspaceUserObject.sshKey
                    };

                    return this.webhostingUserModel.createUser(
                        newUser,
                        this.serviceObject.display.newWebspaceUserObject.password,
                        this.serviceObject.settings.accountId
                    );
                }
            }
        )
        .then(
            (res) => ({ status: res.status, response: res }),
            (err) => {
                if (
                    [undefined, null, ''].indexOf(err.message) < 0
                    && err.message.toLowerCase().search('bundle') !== -1
                ) {
                    return err;
                }
                return {status: 'error', response: err};
            }
        )
        .then(
            (res) => {
                if (res.status === 'error') {
                    return this._createWebspace(
                        webspace,
                        []
                    )
                    .then(() => ({ status: 'error', response: res }));
                } else {
                    userInfo.userId = res.response.id;

                    return this._createWebspace(
                        webspace,
                        [userInfo]
                    );
                }
            },
            (err) => ({status: 'error', response: err})
        );
    };

    private orderCertificate = () => {
        const validationMapping = {
            'dv-certificates': 'DomainValidatedCertificateOrder',
            'ev-certificates': 'OrganizationValidatedCertificateOrder',
            'ov-certificates': 'OrganizationValidatedCertificateOrder'
        };

        const orderData = {
            additionalDomainNames: [undefined, null].indexOf(this.serviceObject.settings.alternativeNames) >= 0
                ? []
                : this.serviceObject.settings.alternativeNames,
            adminContact: this.serviceObject.settings.contacts.adminContact,
            approverEmailAddress: (this.serviceObject.selectedProduct.family === 'dv-certificates')
                ? this.serviceObject.settings.email
                : undefined,

            autoRenew: false,
            csr: this.serviceObject.settings.csrKey,
            organization: (this.serviceObject.selectedProduct.family === 'dv-certificates')
                ? ''
                : this.serviceObject.settings.contacts.organizationContact,

            productCode: this.serviceObject.selectedProduct.selectedPrice.productCode,
            techContact: this.serviceObject.settings.contacts.techContact,
            type: validationMapping[this.serviceObject.selectedProduct.family],
            validationType: (this.serviceObject.selectedProduct.family === 'dv-certificates')
                ? 'validateViaEmail'
                : 'validateViaDns'
        };

        if (
            [undefined, null, ''].indexOf(this.serviceObject.settings.sslValidationMethod) < 0
            && this.serviceObject.settings.sslValidationMethod === 'auto'
        ) {
            orderData.validationType = 'auto';
        }

        return this.sslOrderModel.create(orderData, this.serviceObject.settings.accountId)
        .then(
            (res) => {
                if (res.status === 'error') {
                    return {status: 'error', type: 'ssl-certificate', response: res.err};
                }
                // Need to cheat here because you don't get an actual certificate object back...
                return {status: 'success', type: 'ssl-certificate', response: orderData};
            },
            (err) => ({status: 'error', type: 'ssl-certificate', response: err})
        );
    };

    private orderDatabase = () => {
        const wizardData = this.serviceObject;

        if ([null, undefined].indexOf(wizardData.settings.choosenAccessId) === -1
            && [null, undefined].indexOf(wizardData.settings.choosenAccessId.value) === -1) {
            wizardData.settings.choosenAccessId = wizardData.settings.choosenAccessId.value;
        }

        const access = {
            accessLevel: [],
            userId: wizardData.settings.choosenAccessId || null
        };

        Object.keys(wizardData.display.newAccessObject).map(
            (key) => {
                if (DatabaseAccessLevelsAllRightsConst.indexOf(key) >= 0 && wizardData.display.newAccessObject[key] === true) {
                    access.accessLevel.push(key);
                }
            }
        );

        if (wizardData.display.accessType === '1') {
            return this._createDatabase(
                wizardData,
                [
                    {
                        userId: access.userId,
                        // tslint:disable-next-line:object-literal-sort-keys
                        accessLevel: access.accessLevel,
                        accountId: wizardData.settings.accountId
                    }
                ]
            );
        } else {
            const newUser = {
                accountId: wizardData.display.newDatabaseUserObject.accountId,
                comments: wizardData.display.newDatabaseUserObject.comment,
                dbUserName: null,
                name: wizardData.display.newDatabaseUserObject.name,
                password: wizardData.display.newDatabaseUserObject.password
            };

            let targetAccountId = AuthContextService.account.id;
            if ([undefined, null, ''].indexOf(wizardData.settings.accountId) < 0) {
                targetAccountId = wizardData.settings.accountId;
            }

            return this.databaseUserModel.createUser(
                {
                    name: newUser.name,
                    dbUserName: newUser.dbUserName,
                    accountId: newUser.accountId,
                    comments: newUser.comments,
                    password: newUser.password
                },
                newUser.password,
                targetAccountId
            )
            .then(
                (result) => {
                    wizardData.display.choosenAccessId = result.id;
                    // tslint:disable-next-line:max-line-length
                    return this._createDatabase(wizardData, [{userId: result.id, accessLevel: access.accessLevel}]);
                }
            );
        }
    };

    private _createDatabase = (data, accesses) => {
        const databaseData = data.settings.database;
        let poolId;
        let resourceId;

        const contingent = typeof data.settings.selectedContingent !== 'string'
            ? data.settings.selectedContingent
            : data.display.contingent || null;

        if ([undefined, null].indexOf(contingent) < 0) {
            // set bundleId, poolId, resourceId
            if (contingent.type === 'bundle') {
                databaseData.bundleId = contingent.value;
            } else {
                poolId = contingent.poolId;
                resourceId = contingent.value;
            }
        }

        databaseData.accesses = accesses || [];
        databaseData.productCode = data.selectedProduct.selectedPrice.productCode;

        if ([undefined, null, ''].indexOf(data.settings.accountId) < 0) {
            databaseData.accountId = data.settings.accountId;
        }

        return this.databaseModel.create(
            databaseData,
            null,
            poolId,
            resourceId
        )
        .then(
            (res) => {
                if (res.status === 'error') {
                    return {status: 'error', type: 'database', response: res.err};

                }
                return {status: 'success', type: 'database', response: res};
            },
            (err) => ({status: 'error', type: 'database', response: err})
        );
    };

    private installVirtualMachine = () => {
        if (this.serviceObject.selectedProduct.family === 'ecommerce-virtual-machines') {
            return this.orchestratorModel.installWizardObject(this.serviceObject);
        }

        const virtualMachine: any = {
            name: this.serviceObject.settings.name,
            productCode: this.serviceObject.selectedProduct.selectedPrice.productCode
        };

        const data: any = {
            poolId: null,
            poolIdForCreatedResources: null,
            virtualMachineHostId: null
        };

        let promise: q.IPromise<any>;

        if (this.serviceObject.selectedProduct.family === 'managed-virtual-machines') {
            promise = this.installerModel.installManagedServer(
                virtualMachine,
                data,
                this.serviceObject.settings.accountId
            );
        } else {
            virtualMachine.hostName = this.serviceObject.settings.hostName;

            let osId = '';
            let osData;

            if (this.vmHasOperatingSystem()) {
                osId = this.serviceObject.settings.selectedOs;
                osData = {
                    rootUserKeys: this.serviceObject.settings.publicKey,
                    rootUserPass: this.serviceObject.settings.password
                };

                if ([undefined, null, ''].indexOf(this.serviceObject.settings.extraUserName) < 0) {
                    ['Keys', 'Name', 'Pass'].forEach(
                        (part) => osData[`extraUser${part}`] = this.serviceObject.settings[`extraUser${part}`]
                    );
                }
            }

            promise = this.installerModel.installServer(
                virtualMachine,
                data,
                osId,
                osData,
                this.serviceObject.settings.accountId
            );
        }

        return promise.then(
            (res) => {
                if (res.status === 'error') {
                    return { status: 'error', type: 'machine', response: res.err };
                }
                if (this.serviceObject.selectedProduct.family === 'managed-virtual-machines') {
                    // reload menu to add managed server entry
                    MoleculeMenuMainController.triggerRefresh('ManagedServer');
                }
                return { status: 'success', type: 'machine', response: res.response };
            },
            (err) => ({ status: 'error', type: 'machine', response: err })
        );
    };

    private installMailbox = () => {
        let poolId: string;
        let object = null;
        const apiObject: any = {
            emailAddress: this.serviceObject.settings.emailAddress,
            productCode: this.serviceObject.selectedProduct.selectedPrice.productCode,
            spamFilter: this.serviceObject.settings.spamFilter
        };

        if ([undefined, null].indexOf(this.serviceObject.settings.selectedContingent) === -1) {
            // set bundleId, poolId, resourceId
            object = this.wizardHelper.getContingentIds(this.serviceObject);
            if (object && object.type && object.contingentId) {
                if (object.type === 'bundle') {
                    apiObject.bundleId = object.contingentId;
                } else {
                    poolId = object.contingentId;
                }
            }
        }
        // Set AccountId when creating the object for a subaccount
        apiObject.accountId = AuthContextService.account.id !== this.serviceObject.settings.accountId
            ? this.serviceObject.settings.accountId
            : undefined;

        switch (this.serviceObject.selectedProduct.family) {
            default: break;

            case 'imap-mailboxes':
                apiObject.storageQuota = this.serviceObject.settings.storageQuota;
                apiObject.type = 'ImapMailbox';
                apiObject.forwarderTargets = this.serviceObject.settings.forwarderTargets;
                apiObject.isAdmin = this.serviceObject.settings.isAdmin;
                break;

            case 'catchalls':
                apiObject.emailAddress = this.serviceObject.settings.catchallDomain;
                apiObject.type = 'catchall';
                apiObject.forwarderTarget = this.serviceObject.settings.forwarderTarget;
                break;

            case 'msexchange-mailboxes':
                apiObject.type = 'ExchangeMailbox';
                apiObject.lastName = this.serviceObject.settings.lastName;
                apiObject.firstName = this.serviceObject.settings.firstName;
                apiObject.storageQuota = this.serviceObject.settings.storageQuota;
                apiObject.storageQuota = 10240;
                break;

            case 'mailing-lists':
                apiObject.type = 'MailingList';
                apiObject.name = this.serviceObject.settings.name;
                apiObject.subjectPrefix = this.serviceObject.settings.subjectPrefix;
                apiObject.accessMode = this.serviceObject.settings.accessMode;
                apiObject.replyToMode = this.serviceObject.settings.replyToMode;
                apiObject.replyToEmailAddress = this.serviceObject.settings.replyToEmailAddress;
                apiObject.allowHtmlMails = this.serviceObject.settings.allowHtmlMails;
                apiObject.owners = this.serviceObject.settings.owners;
                apiObject.members = this.serviceObject.settings.members;
                break;

            case 'external-forwarders':
                apiObject.type = 'Forwarder';
                apiObject.forwarderTargets = this.serviceObject.settings.forwarderTargets;
                break;

            case 'smtp-forwarders':
                apiObject.type = 'SmtpForwarder';
                apiObject.server = this.serviceObject.settings.server;
                apiObject.port = [undefined, null, '', 0].indexOf(this.serviceObject.settings.port) < 0
                    ? this.serviceObject.settings.port
                    : undefined;
                break;
        }

        let promise: q.IPromise<any> = q.when(true);

        if (this.serviceObject.selectedProduct.family === 'msexchange-mailboxes') {
            if (this.serviceObject.settings.organizationId === null) {
                const organization = {
                    accountId: apiObject.accountId,
                    comment: this.serviceObject.settings.organization.comment,
                    name: this.serviceObject.settings.organization.name
                };

                promise = this.organizationModel.create(organization, undefined)
                .then((org) => apiObject.organizationId = org.id);
            } else {
                apiObject.organizationId = this.serviceObject.settings.organizationId;
            }
        }

        return promise
        .then(
            () => this.mailboxModel.createMailbox(
                apiObject,
                this.serviceObject.settings.password,
                undefined,
                poolId,
                this.serviceObject.settings.setAutoConfigureDns,
                this.serviceObject.settings.skipDnsCheck
            )
        )
        .then(
            (res) => {
                if (res.status === 'error') {
                    return {status: 'error', type: 'email', response: res.err};
                } else {
                    this.alertManager.success(/* translationId */ 'TR_050719-43d4e7_TR');
                }

                if (res.warnings !== undefined
                    || (Array.isArray(res.warnings) && res.warnings.length > 0)
                ) {
                    res.warnings.forEach(
                        (warning: Types.keenRobotsCommon.ErrorOrWarning) => this.alertManager.warning(warning.text)
                    );
                }

                return {status: 'success', type: 'email', response: res.response};
            },
            (err) => ({status: 'error', type: 'email', response: err})
        );
    };

    private _createDomain = () => {
        const wizardSettings    = this.serviceObject.settings;
        const contacts          = this.wizardServiceHelperDomain.buildRequestContactObject(wizardSettings);
        let dns                 = wizardSettings.dns;
        const owner             = wizardSettings.accountId;
        const bundleId          = this.wizardServiceHelperDomain.getBundleId(wizardSettings);
        const nameservers       = this.domainRegistrationHelper.createNameserverList(wizardSettings.customNameservers);
        const domainQty         = this.serviceObject.children.length;
        const doNotObserveVhost = this.serviceObject.display.doNotObserveVhost !== undefined
            ? this.serviceObject.display.doNotObserveVhost
            : false;
        const promises = this.serviceObject.children.map((domain) => {
            const domainObj = {
                bundleId: domain.inBundle ? bundleId : null,
                // tslint:disable-next-line:object-literal-shorthand
                contacts: contacts,
                name: domain.domainName,
                nameserverSetId: null,
                // tslint:disable-next-line:object-literal-shorthand
                nameservers: nameservers,
                transferLockEnabled: true
            };
            // set DnsSettings
            dns = this.wizardServiceHelperDomain.setDnsSettings(wizardSettings);
            if ([undefined, null].indexOf(wizardSettings.nameservers) < 0
                && wizardSettings.nameservers.nameservers === null
            ) {
                // register without zone create
                wizardSettings.nameservers.nameservers = wizardSettings.customNameservers.filter(
                    (nameserver) => ({ name: nameserver.name, ips: nameserver.ips})
                );
                return this._doRegisterDomain(domain, false, domainObj, null, owner);
            } else if (!this.wizardServiceHelperDomain.hasOwnNameservers(wizardSettings)) {
                // register with zone create/update
                return this.wizardServiceHelperDomain.getZoneStatus(domain)
                .then(
                    (zoneStatus) => {
                        this._setNameservers(wizardSettings, domain, domainObj);

                        if (zoneStatus === 'notSet') {
                            return this._doRegisterDomain(domain, false, domainObj, dns, owner, wizardSettings);
                        } else {
                            const updateZone = wizardSettings.domainZoneLists.withZones.some(
                                (domainZoneName: string) => domainZoneName === domainObj.name
                            );

                            if (updateZone && dns !== null) {
                                this.wizardServiceHelperDomain.updateZone(domainObj);
                                // toDo? reset dns object, because zone doesnt create, because it was updated??
                            }

                            return this._doRegisterDomain(domain, false, domainObj, dns, owner, wizardSettings);
                        }
                    }
                );
            } else {
                domainObj.nameservers = this.domainRegistrationHelper.createNameserverList(
                    wizardSettings.customNameservers
                );
                return this.wizardServiceHelperDomain.doRegister(domain, true, domainObj, dns, owner);
            }
        });

        const vhostPromises = [];
        let domainResults = [];

        return q.all(promises)
        .then(
            (domains) => {
                const vhost = [] ;
                const defaultLocation = [];
                let vhostCreatedAsWebsite = false;

                domainResults = domains;
                domainResults.map(
                    (domain, index) => {
                        const domainVhost = domain.response.response !== undefined
                            ? domain.response.response
                            : domain.response;

                        const vhostManage = doNotObserveVhost
                            ? -1
                            : this.wizardServiceHelperWebhosting.vhostOfDomainRequired(
                                this.serviceObject,
                                domainVhost,
                                wizardSettings.vhost
                              );

                        switch (vhostManage) {
                            case -1:
                                // do not observe vHost
                                vhostPromises.push(q.resolve(domainVhost));
                                break;
                            case 0:
                                // create new vhost
                                vhost[index] = ng.copy(wizardSettings.vhost);
                                if (domainResults.length > 1) {
                                    vhost[index].domainName = ng.copy(domainVhost.name);
                                    vhost[index].domainNameUnicode = ng.copy(domainVhost.nameUnicode);
                                    vhost[index].webRoot = ng.copy(domainVhost.nameUnicode);
                                }

                                defaultLocation[index] = ng.copy(
                                    this.wizardServiceHelperWebhosting.getDefaultLocation(vhost[index])
                                );

                                if (defaultLocation[index].locationType === 'generic') {
                                    if (!vhostCreatedAsWebsite) {
                                        // first vhost create as redirection
                                        vhostCreatedAsWebsite = true;
                                    } else {
                                        // all other vhost create as redirection
                                        defaultLocation[index].locationType = 'redirect';
                                        defaultLocation[index].redirectionStatus = '302';
                                        defaultLocation[index].redirectionUrl = 'http://' + ng.copy(
                                            this.serviceObject.settings.vhost.domainNameUnicode
                                        );

                                        vhost[index].profile = null;
                                    }
                                }

                                vhost[index].locations = vhost[index].locations.map(
                                    (location) => {
                                        if (location.matchType === 'default') {
                                            return defaultLocation[index];
                                        }

                                        return location;
                                    }
                                );

                                vhostPromises.push(this.orderVhost(ng.copy(vhost[index]), null, true));
                                break;
                            case 1:
                                // update vHost - set enableAlias = true;
                                const filters: any = {
                                    subFilter: [
                                        {field: 'vhostDomainNameUnicode', value: domainVhost.nameUnicode},
                                        {field: 'vhostDomainNameUnicode', value: 'www.' + domainVhost.nameUnicode}
                                    ],
                                    subFilterConnective: 'OR'
                                };
                                this.vhostModel.list(
                                    1,
                                    1,
                                    filters
                                ).then((vhostRes) => {
                                    vhost[index] = vhostRes.data[0];
                                    vhost[index].enableAlias = true;
                                    vhostPromises.push(this.updateVhost(vhost[index]));
                                });
                                break;
                            default:
                                // nothing to do here
                        }
                    }
                );

                return q.all(vhostPromises);
            }
        )
        .then(
            (vhostResponses) => {
                const responseError = vhostResponses.some((r) => r.status === 'error');

                if (responseError) {
                    return {status: 'error', type: 'domains', response: vhostResponses};
                }

                return {
                    response: domainResults.map(
                        (domain, index) => {
                            domain.response.response.vhostId = vhostResponses[index].id;
                            return { status: 'success', type: 'domains', response: domain.response};
                    }),
                    status: 'success',
                    type: 'domains'
                };
            },
            (err) => ({status: 'error', type: 'domains', response: err})
        );
    };

    private _doRegisterDomain(domain, ownNameserver, domainObj, dnsObj, owner, wizardSettings = {}) {
        return this.wizardServiceHelperDomain.doRegister(
            domain, ownNameserver, domainObj, dnsObj, owner, wizardSettings
        ).then((res: any) => {
            if (res.status === 'error') {
                return {status: 'error', type: 'domain', response: res.err};
            }
            return {status: 'success', type: 'domain', response: res};
        },
        (err: any) => ({ status: 'error', type: 'domain', response: err})
        );
    }

    private _setNameservers = (wizardSettings, domain, domainObj) => {
        let wizardNameservers = ng.copy(wizardSettings.nameservers.nameservers);

        if (this.serviceObject.display.hasGlueRecord
            && [undefined, false].indexOf(domain.glueRecord) >= 0
        ) {
            wizardNameservers = wizardNameservers.map(
                (nameserver) => {
                    nameserver.ips = [];
                    return nameserver;
                }
            );
        }

        domainObj.nameserverSetId = wizardSettings.nameservers.id;
        domainObj.nameservers = wizardNameservers;
    };

    private orderDomain = () => {
        const wizradDisplay     = this.serviceObject.display;
        const wizardSettings    = this.serviceObject.settings;
        const vhost             = wizardSettings.vhost;

        switch (this.serviceObject.display.domainType) {
            default: break;

            case 'register':
                return this._createDomain();

            case 'external':
            case 'subdomain':
                vhost.domainName = vhost.domainNameUnicode;
                vhost.webspaceId = vhost.webspace;

                return this.orderVhost(vhost);
        }
    };

    private updateVhost = (vhost, returnResponseOnly?) => {
        returnResponseOnly = returnResponseOnly || false;

        return this.vhostModel.update(vhost).then(
            (res) => {
                if (res.status === 'error') {
                    return returnResponseOnly
                        ? res
                        : {status: 'error', response: res.err};
                }
                this.alertManager.success(this.$translate.instant('TR_270219-d8b0c9_TR'));
                return returnResponseOnly
                    ? res
                    : {status: 'success', type: 'vhost', response: [vhost]};
            },
            (err) => returnResponseOnly ? err : {status: 'error', response: err}
        );
    };

    private orderVhost = async (domain?, webspace?, returnResponseOnly?) => {
        let vhost;
        const wizardSettings = this.serviceObject.settings;
        const wizardDisplay = this.serviceObject.display;
        const defaultLocation = this.serviceObject.display.defaultLocation || VhostDefaultLocationsObjectConst[0];

        const defaultVhostObject = await this.vhostModel.defaultVhostObject();

        domain = domain || this.serviceObject.settings.vhost;
        webspace = webspace || this.serviceObject.settings.webspace;

        // check if domain is a domain or vHost
        if (domain.webRoot !== undefined) {
            vhost = domain; // domain is a vhostObject
        } else {
            // domain is a domainObj
            vhost = this.serviceObject.settings.vhost || defaultVhostObject;
            vhost.domainName = domain.nameUnicode || domain.domainNameUnicode;
            vhost.domainNameUnicode = domain.nameUnicode || domain.domainNameUnicode;
            vhost.locations = [defaultLocation];
            vhost.webRoot = domain.webRoot;
        }
        vhost.webspaceId = webspace.id;

        const phpVersion = await this.vhostModel.defaultPhpVersion();

        return this.webhostingHelper.getDefaultPhpIniSet(vhost.webspaceId, phpVersion, false)
        .then(
            (phpIniDefaults) => {
                return this.vhostModel.create(
                    vhost,
                    phpIniDefaults,
                    '',
                    [],
                    this.serviceObject.settings.accountId
                ).then((createRes) => {
                    if (createRes.status !== undefined && createRes.status !== 'error') {
                        this.alertManager.success(this.$translate.instant('TR_270219-3ffc66_TR'));
                    }
                    vhost.id = createRes.id;
                    return createRes;
                });
            }
        )
        .then(
            (response) => {
                if (wizardSettings.createNewARecord
                    && response.domainNameUnicode === wizardSettings.vhost.domainNameUnicode
                ) {
                    // update dns record only on first domainName
                    wizardSettings.createNewARecord = false;
                    return this.wizardServiceHelperWebhosting.updateARecordsOfNewVhost(
                        wizardSettings.vhostZone,
                        wizardSettings.webspace,
                        wizardSettings.vhost,
                        wizardDisplay.vhostRecords
                    ).then(
                        (res) => {
                            if (res.status === 'error') {
                                return returnResponseOnly
                                    ? res
                                    : { status: 'error', response: res.err };
                            }

                            return returnResponseOnly
                                ? response
                                : { status: 'success', type: 'vhost', response: [vhost] };
                        },
                        (err) => returnResponseOnly ? err : { status: 'error', response: err }
                    );
                }
                return returnResponseOnly
                    ? response
                    : { status: 'success', type: 'vhost', response: [vhost] };
            },
            (err) => returnResponseOnly
                ? err
                : { status: 'error', response: err }
        );
    };

    private getRecords = (domain: string) => {
        const records = [
            {
                content: this.serviceObject.settings.serverIp,
                name: domain,
                ttl: 86400,
                type: 'A'
            },
            {
                content: this.serviceObject.settings.serverIp,
                name: 'www.' + domain,
                ttl: 86400,
                type: 'A'
            }
        ];

        if (this.serviceObject.settings.mailServers !== undefined) {
            for (const mailServer of this.serviceObject.settings) {
                records.push({
                    content: mailServer,
                    name: domain,
                    ttl: 86400,
                    type: 'MX'
                });
            }
        }

        return records;
    };

    private vmHasOperatingSystem = () => {
        return this.serviceObject.display.osEnabled
        && [undefined, null, ''].indexOf(this.serviceObject.settings.selectedOs) < 0;
    };

    private orderWebHosting = () => {
        // let poolId, resourceId;
        // let webspaceId;
        let webspacePromise;
        let webspaceResponse;
        const wizardSettings    = this.serviceObject.settings;
        const wizardDisplay     = this.serviceObject.display;
        const userAssignment    = wizardDisplay.accessType === '1';

        // Access
        const userInfo = {
            ftpAccess: wizardDisplay.newAccessObject.ftpAccess ||  wizardDisplay.newAccessObject.ftpLimited,
            homeDir: wizardDisplay.newAccessObject.ftpLimited ? wizardDisplay.newAccessObject.homeDir : undefined,
            sshAccess: wizardDisplay.newAccessObject.sshAccess,
            statsAccess: wizardDisplay.newAccessObject.statsAccess,
            userId: wizardDisplay.accessType === '1' ? wizardSettings.choosenAccessId : null
        };

        if (userAssignment) {
            // webspace with webhosting user assignment
            webspacePromise = this._createWebspace(wizardSettings.webspace, [userInfo], wizardDisplay.contingent);
        } else {
            // webspace with new webhosting user
            const newUser = wizardDisplay.newWebspaceUserObject;

            webspacePromise = this.webhostingUserModel.createUser(
                { name: newUser.name },
                newUser.password,
                wizardSettings.accountId
            ).then(
                (result) => {
                    userInfo.userId = result.id;
                    return this._createWebspace(wizardSettings.webspace, [userInfo], wizardDisplay.contingent);
                }
            );
        }

        return webspacePromise.then(
            (webspace) => {
                webspaceResponse = webspace;
                // set default loaction
                wizardSettings.vhost.locations = wizardSettings.vhost.locations.map(
                    (location) => {
                        if (location.matchType === 'default') {
                            location = this.serviceObject.display.defaultLocation;
                        }

                        return location;
                    }
                );

                if (!wizardDisplay.linkDomainToWebspace) {
                    return {status: 'success', type: 'webspace', response: [webspaceResponse]};
                } else {
                    wizardSettings.vhost.webspaceId = webspace.id;
                    wizardSettings.webspace = webspace.response;
                    wizardSettings.vhost.domainName = wizardSettings.vhost.domainNameUnicode;

                    if (wizardSettings.vhost.profile !== null) {
                        wizardDisplay.profiles.filter(
                            (profile: any) => {
                                if (profile.profileId === wizardSettings.vhost.profile) {
                                    wizardSettings.vhost.phpVersion = profile.phpVersion;
                                    wizardSettings.vhost.serverType = profile.webserverType;
                                    wizardSettings.vhost.locations = profile.locations;
                                    return true;
                                }
                                return false;
                            }
                        );
                    }
                    const vhostResponse = { status: '', type: 'vhost', response: null };
                    return this.orderVhost(wizardSettings.vhost, webspaceResponse.response).then(
                        (res) => {
                            vhostResponse.response = res.response;
                            return { status: 'success', type: 'webspace', response: [webspaceResponse, vhostResponse] };
                        },
                        (err) => {
                            vhostResponse.response = err;
                            return { status: 'error', type: 'webspace', response: [webspaceResponse, vhostResponse] };
                        }
                    );
                }
            }
        );
    };

    private _getBundleWebspaceProductCode = (): string|null => {
        const bundleObject = this.serviceObject.display.factSheetItems.filter(
            (factSheetItem) => {
                return factSheetItem.productCodeTemplate
                    === this.serviceObject.selectedProduct.selectedPrice.productCode;
            }
        )[0];

        return bundleObject.factSheetItems.filter(
            (contingent) => {
                return contingent.category === 'webspace';
            }
        )
        [0].productCode || null;
    };

    private _getBundleWebspaceStorageQuota = (): number => {
        const bundleObject = this.serviceObject.display.factSheetItems.filter(
            (factSheetItem) => {
                return factSheetItem.productCodeTemplate
                    === this.serviceObject.selectedProduct.selectedPrice.productCode;
            }
        )[0];

        const storageQuota = bundleObject.factSheetItems.filter(
            (contingent) => {
                return contingent.category === 'storage';
            }
        )[0].value || 1;

        return storageQuota * 1024;
    };

    private _createWebspace = (webspace, accesses, contingentObject?) => {
        const poolId = this.serviceObject.display.inMachine
            ? this.serviceObject.settings.selectedContingent.poolId
            : [undefined, null].indexOf(contingentObject) === -1 ? contingentObject.poolId : null;
        const resourceId = this.serviceObject.display.inMachine
            ? this.serviceObject.settings.selectedContingent.value
            : [undefined, null].indexOf(contingentObject) === -1 ? contingentObject.value : null;
        webspace.accountId = this.serviceObject.settings.accountId;

        return this.webspaceModel.create(webspace, accesses, null, poolId, resourceId)
        .then(
            (res) => {
                if (res.status === 'error') {
                    return {status: 'error', type: 'webspace', response: res.err};
                }

                return {status: 'success', type: 'webspace', response: res};
            },
            (err) => ({status: 'error', type: 'webspace', response: err})
        );
    };
}

export class MoleculeWizardServiceObjectContainerComponent implements ng.IComponentOptions {
    public bindings = {
        serviceObject: '=',
        subAccountDropdownItems: '<'
    };

    public require = {
        $formMolecule: '^moleculeFormEdit'
    };
    // tslint:disable-next-line
    public template = require('./wizard-service-object-container.html');
    public controller =  MoleculeWizardServiceObjectContainerController;
}
