import * as ng from 'angular';
import { OverviewDataObject } from '../../../../../../organisms';

export class MoleculeOverviewPricelistRowPanelSslController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;

    public starsText = {
        1: 0,
        2: 0
    };

    public addValidation = (product) => {
        const self = this;
        const parts = product.productCodeTemplate.split('-');
        let sslValidation = '';

        const validation = ['quicksslpremium', 'rapidssl', 'truebizid', 'truebizidev']
            .map((val) => {
                if (parts.includes(val)) {
                    return val;
                } else {
                    return undefined;
                }
            })
            .filter(self.nonEmpty);

        if (validation.length === 1) {
            sslValidation = validation[0];
        }

        return sslValidation;
    };

    public addInfoStars = (product) => {
        const parts = product.productCodeTemplate.split('-');
        if (parts.includes('san')) {
            this.starsText[1]++;
            return product.productName + '*';
        } else if (parts.includes('additional')) {
            this.starsText[2]++;
            return product.productName + '**';
        }

        return product.productName;
    };

    private nonEmpty = (value) => {
        return [undefined, null, ''].indexOf(value) < 0;
    };
}

export class MoleculeOverviewPricelistRowPanelSslComponent implements ng.IComponentOptions {
    public require = {
        organismPricelistOverview: '^organismPricelistOverview'
    };
    public bindings = {
        overviewData: '<'
    };
    public controller =  MoleculeOverviewPricelistRowPanelSslController;
    public controllerAs = 'OverviewPricelistCtrl';
    public template = require('./pricelist-ssl-row.html'); // tslint:disable-line:max-line-length
}
