import { Record } from './../dns-api';
import { IStateOptions } from 'angular-ui-router';
import { IStorageItem } from '@/atomic-components/molecules';
import { Contingent, DomainTypes, ZoneRecordsToUpdate, ZoneUpdateData } from '@/atomic-components/organs';
import { FamilyProductBoxObject } from '@/services';
import * as Types from '@/types';
import {
    BillingApi,
    BundleApi,
    DatabaseApi,
    DomainApi,
    DnsApi,
    EmailApi,
    MachineApi,
    WebhostingApi
} from '@/types';

export namespace ViewTypes {
    export type RestoreProduct = RestoreProductVirtualMachine
                                | RestoreProductWebhosting
                                | RestoreProductEmail
                                | RestoreProductDatabase
                                | RestoreProductBundle
                                | RestoreProductDdns;

    export type RestoreProductEmail = EmailApi.CatchAll
                                | EmailApi.Forwarder
                                | EmailApi.ImapMailbox
                                | EmailApi.MailingList
                                | EmailApi.SmtpForwarder
                                | EmailApi.ExchangeMailbox;

    export type RestoreProductBundle = BundleApi.Bundle;

    export type RestoreProductDatabase = DatabaseApi.Database;

    export type RestoreProductDdns = DnsApi.DynamicDnsHostname;

    export type RestoreProductWebhosting = WebhostingApi.Webspace;

    export type RestoreProductVirtualMachine = MachineApi.VirtualMachine;
    export interface WizardDomainContingentObject{
        availableCapacity: number;
        usedQuotas: number;
        productCodes: string[];
    }

    export interface WizardDomainObject {
        domain: DomainApi.DomainStatusResult;
        prices: BillingApi.ArticlePurchasePrice[];
        inBundle: boolean;
        productCode?: string;
    }

    export interface WizardDomainObjectDomain {
        transferMethod: string;
        domainNameUnicode: string;
        authCode: string;
        extension: string;
        domainName: string;
    }

    export interface WizardSelectedDomainObject {
        status: string;
        price: BillingApi.ArticlePurchasePrice;
        label: string;
        domainNameAce: string;
        amount: number;
        domainName: string;
        authCode: string;
        tld: string;
        inBundle: boolean;
        domainObject: WizardDomainObject;
        glueRecord?: boolean;
    }

    export interface DomainRecord {
        name: string;
        type: string;
        content: string;
        ttl: number;
        priority?: number;
    }

    export interface DomainCreateData {
        authCode: string;
        createZone: boolean;
        dnsZone: DnsApi.ZoneConfig;
        domainOrderObject: DomainOrderObject;
        vhostExists: boolean;
        vhost: Types.WebhostingApi.VHost;
        // todo: remove in other components
        // domainZoneLists: metadata.domainZoneLists as Types.ViewTypes.WizardDomainZoneListObject,
        orderType: string;
        priceObject: BillingApi.DomainPrice;
        productCode: string;
        updateRecords: boolean;
        zoneRecordsToUpdate?: ZoneRecordsToUpdate;
    }

    export interface DomainOrderObject extends DomainApi.Domain {
        domainName?: string;
        glueRecord?: boolean;
        nameservers: ViewTypes.Nameservers[]; // Override nameserver type od domain api definition
        nameserverType: 'external' | 'internal';
        nameserverSetId: string;
    }

    export interface DomainWizardApiObject {
        zoneRecordsToUpdate: {
            addRecords: Types.DnsApi.Record[];
            deleteRecords: Types.DnsApi.Record[];
            modifyRecords: Types.DnsApi.Record[];
        };
        accountId: string;
        dnsZone: DnsApi.ZoneConfig;
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        bundle: Types.BundleApi.Bundle;
        createWebspace: boolean;
        dns: DomainWizardDnsObject;
        domainType: DomainTypes;
        phpIniDefaults: Types.WebhostingApi.vhostPhpIniDefault;
        selectedContingent: Contingent;
        webspace: Types.WebhostingApi.Webspace | WebspaceCreateObject;
        vhost: Types.WebhostingApi.VHost;
        domains: DomainCreateData[];
    }

    export interface DomainWizardDnsObject {
        type: string;
        values: DomainWizardDnsValuesObject;
    }

    export interface DomainWizardDnsValuesObject {
        ip: string;
        masterIp: string;
        mxServer: any[];
        records: Types.DnsApi.Record[];
        replacements: DomainWizardDnsValuesReplacements;
        dnsSecOptions: any;
        templateChain: boolean;
        templateId: string;
    }

    export interface DomainWizardDnsValuesReplacements {
        ipv4Replacement: string;
        ipv6Replacement: string;
        mxipv4Replacement: string;
        mxipv6Replacement: string;
    }

    export interface DomainWizardDnsValuesReplacements {
        ipv4Replacement: string;
        ipv6Replacement: string;
        mxipv4Replacement: string;
        mxipv6Replacement: string;
    }

    export interface DomainWizardDnsSettingsObject {
        addCompanyMx?: boolean;
        dnsSecOptions:  DnsApi.DnsSecOptions;
        ip: string;
        masterIp?: string;
        mxServer: any[];
        records?: any[];
        replacements: DomainWizardDnsValuesReplacements;
        templateChain: boolean|string;
        templateId: string;
    }

    export interface BundleVhostFindObject {
        bundleId: string;
        child: boolean;
        domainId: string;
        domainName: string;
        domainNameUnicode: string;
        id: string;
        isRedirect: boolean;
        mainNameUnicode: string;
        path: string;
        phpVersion: string;
        rawType: string;
        status: string;
        type: string;
        vHostId: string;
    }

    export interface BundleDomainFindObject extends DomainApi.Domain {
        domainNameUnicode: string;
        mainNameUnicode: string;
    }

    export interface StrengthBar {
        status: 'active' | 'inactive' | 'warning' | 'error';
        constraintType?: 'charPool' | 'minLength' | 'maxLength' | 'requireOtherCharacters';
        constraint?: unknown;
    }

    export interface PasswordOptions {
        allowEmpty?: boolean;
        allowOtherCharacters?: boolean;
        characterPools?: string[];
        hideCharacters?: boolean;
        illegalCharacters?: string;
        illegalValues?: string[];
        maxLength?: number;
        minCharacterPoolsUsed?: number;
        minLength?: number;
        requireOtherCharacters?: boolean;
    }

    export enum UserAccessType {
        existing = '1',
        new = '2'
    }

    export interface WebspaceUserAccessObject {
        accessLevel: {
            ftpAccess: boolean;
            ftpLimited: boolean;
            sshAccess: boolean;
            statsAccess: boolean;
        };
        accessType: UserAccessType;
        comments: string;
        homeDir: string;
        name: string;
        password: string;
        sshKey: string;
        props?: WebspaceUserAccessPropsObject;
        userId: string;
        userName: string;
        status: string;
    }

    export interface WebspaceUserAccessPropsObject {
        initialState?: Types.ViewTypes.WebspaceUserAccessObject;
        isMarkedForRemoval: boolean;
        isNewUser: boolean;
        isBeingEdited: boolean;
        isHidden: boolean;
        newAccessCreateType: UserAccessType;
        type: string;
        validationErrorList: {
            accessLevel: { text: string }[];
            homeDir: { text: string }[];
            name: [];
        };
        passwordValidationOptions: {
            characterPools: string[];
            maxLength: number;
            minCharacterPoolsUsed: number;
            minLength: number;
        };
    }

    export interface DatabaseUserAccessObject {
        accessLevel: {
            read: boolean;
            schema: boolean;
            write: boolean;
        };
        accessType: UserAccessType;
        comments: string;
        name: string;
        password: string;
        userId: string;
    }

    type WizardSummaryViewDataObjectType = 'string'
                                        | 'integer'
                                        | 'bool'
                                        | 'days'
                                        | 'gb'
                                        | 'mb'
                                        | 'mb_per_sec'
                                        | 'mbit_per_sec'
                                        | 'sec'
                                        | 'vcore_count'
                                        | 'stringArray'
                                        | 'price' | 'price_per_period';

    // WIZARD COMPOSER OBJECT TYPE //
    export interface WizardSummaryViewDataObject {
        description: string | string[];
        label: string;
        labelType?: 'string' | 'productCode' | 'translation';
        type: WizardSummaryViewDataObjectType;
        unit?: string; // deprecated ?? not used in wizard-summary-view.(ts|html) fact-sheet.(ts|html)
        value: string | number | boolean | string[];
    }

    export interface WizardSummaryProductViewPanelObject {
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        bundleContingents?: any;
        contingent?: Contingent;
        domainName?: any;
        factSheets: WizardSummaryViewDataObject[];
        objectAction?: any;
        product: {
            name?: string;
            nameType?: 'translation' | 'productCode';
            shortDescription?: string;
        };
        productCode: string;
        productFamily?: string;
        priceMultiplication?: number;
    }

    export interface WizardComposerObject {
        apiObject: {
            orderItem: {
                items: any[];
                name: string;
                productCode: string;
                type: string;
            };
            ownerAccountId: string;
        };
        productFamily?: string;
        productCode?: string;
        accountId?: string;
        summaryObject?: WizardSummaryProductViewPanelObject[];
    }

    // todo: split into ProductConfigWebspaceBundleObject and ProductConfigEmailBundleObject?
    export interface ProductConfigBundleObject {
        accesses?: {
            customAccesses: {
                database: DatabaseUserAccessObject;
                webspace: WebspaceUserAccessObject;
            };
            password: string;
            useCustom: boolean;
        };
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        bundleName: string;
        databaseAmount?: number;
        domain: ProductConfigBundleDomainObject;
        productCode: string;
        productFamily: string;
    }

    export interface ProductConfigBundleDomainObject {
        authCode: string;
        nameUnicode: string;
        vHostOnly: boolean;
    }

    export interface ProductSelectionOverlayAdd {
        field: string;
        newValue: string;
    }

    export interface ProductSelectionOverlay {
        action: {
            type: string;
            mode: string;
            addInFront?: boolean;
        };
        target?: {
            field: string;
            value?: string;
            useRegex?: boolean;
            regex?: RegExp;
        };
        data?: ProductSelectionOverlayAdd[] | FamilyProductBoxObject[];
    }

    export interface ProductConfigDatabaseObject {
        accesses?: {
            customAccesses: {
                database: DatabaseUserAccessObject;
            };
            password: string;
            useCustom: boolean;
        };
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        additionalStorageItems: number;
        comments?: string;
        contingent?: Contingent;
        databaseName: string;
        productCode: string;
        productFamily: string;
        storageProduct: any;
        storageQuota: number;
    }

    export interface ProductConfigWebspaceVhostSettingsObject {
        domainExtern: boolean;
        systemAlias: string;
        assignDomain: boolean;
        domainName: string;
        configTyp: 'website' | 'redirect';
        configPath: string;
        redirectStatus: number;
        redirectTarget: string;
        createNewARecord: {
            status: boolean;
            id: string;
        };
        enablePrefix: boolean;
        enableRedirect: boolean;
        enableSystemname: boolean;
        enableDirectoryListing: boolean;
        domainProfile: WebhostingApi.Profile;
        serverTypeChangeBlocked: boolean;
        webserverType: 'nginx' | 'apache';
        webserverId?: string;
        poolId?: string;
        phpIni: any;
    }

    export interface ProductConfigWebspaceUserSettingsObject {
        userType: 'existing' | 'new';
        id: string;
        name: string;
        password: string;
        accesses: string[];
        accessHomeDir: string;
        publicKey: string;
        comments: string;
    }
    export interface ProductConfigWebspaceObject {
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        productFamily: string;
        productCode: string;
        contingent?: Contingent;
        webspaceName: string;
        storageQuota: number;
        additionalStorageItems: string[];
        webserverId: string;
        poolId: string;
        vhostSettings: ProductConfigWebspaceVhostSettingsObject;
        user: ProductConfigWebspaceUserSettingsObject;
    }

    export interface WebspaceCreateObject {
        accesses: [];
        poolId: string;
        webserverId: string;
        webspace: {
            accountId: string;
            name: string;
            productCode: string;
            storageQuota: number;
        };
    }

    export interface SpamFilter {
        bannedFilesChecks?: boolean;
        deleteSpam?: boolean;
        headerChecks?: boolean;
        malwareChecks?: boolean;
        modifySubjectOnSpam?: boolean;
        spamChecks?: boolean;
        spamLevel?: 'low' | 'medium' | 'high';
        useGreylisting?: boolean;
    }

    export interface IConfigEmailObject {
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        autoconfigureDns: boolean;
        contingent?: Contingent;
        productCode: string;
        skipDnsCheck: boolean;
    }

    export interface ProductConfigDnsObject {
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        fqdnList?: string[];
        masterIp?: string;
        ipAddress?: string;
        isRecreate?: boolean;
        productCode: string;
        productFamily: string;
        records?: Types.DnsApi.Record[];
        nameserver?: NameserverDefinition;
        settings?: {
            wwwRecord?: boolean;
            starRecord?: boolean;
            dnsSecActive?: boolean;
            dnsSecOptions?: {
                nsecMode?: string;
                publishKsk?: boolean;
            };
            soaValues?: {
                expire: string | number;
                negativeTtl: string | number;
                refresh: string | number;
                retry: string | number;
                ttl: string | number;
            };
            transferAllowed?: boolean;
            zoneTransferWhitelist?: string[];
            emailAddress?: string;
        };
        selectedZoneId?: string;
        copiedZoneConfig?: Types.DnsApi.ZoneConfig;
        templateValues?: {
            templateId: string | null;
            templateReplacements?: {
                ipv4Replacement?: string | null;
                ipv6Replacement?: string | null;
                mailIpv4Replacement?: string | null;
                mailIpv6Replacement?: string | null;
            };
            tieToTemplate: boolean;
        };
    }

    export interface ProductDnsRecordObject extends DnsApi.Record {
        isBeingEdited?: boolean;
    }

    export interface DnsDefaultNameserverSet {
        accountId: string;
        addDate: string;
        defaultNameserverSet: boolean;
        id: string;
        lastChangeDate: string;
        name: string;
        nameservers: string[];
        type: string;
    }

    export interface ProductConfigDdnsObject {
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        fqdn: string;
        password: string;
        productCode: string;
        productFamily: string;
        comment: string;
        existingUserId: string;
        newUser: {
            accountId: string;
            comments: string;
            name: string;
            userName: string;
        };
    }

    export interface ProductConfigEmailObject extends IConfigEmailObject {
        mailbox: {
            emailAddress: string;
            forwarderTargets?: string[];
        };
        isAdmin: boolean;
        password?: string;
        productFamily: 'imap-mailboxes' | string;
        spamProfile: SpamFilter;
        storageQuota?: number;
        additionalStorageInfo: {
            amount: number;
            storageItem: IStorageItem;
        };
    }

    export interface ProductConfigCatchallObject extends IConfigEmailObject {
        mailbox: {
            emailAddress: string;
            forwarderTarget: string;
        };
        productFamily: 'catchalls' | string;
        spamProfile?: SpamFilter;
    }

    export interface ProductConfigMailingListObject extends IConfigEmailObject {
        mailbox: {
            accessMode?: 'owner' | 'member' | 'everyone' | string;
            allowHtmlMails?: boolean;
            digestSize?: number;
            emailAddress: string;
            members?: string[];
            name: string;
            owners: string[];
            replyToEmailAddress?: string; // required if replyToMode == specifiedAddress
            replyToMode?: 'list' | 'self' | 'specifiedAddress' | string;
            spamFilter?: SpamFilter;
            subjectPrefix?: string;
            type: 'MailingList';
        };
        productFamily: 'mailing-lists' | string;
    }

    export interface ProductConfigSmtpForwarderObject extends IConfigEmailObject {
        mailbox: {
            emailAddress: string;
            forwarderPort: number | string;
            forwarderServer: string;
            type: 'SmtpForwarder';
        };
        productFamily: 'smtp-forwarders' | string;
    }

    export interface ProductConfigExternalForwarderObject extends IConfigEmailObject {
        mailbox: {
            emailAddress: string;
            forwarderTargets?: string[];
        };
        productFamily: 'external-forwarders' | string;
        spamProfile: SpamFilter;
    }

    export interface ProductConfigExchangeMailboxObject extends IConfigEmailObject {
        additionalStorageInfo: {
            amount: number;
            storageItem: IStorageItem;
        };
        isAdmin: boolean;
        mailbox: {
            emailAddress: string;
            firstName: string;
            lastName: string;
            spamFilter?: SpamFilter;
            storageQuota: number;
            type: 'ExchangeMailbox';
        };
        organization: {
            comment?: string;
            id?: string;
            name?: string;
        };
        password: string;
        productFamily: 'msexchange-mailboxes' | string;
    }

    export type ProductConfigEmailObjectGeneric =
        | ProductConfigEmailObject
        | ProductConfigCatchallObject
        | ProductConfigMailingListObject
        | ProductConfigSmtpForwarderObject
        | ProductConfigExternalForwarderObject
        | ProductConfigExchangeMailboxObject;

    export interface ProductConfigMachineObject {
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        productCode?: string;
        productFamily?: string;
        installOs?: boolean;
        installApp?: boolean;
        domain?: {
            authCode: string;
            nameUnicode: string;
            vHostOnly: boolean;
        };
        applicationSettings?: any;
        virtualMachine: {
            name: string;
            hostName?: string;
            os?: {
                id: string;
                rootUserPass: string;
                rootUserKeys?: string[];
                extraUserName?: string;
                extraUserPass?: string;
                extraUserKeys?: string[];
            };
        };
    }

    export interface ProductConfigMachineIndividualObject {
        productCode?: string;
        productFamily?: string;
        selectedBillingCycle?: string;
        virtualMachine: {
            account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
            name: string;
        };
        individualProperties: {
            price: string; // cents
            architecture: 'x86_64';
            managementType: 'none' | 'individual' | 'platform';
            virtualMachineHostId: 'auto' | string;
            diskControllerType: 'virtio-scsi' | 'virtio';
            networkBandwidth: number;
            cpu: {
                cpuCount: number;
                cpuPeriod: number;
                cpuQuota: number;
            };
            memory: {
                capacity: number;
            };
            disk: {
                capacity: number;
                type: 'local';
                Bps: number;
                Iops: number;
            };
        };
    }

    export type ProductConfigMachineObjectGeneric = ProductConfigMachineObject | ProductConfigMachineIndividualObject;

    export interface InputField {
        required: boolean;
        value: any;
    }

    export interface SslContactFields {
        phoneNumber?: InputField;
    }

    export interface SslContact<T> {
        fields: T;
        flags?: any;
        show?: boolean;
        filledOut(): boolean;
    }

    export interface AdministrationContactFields extends SslContactFields {
        title?: InputField;
        emailAddress?: InputField;
        firstName?: InputField;
        lastName?: InputField;
    }

    export interface TechnicalContactFields extends SslContactFields {
        title?: InputField;
        emailAddress?: InputField;
        firstName?: InputField;
        lastName?: InputField;
    }

    export interface OrganizationContactFields extends SslContactFields {
        organizationName?: InputField;
        addressLine1?: InputField;
        addressLine2?: InputField;
        postalCode?: InputField;
        city?: InputField;
        region?: InputField;
        country?: InputField;
        faxNumber?: InputField;
    }

    export interface ContactContainer {
        administrationContact: SslContact<AdministrationContactFields>;
        technicalContact: SslContact<TechnicalContactFields>;
        organizationContact: SslContact<OrganizationContactFields>;
    }

    export interface ProductConfigSslObject {
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        sufficientCreditAvailable: boolean;
        sslProductCode: string;
        sslProductFamily: string;
        sslBillingCycle: string;
        sslValidationObject: SslValidationObject;
        additionalDomains: string[];
        contacts: ContactContainer;
        settings: {
            validationMethod: string;
            email: string;
            emailCheckActive: boolean;
            productSpecification: { name: string; value: string }[];
        };
    }

    export type CsrDecodeApiResponse = {
        csr?: {
            additionalDomainNames: [];
            country: string;
            domainName: string;
            eccType: string;
            emailAddress: string;
            keyLength: number;
            keyType: string;
            locality: string;
            organization: string;
            organizationUnit: string;
            state: string;
        };
        errors: [];
        productCodes: string[];
        warnings: [];
    };

    export interface SslValidationObject {
        csrRequestEncoded?: string;
        apiResponse?: CsrDecodeApiResponse | never;
        isWildcard?: boolean;
    }

    export interface OrchestrationJob {
        accountId: string;
        action: string;
        addDate: string;
        clientTransactionId: string;
        displayName: string;
        id: string;
        lastChangeDate: string;
        objectId: string;
        objectType: string;
        orderItemId: string;
        rootOrderItemId: string;
        serverTransactionId: string;
        status: string;
        triggeredBy: any;
    }

    export interface BundleJobInfo {
        link?: {
            routeOptions?: IStateOptions;
            routeParams?: any;
            routeTarget?: string;
            route?: string;
            url?: string;
        };
        name: string;
        objectId?: string;
        objectType?: string;
        value: any;
    }

    // DOMAIN SERVICE INTERFACES //
    export interface ProductConfigDomainDomainObject {
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        bundle: Types.BundleApi.Bundle;
        contacts: DomainContactObject[];
        createWebspace: boolean;
        dns: DomainWizardDnsObject;
        zoneUpdateData: Record<string, ZoneUpdateData>;
        domainType: DomainTypes;
        nameserver: NameserverDefinition;
        phpIniDefaults: Types.WebhostingApi.vhostPhpIniDefault;
        selectedContingent: Contingent;
        selectedDomains: ViewTypes.WizardSelectedDomainObject[];
        vhost: Types.WebhostingApi.VHost;
        vhostExists: boolean;
        webspace: Types.WebhostingApi.Webspace;
        webserverRessource: Types.ResourceApi.Resource;
    }

    export interface DomainContactObject {
        contactType: DomainApi.DomainContactType;
        contactId: string;
        label?: string;
    }

    export interface ProductConfigDomainRedirectionObject {
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        domainName: string;
        type: string;
        title: string;
        redirectionUrl: string;
        adjustZone: boolean;
        metaTagAuthor: string;
        metaTagKeywords: string;
        metaTagDescription: string;
        price: any[];
        adjustRecords: boolean;
        zone: any;
    }

    export interface ProductConfigDomainVhostObject {
        account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
        defaultLocation: any;
        domainType: DomainTypes;
        dns: ProductConfigDomainVhostObjectDns;
        phpIniDefaults: Types.WebhostingApi.vhostPhpIniDefault;
        profiles: any;
        records: any;
        vhost: Types.WebhostingApi.VHost;
        webspace: any;
        zoneUpdateData: Record<string, ZoneUpdateData>;
    }

    export interface ProductConfigDomainVhostObjectDns {
        type: string;
        values: ProductConfigDomainVhostObjectDnsValue;
    }

    export interface ProductConfigDomainVhostObjectDnsValue {
        ip: null;
        masterIp: null;
        mxServer: null;
        records: [];
        replacements: {
            ipv4Replacement: undefined;
            ipv6Replacement: undefined;
            mxipv4Replacement: undefined;
            mxipv6Replacement: undefined;
        };
        templateChain: false;
        templateId: null;
    }

    export interface DnsTypeValues {
        type: string;
        values: DomainWizardDnsValuesObject;
    }

    export interface NameserverListValues {
        nameserverId: string;
        nameservers: Nameservers[];
    }

    export interface DataValues {
        id: string;
        nameservers: Nameservers[];
    }

    export interface Nameservers {
        name: string;
        ipsRequired?: boolean;
        ips: string[];
    }

    export type WizardProductsConfigDomain = ProductConfigDomainDomainObject | ProductConfigDomainVhostObject;

    // DOMAIN //
    export interface WizardDomainSearchDataObject {
        domainInfo: ViewTypes.WizardDomainObject[];
        domainSearchString: string;
        numberOfSelectedDomains: number;
    }

    export interface WizardDomainZoneListObject {
        withZones: any[];
        withoutZones: any[];
        slaveZones: any[];
    }

    export interface PriceObject {
        rawBasisConverted: number;
        exchangeRatio: {
            baseCurrency: string;
        };
        rawTax: any;
        amount: number;
        currency?: string;
        rawPrice: number;
    }

    export interface BalanceList {
        currency?: string;
        sum: number;
    }

    export interface TaxList {
        currency?: string;
        sum: number;
    }

    export type ApiListResponse = EntityWithPagination<any>;

    export interface EntityWithPagination<Entity>{
        data: Entity[];
        pagination: {
            currentPage: number;
            entries: number;
            limit: number;
        };
    }

    // DNS
    export interface NameserverDefinition {
        ownNameservers: OwnNameserver[];
        ownNameserversComplete?: boolean;
        values: NameserverValues;
    }

    export interface NameserverValues {
        default: boolean;
        id: string;
        nameservers: Nameservers[];
        type: string;
        hasGlueRecord?: boolean;
    }
    export interface OwnNameserver {
        ips: string[];
        name: string;
        ipsRequired: boolean;
        nameserverErrors: {text: string}[];
    }

    export interface DomainWizardNameserverSets {
        items?: DomainWizardNameserverItem[];
        title?: string;
    }

    export interface DomainWizardNameserverItem {
        name?: string;
        value?: NameserverValues;
    }

    export interface DomainContingentProduct {
        productCode: string;
        type: string;
    }

    export interface RequiredReplacementTypes {
        ipv4Replacement: boolean;
        ipv6Replacement: boolean;
        mailIpv4Replacement: boolean;
        mailIpv6Replacement: boolean;
    }
}
