import * as ng from 'angular';
import './radio-input.scss';

export class MoleculeFormRadioInputController implements ng.IController {
    public static $inject: string[] = [];

    public ngModel: string|number|boolean;
    public inputValue: string|number|boolean;
    public labelText: string;
    public radioInputName: string;
    public disabled: boolean;
    private onChangeCallback: (param: any) => {};
    private onChangeCallbackParams: unknown;

    public setRadioInputValue(): void {
        this.ngModel = this.inputValue;

        if (this.onChangeCallback !== undefined) {
            let callback: unknown = this.inputValue;

            if (this.onChangeCallbackParams !== undefined) {
                callback = {
                    params: this.onChangeCallbackParams,
                    value: this.inputValue
                };
            }

            this.onChangeCallback(callback);
        }
    }
}

export class MoleculeFormRadioInputComponent implements ng.IComponentOptions {
    public bindings = {
        disabled: '<',
        inputValue: '<',
        labelText: '@',
        ngModel: '=',
        onChangeCallback: '<',
        onChangeCallbackParams: '<?',
        radioInputName: '@'
    };
    public controller =  MoleculeFormRadioInputController;
    public controllerAs = '$formRadioInput';
    public template = require('./radio-input.html');
}
