import * as ng from 'angular';
import { PanelHeaderData } from '../../../atomic-components/molecules/panels';
import { DashboardOverviewObject } from '../../../atomic-components/organisms/overview';
import { UiRights } from '../../../configuration';
import {
    AuthContextService, DomainContactsVerificationOverviewModel, DomainTransferOverviewModel
} from '../../../services';

export class TemplateMainDashboardController {
    public static $inject: string[] = [
        '$translate',
        'authContext',
        'domainContactsVerificationOverviewModel',
        'domainTransferOverviewModel'
    ];

    public pageHeaderData: PanelHeaderData;
    public bundles: any[];
    public virtualMachines: any[];
    public singleWebspaces: any[];
    public singleDomains: any[];
    public nextcloudServers: any[];
    public dashboardOverview: DashboardOverviewObject;
    public productList: any[] = [];
    public managedServerProductList: any[] = [];
    public webspaceProductList: any[] = [];
    public dataLoading = false;
    public faqArticleIdList: string[] = [
        'email-mailbox-create',
        'domain-register-new',
        'billing-pay-bill'
    ];

    private _allProductList: any[] = [];
    private _maxAgencyListingObjects = 3;
    private _isAdmin = false;
    private _contentTemplateName = '';
    private _additionalActionData = {
        linkList: [
            {
                isGranted: this.authContext.isGranted(UiRights.DOM_DOMAINS_LIST),
                route: 'domain.dashboard',
                routeParams: {},
                text: this.$translate.instant('DASHBOARD.GENERAL.DOMAINS')
            },
            {
                isGranted: this.authContext.isGranted(UiRights.SSL_CERTIFICATES_LIST),
                route: 'ssl.dashboard',
                routeParams: {},
                text: this.$translate.instant('MENU.MAIN.PRODUCTS.SSL')
            },
            {
                isGranted: this.authContext.isGranted(UiRights.WEB_OBJECT_LIST),
                route: 'webhosting.dashboard',
                routeParams: {},
                text: this.$translate.instant('MENU.MAIN.PRODUCTS.WEBHOSTING')
            },
            {
                isGranted: this.authContext.isGranted(UiRights.MACHINE_VM_LIST),
                route: 'machine.dashboard',
                routeParams: {},
                text: this.$translate.instant('9fd32096-6a1f-43c6-a467-4ef75d7593e7')
            },
            {
                isGranted: this.authContext.isGranted(UiRights.DNS_ZONES_LIST),
                route: 'dns.dashboard',
                routeParams: {},
                text: this.$translate.instant('MENU.MAIN.PRODUCTS.DNS')
            },
            {
                isGranted: this.authContext.isGranted(UiRights.EMAIL_MAILBOX_LIST),
                route: 'email.dashboard',
                routeParams: {},
                text: this.$translate.instant('TR_080119-8dd4a1_TR')
            },
            {
                isGranted: this.authContext.isGranted(UiRights.DB_DATABASE_LIST),
                route: 'database.dashboard',
                routeParams: {},
                text: this.$translate.instant('TR_090119-f0ccfb_TR')
            }
        ]
    };

    constructor(
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService,
        private domainContactsVerificationOverviewModel: DomainContactsVerificationOverviewModel,
        private domainTransferOverviewModel: DomainTransferOverviewModel
    ) {}

    public $onInit() {
        this._contentTemplateName = this._getTemplateView();
        const hour = new Date().getHours();
        let greeting = /* translationId */ 'TR_110119-922c4c_TR';

        switch (true) {
            case hour >= 5 && hour < 11: // morning
                greeting = /* translationId */ 'TR_010719-fce241_TR';
                break;
            case hour >= 12 && hour < 18: // day
                greeting = /* translationId */ 'TR_010719-a39171_TR';
                break;
            case hour >= 18 && hour < 22: // evening
                greeting = /* translationId */ 'TR_010719-a5afb3_TR';
                break;
            case (hour >= 22 && hour < 24) || hour < 5: // night
                greeting = /* translationId */ 'TR_010719-fe20d4_TR';
                break;
        }

        this.singleWebspaces = this.singleWebspaces.filter(
            (webspace) => (webspace.bundleId === '' && webspace.poolId === null)
        );

        this.pageHeaderData = {
            panelHeaderRoute: '',
            panelIcon: 'home-lg-alt',
            panelTitle: this.$translate
                .instant(greeting, { userName: this.authContext.user.name })
                .replace(/&amp;/g, '&')
        };

        if (this._contentTemplateName === 'agency-customer') {
            this.managedServerProductList = [];
            this.webspaceProductList = this._getPanelObjects();
            this.dashboardOverview = {
                // List of table service data
                overviewsData: [],
                // Dashboard main menu panel
                panelData: {
                    addtionalActionData: this._additionalActionData,
                    mainHeader: {
                        icon: '',
                        isGranted: this.authContext.isGranted(UiRights.BIL_BUNDLE_CREATE),
                        route: 'bundle.new', // to-do: route to new product - is not implemented
                        routeParams: {},
                        text: this.$translate.instant('TR_110119-4eb8d6_TR')
                    }
                }
            };
        } else if (this._contentTemplateName === 'domain-customer') {
            const overviewDate = [];

            if (this.authContext.isGranted(UiRights.DOM_JOBS_LIST)) {
                overviewDate.push({
                    hideOnEmpty: true,
                    listOptions: { limit: 5},
                    overviewModel: this.domainTransferOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'domain.transfers',
                        panelHeaderRouteParams: {},
                        panelIcon: 'globe',
                        panelTitle: this.$translate.instant('TR_110119-812b5d_TR')
                    },
                    serviceItemList: []
                });
            }
            if (this.authContext.isGranted(UiRights.DOM_CONTACTS_LIST)) {
                overviewDate.push({
                    hideOnEmpty: true,
                    listOptions: { limit: 5},
                    overviewModel: this.domainContactsVerificationOverviewModel,
                    panelHeaderData: {
                        includeShowAllLink: true,
                        includeShowDetailsLink: false,
                        panelHeaderRoute: 'domain.contacts.verification',
                        panelHeaderRouteParams: {},
                        panelIcon: 'globe',
                        panelTitle: this.$translate.instant('TR_110119-7a3071_TR')
                    },
                    serviceItemList: []
                });
            }

            this.dashboardOverview = {
                // List of table service data
                overviewsData: overviewDate,
                // Dashboard main menu panel
                panelData: {
                    addtionalActionData: this._additionalActionData,
                    mainHeader: {
                        icon: '',
                        isGranted: this.authContext.isGrantedAny([
                            UiRights.DOM_DOMAINS_CREATE_WITH_CONTACTS,
                            UiRights.DOM_DOMAINS_CREATE_WITH_DEFAULTS,
                            UiRights.DOM_DOMAINS_CREATE_WITH_OWN_NS]
                        ),
                        route: 'domain.domains.new',
                        routeParams: {},
                        text: this.$translate.instant('TR_110119-8242f2_TR')
                    }
                }
            };
        }
    }

    public getTemplate = () => require('./dashboard-' + this._contentTemplateName + '.html?external');

    public isManagedNextcloudServer = (item) => {
        return item.productCode.indexOf('nextcloud') >= 0;
    };

    public isManagedCloudServer = (item) => {
        return item.managementType !== undefined && item.managementType === 'platform';
    };

    public isCloudServer = (item) => {
        return item.managementType !== undefined && item.managementType === 'none';
    };

    public isBundle = (item) => {
        if (item.managementType !== undefined) {
            return false;
        }
        return item.baseContingents !== undefined;
    };

    public isWebspace = (item) => {
        if (item.managementType !== undefined) {
            return false;
        }
        return item.webspaceName !== undefined && item.bundleId === '' && item.poolId === null;
    };

    public showAllBundlesLink = () => {
        return this._allProductList.some((product) => this.isBundle(product));
    };

    public showAllManagedCloudServerLink = () => {
        return this._allProductList.some((product) => this.isManagedCloudServer(product));
    };

    public showAllCloudServerLink = () => {
        return this._allProductList.some((product) => this.isCloudServer(product));
    };

    public showAllManagedNextcloudServerLink = () => {
        return this._allProductList.some((product) => this.isManagedNextcloudServer(product));
    };

    public showAllWebspaceLink = () => {
        return this._allProductList.some((product) => this.isWebspace(product));
    };

    public isEnduser() {
        return !this._isAdmin
            && (this.virtualMachines.length === 0 && this.singleWebspaces.length === 0 && this.bundles.length > 0);
    }

    private _getTemplateView() {
        if (!this.authContext.isGranted(UiRights.BIL_BUNDLE_LIST)) {
            return 'domain-customer';
        }

        switch (true) {
            case this._isDomainCustomer():
                return 'domain-customer';
            default:
                return 'agency-customer';
        }
    }

    private _isDomainCustomer() {
        return this._isAdmin
            || this.virtualMachines.length === 0
            && this.singleWebspaces.length === 0
            && this.bundles.length === 0
            && this.nextcloudServers.length === 0
            && this.singleDomains.length > 0;
    }

    private _getPanelObjects = () => {
        const panelObjects = this.bundles
            .concat(this.singleWebspaces)
            .concat(this.virtualMachines)
            .concat(this.nextcloudServers);

        this._allProductList = ng.copy(panelObjects);

        panelObjects.sort(this.sortByLastChangeDate);

        if (panelObjects.length > this._maxAgencyListingObjects) {
            return panelObjects.splice(0, this._maxAgencyListingObjects);
        }

        return panelObjects;
    };

    private sortByLastChangeDate = (a, b) => {
        const aLastChangeDate = Math.round(new Date(a.lastChangeDate).getTime());
        const bLastChangeDate = Math.round(new Date(b.lastChangeDate).getTime());
        if (aLastChangeDate > bLastChangeDate) {
            return -1;
        }

        if (aLastChangeDate < bLastChangeDate) {
            return 1;
        }

        return 0;
    };
}

export class TemplateMainDashboardComponent implements ng.IComponentOptions {

    public bindings = {
        _isAdmin: '<isAdmin',
        bundles: '<',
        nextcloudServers: '<',
        singleDomains: '<',
        singleWebspaces: '<',
        virtualMachines: '<'
    };
    public controller = TemplateMainDashboardController;
    public template = require('./main-dashboard-template.html');
}
