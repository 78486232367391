import * as ng from 'angular';
import { AlertManagerService } from '../../../../../../services';
import { OrganismOverviewController } from '../../../../../organisms/overview/overview';

export class MoleculePanelOverviewHeaderMultiWipeActionController implements ng.IController {
    public static $inject: string[] = ['$timeout', 'alertManager', '$translate'];

    public OrganismOverview: OrganismOverviewController;
    public confirmHint = false;
    public deleteType: string;
    public serviceName: string;

    constructor(
        private $timeout: ng.ITimeoutService,
        private alertManager: AlertManagerService,
        protected $translate: ng.translate.ITranslateService
    ) {}

    $onInit() {
        this.deleteType = 'permanently';
    }

    public abort = () => {
        this.OrganismOverview.overviewModel.activeMassAction = false;
    };

    public submit = () => {
        const promise = this.OrganismOverview.submitMassAction('wipeSubmit');
            // todo - show request message in view
        promise.then(
            (result) => {
                if (result) {
                    this.alertManager.success(this.$translate.instant('TR_301122-f1d2e8_TR'));
                    this.abort();
                    this.$timeout(() => {
                        this.OrganismOverview.overviewLoad();
                    }, 1000);
                }
            }, (e) => {
                console.error('error', e);
            });

    };

    public getTemplate = () => require('./multi-wipe-' + this.serviceName + '-action.html?external');
}

export class MoleculePanelOverviewHeaderMultiWipeActionComponent implements ng.IComponentOptions {
    public require = {
        OrganismOverview: '^organismOverview'
    };
    public bindings = {
        serviceName: '<'
    };
    public controller =  MoleculePanelOverviewHeaderMultiWipeActionController;
    public template = require('./multi-wipe-action.html');
}
