import * as ng from 'angular';
import { PageHeaderData } from '../../../atomic-components/organisms';
import { UiRights } from '../../../configuration';
import { AuthContextService } from '../../../services';

export class TemplateMailboxEditController {
    public static $inject: string[] = ['$state', '$translate', 'authContext'];

    public modifier = '';
    public pageHeaderData: PageHeaderData;
    public maxMailingListMembers: number;
    public mailbox;
    public showObjectId: boolean;

    public mailboxTypes = [
        { name: this.$translate.instant('EMAIL.MAILBOX'), value: 'ImapMailbox' },
        { name: this.$translate.instant('EMAIL.MAILBOX-TYPE.FORWARDER'), value: 'Forwarder' }
    ];

    public faqArticleIdList: string[] = [
        'email-mailbox-size',
        'email-smtp-auth',
        'email-setup-thunderbird',
        'email-setup-outlook2013',
        'email-setup-apple-mail'
    ];

    public userPanelRight;

    private comeFrom;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private authContext: AuthContextService
    ) {}

    public $onInit = () => {
        const mailbox = this.mailbox;
        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        let panelHeaderBackwardLink;
        let panelHeaderRouteParams;

        switch (true) {
            case stateInBundle:
                panelHeaderBackwardLink = 'bundle.id.mailboxes.overview';
                panelHeaderRouteParams = { bundleId: this.$state.$current.locals.globals.bundle.id };
                break;
            case stateInMachine:
                panelHeaderBackwardLink = 'managed-servers.id.mailboxes.overview';
                panelHeaderRouteParams = { machineId: this.$state.$current.locals.globals.machine.id };
                break;
            default:
                panelHeaderBackwardLink = 'email.mailboxes.overview';
                panelHeaderRouteParams = null;
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: this.$translate.instant('TR_110119-2ddf76_TR'),
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'envelope',
            panelTitle: mailbox.emailAddressUnicode
        };

        if (this.authContext.isGrantedAll([UiRights.ACC_SUBACCOUNT_LIST, UiRights.EMAIL_DOMAINSETTINGS_LIST])) {
            if (this.comeFrom === 'products') {
                this.pageHeaderData.productActions = [
                    {
                        route: 'email.mailboxes.id.domainsettings',
                        stateParams: { mailboxId: this.mailbox.id },
                        title: this.$translate.instant('TR_090119-7178c1_TR')
                    }
                ];
            } else if (this.comeFrom === 'bundle') {
                this.pageHeaderData.productActions = [
                    {
                        route: 'bundle.id.mailboxes.id.domainsettings',
                        stateParams: { mailboxId: this.mailbox.id, bundleId: this.mailbox.bundleId },
                        title: this.$translate.instant('TR_090119-7178c1_TR')
                    }
                ];
            }
        }

        if (
            AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_SUPER_USER_READ)
            || (
                this.mailbox.accountId !== AuthContextService.account.id
                && this.authContext.isGrantedAll([UiRights.EMAIL_MAILBOX_EDIT, UiRights.EMAIL_CATCHALL_EDIT])
            )
        ) {
            if (this.comeFrom === 'products') {
                this.pageHeaderData.productActions = [
                    {
                        route: 'email.mailboxes.id.restrictions',
                        stateParams: { mailboxId: this.mailbox.id },
                        title: this.$translate.instant('TR_250519-7f279b_TR')
                    }
                ];
            } else if (this.comeFrom === 'bundle') {
                this.pageHeaderData.productActions = [
                    {
                        route: 'bundle.id.mailboxes.id.restrictions',
                        stateParams: { mailboxId: this.mailbox.id, bundleId: this.mailbox.bundleId },
                        title: this.$translate.instant('TR_250519-7f279b_TR')
                    }
                ];
            } else if (this.comeFrom === 'managed-servers') {
                this.pageHeaderData.productActions = [
                    {
                        route: 'managed-servers.id.mailboxes.id.restrictions',
                        title: this.$translate.instant('TR_090119-7178c1_TR')
                    }
                ];
            }
        }

        this.userPanelRight = {
            autoResponder: {
                editPanelButton: true
            },
            deletion: {
                delete: this.authContext.isGranted(UiRights.EMAIL_MAILBOX_DELETE),
                deletePermanently: this.authContext.isGranted(UiRights.EMAIL_MAILBOX_DELETE),
                editPanelButton:
                    this.authContext.isGranted(UiRights.EMAIL_MAILBOX_DELETE)
            },
            general: {
                editPanelButton: this.authContext.isGranted(UiRights.EMAIL_MAILBOX_EDIT),
                editPassword: this.authContext.isGranted(UiRights.EMAIL_IMAPMAILBOX_EDIT_PASSWORD)
            },
            mailingList: {
                editPanelButton: true // this.authContext.isGranted(UiRights.EMAIL_MAILINGLIST_EDIT)
            },
            permissions: {
                editPanelButton: true
            },
            restore: {
                editPanelButton: this.authContext.isGranted(UiRights.EMAIL_MAILBOX_RESTORE)
            },
            spamProtection: {
                editPanelButton: true
            },
            storageQouta: {
                editPanelButton:
                    this.mailbox.type !== 'Forwarder'
                    && this.authContext.isGranted(UiRights.EMAIL_IMAPMAILBOX_EDIT_STORAGE_QUOTA),
                overrideStorageQuota:
                    this.mailbox.type !== 'Forwarder'
                    && this.authContext.isGranted(UiRights.EMAIL_IMAPMAILBOX_EDIT_STORAGE_QUOTA)
            }
        };
    };
}

export class TemplateMailboxEditComponent implements ng.IComponentOptions {
    public bindings = {
        comeFrom: '<',
        mailbox: '<',
        maxMailingListMembers: '<',
        showObjectId: '<'
    };
    public controller = TemplateMailboxEditController;
    public controllerAs = '$TemplateMailboxEditCtrl';
    public template = require('./mailbox-edit-template.html');
}
