import * as ng from 'angular';
import * as Types from '../../../../../types';

export class OrganViewPanelDnssecSimpleController {
    public static $inject: string[] = [];

    public dnssec: Types.DnsApi.DnsSecOptions;
    public zone: Types.DnsApi.ZoneConfig;
    public showTables = false;

    public valueLookup = {
        algorithmValues: {
            3: 'DSA/SHA-1 (3)',
            5: 'RSA/SHA-1 (5)',
            6: 'DSA-NSEC3-SHA1 (6)',
            7: 'RSASHA1-NSEC3-SHA1 (7)',
            8: 'RSA/SHA-256 (8)',
            10: 'RSA/SHA-512 (10)',
            13: 'ECDSA Curve P-256 with SHA-256 (13)',
            14: 'ECDSA Curve P-384 with SHA-384 (14)'
        },
        digestTypeValues: {
            1: 'SHA-1 (1)',
            2: 'SHA-256 (2)',
            4: 'SHA-384 (4)'
        },
        keyTypeValues: {
            256: 'ZSK (256)',
            257: 'KSK (257)'
        }
    };

    public toggleTables = () => {
        this.showTables = !this.showTables;
    };

    public getBindFormat = () => {
        const data = this.dnssec.keys
        .map(
            (key) => [this.formatKey(key)]
            .concat(
                key.dsData.map(
                    (record) => this.formatDsRecord(key, record)
                )
            )
            .join('\n')
        )
        .join('\n');

        return data;
    };

    public formatKey = (key: Types.DnsApi.DnsSecKey) => {
        /* tslint:disable-next-line:max-line-length */
        return `${this.zone.name} IN DNSKEY ${key.keyTag} ${key.keyData.flags} ${key.keyData.protocol} ${key.keyData.algorithm} ${key.keyData.publicKey}`;
    };

    public formatDsRecord = (key: Types.DnsApi.DnsSecKey, record) => {
        /* tslint:disable-next-line:max-line-length */
        return `${this.zone.name} IN DS ${key.keyTag} ${record.algorithm} ${record.digestType} ${record.digest}`;
    };
}

export class OrganViewPanelDnssecSimpleComponent implements ng.IComponentOptions {
    public bindings = {
        dnssec: '<',
        zone: '<'
    };
    public controller = OrganViewPanelDnssecSimpleController;
    public template = require('./dnssec-simple.html');
}
