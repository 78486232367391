import ng from 'angular';

import {
    AccountHelperService, AuthContextService, BillingHelperService, FamilyProductBoxObject,
    ProductBoxObject, ProductHelperService, WizardNewHelperService
} from '@/services';
import * as Types from '@/types';

export class MoleculeFormProductBoxNewController implements ng.IController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'accountHelper',
        'billingHelper',
        'productHelper',
        'wizardNewHelper'
    ];
    public account: Types.AccountApi.Account;
    public boxContent: FamilyProductBoxObject | ProductBoxObject;
    public isSelected: boolean;
    public ignoreTabIndex = false;
    public isCurrentProduct = false;
    public displayPrice = true;
    public hideContractPeriod = false;
    public hidePriceInfo = false;

    public recommend = false;
    public recommendAny = false;
    public productCode: string;
    private _loadingSubaccountPrice = false;

    private _subaccountPrice: Types.BillingApi.ArticlePurchasePrice = null;

    private _numberElementsShown = 0;
    private _elementsShown: any = [];
    private _maxElementsPerBox = 5;
    private _keywordsDescriptions: any = [];
    private _billingCyclePeriodTranslation = '';

    constructor(
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        public accountHelper: AccountHelperService,
        private billingHelper: BillingHelperService,
        public productHelper: ProductHelperService,
        private wizardNewHelper: WizardNewHelperService
    ) {}

    public $onInit(): void {
        this._setProductCode();
        this._setbillingCycleInformations();
        this._checkSubaccountPrice();
        this._elementsShown = [];
        this._numberElementsShown = 0;
        if ('keywordsDescriptions' in this.boxContent) {
            this._keywordsDescriptions = this.boxContent.keywordsDescriptions;
        }
    }

    public $onChanges(changes: any) {
        if (changes.account !== undefined && !changes.account.isFirstChange()) {
            this._checkSubaccountPrice();
        }
    }

    public set isSubaccountPriceViewable({}) {} // tslint:disable-line:no-empty
    public get isSubaccountPriceViewable() {
        return !this.loadingSubaccountPrice
            && [undefined, null].indexOf(this._subaccountPrice) < 0
            && this._subaccountPrice.netAmount !== -1;
    }
    public set loadingSubaccountPrice({}) {} // tslint:disable-line:no-empty
    public get loadingSubaccountPrice() {
        return this._loadingSubaccountPrice;
    }

    public getTabIndex = () => {
        if (this.ignoreTabIndex === true) {
            return '-1';
        }
        return '0';
    };

    public getFactLabelTranslation = (property, raw?) => {
        raw = raw || false;

        if (!(property.keyword in this._elementsShown) && this._numberElementsShown <= this._maxElementsPerBox) {
            this._numberElementsShown++;
            this._elementsShown[property.keyword] = this.wizardNewHelper.getFactsheetTranslatedDescription(
                this._keywordsDescriptions,
                property
            );
        }

        return this.wizardNewHelper.getFactsheetLabelTranslation(this._elementsShown[property.keyword], raw);
    };

    public isFactValueBool = (property) => {
        const factDetail = this.getFactLabelTranslation(property, true);
        if ([undefined, null].indexOf(factDetail.type) >= 0) {
            return false;
        }
        return factDetail.type.toLowerCase() === 'bool';
    };

    public getFactValueTranslation = (property) => {
        return this.wizardNewHelper.getFactValueTranslation(
            this.getFactLabelTranslation(property, true),
            property
        );
    };

    public isInSummary = (property: any) => {
        if (this.showElement(property.keyword) === false && this._numberElementsShown === this._maxElementsPerBox) {
            return false;
        }
        if (this.boxContent.summaryKeywords === undefined) {
            return true;
        }
        for (const keyword of this.boxContent.summaryKeywords) {
            if (keyword.category === property.category && keyword.keyword === property.keyword) {
                return true;
            }
        }
        return false;
    };

    public set subaccountSelected({}) {} // tslint:disable-line:no-empty
    public get subaccountSelected() {
        return AuthContextService.account.id !== this.account.id;
    }

    public set billingCyclePeriodTranslation({}) {} // tslint:disable-line:no-empty
    public get billingCyclePeriodTranslation() {
        return this._billingCyclePeriodTranslation;
    }

    private _checkSubaccountPrice = () => {
        if (!this.subaccountSelected) {
            return;
        }
        this._loadingSubaccountPrice = true;
        this.billingHelper.getPriceByProductCode(this.productCode, this.account.id)
            .then((price) => {
                this.$timeout(() => {
                    this._subaccountPrice = price;
                    this._loadingSubaccountPrice = false;
                });
            }
        );
    };

    private _setbillingCycleInformations = () => {
        if ('billingCycles' in this.boxContent) {
            if (this.boxContent.billingCycles !== null) {
                const contractPeriod = this.billingHelper.sortBillingCyclesAsc(this.boxContent.billingCycles)[0];
                this._billingCyclePeriodTranslation = this.wizardNewHelper.getBillingCycleTranslation(contractPeriod);
            } else if ([undefined, null].indexOf(this.boxContent.productCode) < 0) {
                this.billingHelper.getPriceByProductCode(
                    (this.boxContent ).productCode,
                    this.account.id
                )
                .then((price) => {
                        this._billingCyclePeriodTranslation = this.wizardNewHelper
                            .getBillingCycleTranslation(price.contractPeriod);
                    }
                );
            } else {
                this._billingCyclePeriodTranslation = '';
            }
        }
    };

    private showElement = (element: string) => {
        return element in this._elementsShown || this._elementsShown.hasOwnProperty(element);
    };

    private _setProductCode = () => {
        if ('billingCycles' in this.boxContent) {
            this.productCode = this.productHelper.getProductCodeFromProductBoxObject(this.boxContent);
        }
    };
}

export class MoleculeFormProductBoxNewComponent implements ng.IComponentOptions {
    public bindings = {
        account: '<',
        boxContent: '<',
        displayPrice: '<',
        hideContractPeriod: '<?',
        hidePriceInfo: '<?',
        ignoreTabIndex: '<',
        isCurrentProduct: '<',
        isSelected: '<',
        recommend: '<?',
        recommendAny: '<?',
        serviceObject: '<'
    };
    public template = require('./product-box-new.html');
    public controller =  MoleculeFormProductBoxNewController;
}
