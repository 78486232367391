import { DefaultPasswordCharacterPoolsConst, NextcloudUserPasswordCharacterPoolsConst } from '@/configuration';
import { ViewTypes } from '@/types';
import * as ng from 'angular';
import { ValidatePassword } from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';

export class MoleculeInputTextNewPasswordController implements ng.IController {
    public static $inject: string[] = ['$translate'];

    public allowSimplePasswords = false;
    public ddnsPassword = false;
    public nextcloudPassword = false;
    public validationErrorList = [];
    public value: string;
    public placeholder: string;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnBlurParams: any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public modelOptions: FormInputModelOptions;
    public registerToForm: boolean;
    public repeatPassword: boolean;
    public options: any;
    public validationInstructions: any[];
    public inputId: string;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;
    public showSingleError = true;
    public showPasswordStrengthBar = true;
    public passwordSettings: ViewTypes.PasswordOptions = {
        allowEmpty: false,
        allowOtherCharacters: true,
        characterPools: DefaultPasswordCharacterPoolsConst,
        illegalCharacters: '',
        maxLength: null,
        minCharacterPoolsUsed: 2,
        minLength: 8,
        requireOtherCharacters: true
    };

    constructor(private $translate: ng.translate.ITranslateService) {}

    public $onInit() {
        if (this.options) {
            this.passwordSettings = Object.assign(
                {
                    allowEmpty: false,
                    allowOtherCharacters: true,
                    characterPools: DefaultPasswordCharacterPoolsConst,
                    illegalCharacters: '',
                    illegalValues: [''],
                    maxLength: null,
                    minCharacterPoolsUsed: 2,
                    minLength: 8,
                    requireOtherCharacters: false
                } as ViewTypes.PasswordOptions,
                this.options
            );
        }

        if (this.allowSimplePasswords === true) {
            this.passwordSettings.minCharacterPoolsUsed = 1;
            this.passwordSettings.minLength = 3;
            this.passwordSettings.requireOtherCharacters = false;
        }
        if (this.ddnsPassword === true) {
            this.passwordSettings.minCharacterPoolsUsed = 3;
            this.passwordSettings.minLength = 6;
            this.passwordSettings.requireOtherCharacters = false;
        }
        if (this.nextcloudPassword) {
            this.passwordSettings.minCharacterPoolsUsed = 3;
            this.passwordSettings.minLength = 10;
            this.passwordSettings.requireOtherCharacters = false;
            this.passwordSettings.characterPools = NextcloudUserPasswordCharacterPoolsConst;
        }
        this.validationInstructions = [
            {
                instructions: null,
                validator: new ValidatePassword(
                    this.$translate,
                    this.passwordSettings.illegalCharacters,
                    this.passwordSettings.characterPools,
                    this.passwordSettings.minCharacterPoolsUsed,
                    this.passwordSettings.allowOtherCharacters,
                    this.passwordSettings.requireOtherCharacters,
                    this.passwordSettings.minLength,
                    this.passwordSettings.maxLength,
                    this.passwordSettings.allowEmpty,
                    this.passwordSettings.illegalValues
                )
            }
        ];
    }

    public hideCharacters = () => {
        if ([null, undefined].indexOf(this.options) >= 0) {
            return true;
        }
        if ([null, undefined].indexOf(this.options?.hideCharacters) < 0) {
            return this.options.hideCharacters;
        }
        return true;
    };

    public onPaste = (paste) => {
        // implement callback on paste here when needed!
    };
}

export class MoleculeInputTextNewPasswordComponent implements ng.IComponentOptions {
    public bindings = {
        allowSimplePasswords: '<?',
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        ddnsPassword: '<?',
        disabled: '<',
        doNotSaveOnFormEnter: '<',
        inputId: '@?',
        modelOptions: '<',
        nextcloudPassword: '<?',
        options: '<',
        placeholder: '@',
        registerToForm: '<',
        repeatPassword: '<?',
        validationErrorList: '=?',
        value: '='
    };
    public controller = MoleculeInputTextNewPasswordController;
    public template = require('./input-text-with-validation-wrapper.html');
}
