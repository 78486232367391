import * as ng from 'angular';

import {
    PanelHeaderData
} from '../../../atomic-components/molecules/panels/panel-header/panel-header';

export class TemplateBundleBackupsController {
    public static $inject: string[] = ['$state', '$translate'];

    public pageHeaderData: PanelHeaderData;

    public databaseBackups;
    public webspaceBackups;
    public bundle;
    public userPanelRight = {};
    private webspace;

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService
    ) {}

    public $onInit = () => {
        let bundleId;
        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        // Bundle returns an array for `this.webspace`. Just take the first element then.
        if (Array.isArray(this.webspace) && [undefined, null].indexOf(this.webspace[0]) < 0) {
            this.webspace = this.webspace[0];
        }

        if (this.bundle !== undefined) {
            bundleId = this.bundle.id;
        } else if (this.webspace !== undefined) {
            bundleId = this.webspace.bundleId;
        }

        if (this.bundle === undefined) {
            this.bundle = null;
        }

        let panelHeaderBackwardLink;
        let panelHeaderBackwardText;
        let panelHeaderRouteParams;
        let panelHeaderTitle;

        switch (this.$state.current.name.split('.')[0]) {
            case 'bundle':
                panelHeaderBackwardText = this.$translate.instant('TR_090119-a32485_TR');
                panelHeaderTitle = this.$translate.instant('TR_140119-a775d7_TR');
                panelHeaderBackwardLink = 'bundle.id.dashboard';
                panelHeaderRouteParams = { bundleId: bundleId };
                break;
            case 'managed-servers':
                panelHeaderBackwardText = this.$translate.instant('TR_140119-e915b4_TR');
                panelHeaderTitle = this.$translate.instant('TR_140119-8c7ee2_TR');
                panelHeaderBackwardLink = 'managed-servers.id.webspaces.id.edit';
                panelHeaderRouteParams = {
                    machineId: this.$state.$current.locals.globals.machine.id,
                    webspaceId: this.$state.$current.locals.globals.webspace.id
                };
                break;
            default:
                panelHeaderBackwardText = this.$translate.instant('TR_140119-e915b4_TR');
                panelHeaderTitle = this.$translate.instant('TR_140119-8c7ee2_TR');
                panelHeaderBackwardLink = 'webhosting.webspaces.id.edit';
                panelHeaderRouteParams = { webspaceId: this.webspace.id };
        }

        this.pageHeaderData = {
            backwardLink: panelHeaderBackwardLink,
            backwardText: panelHeaderBackwardText,
            panelHeaderRoute: '',
            panelHeaderRouteParams: panelHeaderRouteParams,
            panelIcon: 'hdd',
            panelTitle: panelHeaderTitle
        };
    };
}

export class TemplateBundleBackupsComponent implements ng.IComponentOptions {
    public bindings = {
        bundle: '<',
        databaseBackups: '<',
        webspace: '<',
        webspaceBackups: '<'
    };
    public controller = TemplateBundleBackupsController;
    public controllerAs = '$TemplateBundleBackupsCtrl';
    public template = require('./bundle-backups-template.html');
}
