/* eslint-disable */
import { LinkListOverviewItem } from '@/atomic-components/molecules/links/link-list-overview/link-list-overview';
import {
    AccountFilterField,
    TextFilterField
} from '@/atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { OverviewDataObject } from '@/atomic-components/organisms/overview/overview';
import { ListCallbacks, OverviewModel } from '@/services/abstract';
import { RequestCancellerService } from '@/services/request-canceller';
import { DomainJobModelService } from '@/services';

import ng from 'angular';
import * as Types from '@/types';
export class DomainTransferOverviewModel extends OverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['$translate', 'domainJobModel', '$state']
    );

    public readonly service = 'domain';
    public readonly objectType = 'DomainTransfer';
    public readonly idField = 'jobId';
    public readonly defaultFilterFields = ['jobDomainNameAce'];

    public activeMassAction: string | boolean = false;
    public selected: string[] = [];
    public selectedAll = false;
    public actionLinksAvailabilities = {};
    public overviewData: OverviewDataObject;
    public overviewUtilityChart: unknown[] = [];

    protected listCallbacks: ListCallbacks[] = [];
    private _cachedAvailableFilters: {field: string; value: string}[] = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private $translate: ng.translate.ITranslateService,
        private domainJobModel: DomainJobModelService,
        private $state: ng.ui.IStateService
    ) {
        super(RequestCanceller);

        // declare multi action availably methods
        this.actionLinksAvailabilities = {
            transfersSelected: this.transfersSelected
        };

        // declare multi action availably methods
        this.cutomizedModelListMethods = false;

        this.listCallbacks = [{ callback: this.listTransfers }];
    }

    public get availableFilters() {
        // This is the attempt of fixing an unknown error that prevented the IDs from being translated.
        // Reference: PUI-5525
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('jobDomainNameAce',this.$translate.instant('07445a86-7211-449f-bfa1-d761f73e8786')),
                new AccountFilterField('jobAccountId', this.$translate.instant('TR_140119-b3b187_TR'))
            ];
        }

        return this._cachedAvailableFilters;
    }

    public set availableFilters(value) {
        return;
    }

    public listTransfers = (
        limit?: number,
        page?: number,
        filters?: Types.Finding.Filter,
        sort?: Types.Finding.SortOptions,
        withoutNavigation?: boolean
    ) => {
        withoutNavigation = withoutNavigation || false;

        if ([undefined, null, ''].indexOf(filters) >= 0) {
            filters = {
                subFilter: [
                    {
                        field: 'jobType',
                        value: 'domainTransferOut'
                    },
                    {
                        field: 'jobState',
                        value: 'waiting'
                    }
                ],
                subFilterConnective: 'AND'
            };
        }

        if (withoutNavigation || this.$state.current.name.split('.').indexOf('dashboard') >= 0) {
            return this.domainJobModel.listWithoutPagination(limit, page, filters, sort);
        } else {
            return this.domainJobModel.list(limit, page, filters, sort);
        }
    };

    public actionLinks = (): LinkListOverviewItem[] => {
        return [
            {
                isGranted: true,
                linkList: [
                    {
                        group: 'respond',
                        groupName: this.$translate.instant('TR_160119-6cb847_TR'),
                        isClickable: 'transfersSelected',
                        onclickMethode: () => { this.activeMassAction = 'domainTransferAck'; },
                        route: '',
                        text: 'ACK'
                    },
                    {
                        group: 'respond',
                        isClickable: 'transfersSelected',
                        onclickMethode: () => { this.activeMassAction = 'domainTransferNack'; },
                        route: '',
                        text: 'NACK'
                    }
                ],
                onlyOnExperView: true,
                route: '',
                text: this.$translate.instant('TR_140119-080106_TR'),
                type: 'multi'
            }
        ] as LinkListOverviewItem[];
    };

    public transfersSelected = (transfers: any[]) => {
        for (const transfer of transfers) {
            if (this.selected[transfer.id]) {
                return true;
            }
        }

        return false;
    };
    /* eslint-enable */
}
