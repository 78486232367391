import * as ng from 'angular';
import { OverviewDataObject } from '../../../../../../organisms';

export class MoleculeOverviewPricelistRowPanelDatabasesController implements ng.IController {
    public static $inject: string[] = [];

    public overviewData: OverviewDataObject;
}

export class MoleculeOverviewPricelistRowPanelDatabasesComponent implements ng.IComponentOptions {
    public require = {
        organismPricelistOverview: '^organismPricelistOverview'
    };
    public bindings = {
        overviewData: '<'
    };
    public controller =  MoleculeOverviewPricelistRowPanelDatabasesController;
    public controllerAs = 'OverviewPricelistCtrl';
    public template = require('./pricelist-databases-row.html');
}
