import * as ng from 'angular';
import * as q from 'q';

import * as Types from '../../types';
import { AlertManagerService } from '../alert-manager';
import { FunctionRelayService } from '../function-relay';
import { ModelHelper } from '../model-helper';
import { RequestStatus } from '../rpc-client';
import { EmailRobotService } from './robot';

export class MailboxModelService {
    public static $inject: string[] = [
        '$rootScope',
        '$timeout',
        'alertManager',
        'emailRobot',
        'functionRelay'
    ];

    private imapMailboxCache: any = {};

    constructor(
        private $rootScope: ng.IRootScopeService,
        private $timeout: ng.ITimeoutService,
        private alertManager: AlertManagerService,
        private emailRobot: EmailRobotService,
        private functionRelay: FunctionRelayService
    ) {}

    public getImapMailboxes(accountId: string): q.IPromise<any[]> {
        if (this.imapMailboxCache[accountId] !== undefined) {
            return q.when(ng.copy(this.imapMailboxCache[accountId]));
        }

        const filter = {
            subFilter: [
                { field: 'AccountId', value: accountId },
                { field: 'MailboxType', value: 'ImapMailbox' }
            ],
            subFilterConnective: 'AND'
        };

        return this.list(100, undefined, filter)
        .then(
            (response) => {
                this.imapMailboxCache[accountId] = ng.copy(response.data);
                return response.data;
            }
        );
    }

    public list = (limit?, page?, filters?, sort?) => {
        sort = sort || {
         field: 'mailboxEmailAddressUnicode',
         order: 'ASC'
        };
        page = page || 1;

        return this.emailRobot.listMailboxes(filters, limit, page, sort)
        .then(ModelHelper.returnListResponse);
    };

    public listWithoutPagination = (limit?, page?, filters?, sort?) => {
        sort = sort || {
         field: 'mailboxEmailAddressUnicode',
         order: 'ASC'
        };
        page = page || 1;

        return this.emailRobot.listMailboxesWithoutPagination(filters, limit, page, sort)
        .then(ModelHelper.returnListResponse);
    };

    public findOne = (id) => {
        return this.emailRobot.listMailboxesWithoutPagination({field: 'mailboxId', value: id}, 10, 1)
        .then(ModelHelper.returnFindOneResponse);
    };

    public findDomainsettingsByDomainName = (domainName: string) => {
        return this.emailRobot.oneDomainSettings(domainName)
            .then(ModelHelper.returnOneResponse);
    };

    public updateDomainsettings = (domainSettings: string) => {
        return this.emailRobot.domainSettingsUpdate(domainSettings);
    };

    public findById = (ids) => {
        const filter = { subFilterConnective: 'OR', subFilter: ids.map(this.idFilter) };
        return this.emailRobot.listMailboxesWithoutPagination(filter, 0, 1)
        .then(ModelHelper.returnFindResponse);
    };

    public findActiveMailAdressesByDomain = (
        domainNameUnicode,
        limit: number,
        additionalFilter?: Types.Finding.Filter
    ) => {
        const subFilter: Types.Finding.Filter[] = [
            { field: 'MailboxDomainNameUnicode', value: domainNameUnicode },
            { field: 'MailboxStatus', value: 'active' }
        ];

        if (additionalFilter !== undefined) {
            subFilter.push(additionalFilter);
        }

        return this.emailRobot.listMailboxesWithoutPagination(
            {
                subFilter: subFilter,
                subFilterConnective: 'AND'
            },
            limit,
            1
        )
        .then(ModelHelper.returnFindResponse);
    };

    public findMailAdressesByDomain = (domainNameUnicode, limit?: number, additionalFilter?: Types.Finding.Filter) => {
        limit = limit || 10;
        const subFilter: Types.Finding.Filter[] = [
            { field: 'MailboxDomainNameUnicode', value: domainNameUnicode }
        ];

        if (additionalFilter !== undefined) {
            subFilter.push(additionalFilter);
        }

        return this.emailRobot.listMailboxesWithoutPagination(
            {
                subFilter: subFilter,
                subFilterConnective: 'AND'
            },
            limit,
            1
        )
        .then(ModelHelper.returnFindResponse);
    };

    public listByOrganization = (limit?, page?, filters?, sort?) => {
        sort = sort || {
            field: 'mailboxEmailAddressUnicode',
            order: 'ASC'
        };
        page = page || 1;

        return this.emailRobot.listOrganizationMailboxes(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public listOrganization = (limit?, page?, filters?, sort?) => {
        sort = sort || {
            field: 'OrganizationName',
            order: 'ASC'
        };
        page = page || 1;

        return this.emailRobot.listOrganizations(filters, limit, page, sort)
            .then(ModelHelper.returnListResponse);
    };

    public findMailAdressesByOrganizationWithoutPagination = (
        organizationId,
        limit?: number,
        additionalFilter?: Types.Finding.Filter
    ) => {
        limit = limit || 10;
        const subFilter: Types.Finding.Filter[] = [
            { field: 'OrganizationId', value: organizationId }
        ];

        if (additionalFilter !== undefined) {
            subFilter.push(additionalFilter);
        }

        return this.emailRobot.listOrganizationMailboxesWithoutPagination(
            {
                subFilter: subFilter,
                subFilterConnective: 'AND'
            },
            limit,
            1
        )
        .then(ModelHelper.returnFindResponse);
    };

    public findMailAdressesByOrganization = (
        organizationId,
        limit?: number,
        additionalFilter?: Types.Finding.Filter
    ) => {
        limit = limit || 10;
        const subFilter: Types.Finding.Filter[] = [
            { field: 'OrganizationId', value: organizationId }
        ];

        if (additionalFilter !== undefined) {
            subFilter.push(additionalFilter);
        }

        return this.emailRobot.listOrganizationMailboxes(
            {
                subFilter: subFilter,
                subFilterConnective: 'AND'
            },
            limit,
            1
        ).then(ModelHelper.returnListResponse);
    };

    public delete = (mailboxes, permanently, date?) => {
        const promises = mailboxes.map(
            (mailbox) => {
                if (permanently) {
                    return this.deleteOnePermanently(mailbox, date);
                } else {
                    return this.deleteOne(mailbox, date);
                }
            });
        return q.all(promises)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public createMailbox = (mailbox, password, owner, poolId, autoconfigureDns?: boolean, skipDnsCheck?: boolean) => {
        return this.emailRobot.createMailbox(mailbox, password, owner, poolId, autoconfigureDns, skipDnsCheck)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED))
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public createOrganization = (organization, owner) => {
        return this.emailRobot.createOrganization(organization, owner)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED))
            .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public mailboxModifyRestrictions = (apiObject, owner?: string) => {
        return this.emailRobot.mailboxModifyRestrictions(apiObject, owner)
            .then(this.returnResponse)
            .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public update = (mailbox, password) => {
        return this.emailRobot.updateMailbox(mailbox, password)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED))
        .then(this.refreshDeposit(), this.refreshDeposit(RequestStatus.FAILED));
    };

    public emailCheck = (email, owner, bundleId, productCode, skipDnsCheck?) => {
        /* if ([undefined, null, ''].includes(email) || !email.includes('@') || !email.includes('.')) {
            return $q.when({
                status: 'emailAddressInvalid',
                verificationCode: null
            });
        }*/
        return this.emailRobot.checkEmailAddress(email, owner, bundleId, productCode, skipDnsCheck)
            .then(this.returnResponse);
    };

    public restore = (mailboxes) => {
        const promises = ModelHelper.sequentiallyRequestApi(
            mailboxes,
            'id',
            this,
            'restoreOne',
            /* translationID */'TR_070819-6042e9_TR',
            this.$timeout,
            this.alertManager
        );

        return promises.then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public purgeRestorable = (mailboxId) => {
        return this.emailRobot.purgeRestorableMailbox(mailboxId)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    public mailboxDeletionCancel = (mailboxId) => {
        return this.emailRobot.mailboxDeletionCancel(mailboxId)
        .then(this.refresh(), this.refresh(RequestStatus.FAILED));
    };

    private refresh = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.$rootScope.$broadcast('mailboxes.refresh');
        this.imapMailboxCache = {};
        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return q.reject(result);
        }
    };

    private refreshDeposit = (status: RequestStatus = RequestStatus.SUCCESSFUL) => (result) => {
        this.functionRelay.call('updateDeposit', {});
        if (status === RequestStatus.SUCCESSFUL) {
            return result;
        } else {
            return q.reject(result);
        }
    };

    private idFilter = (id) => {
        return {field: 'mailboxId', value: id};
    };

    private returnResponse = (result) => {
        return result.response;
    };

    private deleteOne = (mailbox, date?) => {
        return this.emailRobot.deleteMailbox(mailbox, date);
    };

    private deleteOnePermanently = (mailbox, date?) => {
        return this.emailRobot.deletePermanentlyMailbox(mailbox, date);
    };

    private restoreOne = (mailbox) => {
        return this.emailRobot.restoreMailbox(mailbox.id);
    };
}
