import * as ng from 'angular';
import { NextcloudHelperService } from '@/services';
import * as Types from '@/types';
import { ProductApi, UI } from '@/types';

export interface ProductConfigNextcloudKnownAddonsObject {
    uiName: string;
    unit: string;
    labelText: string;
}

export interface ProductConfigNextcloudAddonMetadataObject {
    amount: number;
    current: number;
    initial: number;
    productCode: string;
    productData: ProductApi.Product;
    step: number;
}

export interface ProductConfigVoucher {
    costFreePeriodInDays: number;
    eligibleProductCodes: string[];
    end: string;
    start: string;
    type: string;
    voucherCode: string;
}

export interface ProductConfigNextcloudObject {
    account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    addons?: {
        [key: string]: ProductConfigNextcloudAddonMetadataObject;
    };
    displayname: string;
    maxNumberOfUsers: number;
    nextcloudBackupProductCode: string;
    nextcloudProductCode: string;
    nextcloudUser: {
        password: string;
        user: {
            emailAddress: string;
            name: string;
            username: string;
        };
    };
    talkUsers: number;
    voucher?: ProductConfigVoucher;
    productMaxStorageQuota: number;
}

export class OrganismNextcloudWizardProductConfigController {
    public static $inject: string[] = ['$timeout', '$translate', 'nextcloudHelper'];

    public metadata: ProductConfigNextcloudObject;
    public formCompleted = {
        nextcloud: ()           => {/* fol */},
        nextcloudConditions: () => {/* fol */},
        nextcloudUser: ()       => {/* fol */}
    };
    public addonData: UI.nextcloudAggregatedProductAddonsData;
    public knownAddons: ProductConfigNextcloudKnownAddonsObject[];
    public onChangeCallback: () => unknown;

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private _selectedProductCode: string = null;

    public constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        public nextcloudHelper: NextcloudHelperService
    ) {}

    public $onInit(): void {
        this._setMetadata();
        this.knownAddons = [
            {
                uiName: 'storage',
                unit: 'MB',
                labelText: this.$translate.instant('0bbaab10-cf54-4d19-84bb-261d877e6605')
            },
            {
                uiName: 'users',
                unit: this.$translate.instant('TR_220519-49de95_TR'),
                labelText: this.$translate.instant('TR_110919-292d2b_TR')
            },
            {
                uiName: 'officeUsers',
                unit: this.$translate.instant('TR_220519-49de95_TR'),
                labelText: this.$translate.instant('TR_120221-a0145f_TR')
            },
            {
                uiName: 'talkUsers',
                unit: this.$translate.instant('TR_220519-49de95_TR'),
                labelText: this.$translate.instant('TR_281220-adc47d_TR')
            }
        ];
    }

    get selectedProductCode(): string {
        return this._selectedProductCode;
    }

    set selectedProductCode(value: string) {
        this._selectedProductCode = value;

        if (this._selectedProductCode !== undefined) {
            if (typeof this._selectedProductCode === 'string') {
                void this.nextcloudHelper.aggregateProductAddonsData(this._selectedProductCode).then(
                    (aggregatedProductAddonData) => {
                        void this.$timeout(() => {
                            this.addonData = aggregatedProductAddonData;
                            this._setMetadata(aggregatedProductAddonData);
                        });
                    }
                );
            }
        }
    }

    // eslint-disable-next-line no-empty-pattern,@typescript-eslint/no-empty-function
    public set configCompleted({}) {} // tslint:disable-line:no-empty
    public get configCompleted(): boolean {
        let completed = true;

        for (const part in this.formCompleted) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore TS7053
            if (!this.formCompleted[part]) {
                completed = false;
            }
        }

        return completed;
    }

    private _setMetadata = (aggregatedData?: UI.nextcloudAggregatedProductAddonsData): void => {
        if (aggregatedData?.storage?.isExtensible) {
            this.metadata.addons.storage = {
                amount: 0,
                current: (aggregatedData.storage.included as number),
                initial: (aggregatedData.storage.included as number),
                productCode: aggregatedData.storage.productData.productCode,
                productData: aggregatedData.storage.productData,
                step: (aggregatedData.storage.step as number)
            };
        }

        if (aggregatedData?.users?.isExtensible) {
            this.metadata.addons.users = {
                amount: 0,
                current: (aggregatedData.users.included as number),
                initial: (aggregatedData.users.included as number),
                productCode: aggregatedData.users.productData.productCode,
                productData: aggregatedData.users.productData,
                step: (aggregatedData.users.step as number)
            };
        }

        if (aggregatedData?.officeUsers?.isExtensible) {
            this.metadata.addons.officeUsers = {
                amount: 0,
                current: (aggregatedData.officeUsers.included as number),
                initial: (aggregatedData.officeUsers.included as number),
                productCode: aggregatedData.officeUsers.productData.productCode,
                productData: aggregatedData.officeUsers.productData,
                step: (aggregatedData.officeUsers.step as number)
            };
        }

        if (aggregatedData?.talkUsers?.isExtensible) {
            this.metadata.addons.talkUsers = {
                amount: 0,
                current: (aggregatedData.talkUsers.included as number),
                initial: (aggregatedData.talkUsers.included as number),
                productCode: aggregatedData.talkUsers.productData.productCode,
                productData: aggregatedData.talkUsers.productData,
                step: (aggregatedData.talkUsers.step as number)
            };
        }

        if (aggregatedData?.productData?.specificationItems) {
            let storageQuotaMaximum = -1;
            aggregatedData.productData.specificationItems.some(
                (item) => {
                    if (item.name === 'storageQuotaMaximum' && item.type === 'NumberSpecificationItem') {
                        storageQuotaMaximum = item.value;
                        return true;
                    }

                    return false;
                }
            );

            this.metadata.productMaxStorageQuota = storageQuotaMaximum;
        }
    };

}

export class OrganismNextcloudWizardProductConfigComponent implements ng.IComponentOptions {
    public bindings = {
        _account: '<account',
        selectedProductCode: '<',
        configCompleted: '=',
        metadata: '=',
        onChangeCallback: '<?',
        voucherCode: '<'
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    public template = require('./nextcloud-wizard-product-config.html');
    public controller = OrganismNextcloudWizardProductConfigController;
}
