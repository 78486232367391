import { AuthContextService, ProductsRobotService } from '@/services';
import * as ng from 'angular';

import { NextcloudHelperService } from '../../../../services/helpers/nextcloud-helper';
import * as Types from '../../../../types';
import { LinkListItem } from '../../links/link-list/link-list';
import { UiRights } from '@/configuration';

export class MoleculePanelNextcloudStatusController implements ng.IController {
    public static $inject: string[] = ['$state', '$translate', 'nextcloudHelper', 'productsRobot'];

    public nextcloud: Types.ManagedApplicationApi.Nextcloud;
    public nextcloudProduct: Types.ProductApi.TemplateProduct;
    public filteredPanelItemList: LinkListItem[];
    public talkUsersGaugeDisabled = true;

    public nextcloudApps: Types.ManagedApplicationApi.NextcloudUserPlugin[];
    public nextcloudHasUnfinishedJob = false;

    private panelItemList: LinkListItem[];

    constructor(
        private $state: ng.ui.IStateService,
        private $translate: ng.translate.ITranslateService,
        private nextcloudHelper: NextcloudHelperService,
        private productsRobot: ProductsRobotService
    ) {

        const baseState = this.$state.current.name.split('.').slice(0, -1).join('.');

        this.panelItemList = [
            {
                route: `${baseState}.upgrade`,
                text: this.$translate.instant('TR_240420-8179bc_TR')
            },
            {
                route: `${baseState}.config`,
                text: this.$translate.instant('TR_230621-29daf9_TR')
            },
            {
                route: `${baseState}.addons`,
                text: this.$translate.instant('TR_180221-2542cc_TR')
            },
            {
                route: `${baseState}.use-own-domain`,
                text: this.$translate.instant('TR_220519-9a4f92_TR')
            }

        ];

    }
    private addBackupToPanelItemList(baseState: string): void{
        if (
            this.nextcloud.backupEnabled
            && AuthContextService.isGrantedAny(
                [ UiRights.ADMIN_SYSTEM_SUPER_USER_EDIT, UiRights.ADMIN_SYSTEM_INFRASTRUCTURE_ADMIN ]
            )
        ){
            this.panelItemList.push({
                route: `${baseState}.backup`,
                text: this.$translate.instant('TR_080119-57a2c9_TR')
            });
        }

        if (!this.nextcloud.backupEnabled){
            this.panelItemList.push({
                route: `${baseState}.addons`,
                text: this.$translate.instant('TR_110119-33267b_TR')
            });
        }
    }

    public async $onInit() {
        const baseState = this.$state.current.name.split('.').slice(0, -1).join('.');
        this.addBackupToPanelItemList(baseState);

        this.nextcloudHelper.checkNextcloudJobRunning(this.nextcloud.id).then((jobRunning) => {
            this.nextcloudHasUnfinishedJob = jobRunning;
        });

        this.nextcloudProduct = (
            await this.productsRobot
                .productFind(this.nextcloud.productCode))
            .response as Types.ProductApi.TemplateProduct;

        this.panelItemList.forEach(
            (panelItem, key) => {
                if (
                    panelItem.route.indexOf('use-own-domain') >= 0
                    && this.nextcloud.individualDomainNameStatus === 'active'
                ) {
                    panelItem.text = this.$translate.instant('TR_120321-a7df90_TR');
                }

                Object.defineProperty(
                    panelItem,
                    'disabled',
                    {
                        get: () => this.nextcloudHasUnfinishedJob
                    }
                );
            }
        );

        this.filteredPanelItemList = this.panelItemList.filter((el) => {
            return el !== undefined && el.route.indexOf((this.$state.current.url as string).replace('/', '')) < 0;
        });
    }

    public get maxTalkUsers() {
        if (!this.nextcloudProduct) {
            return undefined;
        }

        const talkUsersMaximumSpec = this.nextcloudProduct
            ?.specificationItems
            ?.find((specItem) => specItem.name === 'talkUsersMaximum')
            ?.value;

        if (!talkUsersMaximumSpec) {
            return undefined;
        }

        return parseInt(talkUsersMaximumSpec, 10);
    }

    public get talkUserQuotaUsagePercentage() {
        return (this.maxTalkUsers !== undefined)
            ? ((this.nextcloud.talkUsers / this.maxTalkUsers) * 100)
            : 0;
    }

    public get userQuotaUsagePercentage() {
        return ((this.nextcloud.currentNumberOfUsers / this.nextcloud.maxNumberOfUsers) * 100);
    }

    public get appsQuotaUsagePercentage() {
        return ((this.nextcloud.enabledApps.length / this.nextcloudApps.length) * 100);
    }

    public get nextcloudVersionText() {
        const nextcloudVersion = this.nextcloud.nextcloudVersion !== null
            ? this.nextcloud.nextcloudVersion
            : this.$translate.instant('GENERAL.OBJECT.STATUS.UNKNOWN');

        return `${this.$translate.instant('c0470e54-9d75-489a-8ebf-ae858e5a7996')}: ${nextcloudVersion}`;
    }
}

export class MoleculePanelNextcloudStatusComponent implements ng.IComponentOptions {
    public bindings = {
        nextcloud: '<'
    };

    public controller =  MoleculePanelNextcloudStatusController;
    public template = require('./panel-nextcloud-status.html');
}
