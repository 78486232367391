import ng from 'angular';

import { Contingent } from '@/atomic-components/organs/create/configuration/general/contingents';
import { AuthContextService } from '@/services';
import { AccountApi } from '@/types';

export class MoleculePanelRowProductPriceController implements ng.IController {
    public static $inject: string[] = ['$timeout'];

    public contingentChanged: boolean;
    public pricePostfixText: string;
    public priceMultiplicator: number;
    public priceStroke: boolean;
    public productCode: string;
    public selectedAccount: AccountApi.Account | AccountApi.Subaccount;

    private _contingent: Contingent;
    private _customLabel: string;

    public constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    public get contingent(): Contingent {
        return this._contingent;
    }
    public set contingent(value: Contingent) {
        this.contingentChanged = true;
        void this.$timeout(() => {
            this.contingentChanged = false;
        });
        this._contingent = value;
    }

    public set issetProductCode(_) {/* */}
    public get issetProductCode(): boolean {
        return [undefined, null, ''].indexOf(this.productCode) < 0;
    }

    public set customLabel(_) {/* */}
    public get customLabel(): string {
        return this._customLabel;
    }

    public set customPriceLabelViewable(_) {/* */}
    public get customPriceLabelViewable(): boolean {
        return this.customLabel !== undefined
            && this.customLabel !== ''
        ;
    }

    public set ownAccountPriceLabelViewable(_) {/* */}
    public get ownAccountPriceLabelViewable(): boolean {
        return !this.customPriceLabelViewable
            && AuthContextService.account.id === this.selectedAccount.id;
    }

    public set defaultPriceLabelViewable(_) {/* */}
    public get defaultPriceLabelViewable(): boolean {
        return !this.customPriceLabelViewable
            && !this.ownAccountPriceLabelViewable;
    }

    public set subaccountPriceViewable(_) {/* */}
    public get subaccountPriceViewable(): boolean {
        return AuthContextService.account.id !== this.selectedAccount.id;
    }
}
export class MoleculePanelRowProductPriceComponent implements ng.IComponentOptions {
    public bindings = {
        _customLabel: '@?customLabel',
        contingent: '<?',
        productCode: '<',
        priceMultiplicator: '<?',
        pricePostfixText: '<?',
        priceStroke: '<?',
        selectedAccount: '<'
    };
    public controller = MoleculePanelRowProductPriceController;
    public template = require('./panel-row-product-price.html');
}
