import * as ng from 'angular';
import { StorageModelService } from '../../../../../services';
import { WizardCreateObject } from '../../general';
import { NextcloudWizardConfirmation } from './';

export class OrganismNextcloudWizardConfirmViewController {
    public static $inject: string[] = [
        '$translate',
        'localInterval',
        'nextcloudWizardConfirmation',
        'storageModel'
    ];

    public wizardCreateObjectList: WizardCreateObject[] = [];
    private _apiProductObject: any; // Required information for the products that need to be ordered.

    public constructor(
        private $translate: ng.translate.ITranslateService,
        private localInterval: ng.IIntervalService,
        private nextcloudWizardConfirmation: NextcloudWizardConfirmation,
        private storageModel: StorageModelService
    ) {}

    public $onDestroy() {
        this.localInterval.cancel(this.nextcloudWizardConfirmation.checkJobStatusIntervalHandler);
    }

    public $onInit() {
        this.nextcloudWizardConfirmation.useOrchestrationService = false;
        // Build the promise-chain. For nextcloud its just the create-call.
        if (this._apiProductObject.apiObject.nextcloud) {
            this.wizardCreateObjectList.push({
                callback:
                    () => {
                        return this.storageModel
                            .nextcloudCreate(
                                this._apiProductObject.apiObject.nextcloud,
                                this._apiProductObject.apiObject.users,
                                this._apiProductObject.apiObject.appConfigs,
                                this._apiProductObject.apiObject.voucher?.voucherCode
                            );
                    },
                labelText: this.$translate.instant('TR_140519-4f0af6_TR'),
                objectType: 'Nextcloud'
            });
        }
    }
}

export class OrganismNextcloudWizardConfirmViewComponent implements ng.IComponentOptions {
    public bindings = {
        _apiProductObject: '<apiProductObject'
    };
    public template = require('./nextcloud-wizard-confirm-view.html');
    public controller = OrganismNextcloudWizardConfirmViewController;
}
