import * as ng from 'angular';
import { Countries } from '../configuration';

export class CountryListService {
    public static $inject: string[] = ['$translate'];

    constructor(private $translate: ng.translate.ITranslateService) {}

    public getRawList = () => {
        const countryList = {};
        Countries.forEach((country) => {
            countryList[country.countryCode.toLowerCase()] = this.$translate.instant(country.tId);
        });
        return countryList;
    };

    public getList = () => {
        return this.formatList(this.getRawList());
    };

    private formatList = (result) => {
        return Object.keys(result)
        .map((key) => ({ code: key.toLowerCase(), name: result[key] }));
    };
}
