import ng from 'angular';

import { UiLanguagesConst, UIRegExp } from '@/configuration';
import { CalculatePriceFilter, ConvertAmountFilter } from '@/filters';
import {
    AlertManagerService, ApiErrorModel, AuthContextService, MachineModelService, NavigationService,
    PriceModelService, ProductsModelService, SelectBoxContentCreator
} from '@/services';

export class OrganEditPanelMachineUpgradeController {
    public static $inject: string[] = [
        '$q',
        'alertManager',
        'apiErrorModel',
        'authContext',
        'calculatePriceFilter',
        'convertAmountFilter',
        'machineModel',
        'navigation',
        'priceModel',
        'productsModel',
        'selectBoxContentCreator',
        '$translate'
    ];

    public machine: any;
    public language: string;
    public restartVm = false;
    public upgradeConfirmed = false;
    public productFamilies: any = [];
    public currentProductName = '';
    public currentProductCode = '';
    public contractPeriod = '6';
    public currentProductFamily: string|null = null;
    public currentProductBox: any = [];
    public targetProductBoxes: any = [];
    public selectedProductCode = '';
    public selectedProductName = '';
    public machinePriceList: any = [];
    private _changeType = '';

    constructor(
        private $q: ng.IQService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private authContext: AuthContextService,
        private calculatePriceFilter: CalculatePriceFilter,
        private convertAmountFilter: ConvertAmountFilter,
        private machineModel: MachineModelService,
        private navigation: NavigationService,
        private priceModel: PriceModelService,
        private productModel: ProductsModelService,
        private selectBoxContentCreator: SelectBoxContentCreator,
        protected $translate: ng.translate.ITranslateService
    ) {}

    public $onInit() {
        this._changeType = this.$translate.instant('TR_110119-d5119c_TR');
        this.language = UiLanguagesConst[this.authContext.user.language];
        this.currentProductCode = this.machine.productCode;
        this.priceModel.getMachinePrices().then(
            (apiResponse) => {
                this.machinePriceList = apiResponse;
            }
        );

        // parse current contracts contractual period from the product code
        this.contractPeriod = UIRegExp.ProductCodeToContractPeriod.exec(this.machine.productCode)[1];

        // set local product family information via API
        const productFamilyDetails = this.setProductFamilyData();

        this.generateProductFamilySelectBoxContent(productFamilyDetails);
    }

    get contractPeriodText(): string {
        switch (this.contractPeriod) {
            case '1':
                return 'monthly';
            case '3':
                return 'quarterly';
            case '6':
                return 'semiannually';
            case '12':
                return 'annually';
            default:
                return this.contractPeriod + ' ' + this.$translate.instant('GENERAL.MONTHS');
        }
    }

    get changeType(): string {
        if  ([null, undefined, ''].indexOf(this.selectedProductCode) === -1) {
            const selectedProductFamily = this.productFamilies.filter(
                (family) => {
                    return this.selectedProductCode === family.productCode;
                }
            );
            if (selectedProductFamily[0].changeMode === 'downgrade') {
                return this.$translate.instant('TR_110119-6754fe_TR');
            } else {
                return this.$translate.instant('TR_110119-d5119c_TR');
            }
        }
        return this._changeType;
    }

    public get showSavePanel() {
        return this.upgradeConfirmed;
    }

    public isAdmin = () => {
        return this.authContext.isRootOrCompanyAccount === true;
    };

    public getPriceByProductCode: any = (productCode: string, type: string|null = null) => {
        if (type === null) {
            type = this.authContext.account.isCommercialCustomer ? 'net' : 'gross';
        }
        if ([undefined].indexOf(this.machinePriceList) !== -1) {
            return '';
        }
        let price = this.machinePriceList.filter(
            (priceRow) => {
                return priceRow.productCode === productCode;
            }
        );
        if ([null, undefined].indexOf(price) >= 0 || price instanceof Array === false || price.lenght === 0) {
            return '';
        }
        price = price[0];
        // calculate price and break it down to the monthly cost
        return this.calculatePriceFilter(
            price.price,
            price.vatRate,
            1,
            type,
            undefined,
            false,
            price.productCode,
            price.contractPeriod
        ) / parseInt(this.contractPeriod, 10);
    };

    public getBruttoNettoText = (productCode: string, secondProductCode: string|null = null) => {
        if (this.authContext.account.isCommercialCustomer === false) {
            return 'brutto';
        }
        let price = this.getPriceByProductCode(productCode, 'gross');
        if (price === '') {
            return '';
        }
        if (secondProductCode !== null) {
            price = price - this.getPriceByProductCode(secondProductCode, 'gross');
        }
        const bruttoNettoText = 'netto (' + this.convertAmountFilter(price) + ' '
            + this.getCurrencyByProductCode(productCode) + ' brutto)';
        return bruttoNettoText;
    };

    public getCurrencyByProductCode = (productCode: string) => {
        if ([undefined].indexOf(this.machinePriceList) !== -1) {
            return '';
        }
        let price = this.machinePriceList.filter(
            (priceRow) => {
                return priceRow.productCode === productCode;
            }
        );
        if ([null, undefined].indexOf(price) >= 0 || price instanceof Array === false || price.lenght === 0) {
            return '';
        }
        price = price[0];
        return price.currency;
    };

    public selectTarget = (item) => {
        this.selectedProductCode = this.replaceBillingCycleInProductCodeTemplate(item.productCodeTemplate);
        this.selectedProductName = item.name;
    };

    public isSelectedProduct = (item) => {
        return this.replaceBillingCycleInProductCodeTemplate(item.productCodeTemplate) === this.selectedProductCode;
    };

    public upgradeMachine = () => {
        this.apiErrorModel.destroyErrorList();
        this.machineModel.changeProduct(this.machine.id, this.selectedProductCode, this.restartVm).then(
            (response) => {
                if (!this.apiErrorModel.apiResponseHasError(response)) {
                    this.alertManager.success(this.$translate.instant('TR_110119-a4fe2a_TR'));
                    this.navigation.go(
                        'machine.virtualmachines.id.edit',
                        { machineId: this.machine.id },
                        { reload: true }
                    );
                    return response;
                }
                return this.$q.reject(false);
            },
            (error) => {
                this.alertManager.error(this.$translate.instant('TR_110119-467607_TR'));
                return this.$q.reject(error);
            }
        );
    };

    private replaceBillingCycleInProductCodeTemplate = (productCodeTemplate) => {
        return productCodeTemplate.replace(UIRegExp.BillingCyclePlaceholder, this.contractPeriod);
    };

    private setProductFamilyData() {
        return this.productModel.findOne(this.machine.productCode, this.language).then(
            (productDetails) => {
                this.currentProductName = productDetails.response.name;
                return this.productModel.findProducts('machine', productDetails.response.family, this.language);
            }
        ).then(
            (productFamilyDetails) => {
                let currentServerTierHit = false;
                this.productFamilies = productFamilyDetails.response.data.map(
                    (productFamily) => {
                        if (this.currentProductFamily === null) {
                            this.currentProductFamily = productFamily.family;
                        }
                        if (productFamily.name === this.currentProductName) {
                            currentServerTierHit = true;
                            return false;
                        }
                        const drilldownProductFalilyInfo: any = {};
                        if (currentServerTierHit === true) {
                            drilldownProductFalilyInfo.changeMode = 'upgrade';
                        } else {
                            // currently downgrades are not possible #api
                            return false;
                            // drilldownProductFalilyInfo.changeMode = 'downgrade';
                        }
                        drilldownProductFalilyInfo.productCode = this.replaceBillingCycleInProductCodeTemplate(
                            productFamily.productCodeTemplate
                        );
                        return drilldownProductFalilyInfo;
                    }
                ).filter((productFamily) => productFamily !== false);
            }
        );
    }

    private generateProductFamilySelectBoxContent = (productFamilyDetails) => {
        productFamilyDetails.then(
            (response) => {
                const boxItems = this.selectBoxContentCreator.createSelectBoxes(
                    'machine', [this.currentProductFamily], false
                );
                boxItems.then((boxContent) => {
                    this.targetProductBoxes = ng.copy(boxContent);
                    this.currentProductBox = ng.copy(boxContent);
                    this.currentProductBox[0].products = this.currentProductBox[0].products.filter((product) => {
                        return this.currentProductCode === this.replaceBillingCycleInProductCodeTemplate(
                            product.productCodeTemplate
                        );
                    });
                    this.targetProductBoxes[0].products = this.targetProductBoxes[0].products.filter((product) => {
                        return this.productFamilies.some((family) => {
                            return family.productCode === this.replaceBillingCycleInProductCodeTemplate(
                                product.productCodeTemplate
                            );
                        });
                    });
                });
            }
        );
    };
}

export class OrganEditPanelMachineUpgradeComponent implements ng.IComponentOptions {
    public bindings = {
        machine: '<'
    };
    public template = require('./machine-upgrade.html');
    public controller = OrganEditPanelMachineUpgradeController;
}
