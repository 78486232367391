import * as ng from 'angular';

import { NavigationService } from '@/services';

import {
    AuthenticationRobotService, TwoFaHelperService, UserModelService
} from '../../../services';
import { AuthContextService } from '../../../services/auth-context';

export const AUTHENTICATION_LOGIN_ROUTES = [
    {
        name: 'login',

        state: {
            data: {
                authenticate: false
            },
            onEnter: () => {
                ng.element(document.getElementsByTagName('body')[0]).addClass('login');
            },
            resolve: {
                expired: ($stateParams: ng.ui.IStateParamsService) => {
                    return $stateParams.expired;
                }
            },
            url: '/login?expired',
            views: {
                'wrapper@': {
                    template: '<template-login expired="$resolve.expired"></tempate-login>'
                }
            }
        }
    }
];

export class TemplateLoginController implements ng.IController {
    public static $inject: string[] = [
        '$location',
        '$timeout',
        '$window',
        'authenticationRobot',
        'navigation',
        'twoFaHelper',
        'userModel'
    ];
    public isInvalid: boolean;
    public expired: boolean;
    public showRegLink: boolean; // ToDo: Actually show registration link? This is currently unused...
    public step: string;
    public timer2faHint: boolean;
    public showPasscodes = false;
    public errorText = '';
    public twofaData: any = {
        password: '',
        qrUrlString: '',
        status: '',
        token: ''
    };
    public last2FaToken: string;

    private inProgress = false;
    private timer2fa: ng.IPromise<void> | ng.IPromise<any>;
    private credentials: any;
    private userData: any;

    constructor(
        private $location: ng.ILocationService,
        private $timeout: ng.ITimeoutService,
        private $window: ng.IWindowService,
        private authenticationRobot: AuthenticationRobotService,
        private navigation: NavigationService,
        private twoFaHelper: TwoFaHelperService,
        private userModel: UserModelService
    ) {
        this.credentials = {email: '', password: '', token: ''};
        this.isInvalid = false;
        this.showRegLink = (['secure.hosting.de'].indexOf(this.$location.host()) >= 0);
        this.step = 'login';
        this.timer2faHint = false;
    }

    public $onDestroy() {
        this.resetTimer2fa();
    }

    public $doCheck(): void {
        // @ts-ignore TS2339
        const code2Fa = (this.twofaData.confirmationCode as string);

        if (this.step === 'enabling2fa' && code2Fa?.length === 6 && code2Fa !== this.last2FaToken) {
            this.last2FaToken = code2Fa;
            this.enableTwoFa();
        }
    }

    public submit = () => {
        if (
            this.inProgress
            || [undefined, null, ''].indexOf(this.credentials.email) >= 0
            || [undefined, null, ''].indexOf(this.credentials.password) >= 0
        ) {
            return;
        }

        this.inProgress = true;
        this.timer2faHint = false;

        this.authenticationRobot.login(this.credentials.email, this.credentials.password)
        .then(
            (result: any) => {
                if (result.errorCode !== undefined) {
                    if (result.errorCode === 20312) {
                        this.$timeout(() => this.step = '2faActive');
                        this.inProgress = false;

                        this.timer2fa = this.$timeout(
                            () => {
                                this.step = 'login';
                                this.timer2faHint = true;
                                this.credentials = {email: '', password: '', token: ''};
                            },
                            60 * 1000
                        );
                    } else {
                        this.errorText = result.errorText;
                        this.triggerInvalidAnimation();
                    }
                } else if (
                    result.response.user !== undefined
                    && ['waitingForActivation', 'required'].indexOf(result.response.user.twoFactorAuthStatus) >= 0
                ) {
                    this.errorText = '';
                    this.step = 'enabling2fa';
                    this.userData = result.response.user;

                    this.twoFaHelper.enableGoogleAuthenticator(result.response.user, this.credentials.password)
                    .then(
                        (reply) => {
                            if (!reply.issetQrCode || reply.error != null) {
                                this.twofaData.password = '';
                            } else {
                                this.twofaData.password = '';
                                this.twofaData.token = reply.qrString;
                                this.twofaData.qrUrlString = reply.qrUrlString;
                                this.twofaData.status = 'waitingForActivation';
                            }
                        }
                    );
                } else if (result.response.user.twoFactorAuthStatus === 'disabled') {
                    this.redirectToAvvIfUnsigned();
                }
            },
            () => this.triggerInvalidAnimation()
        );
    };

    public submitTwoFactor = () => {
        if (this.inProgress) {
            return;
        }

        this.inProgress = true;

        this.authenticationRobot.login(this.credentials.email, this.credentials.password, this.credentials.token)
        .then(
            (response: any) => {
                if (response !== undefined && response.errorCode === 10105) {
                    this.triggerInvalidAnimation();
                    this.inProgress = false;
                }

                this.redirectToAvvIfUnsigned();
            },
            () => {
                this.triggerInvalidAnimation();
                this.inProgress = false;
            }
        );
    };

    public enableTwoFa = () => {
        this.userModel.userActivateTwoFactorAuthentication(this.userData.id, this.twofaData.confirmationCode)
        .then(
            (reply) => {
                if (reply.status === 'success') {
                    this.showPasscodes = true;
                    this.twofaData.passcodes = reply.responses;
                } else {
                    this.triggerInvalidAnimation();
                }
            },
            () => this.triggerInvalidAnimation()
        );
    };

    // Redirect to avv if the user is a direct customer and has not yet signed.
    public redirectToAvvIfUnsigned = () => {
        if (
            AuthContextService.authenticated
            && AuthContextService?.account?.billingSettings?.dataProcessingAgreementSignedCurrentVersion === false
            && AuthContextService?.isDirectCustomer
        ) {
            this.navigation.go('avv');
        } else {
            this.navigation.goToLastTarget();
        }
    };

    private resetTimer2fa = () => {
        this.$timeout.cancel(this.timer2fa);
    };

    private triggerInvalidAnimation = () => {
        this.isInvalid = true;

        this.$timeout(
            () => {
                this.inProgress = false;
                this.isInvalid = false;
            },
            550
        );
    };

    public get cookieEnabled() {
        return this.$window.navigator.cookieEnabled; // Non angular version: window.navigator.cookieEnabled
    }
}

export class TemplateLoginComponent implements ng.IComponentOptions {
    public bindings = {
        expired: '<'
    };

    public template = require('./login.html');
    public controller = TemplateLoginController;
}
