import * as ng from 'angular';
import { AccessLevels, EditPanelRight } from '@/atomic-components/molecules';
import { SslOptions } from '@/atomic-components/organs/panels/edit-panels/domain';
import { knownManagedSslProductCodesConst, UiRights } from '@/configuration';
import {
    AlertManagerService,
    ApiErrorModel,
    AuthContextService,
    DomainModelService,
    NavigationService, PriceCacheService,
    VhostModelService,
    WebhostingJobModelService
} from '@/services';
import * as Types from '@/types';
import { DomainApi } from '@/types';

export class OrganismEditFormDomainController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'alertManager',
        'apiErrorModel',
        'domainModel',
        'navigation',
        'priceCache',
        'vhostModel',
        'webhostingJobModel'
    ];

    public additionalPhpOptionsList: any[] = [];
    public advancedPhpOptionsList: any[] = [];
    public applyProfileToPhpSettings: (profileName: string) => void;
    public basicPhpOptionsList: any[] = [];
    public domainType = '';
    public initalSslOptions: SslOptions;
    public phpConfigurationMetaData: any[];
    public phpIniList: { values: any[] };
    public phpVersions: any[];
    public profiles: any;
    public selectedRestoreAction = '';
    public showObjectId: boolean;
    public showPhpAdvancedOptions: boolean;
    public sslOptions: SslOptions;
    public userPanelRight: Record<string, EditPanelRight>;
    public vHost: Types.WebhostingApi.VHost;
    public vhostPanelComplete = false;
    public webspace: Types.WebhostingApi.Webspace;
    public vHostHasUnfinishedJob = false;
    public sslCertificateBackup = '';

    public originalDefaultLocation: Types.WebhostingApi.Location;
    public originalVhost: Types.WebhostingApi.VHost;
    public originalWebspace: Types.WebhostingApi.Webspace;
    public linkedToBundle = false;
    public bundleInclusive = false;
    public deletionDomainTooConfirm = false;

    public accessLevels: AccessLevels[];
    public autoValidationErrors: any[] = [];
    public vhostBundleDomain: Types.DomainApi.Domain;
    public autoValidationCapable: boolean;
    private tmpSslSettings: (string | boolean | any);
    public legacyPHPChargeableProductCode = '';

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private apiErrorModel: ApiErrorModel,
        private domainModel: DomainModelService,
        private navigation: NavigationService,
        private priceCache: PriceCacheService,
        private vhostModel: VhostModelService,
        private webhostingJobModel: WebhostingJobModelService
    ) {
        this.autoValidationCapable = null;
        this.tmpSslSettings = {
            certAdditionalInfo: '',
            certificates: '',
            hstsActivated: false,
            hstsAllowPreload: false,
            hstsIncludeSubdomains: false,
            hstsMaxAge: null,
            managedSslProductCode: '',
            profile: 'modern',
            sslPrivateKey: ''
        };
    }

    public get defaultLocation(): Types.WebhostingApi.Location {
        return this.vHost.locations.filter((location) => location.matchType === 'default')[0];
    }

    public set defaultLocation(value: Types.WebhostingApi.Location) {
        this.vHost.locations = [value].concat(
            this.vHost.locations.filter((location) => location.matchType !== 'default')
        );
    }

    public $onInit = async (): Promise<void> => {
        if (AuthContextService.isGranted(UiRights.WEB_JOBS_LIST)) {
            this.webhostingJobModel.findRunningJobsForVHost(this.originalVhost)
                .then(
                    (result) => {
                        this.vHostHasUnfinishedJob = result.status === 'success'
                            && result.response.data.length > 0;

                        if (this.vHostHasUnfinishedJob) {
                            for (const panelType of Object.keys(this.userPanelRight)) {
                                this.userPanelRight[panelType].editPanelButton = false;
                            }
                        }
                    }
                );
        }

        const inBundleState = this.$state.current.name.split('.').indexOf('bundle') >= 0;

        this.webspace = JSON.parse(JSON.stringify(this.originalWebspace));
        this.vHost = JSON.parse(JSON.stringify(this.originalVhost));
        this.phpIniList = JSON.parse(JSON.stringify(this.phpIniList));
        this.phpConfigurationMetaData = JSON.parse(JSON.stringify(this.phpConfigurationMetaData));
        this._setDefaultLocation();

        this.sslOptions = {
            isReadOnly: (this.vHost.status !== 'active') || !AuthContextService.isGranted(UiRights.WEB_VHOST_EDIT),
            profiles: [
                {
                    name: this.$translate.instant('WEBHOSTING.VHOST.SSL.PROFILE.OPTIONS.MODERN'),
                    value: 'modern'
                },
                {
                    name: this.$translate.instant('WEBHOSTING.VHOST.SSL.PROFILE.OPTIONS.INTERMEDIATE'),
                    value: 'intermediate'
                }
            ],
            sslIndividualRoute: inBundleState
                ? 'bundle.id.domains.id.ssl'
                : 'webhosting.webspaces.id.vhosts.id.ssl',
            sslIndividualRouteParams: {
                bundleId: this.webspace.bundleId,
                domainId: this.vHost.id,
                webspaceId: this.originalWebspace?.id,
                vHostId: this.vHost.id
            },
            type: 'off',
            types: [
                {
                    name: this.$translate.instant('TR_100119-8a25ce_TR'),
                    value: 'managed'
                }, {
                    name: this.$translate.instant('TR_080519-61f5cd_TR'),
                    value: 'rapid-ssl'
                }, {
                    name: this.$translate.instant('4d268ee2-c421-4e02-b842-b00b39de87f5'),
                    value: 'custom'
                }, {
                    name: this.$translate.instant('GENERAL.OBJECT.STATUS.DISABLED'),
                    value: 'off'
                }
            ]
        };

        this.sslOptions.type = this.getSslType();
        this.initalSslOptions = ng.copy(this.sslOptions);
        this.setSslSettings();

        let domainFind: DomainApi.Domain;

        if (AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST)) {
            [domainFind] = await this.domainModel.findByName([this.vHost.domainNameUnicode]);
        }

        this.bundleInclusive = domainFind ? domainFind.bundleId.length > 0 : false;
    };

    public get showBundleHint(): boolean {
        return [undefined, null, ''].indexOf(this.webspace.bundleId) < 0
            && this.$state.current.name.split('.')[0] !== 'bundle';
    }

    public directoryProtectionRedirect = (): void => {
        let route = 'webhosting.webspaces.id.vhosts.id.directory-protection';

        if (this.$state.current.name.indexOf('bundle') === 0) {
            route = 'bundle.id.domains.id.directory-protection';
        } else if (this.$state.current.name.indexOf('managed-servers') === 0) {
            route = 'managed-servers.id.webspaces.id.vhosts.id.directory-protection';
        }

        void this.navigation.go(route);
    };

    public sslTypeChanged = (): void => {
        this.autoValidationCapable = null;
        this.autoValidationErrors = [];
        void this.$timeout(this.setSslSettings, 50);
    };

    public cancelGeneral = (): void => {
        this.vHost = ng.copy(this.originalVhost);

        this.vHost.locations = this.vHost.locations.map(
            (location) => {
                if (location.matchType === 'default') {
                    location = ng.copy(this.originalDefaultLocation);
                }

                return location;
            }
        );

        this.defaultLocation = this.originalDefaultLocation;
    };

    public cancelPhpOptions = (): void => {
        this.phpIniList = JSON.parse(JSON.stringify(this.phpIniList));
        this.phpConfigurationMetaData = JSON.parse(JSON.stringify(this.phpConfigurationMetaData));
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public cancelDirProtection = (): void => {};

    public cancelSsl = (): void => {
        this.sslOptions = ng.copy(this.initalSslOptions);
        this.vHost.sslSettings = ng.copy(this.originalVhost.sslSettings);
        this.vHost.redirectHttpToHttps = ng.copy(this.originalVhost.redirectHttpToHttps);
        this.sslOptions.type = this.getSslType();
        this.sslTypeChanged();
    };

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public cancelAppInstall = (): void => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public cancelLocations = (): void => {};

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public cancelDelete = (): void => {};

    public get isRedirect(): boolean {
        return this.defaultLocation.locationType === 'redirect';
    }

    public get redirectionUrl(): string {
        return this.defaultLocation.redirectionUrl;
    }

    public get isRestorable(): boolean {
        return this.vHost.status === 'restorable';
    }

    public delete = async (): Promise<unknown> => {
        const vhostDeleteRes = await this.vhostModel.delete(this.originalVhost);

        this.alertManager.success(this.$translate.instant('TR_060120-51c5d4_TR'));

        if (this.linkedToBundle && this.deletionDomainTooConfirm) {
            const detachRes = await this.domainModel.domainDetachFromBundle(this.originalVhost.domainNameUnicode);

            this.alertManager.success(
                this.$translate.instant('3e8cf83a-0473-4751-b359-67e09d743397')
            );

            return detachRes as unknown;
        }

        return vhostDeleteRes as unknown;
    };

    public restoreOrPurge = (): void => {
        this.apiErrorModel.destroyErrorList();

        if (this.selectedRestoreAction === 'restore') {
            void this.vhostModel.restoreOne(this.originalVhost)
                .then(() => this.updateVhostData());
        } else if (this.selectedRestoreAction === 'purge') {
            void this.vhostModel.purgeRestorable(this.vHost.id).then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-d6dbda_TR'));
                    void this.navigation.go('webhosting.dashboard', {}, { reload: true });
                }
            );
        }
    };

    public save = async (): Promise<unknown> => {
        let phpIni = null;
        let expectedCosts = null;
        this.apiErrorModel.destroyErrorList();
        this.setSslSettings();
        if (this.legacyPHPChargeableProductCode.length > 0) {
            expectedCosts = await this.priceCache.getPriceByProductCode(
                this.legacyPHPChargeableProductCode,
                this.webspace.accountId
            ).then((reply) => reply[0].netAmount);
        }

        if (this.isRedirect) {
            let defaultredirectLocation;
            this.vHost.profile = '';

            for (const location of this.vHost.locations) {
                if (location.matchType === 'default' && location.locationType === 'redirect') {
                    location.setByProfile = false;
                    defaultredirectLocation = location;

                    break;
                }
            }

            this.vHost.locations = [defaultredirectLocation];
        } else {
            const savedOptions: string[] = [];
            let phpSettings: any[] = [];
            phpSettings = phpSettings.concat(this.getPhpSettings(this.basicPhpOptionsList, savedOptions));
            phpSettings = phpSettings.concat(this.getPhpSettings(this.advancedPhpOptionsList, savedOptions));
            phpSettings = phpSettings.concat(this.getPhpSettings(this.additionalPhpOptionsList, savedOptions));
            phpSettings = phpSettings.concat(this.getPhpSettings(this.phpIniList.values, savedOptions));
            phpSettings = this.removeUnavailablePhpOptions(phpSettings);

            phpIni = {
                values: phpSettings,
                vhostId: this.vHost.id
            };

            this.vHost.locations = this.vHost.locations.map(
                (location) => {
                    if (location.matchType === 'default') {
                        location = this.defaultLocation;
                    }

                    return location;
                }
            );
        }

        if (!this.vHost.enableAlias) {
            this.vHost.redirectToPrimaryName = false;
        }

        return this.vhostModel.update(this.vHost, phpIni, undefined, null, expectedCosts).then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.vHost = ng.copy(res.response);
                    this.originalVhost = ng.copy(res.response);
                    this._resetDomainSettings();
                    this.alertManager.success(this.$translate.instant('TR_100119-e1c214_TR'));
                    return res;
                }

                this._resetDomainSettings();
                return Promise.reject(false);
            },
            (err) => {
                this._resetDomainSettings();
                return Promise.reject(err);
            }
        );
    };

    public checkAutoValidationCapable = () => {
        if (this.autoValidationCapable !== null) {
            return;
        }

        const hostsToCheck = [this.vHost.domainNameUnicode];

        if (this.vHost.enableAlias === true) {
            const wwwIndex = this.vHost.domainNameUnicode.indexOf('www.');

            if (wwwIndex === 0) {
                const alias =  this.vHost.domainNameUnicode.slice(4);
                hostsToCheck.push(alias);
            } else {
                hostsToCheck.push('www.' + this.vHost.domainNameUnicode);
            }
        }

        this.apiErrorModel.destroyErrorList();

        this.vhostModel.checkAutoValidationCapable(
            hostsToCheck,
            this.vHost.accountId,
            this.vHost.sslSettings.managedSslProductCode
        ).then(
            (res) => {
                if (!this.apiErrorModel.apiResponseHasError(res)) {
                    this.autoValidationCapable = (res.responses.length === 0);
                    this.autoValidationErrors = res.responses;
                }

                return Promise.reject(false);
            }
        );
    };

    public getPhpSettings = (optionsList: any[], savedOptionNames: string[]) => {
        const settings: any[] = [];

        for (const option of optionsList) {
            if (savedOptionNames.indexOf(option.key) < 0
                && (!option.markedForRemoval
                || option.markedForRemoval === null
                || option.markedForRemoval === undefined)
            ) {
                let value = option.value;

                if (typeof value !== 'string') {
                    value = JSON.stringify(option.value);
                }

                settings.push({
                    key: option.key,
                    value: value
                });
            }

            savedOptionNames.push(option.name);
        }

        return settings;
    };

    private updateVhostData = () => {
        this.vhostModel.findOne(this.vHost.id).then(
            (reply) => {
                this.vHost = ng.copy(reply);
                this.originalVhost = ng.copy(reply);
            }
        );
    };

    private removeUnavailablePhpOptions = (
        phpSettings: Types.WebhostingApi.PhpConfigurationMetadata[]
    ): Types.WebhostingApi.PhpConfigurationMetadata[] => {
        const phpVersion = this.vHost.phpVersion;
        const allPhpMetaData = ng.copy(this.phpConfigurationMetaData);

        // add immutable values
        for (const value of this.phpIniList.values) {
            const alreadySetAndIsImmutable = allPhpMetaData.some((iniSetting) => {
                // look if value does not exist in allPhpIniSettings
                return iniSetting.key === value.key;
            });

            if (!alreadySetAndIsImmutable) {
                allPhpMetaData.push(ng.copy(value.metadata));
            }
        }

        phpSettings = phpSettings.filter((phpSetting) => {
            const filteredMeta = allPhpMetaData.filter((phpSettingMeta) => {
                return phpSettingMeta.phpVersions.indexOf(phpVersion) >= 0 && phpSettingMeta.key === phpSetting.key;
            });

            return filteredMeta.length > 0;
        });

        return phpSettings;
    };

    private getSslType = (): string => {
        if (this.vHost.sslSettings === null) {
            return 'off';
        }

        if (knownManagedSslProductCodesConst.indexOf(this.vHost.sslSettings.managedSslProductCode) < 0) {
            return 'custom';
        }

        if (this.vHost.sslSettings.managedSslProductCode.indexOf('rapidssl') >= 0) {
            return 'rapid-ssl';
        }

        return 'managed';
    };

    private setSslSettings = (): void => {
        // Backup the current certificate.
        if (this.vHost.sslSettings?.certificates?.length > 0) {
            this.sslCertificateBackup = ng.copy(this.vHost.sslSettings.certificates);
        }

        switch (this.sslOptions.type) {
            case 'managed':
                this.vHost.sslSettings = this.vHost.sslSettings === null
                    ? ng.copy(this.tmpSslSettings)
                    : this.vHost.sslSettings;
                this.vHost.sslSettings.managedSslProductCode = 'ssl-letsencrypt-dv-3m';
                this.vHost.sslSettings.certificates = '';
                void this.checkAutoValidationCapable();
                break;

            case 'rapid-ssl':
                this.vHost.sslSettings = this.vHost.sslSettings === null
                    ? ng.copy(this.tmpSslSettings)
                    : this.vHost.sslSettings;
                this.vHost.sslSettings.managedSslProductCode = 'ssl-geotrust-rapidssl-12m';
                this.vHost.sslSettings.certificates = '';
                void this.checkAutoValidationCapable();
                break;

            case 'custom':
                this.vHost.sslSettings = this.vHost.sslSettings === null
                    ? ng.copy(this.tmpSslSettings)
                    : this.vHost.sslSettings;

                if (
                    [null, undefined, ''].concat(knownManagedSslProductCodesConst)
                        .indexOf(this.vHost.sslSettings.managedSslProductCode) >= 0
                ) {
                    this.vHost.sslSettings.managedSslProductCode = '';
                }

                // Continue using current certificate if no custom one is specified.
                if (this.vHost.sslSettings?.certificates?.length === 0 && this.sslCertificateBackup.length > 0) {
                    this.vHost.sslSettings.certificates = ng.copy(this.sslCertificateBackup);
                }

                this.vHost.redirectHttpToHttps = true;
                break;

            default:
                this.vHost.sslSettings = null;
                this.vHost.redirectHttpToHttps = false;
        }
    };

    private _resetDomainSettings = (): void => {
        this.cancelGeneral();

        for (const location of this.vHost.locations) {
            if (location.matchType === 'default' && !location.phpEnabled) {
                this.showPhpAdvancedOptions = false; // Does not work...
            }
        }
    };

    private _setDefaultLocation = (): void => {
        this.originalDefaultLocation = ng.copy(this.defaultLocation);
    };

}

export class OrganismEditFormDomainComponent implements ng.IComponentOptions {
    public bindings = {
        domainType: '<',
        originalVhost: '<vHost',
        originalWebspace: '<webspace',
        phpConfigurationMetaData: '<',
        phpIniList: '<',
        phpVersions: '<',
        profiles: '<',
        showObjectId: '<',
        userPanelRight: '='
    };

    public controllerAs = '$editFormOrganism';
    public controller = OrganismEditFormDomainController;
    public template = require('./domain-edit.html');
}
