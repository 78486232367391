import ng from 'angular';
import { UiRights } from '../../../../configuration';
import { AuthContextService } from '../../../../services';
import { Breadcrumb, BreadcrumbItem } from '../breadcrumb';
import { BreadcrumbSslTopicModel } from './';
import { BreadcrumbTopicModel, TopicCases } from './topic-model';

export class BreadcrumbWebhostingTopicModel extends BreadcrumbTopicModel {
    public static $inject: string[] = BreadcrumbTopicModel.$inject.concat(['breadcrumbSslTopicModel']);

    public readonly topicName = 'webhosting';
    public readonly specialTopicCases: TopicCases;

    /**
     *   List of route parts, which are excluded from mail class generalRoutes methode
     */
    public readonly excludeGeneralRouteParts = ['overview', 'edit', 'new', 'rename', 'delete'];
    public webspaceActionDropdownItems: BreadcrumbItem[] = [];
    public vhostActionDropdownItems: BreadcrumbItem[] = [];

    private webhostingActionDropdownItems: BreadcrumbItem[] = [];

    constructor(
        protected $state: ng.ui.IStateService,
        protected $translate: ng.translate.ITranslateService,
        private breadcrumbSslTopicModel: BreadcrumbSslTopicModel
    ) {
        super($state, $translate);

        this.webhostingActionDropdownItems = [
            {
                isGranted: UiRights.WEB_OBJECT_LIST,
                name: this.$translate.instant('TR_080119-f4ef2a_TR'),
                part: 'overview',
                route: 'webhosting.webspaces.overview'
            },
            {
                isGranted: UiRights.WEB_OBJECT_LIST,
                name: this.$translate.instant('TR_080119-acb35a_TR'),
                part: 'vhosts',
                route: 'webhosting.vhosts.overview'
            },
            {
                isGranted: UiRights.WEB_JOBS_LIST,
                part: 'jobs',
                route: 'webhosting.webspaces.jobs'
            },
            {
                isGranted: UiRights.WEB_WEBSPACE_CREATE,
                part: 'new',
                route: 'webhosting.webspaces.new'
            }
        ];

        this.webspaceActionDropdownItems = [
            {
                isGranted: UiRights.WEB_OBJECT_LIST,
                name: this.$translate.instant('TR_140119-aeb48a_TR'),
                part: 'vhosts',
                route: 'webhosting.webspaces.id.vhosts.overview'
            },
            {
                isGranted: UiRights.WEB_WEBSPACE_EDIT,
                name: this.$translate.instant('TR_STORAGE_TR'),
                part: 'storage',
                route: 'webhosting.webspaces.id.storage'
            },
            {
                isGranted: UiRights.WEB_WEBSPACE_EDIT,
                name: this.$translate.instant('TR_070619-458016_TR'),
                part: 'php-boost',
                route: 'webhosting.webspaces.id.php-boost'
            },
            {
                isGranted: UiRights.WEB_WEBSPACE_EDIT,
                name: this.$translate.instant('TR_080119-890055_TR'),
                part: 'ftp-ssh',
                route: 'webhosting.webspaces.id.ftp-ssh'
            },
            {
                isGranted: UiRights.BACKUP_BACKUP_LIST,
                name: this.$translate.instant('TR_080119-57a2c9_TR'),
                part: 'backups',
                route: 'webhosting.webspaces.id.backups.overview'
            },
            {
                isGranted: UiRights.WEB_OBJECT_LIST,
                name: this.$translate.instant('e2a342f1-432b-41d5-b5de-68e07820d023'),
                part: 'cronjobs',
                route: 'webhosting.webspaces.id.cronjobs.overview'
            }
        ];

        this.vhostActionDropdownItems = [
            {
                isGranted: UiRights.WEB_VHOST_EDIT,
                part: 'edit',
                route: 'webhosting.webspaces.id.vhosts.id.edit'
            },
            {
                isGranted: UiRights.WEB_VHOST_EDIT,
                name: this.$translate.instant('TR_080119-3ee53c_TR'),
                part: 'app-install',
                route: 'webhosting.webspaces.id.vhosts.id.app-install'
            },
            {
                isGranted: UiRights.WEB_VHOST_EDIT,
                name: this.$translate.instant('TR_080119-dd59e7_TR'),
                part: 'locations-edit',
                route: 'webhosting.webspaces.id.vhosts.id.locations-edit'
            },
            {
                isGranted: UiRights.WEB_VHOST_EDIT,
                name: this.$translate.instant('TR_100419-cc6ac5_TR'),
                part: 'directory-protection',
                route: 'webhosting.webspaces.id.vhosts.id.directory-protection'
            }
        ];

        this.specialTopicCases = {
            'admin-options': this.getAdminOptionsRoute,
            'app-install': this.getWebhostingEditRoute,
            'backups': this.getWebspaceActionRoutes,
            'cronjobs': this.getWebspaceActionRoutes,
            'delete': this.getDeleteRoute,
            'details': this.jobDetailsRoute,
            'directory-protection': this.getWebhostingEditRoute,
            'edit': this.getWebhostingEditRoute,
            'ftp-ssh': this.getWebspaceActionRoutes,
            'jobs': () => this.getProductActionDropdown(this.webhostingActionDropdownItems),
            'locations-edit': this.getWebhostingEditRoute,
            'new': this.getNewWebspaceRoute,
            'overview': this.getOverviewsRoute,
            'php-boost': this.getWebspaceActionRoutes,
            'rename': this.getWebspaceRenameRoutes,
            'ssl': this.getSslAction,
            'storage': this.getWebspaceActionRoutes,
            'users': this.getUsersServiceRoute,
            'vhosts': this.getWebspaceActionRoutes
        };
    }

    public getWebspaceRenameRoutes = (): Breadcrumb[] => {
        const breadcrumbs = [];
        breadcrumbs.push(this.getProductActionDropdown(this.webhostingActionDropdownItems, {}, 'overview')[0]);

        breadcrumbs.push({
            active: false,
            isGranted: UiRights.WEB_OBJECT_LIST,
            route: 'webhosting.webspaces.id.edit',
            routeParams: { webspaceId: this.globals.webspace.id },
            type: 'text',
            value: this.globals.webspace.name
        });
        breadcrumbs.push(this.getRenameRoute()[0]);

        return breadcrumbs;
    };

    public getDeleteRoute = (): Breadcrumb[] => {
        if (
            this.$state.$current.locals.globals.webspace !== undefined
            && [undefined, null, ''].indexOf(this.$state.$current.locals.globals.webspace.bundleId) >= 0
            && [undefined, null, ''].indexOf(this.$state.$current.locals.globals.webspace.poolId) >= 0
        ) {
            return this.getTerminateRoute();
        }

        return [
            {
                active: true,
                route: '',
                type: 'text',
                value: this.$translate.instant('TR_140119-aee71e_TR')
            }
        ];
    };

    public getFirstLevelData = (): Breadcrumb[] => {
        this.breadcrumbs.push({
            active: false,
            type: 'text',
            value: this.$translate.instant('MENU.MAIN.PRODUCTS.LABEL')
        });

        if (AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST)) {
            this.breadcrumbs = this.breadcrumbs.concat(
                this.getServiceListDropdownBox({
                    active: this.steps.length === 2,
                    name: this.$translate.instant('MENU.MAIN.PRODUCTS.WEBHOSTING'),
                    route: 'webhosting.dashboard',
                    routeParams: {},
                    step: 'webhosting'
                })
            );
        }

        return this.breadcrumbs;
    };

    public getOverviewsRoute = (behaviorStep?: string, step?: string): Breadcrumb[] => {
        let breadcrumbs = [];

        behaviorStep = behaviorStep || this.steps[this.index - 1];

        switch (behaviorStep) {
            case 'webspaces':
                return this.getProductActionDropdown(this.webhostingActionDropdownItems);
            case 'vhosts':
                if (this.index > 2 && this.steps[this.index] !== 'overview') {
                    // if vhosts step is under webspaces
                    breadcrumbs = this.getProductActionDropdown(this.webhostingActionDropdownItems, {}, step);
                    return breadcrumbs.concat(
                        this.getProductActionDropdown(this.webspaceActionDropdownItems, {}, 'vhosts')
                    );
                }

                return this.getProductActionDropdown(this.webhostingActionDropdownItems, {}, 'vhosts');
            default:
                break;
        }
    };

    public getWebhostingEditRoute = (checkStep?: string): Breadcrumb[] => {
        let breadcrumbs: Breadcrumb[] = [];
        let route = '';
        let routeParams = {};
        checkStep = checkStep || this.steps[this.index - 2];

        switch (checkStep) {
            case 'webspaces':
                breadcrumbs = this.getProductActionDropdown(
                    this.webhostingActionDropdownItems,
                    routeParams,
                    'overview'
                );
                route = 'webhosting.webspaces.id.edit';
                routeParams = { webspaceId: this.globals.webspace.id };
                breadcrumbs.push({
                    active: false,
                    isGranted: UiRights.WEB_OBJECT_LIST,
                    route: 'webhosting.webspaces.id.edit',
                    routeParams: { webspaceId: this.globals.webspace.id },
                    type: 'text',
                    value: this.globals.webspace.name
                });
                break;
            case 'vhosts':
                routeParams = { webspaceId: this.globals.webspace.id, domainId: this.globals.vHost.id };
                if (this.step === 'ssl') {
                    route = 'webhosting.webspaces.id.edit';
                    breadcrumbs.push({
                        active: true,
                        isGranted: UiRights.WEB_WEBSPACE_EDIT,
                        route: route,
                        routeParams: routeParams,
                        type: 'text',
                        value: this.getActiveDropdownValue({ part: 'edit' })
                    });
                } else {
                    breadcrumbs = breadcrumbs.concat(
                        this.getProductActionDropdown(this.vhostActionDropdownItems, routeParams, this.step)
                    );
                }
                break;
            case 'users':
                breadcrumbs.push({
                    active: true,
                    isGranted: UiRights.WEB_OBJECT_LIST,
                    route: '',
                    routeParams: {},
                    type: 'text',
                    value: this.getActiveDropdownValue({ part: 'edit' })
                });
                break;
            case 'cronjobs':
                breadcrumbs = breadcrumbs.concat(this.getEditRoute());
                break;
            default:
                break;
        }

        return breadcrumbs;
    };

    public getWebspaceActionRoutes = (): Breadcrumb[] => {
        if (this.step === 'vhosts' && this.steps[this.index - 1] === 'webhosting') {
            return; // on vhost overview from webhosting dashboard
        }

        const routeParams = {
            machineId: this.globals.machine !== undefined ? this.globals.machine.id : null,
            webspaceId: this.globals.webspace !== undefined ? this.globals.webspace.id : null
        };

        let breadcrumbs = this.getWebhostingEditRoute('webspaces');

        breadcrumbs = breadcrumbs.concat(
            this.getProductActionDropdown(this.webspaceActionDropdownItems, routeParams, this.step)
        );

        switch (this.step) {
            case 'storage':
                breadcrumbs = breadcrumbs.concat(this.getEditRoute());
                break;
            case 'php-boost':
                breadcrumbs = breadcrumbs.concat(this.getEditRoute());
                break;
            default:
                break;
        }

        return breadcrumbs;
    };

    public getSslAction = (): Breadcrumb[] => [
        ...this.getWebhostingEditRoute(),
        ...this.breadcrumbSslTopicModel.getSslAction()
    ];

    public getUsersServiceRoute = (): Breadcrumb[] => {
        let stepLength = this.steps.length - 2;
        if (['new'].indexOf(this.steps[this.steps.length - 1]) >= 0) {
            stepLength = this.steps.length - 1;
        }
        this.breadcrumbs.push({
            active: this.step === 'users' && this.index === stepLength,
            route: 'webhosting.users.overview',
            routeParams: {},
            type: 'text',
            value: this.$translate.instant('TR_090119-a31e5e_TR')
        });
        return this.breadcrumbs;
    };

    public getFtpSshServiceRoute = (): Breadcrumb[] =>
        this.getServiceListDropdownBox({
            active: this.step === 'ftp-ssh',
            isGranted: UiRights.WEB_OBJECT_LIST,
            name: this.$translate.instant('TR_080119-c09f6f_TR'),
            route: '',
            routeParams: {},
            step: 'ftp-ssh'
        });

    public getNewWebspaceRoute = (): Breadcrumb[] => {
        let breadcrumbs: Breadcrumb[] = [];
        const routeParams = {};

        switch (this.steps[this.index - 1]) {
            case 'webspaces':
                breadcrumbs = this.getProductActionDropdown(this.webhostingActionDropdownItems, routeParams, 'new');
                break;
            case 'vhosts':
                if (this.steps.length <= 3) {
                    breadcrumbs = this.getProductActionDropdown(
                        this.webhostingActionDropdownItems,
                        routeParams,
                        'vhosts'
                    );
                }
                breadcrumbs = breadcrumbs.concat(this.getNewRoute());
                break;
            default:
                breadcrumbs = breadcrumbs.concat(this.getNewRoute());
                break;
        }

        return breadcrumbs;
    };

    public getAdminOptionsRoute = (): Breadcrumb[] => [
        ...this.getProductActionDropdown(
            this.webhostingActionDropdownItems,
            { webspaceId: this.globals.webspace.id },
            'overview'
        ),
        {
            active: false,
            isGranted: UiRights.WEB_OBJECT_LIST,
            route: 'webhosting.webspaces.id.edit',
            routeParams: { webspaceId: this.globals.webspace.id },
            type: 'text',
            value: this.globals.webspace.name
        },
        {
            active: true,
            isGranted: UiRights.WEB_OBJECT_LIST,
            route: 'webhosting.webspaces.id.admin-options',
            routeParams: { webspaceId: this.globals.webspace.id },
            type: 'text',
            value: this.$translate.instant('TR_090119-9c9bb6_TR')
        }
    ];
}
