import * as ng from 'angular';
import './text-box.scss';

export class AtomTextBoxController implements ng.IController {
    public static $inject: string[] = [];

    public modifier: string;
    public stroke: boolean;
    public disabled: boolean;
    public color: string;

    public $onInit() {
        this.modifier = this.modifier || '';
        this.stroke = this.stroke || false;
        this.disabled = this.disabled || false;
    }
}

export class AtomTextBoxComponent implements ng.IComponentOptions {
    public transclude = true;
    public bindings = {
        color: '@',
        disabled: '<',
        modifier: '@?',
        stroke: '<'
    };
    public controller =  AtomTextBoxController;
    public template = require('./text-box.html');
}
