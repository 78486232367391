import * as ng from 'angular';
import { DomainTypes, ZoneUpdateData } from '@/atomic-components/organs';
import { ViewTypes, WebhostingApi } from '@/types';

export class OrganismFormDisplayDnsRecordsUpdaterController {
    public static $inject: string[] = ['$timeout'];

    public dnsSetting: {
        type: string;
        values: ViewTypes.DomainWizardDnsSettingsObject;
    };
    public domainList: unknown[] = [];
    public vhost: WebhostingApi.VHost;
    public selectedNameservers: unknown[];
    public completedDnsRecordsToUpdateItems: object;
    public zoneUpdateData: object;
    public domainType: DomainTypes;

    private _accountId: string;
    private _domainList: unknown[] = [];
    private _externalNameservers = false;
    private _nameservers: unknown[];
    private _nameserversComplete = false;
    private _selectedNameservers: unknown;
    private _webspace: WebhostingApi.Webspace;

    constructor(
        private $timeout: ng.ITimeoutService
    ) {}

    public $onInit(): void {
        this._selectedNameservers = ng.copy(this.selectedNameservers);
        this._domainList = ng.copy(this.domainList);
        if (!this.domainType) {
            this.domainType = DomainTypes.REGISTER;
        }
        this._setZoneUpdateData();
        this._setCompletedDnsRecordsToUpdateList();
    }

    public $onChanges(changes: ng.IOnChangesObject): void {
        if (changes.selectedNameservers && changes.selectedNameservers.isFirstChange()) {
            this._setCompletedDnsRecordsToUpdateList();
            this._setSelectedNameservers();
        }

        if (changes._webspace) {
            // So that the webspace watcher of the lower component can register the change (=> magic timeout)
            void this.$timeout(() => this.webspace = changes._webspace.currentValue as WebhostingApi.Webspace);
        }

        if (changes._externalNameservers) {
            this._setSelectedNameservers();
        }
    }

    public $doCheck(): void {
        if (JSON.stringify(this._selectedNameservers) !== JSON.stringify(this.selectedNameservers)) {
            this._selectedNameservers = ng.copy(this.selectedNameservers);
            this._setSelectedNameservers();
        }

        if (JSON.stringify(this.domainList) !== JSON.stringify(this._domainList)) {
            this._domainList = ng.copy(this.domainList);
            this._setZoneUpdateData();
            this._setCompletedDnsRecordsToUpdateList();
        }
    }

    public get accountId(): string {
        return this._accountId;
    }

    public set showPanel(_) {/* */}
    public get showPanel(): boolean {
        return this.domainList?.length > 0;
    }

    public set showExternalNameserversHint(_) {/* */}
    public get showExternalNameserversHint(): boolean {
        return this.externalNameservers
            && !this.webspace;
    }

    public set nameservers(_) {/* */}
    public get nameservers(): unknown {
        return this._nameserversComplete
            ? this._nameservers
            : undefined;
    }

    public set webspace(webspace) {
        this._webspace = webspace;
    }
    public get webspace(): WebhostingApi.Webspace {
        return this._webspace;
    }

    public set enableAlias(_) {/* */}
    public get enableAlias(): boolean {
        return this.vhost?.enableAlias;
    }

    public set externalNameservers(_) {/* */}
    public get externalNameservers(): boolean {
        return this._externalNameservers;
    }

    public set hideRecordsUpdaterPanel(_) {/* */}
    public get hideRecordsUpdaterPanel(): boolean {
        return this.externalNameservers
            && !this.webspace;
    }

    public get domainTypeRegister(): boolean {
        return this.domainType === DomainTypes.REGISTER;
    }

    public set complete(_) {/* */}
    public get complete(): boolean {
        if (!this.completedDnsRecordsToUpdateItems) {
            return false;
        }

        return Object.values(this.completedDnsRecordsToUpdateItems)
            .every((complete) => complete);
    }

    private _setZoneUpdateData = (): void => {
        this.zoneUpdateData = {};

        for (const domain of this.domainList) {
            const domainName: string = this.domainType === DomainTypes.REGISTER
                ? domain?.domainObject?.domain?.domainName
                : domain.domainName;

            if (domainName) {
                Object.defineProperty(
                    this.zoneUpdateData,
                    domainName,
                    // domain.domainName,
                    {
                        value: {
                            zone: null,
                            records: null,
                            update: true
                        } as ZoneUpdateData,
                        writable: true
                    }
                );
            }
        }
    };

    private _setCompletedDnsRecordsToUpdateList = (): void => {
        this.completedDnsRecordsToUpdateItems = {};

        for (const dnsZoneCheck of this.domainList) {
            const domainNameUnicode: string = this.domainType === DomainTypes.REGISTER
                ? dnsZoneCheck?.domainObject?.domain?.domainNameUnicode
                : dnsZoneCheck.domainNameUnicode;

            if (domainNameUnicode) {
                Object.defineProperty(
                    this.completedDnsRecordsToUpdateItems,
                    domainNameUnicode,
                    {
                        value: false,
                        writable: true
                    }
                );
            }
        }
    };

    private _setSelectedNameservers = (): void => {
        if (this.webspace) {
            this._nameservers = null;
        }

        this._nameservers = this.externalNameservers
            ? undefined
            : this.selectedNameservers;
    };
}

export class OrganismFormDisplayDnsRecordsUpdaterComponent implements ng.IComponentOptions {
    public bindings = {
        _accountId: '=accountId',
        _externalNameservers: '<externalNameservers',
        _nameserversComplete: '<nameserversComplete',
        _webspace: '<?webspace',
        complete: '=',
        dnsSetting: '<',
        domainList: '=',
        domainType: '<?',
        selectedNameservers: '<?',
        vhost: '<?',
        zoneUpdateData: '='
    };
    public controller = OrganismFormDisplayDnsRecordsUpdaterController;
    public template = require('./dns-records-updater.html');
}
