import { LinkListOverviewItem } from '../../atomic-components/molecules/links';
import { AccountFilterField, ContactFilterField, GroupedSelectFilterField, SelectFilterField, TextFilterField } from '../../atomic-components/molecules/search/fancy-filter-fields/filter-field.class';
import { UiRights } from '../../configuration';
import { JobOverviewModel, OverviewModel } from '../abstract';
import { AuthContextService } from '../auth-context';
import { RequestCancellerService } from '../request-canceller';

export class WebhostingJobOverviewModel extends JobOverviewModel {
    public static $inject: string[] = OverviewModel.$inject.concat(
        ['webhostingJobModel', '$translate']
    );

    readonly service = 'webhostingJob';

    protected listCallbacks = [{callback: this.webhostingJobModel.list}];

    private _cachedAvailableFilters = null;

    constructor(
        RequestCanceller: RequestCancellerService,
        private webhostingJobModel: any,
        protected $translate: ng.translate.ITranslateService
    ) {
        super(RequestCanceller);
    }

    public set availableFilters(value) {
        return;
    }

    public get availableFilters() {
        if ([undefined, null].indexOf(this._cachedAvailableFilters) >= 0) {
            this._cachedAvailableFilters = [
                new TextFilterField('jobDisplayName', this.$translate.instant('TR_140119-796b28_TR')),
                new SelectFilterField('jobObjectType', this.$translate.instant('TR_070519-16189c_TR'), [{
                    name: this.$translate.instant('TR_140119-967073_TR'),
                    value: 'User'
                }, {
                    name: this.$translate.instant('TR_210319-eeec82_TR'),
                    value: 'Webspace'
                }, {
                    name: this.$translate.instant('TR_140119-3834d8_TR'),
                    value: 'VHost'
                }, {
                    name: this.$translate.instant('TR_140119-bb0ac4_TR'),
                    value: 'apptemp'
                }, {
                    name: this.$translate.instant('TR_140119-addc6d_TR'),
                    value: 'sslfake'
                }]),
                new SelectFilterField('jobAction', this.$translate.instant('TR_140119-3b64a8_TR'), [{
                    name: this.$translate.instant('TR_140119-5fee6c_TR'),
                    value: 'appInstall'
                }, {
                    name: this.$translate.instant('TR_140119-50d91c_TR'),
                    value: 'create'
                }, {
                    name: this.$translate.instant('TR_140119-aee71e_TR'),
                    value: 'delete'
                }, {
                    name: this.$translate.instant('TR_140119-20aa42_TR'),
                    value: 'purgeRestorable'
                }, {
                    name: this.$translate.instant('TR_140119-d46abf_TR'),
                    value: 'deleteScheduled'
                }, {
                    name: this.$translate.instant('TR_140119-7d63e4_TR'),
                    value: 'deleteScheduledPermanently'
                }, {
                    name: this.$translate.instant('TR_140119-d1b7a4_TR'),
                    value: 'restore'
                }, {
                    name: this.$translate.instant('TR_140119-a1847d_TR'),
                    value: 'update'
                }, {
                    name: this.$translate.instant('TR_140119-a25112_TR'),
                    value: 'orderManagedSsl'
                }, {
                    name: this.$translate.instant('TR_140119-80468b_TR'),
                    value: 'deleteManagedSslIrreversible'
                }, {
                    name: this.$translate.instant('TR_140119-199a8b_TR'),
                    value: 'updateManagedSsl'
                }, {
                    name: this.$translate.instant('TR_140119-7921b4_TR'),
                    value: 'renewManagedSsl'
                }, {
                    name: this.$translate.instant('TR_210119-1850b1_TR'),
                    value: 'remove'
                }, {
                    name: this.$translate.instant('TR_210119-823a4b_TR'),
                    value: 'removePermanently'
                }, {
                    name: this.$translate.instant('TR_210119-f4ea9d_TR'),
                    value: 'removeScheduled'
                }, {
                    name: this.$translate.instant('TR_210119-bf6730_TR'),
                    value: 'removeScheduledPermanently'
                }, {
                    name: this.$translate.instant('GENERAL.JOB.ACTION.DELETE-MANAGED-SSL-RESTORABLE'),
                    value: 'deleteManagedSslRestorable'
                }, {
                    name: this.$translate.instant('TR_210119-f56b57_TR'),
                    value: 'webspaceEmailSenderDisable'
                }, {
                    name: this.$translate.instant('TR_210119-5f2ce8_TR'),
                    value: 'webspaceEmailSenderDefer'
                }, {
                    name: this.$translate.instant('TR_210119-7e450d_TR'),
                    value: 'webspaceEmailSenderActivate'
                }, {
                    name: this.$translate.instant('TR_210119-089115_TR'),
                    value: 'webspaceDisable'
                }, {
                    name: this.$translate.instant('TR_210119-93ac52_TR'),
                    value: 'webspaceEnable'
                }, {
                    name: this.$translate.instant('TR_210119-c4e98b_TR'),
                    value: 'StorageUsageSync'
                }]),
                new AccountFilterField('AccountId', this.$translate.instant('TR_140119-b3b187_TR')),
                new SelectFilterField('jobStatus', this.$translate.instant('TR_140119-8e4b60_TR'), [{
                    name: this.$translate.instant('TR_140119-888f29_TR'),
                    value: 'timed'
                }, {
                    name: this.$translate.instant('TR_140119-601a80_TR'),
                    value: 'new'
                }, {
                    name: this.$translate.instant('TR_140119-d5047f_TR'),
                    value: 'inProgress'
                }, {
                    name: this.$translate.instant('TR_140119-8bd7bf_TR'),
                    value: 'successful'
                }, {
                    name: this.$translate.instant('TR_140119-8399ef_TR'),
                    value: 'failed'
                }, {
                    name: this.$translate.instant('TR_140119-7acde1_TR'),
                    value: 'support'
                }, {
                    name: this.$translate.instant('TR_140119-a235c9_TR'),
                    value: 'canceled'
                }, {
                    name: this.$translate.instant('TR_140119-5b87d8_TR'),
                    value: 'done'
                }])
            ];
        }

        return this._cachedAvailableFilters;
    }

    public actionLinks = (params: { [param: string]: any }): LinkListOverviewItem[] => {
        return [
            {
                isGranted: AuthContextService.isGrantedAny([
                    UiRights.WEB_USER_EDIT,
                    UiRights.WEB_USER_DELETE,
                    UiRights.WEB_USER_CREATE,
                    UiRights.WEB_OBJECT_LIST
                ]),
                linkList: [
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGrantedAny([
                            UiRights.WEB_USER_EDIT,
                            UiRights.WEB_USER_DELETE,
                            UiRights.WEB_USER_CREATE
                        ]),
                        route: 'webhosting.users.overview',
                        text: this.$translate.instant('TR_140119-9183af_TR')
                    },
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST),
                        route: 'webhosting.webspaces.overview',
                        text: this.$translate.instant('TR_080119-f4ef2a_TR')
                    },
                    {
                        group: 'redirect',
                        isGranted: AuthContextService.isGranted(UiRights.WEB_OBJECT_LIST),
                        route: 'webhosting.vhosts.overview',
                        text: this.$translate.instant('TR_080119-acb35a_TR')
                    }
                ],
                route: '',
                text: this.$translate.instant('TR_100119-a22157_TR'),
                type: 'multi'
            }
        ] as LinkListOverviewItem[];
    };
}
