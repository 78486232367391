import * as ng from 'angular';
import * as q from 'q';
import { UiRights } from '../../../../../configuration';
import {
    AlertManagerService,
    AuthContextService,
    DnsJobModelService,
    DnsZoneModelService,
    NavigationService
} from '../../../../../services';
import * as Types from '../../../../../types';

export class OrganismEditFormDnsZoneController {
    public static $inject: string[] = [
        '$translate',
        'dnsJobModel',
        'dnsZoneModel',
        'alertManager',
        'navigation'
    ];

    public originalZone: Types.DnsApi.ZoneConfig;
    public zone: Types.DnsApi.ZoneConfig;
    public records: Types.DnsApi.Record[];
    public defaultNameserver: any[];
    public userSettingShowExpertSettings: boolean;
    public showExpertSettings: boolean;
    public dnsSecOptions: Types.DnsApi.DnsSecOptions;
    public dnsSecActive: boolean;
    public deletionConfirmed = false;
    public showLargeZoneWarning = false;
    public deactivatedZonePanelItems = [];
    public deactivatedZonePanelCallout = {};
    public zoneHasUnfinishedJob = false;
    public userHasRightDnsZonesCreate = AuthContextService.isGranted(UiRights.DNS_ZONES_CREATE);
    public userHasRightDnsZonesDelete = AuthContextService.isGranted(UiRights.DNS_ZONES_EDIT);
    public selectedRestoreAction: string;

    constructor(
        private $translate: ng.translate.ITranslateService,
        private dnsJobModel: DnsJobModelService,
        private dnsZoneModel: DnsZoneModelService,
        private alertManager: AlertManagerService,
        private navigation: NavigationService
    ) {
        this.deactivatedZonePanelCallout = {
            message: this.$translate.instant('TR_100119-93c220_TR'),
            type: 'warning'
        };
    }

    public $onInit = () => {
        this.zone = ng.copy(this.originalZone);
        if (this.records.length > 200) {
            this.showLargeZoneWarning = true;
        }
        if ([undefined].indexOf(this.userSettingShowExpertSettings) >= 0) {
            this.userSettingShowExpertSettings = false;
        }
        if ([undefined].indexOf(this.showExpertSettings) >= 0 || this.zone.status === 'restorable') {
            this.showExpertSettings = false;
        }

        if (AuthContextService.isGranted(UiRights.EMAIL_JOBS_LIST)) {
            this.dnsJobModel.findRunningJobsForZone(this.originalZone)
            .then(
                (result) => {
                    this.zoneHasUnfinishedJob = result.status === 'success'
                    && result.response.data.length > 0;
                }
            );
        }
    };

    public saveZone = () => {
        if (this.zone.type.toLowerCase() === 'slave') {
            return this._updateSlaveZone()
            .then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-c166c8_TR'));
                }
            );
        } else {
            return this._updateNativeZone()
            .then(
                () => {
                    this.alertManager.success(this.$translate.instant('TR_100119-0cce92_TR'));
                }
            );
        }
    };

    public deleteZone = () => {
        this.dnsZoneModel.delete([this.zone])
        .then(
            () => {
                this.alertManager.success(this.$translate.instant('TR_100119-9a8412_TR'));
                this.navigation.go('dns.zones.overview');
            }
        );
    };

    public refresh = () => {
        this.navigation.reloadCurrentState(true);
    };

    public cancelDeletion = () => {
        this.deletionConfirmed = false;
    };

    public restoreOrPurge = () => {
        if (this.selectedRestoreAction === 'restore') {
            this.dnsZoneModel.restoreDnsZone(this.zone.id).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_281122-460116_TR'));
                    this.navigation.reloadCurrentState();
                }
            );
        } else if (this.selectedRestoreAction === 'purge') {
            this.dnsZoneModel.purgeRestorableDnsZone(this.zone.id).then(
                (apiResponse) => {
                    this.alertManager.success(this.$translate.instant('TR_281122-cf4c19_TR'));
                    this.navigation.go('dns.zones.overview');
                }
            );
        }
    };

    private _updateSlaveZone = () => {
        const updatedZone = ng.copy(this.zone);
        updatedZone.templateValues = null;
        return this.dnsZoneModel.updateSlave(updatedZone);
    };

    private _updateNativeZone = () => {
        // Always returns... 🐳
        if (!this.userSettingShowExpertSettings && !this.showExpertSettings) {
            return;
        }

        if (!this.dnsSecActive) {
            this.zone.dnsSecMode = 'off';
        } else {
            this.zone.dnsSecMode = 'AUTOMATIC';
        }

        const dnsSecOptions: Types.DnsApi.DnsSecOptions = this.dnsSecActive
        ? this.dnsSecOptions
        : undefined;

        if (this.zone.type !== 'MASTER') {
            this.zone.zoneTransferWhitelist = [];
        }

        this.zone.soaValues.negativeTtl = +this.zone.soaValues.negativeTtl;
        this.zone.soaValues.refresh = +this.zone.soaValues.refresh;
        this.zone.soaValues.retry = +this.zone.soaValues.retry;
        this.zone.soaValues.ttl = +this.zone.soaValues.ttl;
        this.zone.soaValues.expire = parseInt(`${this.zone.soaValues.expire}`, 10);

        if (
            JSON.stringify(this.zone.zoneTransferWhitelist)
            === JSON.stringify(this.originalZone.zoneTransferWhitelist)
        ) {
            return this.dnsZoneModel.updateZone(this.zone, [], [], dnsSecOptions)
            .then(
                (res) => {
                    if (res.code !== undefined) {
                        return q.reject(res.errors);
                    } else {
                        return { response: res.response };
                    }
                }
            );
        } else {
            return this.dnsZoneModel.updateNative(this.zone, this.records, undefined, undefined, dnsSecOptions)
            .then(
                (res) => {
                    if (res.code !== undefined) {
                        return q.reject(res.errors);
                    } else {
                        return { response: res.response };
                    }
                }
            );
        }
    };
}

export class OrganismEditFormDnsZoneComponent implements ng.IComponentOptions {
    public bindings = {
        defaultNameserver: '<',
        dnsSecOptions: '<',
        originalZone: '<zone',
        records: '<',
        userSettingShowExpertSettings: '<'
    };
    public controller = OrganismEditFormDnsZoneController;
    public template = require('./dns-zone-edit.html');
}
