import ng from 'angular';
import {
    DomainContactValidatorService,
    DomainHandleModelService,
    DomainHelper,
    DomainModelService
} from '@/services';
import {
    DomainContactPanelMode,
    EditPanelRight,
    MoleculeFormEditController,
    OrganismEditFormDomainProductController
} from '@/atomic-components';
import { HandleDefaultLabels, HandleTypes, HandleMissingContactHintTexts } from '@/configuration/domain/handle';
import { DomainApi, ViewTypes } from '@/types';

import './domain-contacts.scss';

export class OrganEditPanelDomainContactsController {
    public static $inject: string[] = [
        '$timeout',
        '$translate',
        'domainContactValidator',
        'domainHandleModel',
        'domainModel'
    ];

    public panelRight: EditPanelRight;
    public editFormOrganism: OrganismEditFormDomainProductController;
    public domain: DomainApi.Domain;
    public contactSetValidationErrors: unknown[] = [];
    public domains: {tld: string}[];
    public showContactInfoModal = false;
    public loadContactInfo = false;
    public contactInfo: DomainApi.Contact = null;
    public panelMode: DomainContactPanelMode = DomainContactPanelMode.DISPLAY;
    public updateIsOwnerChange: boolean = undefined;
    public designateAgent = false;
    public domainContactList: ViewTypes.DomainContactObject[] = [];
    public contactNotSet: string[] = [];
    public handleTypesConst = HandleTypes;

    private $editFormMolecule: MoleculeFormEditController;
    private _registrationIndex: number;

    constructor(
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private domainContactValidator: DomainContactValidatorService,
        private domainHandleModel: DomainHandleModelService,
        private domainModel: DomainModelService
    ) {}

    public $onInit(): void {
        this.panelRight.editPanelButton = HandleTypes.every(
            (handleType) => this.domainContactList.some(
                (contact) => {
                    return contact.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase();
                }
            )
        );
        this._registrationIndex = this.$editFormMolecule.registerValidator(this);
        this.$editFormMolecule.validatorStatus[this._registrationIndex] = true;
        this.domains = [{ tld: DomainHelper.getTld(this.domain.nameUnicode) }];

    }

    public get contactsNotSet(): boolean {
        return this.contactNotSet.length > 0;
    }

    public issetHandle = (handleType: string): boolean => {
        return this.domainContactList.some(
            (handle) => handle.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase()
        );
    };

    public filterNotOwner = (contactType: string): boolean => {
        return 'owner' !== contactType.toLowerCase();
    };

    public validateContactSet = async (): Promise<void> => {
        if (!this._allHandlesSet()) {
            this.contactSetValidationErrors = [];
            this.$editFormMolecule.validatorStatus[this._registrationIndex] = false;
        }

        this.updateIsOwnerChange = undefined;

        const tlds: string[] = [];
        for (const domain of this.domains) {
            tlds.push(domain.tld );
        }

        const contacts = this.domainContactList
            .map(
                (contact) => ({
                    contact: contact.contactId,
                    type: contact.contactType
                })
            );

        await this.domainContactValidator.contactSetUsableFor(
            contacts,
            tlds,
            this.domain.trusteeServiceEnabled,
            this.domain.accountId
        )
        .then(
            async (reply: DomainApi.ContactSetUsableProblem[]) => {
                this.contactSetValidationErrors = reply;
                this.$editFormMolecule.validatorStatus[this._registrationIndex] =
                (this.contactSetValidationErrors.length === 0);

                if (!reply) {
                    return;
                }

                const tmpDomain = ng.copy(this.domain);
                tmpDomain.contacts = this.domainContactList.map(
                    (contact) => {
                        return {
                            contact: contact.contactId,
                            type: contact.contactType
                        };
                    }
                );

                await this.domainModel.domainUpdateIsIcannOwnerChange(tmpDomain)
                .then(
                    (response) => {
                        this.updateIsOwnerChange = response;
                        this.designateAgent = this.designateAgent && response;
                    }
                );
            }
        );
    };

    public showContactInfo = async (contactId: string): Promise<void> => {
        if (this.loadContactInfo || this.showContactInfoModal) {
            return;
        }
        this.loadContactInfo = true;
        await this.domainHandleModel.findOne(contactId).then(
            (reply: DomainApi.Contact) => {
                void this.$timeout(() => {
                    this.loadContactInfo = false;
                    this.contactInfo = reply;
                    this.showContactInfoModal = true;
                });
            }
        );
    };

    public closeContactInfoModal = (): void => {
        this.showContactInfoModal = false;
        this.contactInfo = null;
    };

    public getContactId = (handleType: string): string => {
        let handleObject: ViewTypes.DomainContactObject;

        this.domainContactList.some(
            (handle) => {
                if (handle.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase()) {
                    handleObject = handle;
                    return true;
                }

                return false;
            }
        );

        return handleObject.contactId;
    };

    public getContactLabel = (handleType: string): string => {
        let handleObject: ViewTypes.DomainContactObject;

        this.domainContactList.some(
            (handle) => {
                if (handle.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase()) {
                    handleObject = handle;
                    return true;
                }

                return false;
            }
        );

        return handleObject.label;
    };

    public getDefaultHandleLabel = (handleType: string): string => {
        let label = '';
        HandleDefaultLabels.some(
            (handle) => {
                if (handle.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase()) {
                    label = this.$translate.instant(handle.label);

                    return true;
                }

                return false;
            }
        );

        return label;
    };

    public getDefaultHandleMissingHintText = (handleType: string): string => {
        let hintText = '';
        HandleMissingContactHintTexts.some(
            (contact) => {
                if (contact.contactType.toLocaleLowerCase() === handleType.toLocaleLowerCase()) {
                    hintText = this.$translate.instant(contact.label);

                    return true;
                }

                return false;
            }
        );

        return hintText;
    };

    private _allHandlesSet = (): boolean => {
        return this.domainContactList.every((contact) => ![undefined, null, ''].includes(contact.contactId));
    };
}

export class OrganEditPanelDomainContactsComponent implements ng.IComponentOptions {
    public bindings = {
        designateAgent: '=?',
        domain: '<',
        domainContactList: '<',
        panelRight: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit',
        $editFormOrganism: '^organismEditFormDomainProduct'
    };
    public controller = OrganEditPanelDomainContactsController;
    public template = require('./domain-contacts.html');
}
