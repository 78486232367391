import { ProductFamiliesConst, UiLanguagesConst, UiRights } from '@/configuration';
import { CalculatePriceFilter } from '@/filters/calculate-price';
import { AuthContextService, ProductsModelService, UserSettingsModelService } from '@/services';
import * as Types from '@/types';
import * as Sentry from '@sentry/browser';

import { PriceCacheService, PriceModelService, PromoManagerService } from './';

export class PricelistHelperService {
    public static $inject: string[] = [
        'priceCache',
        'productsModel',
        'promoManager',
        'priceModel',
        'userSettingsModel',
        'calculatePriceFilter'
    ];

    constructor(
        private priceCache: PriceCacheService,
        private productsModel: ProductsModelService,
        private promoManager: PromoManagerService,
        private priceModel: PriceModelService,
        private userSettingsModel: UserSettingsModelService,
        private calculatePriceFilter: CalculatePriceFilter
    ) {}

    public getDnsPriceDetails = (): PromiseLike<any> => {
        const service = 'dns';
        return this._getProductsOfProductFamilies(service, ProductFamiliesConst.dns)
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getDomainsPriceDetails = (): PromiseLike<any> => {
        // Domain products has no product families
        // So only get domaion service prices
        return this._getProductServicePrice('domains');
    };

    public getMachinePriceDetails = (): PromiseLike<any> => {
        const service = 'machine';
        return this._getProductsOfProductFamilies('machine', ProductFamiliesConst.machines)
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getSslPriceDetails = (): PromiseLike<any> => {
        const service = 'ssl';
        return this._getProductsOfProductFamilies(service,  ProductFamiliesConst.ssl, true)
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getEmailPriceDetails = (): PromiseLike<any> => {
        const service = 'email';
        return this._getProductsOfProductFamilies(service, ProductFamiliesConst.emailPriceList)
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getWebhostingPriceDetails = (): PromiseLike<any> => {
        const service = 'webhosting';
        return this._getProductsOfProductFamilies(service, ProductFamiliesConst.webhosting)
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getDatabasesPriceDetails = (): PromiseLike<any> => {
        const service = 'database';
        return this._getProductsOfProductFamilies(service, ProductFamiliesConst.database)
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getStorageProductPriceDetails = (): PromiseLike<any> => {
        const service = 'managedapplication';
        return this._getProductsOfProductFamilies(service, ProductFamiliesConst.nextcloud, true)
            .then((products) => {
                return this._setPricelist(service, products);
            });
    };

    public getPromoPriceDetails(): PromiseLike<any> {
        return this.promoManager.getPromotions();
    }

    public getChangedPriceDetails = (): Promise<any> => {
        const promises = [];

        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_DOMAIN)) {
            promises.push(this.priceCache.listDomainPriceChanges());
        }
        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_SSL)) {
            promises.push(this.priceCache.listSslPriceChanges());
        }
        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_EMAIL)) {
            promises.push(this.priceCache.listMailPriceChanges());
        }
        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_WEBHOSTING)) {
            promises.push(this.priceCache.listWebhostingPriceChanges());
        }
        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_DATABASE)) {
            promises.push(this.priceCache.listDatabasePriceChanges());
        }
        if (AuthContextService.isGranted(UiRights.BIL_LIST_PRICES_MACHINE)) {
            promises.push(this.priceCache.listVmPriceChanges());
        }

        return Promise.all(promises)
            .then((priceLists) => {
                return priceLists
                    .reduce(
                        (previous, current) => {
                            return previous.concat(current);
                        }
                    )
                    .sort(
                        (a, b) => {
                            if (a.startPIT < b.startPIT) {
                                return -1;
                            }
                            if (a.startPIT > b.startPIT) {
                                return 1;
                            }
                            return 0;
                        }
                    );
            });
    };

    /**
     *  Get all products of productFamilies
     */
    private _getProductsOfProductFamilies = (
        service: string,
        productFamilies: string[],
        withRelatedProducts?: boolean
    ): PromiseLike<Types.ProductApi.AbstractProduct[]> => {
        const language = UiLanguagesConst[AuthContextService.user.language];
        const productPromises = productFamilies.map(
            (productFamily) => {
                return this.productsModel.findProducts(service, productFamily, language, withRelatedProducts)
                    .then((productResponseData) => productResponseData.response.data)
                    .then((products) => products);
            }
        );

        return Promise.all(productPromises).then((products) => [].concat(...products));
    };

    /**
     *  List product with billing cycle prices
     */
    private _setPricelist = (service: string, products): Promise<any> => {
        return this._getProductServicePrice(service)
            .then((servicePrices) => products.map((serviceProduct) => this._setProductPrice(serviceProduct, servicePrices);
    };

    private _setProductPrice = (serviceProduct, servicePrices) => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-return
        const product = {
            prices: [],
            productCode: serviceProduct.type === 'Product' ? serviceProduct.productCode : '',
            productCodeTemplate: serviceProduct.type === 'TemplateProduct'
            ? serviceProduct.productCodeTemplate
            : '',
            productName: serviceProduct.name,
            productType: serviceProduct.type,
            relatedProducts: []
        };

        if (product.productType === 'TemplateProduct') {
            serviceProduct.billingCycles.map((cycle) => {
                product.prices[cycle] = this._getProductPriceOfProductCode(
                    product.productCodeTemplate.replace('##BILLING_CYCLE##', cycle),
                    servicePrices
                );
            });
        } else if (product.productType === 'Product') {
            // First get product price...
            const productPrice = this._getProductPriceOfProductCode(product.productCode, servicePrices);
            // Secondly get billing cycle form price contract period
            const cycle = this._getCycleOfContractPeriod(productPrice);
            if (cycle !== null) {
                product.prices[cycle] = productPrice;
            } else {
                // if there ist a unknown contract period wording, add price as next pric in product.prices
                product.prices.push(productPrice);
            }
        }

        if (serviceProduct?.relatedProducts && serviceProduct.relatedProducts.length > 0) {
            product.relatedProducts = serviceProduct.relatedProducts.map(
                (relatedProduct) => this._setProductPrice(relatedProduct, servicePrices)
            );
        }

        return product;
    };

    private _getProductPriceOfProductCode = (
        productCode: string,
        servicePrices: Types.BillingApi.ArticlePurchasePrice[]
    ) => {
        const cyclePrice = servicePrices.find((price: Types.BillingApi.ArticlePurchasePrice) => {
            return productCode === price.productCode;
        });

        if (cyclePrice !== undefined) {
            // set price only, if price is defined!
            cyclePrice.uiPrice = this.calculatePriceFilter(
                cyclePrice.netAmount,
                cyclePrice.amounts[0].vatRate,
                1,
                'gross',
                undefined,
                false,
                productCode,
                cyclePrice.contractPeriod
            );
            return cyclePrice;
        }

        // throw sentry error
        Sentry.captureMessage('API pricelist error', {
            extra: { productCode: productCode, message: 'Missing price in billing servicePristList request' },
            tags: { key: 'api', service: 'billing', apiMethod: 'servicePriceList' }
        });

        return {
            uiPrice: 'N/A'
        };
    };

    /**
     * ProductFind object with type: Product has no information about billingCycles.
     * So we get this information from servicePriceList contractPeriod
     * (ex. monthly, quarterly, semiannually, annually1, ....)
     */
    private _getCycleOfContractPeriod = (productPrice: Types.BillingApi.ArticlePurchasePrice): string => {
        const cycle = [
            {
                contractPeriod: 'monthly',
                cycle: '1'
            },
            {
                contractPeriod: 'quarterly',
                cycle: '3'
            },
            {
                contractPeriod: 'semiannually',
                cycle: '6'
            },
            {
                contractPeriod: 'annually',
                cycle: '12'
            }
        ].filter((val) => productPrice.contractPeriod === val.contractPeriod);
        return cycle.length === 1 ? cycle[0].cycle : null;
    };

    /**
     *  Get product service prices
     */
    private _getProductServicePrice = (service: string): Promise<any> => {
        switch (service) {
            case 'dns':
                return this.priceModel.getDnsPrices();

            case 'machine':
                return this.priceModel.getMachinePrices();

            case 'ssl':
                return this.priceModel.getSSLPrices();

            case 'email':
                return this.priceModel.getEmailPrices();

            case 'webhosting':
                return this.priceModel.getWebhostingPrices();

            case 'database':
                return this.priceModel.getDatabasePrices();

            case 'domains':
                return this.priceModel.getDomainPrices();

            case 'managedapplication':
                return this.priceModel.getManagedApplicationPrices();

            default:
                return Promise.resolve([]);
        }
    };
}
