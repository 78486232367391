import './wizard-product-selection.scss';

import ng from 'angular';

import {
    buttonInterface, Contingent, ContingentType, ContingentUsage, ProductConfigVoucher,
    WizardPromoProduct
} from '@/atomic-components';
import { NextcloudFreeProductCodeConst } from '@/configuration';
import {
    AuthContextService, DepositModelService, ManagedApplicationRobotService,
    SelectBoxContentCreatorNew
} from '@/services';
import {
    BillingHelperService, ProductHelperService, WizardNewHelperService
} from '@/services/helpers';
import { FamilyProductBoxObject, ProductBoxObject } from '@/services/products';
import * as Types from '@/types';

import ProductSelectionOverlay = Types.ViewTypes.ProductSelectionOverlay;

/*
 * Komponent ist abgeleitet von molecule wizard product select
 */
export class OrganismWizardProductSelectionController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'billingHelper',
        'depositModel',
        'managedApplicationRobot',
        'productHelper',
        'selectBoxContentCreatorNew',
        'wizardNewHelper'
    ];

    public account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    public contingentAvailable = false;
    public productFamilies: string[];

    public cycleSelectItems: buttonInterface[] = [];
    public service: string;
    public inMachine = false;
    public hideContingentSelection = false;
    public overlayData: ProductSelectionOverlay[];
    public outerProductCode: string = null;
    public outerProductFamily: string = null;
    public productFamilySelectTitle: string = null;
    public productFamilyChangeCallback: (param: unknown) => void;
    public productSelectTitle: string = null;
    public selectedProductFamily: string = null;
    public selectedProduct: ProductBoxObject = null;
    public _selectedBillingCycle: number = null;
    public promoProductItems: WizardPromoProduct[];
    public useSimplePanelTitle = false;
    public virtualProductHasBeenPreselected = false;
    public showVoucherInput = false;
    public isVoucherCodeValid = false;
    public voucherCode = '';
    public voucherCodeChangeCallback: (param?: unknown) => void;
    public voucherHasError = false;
    public voucherInputInfo = '';
    public isValidVoucher = false;

    public productName: string;

    public preselectedItemObject: { productFamily: string; productCode: string };

    /**
     * When creating a product in a bundle or on a managed server,
     * account and contingent are already decided, so we shouldn't
     * show the dropdowns...
     *
     * Not used yet, because we have to make sure all affected wizards
     * set both account and contingent correctly, first.
     */
    public disableAccountAndContingentSelection: boolean;
    public isCheckedVoucher = false;
    public voucher: ProductConfigVoucher;
    public status: any;

    private _cycleSelectItems: buttonInterface[] = [];
    private _productFamilyIsChanging = false;
    private _selectFamilyBoxes: FamilyProductBoxObject[];
    private _productBoxesLoaded = false;
    private _selectedContingent: Contingent;
    private _selectedFamily: FamilyProductBoxObject = null;
    private _selectedProductCode: string;
    private _showProductFamilySelectionWithSingleFamily = false;
    private _showProductSelectionWithSingleProduct = false;

    constructor (
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        private $translate: ng.translate.ITranslateService,
        private billingHelper: BillingHelperService,
        private depositModel: DepositModelService,
        private managedApplicationRobot: ManagedApplicationRobotService,
        private productHelper: ProductHelperService,
        private selectBoxContentCreatorNew: SelectBoxContentCreatorNew,
        private wizardNewHelper: WizardNewHelperService
    ) {
        const stateInBundle = this.$state.current.name.split('.')[0] === 'bundle';
        const stateInMachine = this.$state.current.name.split('.')[0] === 'managed-servers';

        this.disableAccountAndContingentSelection = stateInBundle || stateInMachine;
    }

    public $onInit() {
        this.hideContingentSelection = this.hideContingentSelection || false;
        this._setProductSelectTitle();
    }

    public $onChanges(changes: any) {
        if (changes.account !== undefined
            && [undefined, null].indexOf(changes.account.currentValue) < 0
            && [undefined, null].indexOf(changes.account.currentValue.id) < 0
        ) {
            this._resetProductData(changes.account.currentValue);
        }
    }

    public get selectedContingent() {
        return this._selectedContingent;
    }

    public set selectedContingent(contingent: Contingent) {
        this._selectedContingent = contingent;

        switch (contingent?.type) {
            default:
            case undefined:
                this.cycleSelectItems = this._cycleSelectItems;

                break;

            case ContingentType.bundle:
                this.cycleSelectItems = this._cycleSelectItems.filter(
                    (cycleSelectItem) => (contingent.misc.bundle as Types.BundleApi.Bundle).effectiveContingentUsage
                    .some(
                        (contingentUsage) => {
                            if (contingentUsage.availableCapacity === 0) {
                                return false;
                            }

                            return contingentUsage.productCodes.indexOf(
                                this.selectedProduct.productCode
                                || this.selectedProduct.productCodeTemplate.replace(
                                    '##BILLING_CYCLE##',
                                    `${cycleSelectItem.value}`
                                )
                            ) >= 0;
                        }
                    )
                );

                break;

            case ContingentType.databaseServer:
            case ContingentType.webserver:
                if (contingent.misc?.virtualMachineProduct) {
                    this.cycleSelectItems = this._cycleSelectItems.filter(
                        (item) => item.value === contingent.misc.virtualMachineProduct.billingCycle
                    );
                } else {
                    this.cycleSelectItems = this._cycleSelectItems;
                }

                break;
        }

        if (this.cycleSelectItems.length > 0) {
            this._selectedBillingCycle = this.cycleSelectItems[this.cycleSelectItems.length - 1].value as number;
            this.billingCycleChangeCallback(this._selectedBillingCycle);
        }
    }

    public set selectFamilyBoxes(value) {
        this._selectFamilyBoxes = value;
    }

    public get selectFamilyBoxes() {
        return this._selectFamilyBoxes;
    }

    public set selectedProductCode({}) {} // tslint:disable-line:no-empty
    public get selectedProductCode() {
        return this._selectedProductCode;
    }

    public set showBillingCycleSelection({}) {} // tslint:disable-line:no-empty
    public get showBillingCycleSelection() {
        if (
            this.contingentAvailable
            && this.selectedContingent?.id !== ContingentUsage.chargeable
            && this.selectedContingent?.type !== ContingentType.pool
        ) {
            return false;
        }

        return [undefined, null].indexOf(this.cycleSelectItems) < 0
            && this.cycleSelectItems.length > 1;
    }

    public set showProductSelectionSection({}) {} // tslint:disable-line:no-empty
    public get showProductSelectionSection() {
        return [undefined, null].indexOf(this.account) < 0;
    }

    public set loadingProductSelectionData({}) {} // tslint:disable-line:no-empty
    public get loadingProductSelectionData() {
        return !this._productBoxesLoaded;
    }

    public set loadedProductSelectionData({}) {} // tslint:disable-line:no-empty
    public get loadedProductSelectionData() {
        return this._productBoxesLoaded;
    }

    public set showProductFamilySelection({}) {} // tslint:disable-line:no-empty
    public get showProductFamilySelection() {
        if (!Array.isArray(this.selectFamilyBoxes)
            || this.selectFamilyBoxes.length === 0
        ) {
            // family boxes === undefined or empty array
            return false;
        }

        if (this.selectFamilyBoxes.length === 1) {
            // only one box isset
            return this._showProductFamilySelectionWithSingleFamily;
        }

        // here we go - more than one boxes isset - show always boxes
        return true;
    }

    public set showProductSelection({}) {} // tslint:disable-line:no-empty
    public get showProductSelection() {
        if (this._selectedFamily === null || this._productFamilyIsChanging) {
            return false;
        } else if (this._showProductSelectionWithSingleProduct) {
            return true;
        }

        return Array.isArray(this._selectedFamily.products)
            ? this._selectedFamily.products.length > 1
            : false;
    }

    public set showProductBillincCycleSelection({}) {} // tslint:disable-line:no-empty
    public get showProductBillincCycleSelection() {
        return [undefined, null].indexOf(this.selectedProduct) < 0
            && (!this.hideContingentSelection || this.showBillingCycleSelection || !this.hidePrice);
    }

    public set selectProductBoxes({}) {} // tslint:disable-line:no-empty
    public get selectProductBoxes() {
        return [undefined, null].indexOf(this._selectedFamily) < 0
            ? this._selectedFamily.products
            : [];
    }

    public set hidePrice({}) {} // tslint:disable-line:no-empty
    public get hidePrice() {
        return this._selectedFamily?.hidePrice === true;
    }

    public set selectedProductFamilyName({}) {} // tslint:disable-line:no-empty
    public get selectedProductFamilyName() {
        return this._selectedFamily !== null
            ? (this.useSimplePanelTitle ? '' : this._selectedFamily.name)
            : '';
    }

    public set productSelectPanelTitle({}) {} // tslint:disable-line:no-empty
    public get productSelectPanelTitle() {
        let title = '';

        if (this.useSimplePanelTitle) {
            title = this.productSelectTitle;
        } else {
            title = this.selectedProductFamilyName;

            if (this.productSelectTitle !== null) {
                title = `${title} - ${this.productSelectTitle}`;
            }
        }

        return title;
    }

    public set selectedProductName({}) {} // tslint:disable-line:no-empty
    public get selectedProductName() {
        return [undefined, null].indexOf(this.selectedProduct) < 0
            ? this.selectedProduct.name
            : '';
    }

    public set billingCyclePanelTitle({}) {} // tslint:disable-line:no-empty
    public get billingCyclePanelTitle() {
        let panelTitle = '';
        if ([undefined, null].indexOf(this.selectedProduct) < 0) {
            return panelTitle;
        }

        panelTitle = this.selectedProduct.name;

        if (this.selectedProductFamily === 'individual-virtual-machines') {
            panelTitle = this.$translate.instant('TR_180520-013c2d_TR');
        }

        return panelTitle;
    }

    public get priceAlteration(): string | undefined {
        if (this.isCheckedVoucher && this.isValidVoucher) {
            return this.$translate.instant('TR_150621-6c947f_TR',
                { costFreePeriodInDays: this.voucher.costFreePeriodInDays.toString() }
            );
        }
        return undefined;
    }

    public get selectedProductIsInContingent() {
        if (!this._selectedContingent) {
            return false;
        }

        if (this._selectedContingent.type === ContingentType.standalone || this._productIsContingentIndependent()) {
            return false;
        }

        return true;
    }

    public get showProductPrice() {
        return this.selectedProductCode !== null
            && !this.selectedProductIsInContingent
            && this.selectedProductFamily !== 'individual-virtual-machines';
    }

    public familyChangeCallback = (selectedFamily: FamilyProductBoxObject) => {
        this._productFamilyIsChanging = true;
        this.$timeout(() => {
            if (this.productFamilyChangeCallback !== undefined) {
                this.productFamilyChangeCallback(selectedFamily.family);
            }
            selectedFamily.products = this._applyOverlayData(selectedFamily.products, 'product') as ProductBoxObject[];
            this._selectedFamily = selectedFamily;
            this.selectedProduct = null;
            this._selectedBillingCycle = null;
            if (selectedFamily.products.length === 1) {
                if (!this.preselectedItemObject?.productFamily) {
                    this.selectedProductFamily = selectedFamily.family;
                } else {
                    this.selectedProductFamily = ng.copy(this.preselectedItemObject.productFamily);
                    this.preselectedItemObject.productFamily = null;
                }
                this.selectedProduct = selectedFamily.products[0];
                this._setCycleItems();
            }
            this._tryToSetProductCode();
            this._productFamilyIsChanging = false;
        });
    };

    public productChangeCallback = (selectedBox: FamilyProductBoxObject | ProductBoxObject) => {
        // Auch wenn es total unintuitiv ist, benötigen wir das setzen der ProductFamily hier,
        // da die Family (wenn Produkt verschiedene BillingCycles hat) erst nach der Auswahl des Produktes gesetzt wird.
        // Und der ProductCode wird erst in der _tryToSetProductCode Methode gesetzt (?!!!)
        if (!this.preselectedItemObject?.productFamily) {
            this.selectedProductFamily = selectedBox.family;
        } else {
            this.selectedProductFamily = ng.copy(this.preselectedItemObject.productFamily);
            this.preselectedItemObject.productFamily = null;
        }
        this.$timeout(() => {
            this.selectedProduct = selectedBox as ProductBoxObject;
            this._selectedBillingCycle = null;
            this._setCycleItems();
            this._tryToSetProductCode();
        });
    };

    public billingCycleChangeCallback = (billingCycle: number) => {
        this._selectedBillingCycle = billingCycle;
        this._tryToSetProductCode();
    };

    public changeVoucherCode = () => {
        this.isCheckedVoucher = false;
    };

    public checkVoucherCodeValid = async () => {
        const { voucher, status }: { voucher: ProductConfigVoucher; status: string } = (await this.depositModel.getVoucherDetails(this.voucherCode)).response;
        this.voucher = voucher;
        this.status = status;

        switch (status) {
            case 'doesNotExistOrIneligible':
                // Voucher code not found or access through account is prohibited.
                this.voucherInputInfo = this.$translate.instant('TR_150621-6c913f_TR');
                break;
            case 'notEffectiveYet':
                // Voucher code not yet valid.
                this.voucherInputInfo = this.$translate.instant('TR_150621-bb557a_TR');
                break;
            case 'expired':
                // Code is expired.
                this.voucherInputInfo = this.$translate.instant('TR_150621-c35113_TR');
                break;
            case 'valid':
            case 'success':
               // Can be cashed in.
               this.voucherInputInfo = this.$translate.instant('TR_150621-9ba703_TR');
               if (voucher.type === 'ProductVoucher') {
                    if (voucher.eligibleProductCodes?.includes(this.selectedProductCode)) {
                        this.voucherCodeChangeCallback(voucher);
                        this.isValidVoucher = true;
                        this.voucherHasError = false;
                        this.isCheckedVoucher = true;
                        return;
                    } else {
                        this.voucherInputInfo = this.$translate.instant('TR_150621-b5f7d1_TR');
                    }
               }
               break;
           case 'redeemed':
               // Code has already been used.
               this.voucherInputInfo = this.$translate.instant('TR_150621-24662d_TR');
               break;
           default:
               this.voucherInputInfo = this.$translate.instant('TR_150621-c26896_TR');
               break;
        }
        this.voucherCodeChangeCallback();
        this.isValidVoucher = false;
        this.voucherHasError = true;
        this.isCheckedVoucher = true;
    };

    private _productIsContingentIndependent = (): boolean => {
        return ['virtual-machines'].indexOf(this.selectedProductFamily) >= 0;
    };

    private _tryToSetProductCode = () => {
        /**
         * Depending on how given selections are set or other product
         * information is defined, the product to be ordered is set
         * (family, product and/or contract duration).
         */
        // CHECK: PRODUCT FAMILY
        if (this.selectedProduct === null
            && !Array.isArray(this.selectFamilyBoxes)
            || this.selectFamilyBoxes.length === 1
            && !this._showProductFamilySelectionWithSingleFamily
        ) {
            /**
             * If there is only one FamilyBox and _showProductFamilySelectionWithSingleFamily = false,
             * then set the one FamilyBox as selected
             */
            this._selectedFamily = this.selectFamilyBoxes[0];
        }

        if (this._selectedFamily !== null
            && this.selectedProduct === null
            && !this._showProductSelectionWithSingleProduct
        ) {
            /** family is selected and only one product is given in family,
             *  select this single product, because of product selection is not shwon
             */
            this.selectedProduct = this._selectedFamily.products.length === 1
                ? this._selectedFamily.products[0]
                : null;
        }

        // CHECK: PRODUCT
        if (this.selectedProduct !== null) {
            this._setCycleItems();
            if ([undefined, null].indexOf(this.selectedProduct?.productCode) < 0) {
                // If the product code is set in the selected product, the billing cycle does not play a role.
                this._selectedProductCode = this.selectedProduct.productCode;
                return;
            }

            if (this._selectedBillingCycle === null
                && this.selectedProduct?.billingCycles?.length === 1)  {
                this._selectedBillingCycle =  this.selectedProduct.billingCycles[0];
            }
        }

        // CHECK: BILLING CYCLE AND POSSIBLE SET PRODUCTCODE
        if (!this.preselectedItemObject?.productCode) {
            if (!this._selectedProductCode || !this.virtualProductHasBeenPreselected) {
                this._selectedProductCode = this._selectedBillingCycle !== null
                    ? this.productHelper.getProductCodeFromProductBoxObject(
                        this.selectedProduct,
                        this._selectedBillingCycle)
                    : null;
            }
        } else {
            this._selectedProductCode = ng.copy(this.preselectedItemObject.productCode);
            this.preselectedItemObject.productCode = null;
        }
    };

    private _setCycleItems = () => {
        if (
            this.selectedProduct
            && this.selectedProduct.billingCycles !== null
            && this.selectedProduct.billingCycles.length >= 1
        ) {
            this._sortBillingCycles();
        } else {
            this._cycleSelectItems = [];
        }
    };

    private _resetProductData = (account?: Types.AccountApi.Account | Types.AccountApi.Subaccount) => {
        /**
         * Currently the method is only called after onChange account.
         * With onChange this.account is not updated yet.
         * The following part, however, should make it possible that the method works without passing the account.
         */
        if (account !== undefined || [undefined, null].indexOf(this.account) >= 0 || account.id !== this.account.id) {
            this.account = account;
        }

        this._getSelectionData().then(
            () => {
                this.$timeout(() => this._productBoxesLoaded = true);
                this._tryToSetProductCode();
            },
            (err) => {
                //
                this.$timeout(() => this._productBoxesLoaded = true);
            }
        );
    };

    private _sortBillingCycles = () => {
        /**
         * There is more than one billing cycle, define the radio button items for the selection display
         */

        const tmpSelectItems = this.billingHelper
        .sortBillingCyclesAsc(this.selectedProduct.billingCycles)
        .map(
            (cycle: number) => {
                this.wizardNewHelper.getBillingCycleTranslation(cycle);

                return {
                    label: this.wizardNewHelper.getBillingCycleTranslation(cycle),
                    value: cycle
                };
            }
        );

        if (JSON.stringify(tmpSelectItems) !== JSON.stringify(this._cycleSelectItems)) {
            this._cycleSelectItems = tmpSelectItems;
            this.selectedContingent = this._selectedContingent;
        }
    };

    private _getSelectionData = (): PromiseLike<any> => {
        this.selectFamilyBoxes = [];
        this.selectedProductCode = null;
        this._productBoxesLoaded = false;
        this._selectedFamily = null;
        this.selectedProduct = null;

        switch (this.service) {
            case 'managedapplication':
                /**
                 * Check at managedapplication (nextcloud) product:
                 *
                 * - whether orders are placed for a subaccount
                 * - whether users have already set up a free product
                 *
                 * If one of the two cases applies, ignore the productCode
                 * for the free product and do not display it in the product overview
                 */
                return this.managedApplicationRobot.nextcloudsListWithoutPagination(
                    {
                        field: 'nextcloudProductCode',
                        value: NextcloudFreeProductCodeConst
                    },
                    1,
                    null
                )
                .then(
                    (productResponse) => {
                        const ignoreProductCodeList = [];
                        if (AuthContextService.account.id !== this.account.id
                            || (
                                productResponse.response !== undefined
                                && productResponse.response.data !== undefined
                                && productResponse.response.data.length > 0
                            )
                        ) {
                            ignoreProductCodeList.push(NextcloudFreeProductCodeConst);
                        }

                        return this.selectBoxContentCreatorNew.createSelectBoxes(
                            this.service,
                            this.productFamilies,
                            ignoreProductCodeList
                        );
                    }
                )
                .then(
                    (reply: any) => { // any .... what is about FamilyProductBoxObject[]??
                        this.selectFamilyBoxes = reply;
                        return reply;
                    }
                );

            default:
                return this.selectBoxContentCreatorNew.createSelectBoxes(
                    this.service,
                    this.productFamilies
                ).then((reply: FamilyProductBoxObject[]) => {
                    this.selectFamilyBoxes = this._applyOverlayData(reply, 'family') as FamilyProductBoxObject[];
                });
        }
    };

    private _applyOverlayData = (initialData: (FamilyProductBoxObject | ProductBoxObject)[], overlayType: string) => {
        if (this.overlayData === undefined) {
            return initialData;
        }

        for (const overlay of this.overlayData) {
            if (overlay.action.type !== overlayType) {
                continue;
            }

            switch (overlay.action.mode) {
                case 'add':
                    for (const overlayObject of (overlay.data as FamilyProductBoxObject[])) {
                        if (overlay.action.addInFront === true) {
                            initialData.unshift(overlayObject as never);
                        } else {
                            initialData.push(overlayObject as never);
                        }
                    }
                    break;

                case 'extend':
                    initialData = initialData.map((boxData: FamilyProductBoxObject | ProductBoxObject) => {
                        const isTarget = overlay.target.useRegex
                            ? overlay.target.regex.test((boxData as unknown as Record<string, string>)[overlay.target.field])
                            : (boxData as unknown as Record<string, string>)[overlay.target.field] === overlay.target.value;

                        if (isTarget) {
                            for (const replace of (overlay.data as Types.ViewTypes.ProductSelectionOverlayAdd[])) {
                                (boxData as unknown as Record<string, string>)[replace.field] = replace.newValue;
                            }
                        }

                        return boxData;
                    });
                    break;

                case 'remove':
                    initialData = initialData.filter((boxData) =>
                    (boxData as unknown as Record<string, string>)[overlay.target.field] !== overlay.target.value
                    );
                    break;
            }
        }

        return initialData;
    };

    private _setProductSelectTitle = () => {
        this._showProductFamilySelectionWithSingleFamily = false;
        this._showProductSelectionWithSingleProduct = false;
        this.productFamilySelectTitle = null;
        this.productSelectTitle = null;
        switch (this.service) {
            case 'bundle':
                this.productFamilySelectTitle = this.$translate.instant('TR_090119-8c1394_TR');
                break;
            case 'database':
                this.productFamilySelectTitle = this.$translate.instant('TR_090119-8c1394_TR');
                break;
            case 'dns':
                this._showProductSelectionWithSingleProduct = false;
                this.productFamilySelectTitle = this.$translate.instant('TR_100920-49e2a3_TR');
                this.productSelectTitle = this.$translate.instant('TR_110119-8ed5ce_TR');
                break;
            case 'email':
                this.productFamilySelectTitle = this.$translate.instant('TR_090119-8c1394_TR');
                break;
            case 'machine':
                this._showProductFamilySelectionWithSingleFamily = true;
                this._showProductSelectionWithSingleProduct = false;
                this.productFamilySelectTitle = this.$translate.instant('TR_140119-ea5cad_TR');
                this.productSelectTitle = this.$translate.instant('TR_140119-6b5391_TR');
                break;
            case 'managedapplication':
                this.productFamilySelectTitle = this.$translate.instant('TR_090119-8c1394_TR');
                break;
            case 'redirection':
                this.productFamilySelectTitle = this.$translate.instant('TR_090119-8c1394_TR');
                break;
            case 'ssl':
                this._showProductSelectionWithSingleProduct = true;
                this.productSelectTitle = this.$translate.instant('TR_140119-dc7955_TR');
                this.productFamilySelectTitle =  this.$translate.instant('TR_140119-abb7e5_TR');
                break;
            case 'webhosting':
                this._showProductSelectionWithSingleProduct = true;
                this.productFamilySelectTitle = this.$translate.instant('TR_090119-8c1394_TR');
                break;
            default:
                this.productSelectTitle = null;
                this.productFamilySelectTitle = this.$translate.instant('TR_090119-8c1394_TR');
        }
    };
}

export class OrganismWizardProductSelectionComponent implements ng.IComponentOptions {
    public bindings = {
        _selectedBillingCycle: '=?selectedBillingCycle',
        account: '<',
        hideContingentSelection: '<?',
        inMachine: '<?',
        outerProductCode: '<?',
        outerProductFamily: '<?',
        overlayData: '<?',
        preselectedItemObject: '<?',
        productFamilies: '<',
        productFamilyChangeCallback: '<?',
        promoProductItems: '<?',
        selectedContingent: '=?',
        selectedProductCode: '=?',
        selectedProductFamily: '=?',
        service: '<',
        showVoucherInput: '<',
        useSimplePanelTitle: '<?',
        virtualProductHasBeenPreselected: '<?',
        voucherCodeChangeCallback: '<?'
    };
    public template = require('./wizard-product-selection.html');
    public controller = OrganismWizardProductSelectionController;
}
