import * as ng from 'angular';
import {
    InputPlaceholderConst,
    UiRights,
    VhostDefaultLocationsObjectConst
} from '@/configuration';
import {
    AuthContextService,
    CleanableStringType,
    DataObject,
    DomainInfoHelperService,
    DomainModelService,
    StringCleaner,
    ValidateURL,
    VhostModelService
} from '@/services';
import * as Types from '@/types';

export class MoleculeRowVhostGeneralController {
    public static $inject: string[] = [
        '$state',
        '$timeout',
        '$translate',
        'domainInfoHelper',
        'domainModel',
        'vhostModel'
    ];

    public vhost: Types.WebhostingApi.VHost;
    public applyProfileToPhpSettings: (profileName: string) => void;
    public profiles: Types.WebhostingApi.Profile[];
    public defaultLocationPassed: Types.WebhostingApi.Location;
    public webspace: Types.WebhostingApi.Webspace = null;
    public domainName: string | null = null;
    public accountId: string | null;
    public additionalAvailableFqdn = '';
    public isRedirectOuter: boolean;
    public profileLoaded = false;
    public showObjectId: boolean;
    public vhostZone: Types.DnsApi.ZoneConfig = null;
    public subdomainCreateRouteInfo = {
        route: '',
        params: {}
    };
    public vhostNamevalidationErrors: unknown[] = [];
    public domainNameUnicode = '';
    public domainTypeButtons: {label: string; value: string}[] = [];
    public inputPlaceholder = InputPlaceholderConst;
    public profileDropdownItems: {name: string; value: string}[] = [];
    public redirectionDropdownItems: {name: string; value: string}[] = [];
    public serverTypes: Record<string, string> = { apache: 'Apache', nginx: 'nginx' };
    public webServerDropdownItems: {name: string; value: string}[] = [];
    public domainEditParams: { domainId: string };

    public defaultLocationOrig: Types.WebhostingApi.Location;
    public vhostOrig: Types.WebhostingApi.VHost;
    public redirectionUrlValidationInstructions: DataObject[];

    private onChangeTimeout: ng.IPromise<unknown>;
    private viewModus: string;
    private _createVhost = false;
    private _createDomain = false;
    private _createSubdomain = false;
    private _createExternalDomain = false;
    private _hasBeenInitialized = false;
    private _vhostChecked = false;
    private _vhostAlreadyExists = false;
    private _checkVhostRequiredLoading = false;
    private _webRoot: string;

    constructor(
        private $state: ng.ui.IStateService,
        private $timeout: ng.ITimeoutService,
        protected $translate: ng.translate.ITranslateService,
        private domainInfoHelper: DomainInfoHelperService,
        private domainModel: DomainModelService,
        private vhostModel: VhostModelService
    ) {
        this.domainTypeButtons = [
            {
                label: this.$translate.instant('TR_100119-e6d165_TR'),
                value: 'generic'
            },
            {
                label: this.$translate.instant('TR_100119-688348_TR'),
                value: 'redirect'
            }
        ];

        this.profileDropdownItems = [{
            name: this.$translate.instant('TR_100119-d642b2_TR'),
            value: ''
        }];

        this.redirectionDropdownItems = [
            {
                name: this.$translate.instant('TR_110119-7afd3e_TR'),
                value: '301'
            },
            {
                name: this.$translate.instant('TR_110119-abaecd_TR'),
                value: '302'
            },
            {
                name: this.$translate.instant('TR_110119-287708_TR'),
                value: '307'
            }
        ];
    }

    public async $onInit(): Promise<void> {
        this.vhostOrig = ng.copy(this.vhost);
        this.defaultLocationOrig = ng.copy(this.defaultLocation);

        this.webspace = this.webspace || null;
        this.domainName = this.domainName || null;
        this._createDomain = this._createDomain || false;
        this._createVhost = this._createVhost || false;
        this.webRoot = this.vhost ? this.vhost.webRoot : '';

        await this._checkVhost(this.domainName);

        if (this._createVhost || this._createDomain || this._createSubdomain || this._createExternalDomain) {
            this.viewModus = 'editable';
        }

        this._setProfiles();

        this.webServerDropdownItems = Object.keys(this.serverTypes)
        .map((key) => ({
            name: this.serverTypes[key],
            value: key
        }));

        this._setSubdomainCreateRouteInfo();

        this.redirectionUrlValidationInstructions = [
            {
                instructions: {},
                validator: new ValidateURL(this.$translate, this.domainInfoHelper, false, ['https', 'http'])
            }
        ];

        this._hasBeenInitialized = true;
    }

    public $onChanges(): void {
        void this.getDomainEditParams();
    }

    public set webRoot(value: string) {
        if (this.vhost) {
            this.vhost.webRoot = value;
        }

        this._webRoot = value;
    }
    public get webRoot(): string {
        return this._webRoot;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set defaultLocation({}) {}
    public get defaultLocation(): Types.WebhostingApi.Location {
        if ([undefined, null].indexOf(this.vhost) >= 0 || !this.vhost.locations) {
            return;
        }

        if ([undefined, null].indexOf(this.defaultLocationPassed) >= 0) {
            return this.vhost.locations
            .filter((location) => location.matchType === 'default')
            [0];
        } else {
            return this.defaultLocationPassed;
        }
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set vhostAlreadyExists({}) {}
    public get vhostAlreadyExists(): boolean {
        return this._vhostAlreadyExists;
    }

    public domainChanged = (domain: { value: string }): void => {
        if ([undefined, null].indexOf(domain) < 0 && domain.value !== undefined) {
            if ([undefined, null].indexOf(this.onChangeTimeout) < 0) {
                this.$timeout.cancel(this.onChangeTimeout);
                this.onChangeTimeout = undefined;
            }

            this.webRoot = domain.value;

            this.onChangeTimeout = this.$timeout(
                () => this.changesOnblur(domain.value),
                800
            );
        }
    };

    public changesOnblur = (domain: string): void => {
        if ([undefined, null].indexOf(this.onChangeTimeout) < 0) {
            this.$timeout.cancel(this.onChangeTimeout);
            this.onChangeTimeout = undefined;
        }

        void this._checkVhost(domain);
    };

    public getDomainEditParams = async (): Promise<void> => {
        let domainFindRes;

        if (this.vhost?.domainNameUnicode !== undefined && AuthContextService.isGranted(UiRights.DOM_DOMAINS_LIST)) {
            const domain = StringCleaner.clean(this.vhost.domainNameUnicode).as(CleanableStringType.DomainNames);
            [domainFindRes] = await this.domainModel.findByName([domain]);
        }

        this.domainEditParams = domainFindRes ? { domainId: domainFindRes.id } : undefined;
    };

    public get editable(): boolean {
        return this.viewModus === 'editable';
    }

    public get readonly(): boolean {
        return [undefined, 'readonly'].indexOf(this.viewModus) >= 0;
    }

    public get isAdmin(): boolean {
        return AuthContextService.isGranted(UiRights.ADMIN_SYSTEM_WEBHOSTING_ADMIN);
    }

    public get isResourceOwner(): boolean {
        return ![null, undefined].includes(this.webspace.webserverId);
    }

    public get profile(): string {
        return this.vhost.profile;
    }

    public set profile(val: string) {
        this.vhost.profile = val;

        if (this.applyProfileToPhpSettings !== undefined) {
            this.applyProfileToPhpSettings(val);
        }

        for (const profile of this.profiles) {
            if (profile.profileId === val) {
                this.vhost.serverType = profile.webserverType;
                break;
            }
        }
    }

    public get editVhost(): boolean {
        return [undefined, false].indexOf(this._createVhost) >= 0;
    }

    public get createVhost(): boolean {
        return this._createVhost;
    }

    public get createDomain(): boolean {
        return this._createDomain;
    }

    public get createSubdomain(): boolean {
        return this._createSubdomain;
    }

    public get createExternalDomain(): boolean {
        return this._createExternalDomain;
    }

    public get isRedirect(): boolean {
        return [undefined].indexOf(this.defaultLocation) < 0
            && this.defaultLocation.locationType === 'redirect';
    }

    public getCurrentProfile = () => {
        for (const profile of this.profiles) {
            if (profile.profileId === this.vhost.profile) {
                return profile.name;
            } else if (!this.isProfileEditable) {
                return this.vhost.profile;
            }
        }
    };

    public get isProfileEditable(): boolean {
        // Make it editable when vhost.profile has no 'raw:' in the name.
        // Don't make it editable when vhost.profile has 'raw:' in the name.
        return this.vhost.profile.indexOf('raw:') < 0;
    }

    public get domainType() {
        if (
            [undefined].indexOf(this.defaultLocation) >= 0
            || [undefined, null].indexOf(this.defaultLocation.locationType) >= 0
        ) {
            return this.domainTypeButtons[0].label;
        }
        return this.domainTypeButtons
            .filter(
                (type) => type.value === this.defaultLocation.locationType
            )[0].label;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set webspaceId({}) {}
    public get webspaceId(): string {
        return this.webspace?.id
            || this.vhost?.webspaceId;
    }

    /* eslint-disable-next-line no-empty-pattern, @typescript-eslint/no-empty-function */
    public set complete({}) {}
    public get complete(): boolean {
        if ([undefined, false].indexOf(this._hasBeenInitialized) >= 0 || this._vhostAlreadyExists) {
            return false;
        }

        if (!this.profileLoaded) {
            return false;
        }

        if (this.vhostNamevalidationErrors.length > 0) {
            return false;
        }

        if (
            [undefined].indexOf(this.defaultLocation) >= 0
            || [undefined, null, ''].indexOf(this.defaultLocation.locationType) >= 0
        ) {
            return false;
        }

        if (this.vhost !== undefined && [undefined, null, ''].indexOf(this.vhost.domainNameUnicode) >= 0) {
            return false;
        }

        switch (this.defaultLocation.locationType) {
            case 'generic':
                return [undefined, null, ''].indexOf(this.webRoot) === -1;
            case 'redirect':
                return [undefined, null, ''].indexOf(this.defaultLocation.redirectionStatus) === -1
                    && [undefined, null, ''].indexOf(this.defaultLocation.redirectionUrl) === -1;
            default:
                return false;
        }
    }

    public get showVhostAlreadyExistsHint(): boolean {
        return this._vhostChecked && this._vhostAlreadyExists;
    }

    private _setSubdomainCreateRouteInfo = (): void => {
        const stateParts = this.$state.current.name.split('.');
        switch (stateParts[0]) {
            case 'webhosting':
                this.subdomainCreateRouteInfo = {
                    route: 'webhosting.webspaces.id.vhosts.new',
                    params: { webspaceId: this.webspaceId, domainType: 'subdomain' }
                };
                break;
            case 'bundle':
                this.subdomainCreateRouteInfo = {
                    route: 'bundle.id.domains.new',
                    params: {
                        bundleId: this.$state.params.bundleId,
                        webspaceId: this.webspaceId,
                        domainType: 'subdomain'
                    }
                };
                break;
            default:
                this.subdomainCreateRouteInfo = {
                    route: 'domain.domains.new',
                    params: { webspaceId: this.webspaceId, domainType: 'subdomain' }
                };
        }
    };

    private updateAdditionalAvailableFqdn = (fqdn: string): void => {
        if ([null, undefined, ''].indexOf(fqdn) === -1) {
            const domainParts = fqdn.split('.');

            if (domainParts[0] === 'www') {
                domainParts.shift();
            } else {
                domainParts.unshift('www');
            }

            this.additionalAvailableFqdn = domainParts.join('.');
        }
    };

    private _checkVhost = async (vhostName: string): Promise<void> => {
        if (
            this.vhostNamevalidationErrors.length > 0
            || this._checkVhostRequiredLoading === true
            || [undefined, null, ''].indexOf(vhostName) >= 0
            || vhostName.substr(0, 1) === '.'
        ) {
            return;
        }

        this._vhostChecked = false;
        this._checkVhostRequiredLoading = true;

        await this._setVhost();

        if (this.vhost.domainName === '' || this.vhost.domainNameUnicode === '') {
            this._checkVhostRequiredLoading = false;
            this._vhostChecked = true;
            return;
        }

        void this.vhostModel
            .vhostNormalize(this.vhost)
            .then(
                (normalizedVhost) => {
                    this.vhost = ng.copy(normalizedVhost.response.vhost);
                    this.updateAdditionalAvailableFqdn(this.vhost.domainNameUnicode);
                    const filter = { field: 'VHostDomainNameAce', value: this.vhost.domainName };
                    return this.vhostModel.listWithoutPagination(null, 1, filter);
                }
            )
            .then(
                (vhostListResponse) => {
                    this._vhostAlreadyExists = vhostListResponse?.data?.length > 0;
                    this._checkVhostRequiredLoading = false;
                    this._vhostChecked = true;
                }
            );
    };

    private _setProfiles = (): void => {
        if ([undefined, null].indexOf(this.profiles) === -1) {
            this._setProfileItems();
            this.profileLoaded = true;
        }

        if ([undefined, null].indexOf(this.webspace) === -1) {
            void this.vhostModel.listProfiles(this.webspace.id).then(
                (profileList) => {
                    this.profiles = profileList;
                    this._setProfileItems();
                    this.profileLoaded = true;
                }
            );
        } else {
            this.profileLoaded = true;
        }
    };

    private _setProfileItems = (): void => {
        this.profileDropdownItems = [{
            name: this.$translate.instant('TR_100119-d642b2_TR'),
            value: ''
        }];

        for (const profile of this.profiles) {
            this.profileDropdownItems.push({
                name: profile.name,
                value: profile.profileId
            });
        }
    };

    private _setVhost = async (): Promise<void> => {
        const vhostName = this._createDomain
            ? this.domainName ? this.domainName : ''
            : this.domainNameUnicode;

        this.vhost = await this.vhostModel.defaultVhostObject();
        this.vhost.accountId = this.accountId;
        this.vhost.domainName = vhostName;
        this.vhost.domainNameUnicode = vhostName;
        this.vhost.enableAlias = this.createExternalDomain || this._createDomain;
        this.vhost.redirectToPrimaryName = false; // unlike as default value ....??
        this.vhost.systemAlias = this.$translate.instant('TR_110119-ae3a43_TR');
        this.webRoot = [undefined, null].indexOf(this.domainName) === -1 ? this.domainName : '';
        this.vhost.locations = ng.copy(VhostDefaultLocationsObjectConst);
        this.vhost.webspaceId = this.webspaceId;
        this.webRoot = vhostName;
    };
}

export class MoleculeRowVhostGeneralComponent implements ng.IComponentOptions {
    public bindings = {
        _createDomain: '<createDomain',
        _createExternalDomain: '<createExternalDomain',
        _createSubdomain: '<createSubdomain',
        _createVhost: '<createVhost',
        accountId: '<',
        applyProfileToPhpSettings: '=',
        complete: '=?',
        defaultLocationPassed: '=defaultLocation',
        domainName: '<',
        isRedirectOuter: '<',
        profiles: '=?',
        showObjectId: '<',
        vhost: '=',
        viewModus: '<',
        webspace: '<'
    };

    public controller = MoleculeRowVhostGeneralController;
    public template = require('./vhost-general.html');
}
