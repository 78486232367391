import * as ng from 'angular';
import { DataObject, GeneralValidator } from '../../../../../services';
import { FormInputModelOptions } from '../../../../atoms/form-elements';
import { MoleculeFormEditController } from '../../../forms/form-edit/form-edit';
import { ValidateOptions } from '../date-select/date-select';
import './input-text-with-validation.scss';

export class MoleculeInputTextWithValidationController implements ng.IController {
    public static $inject: string[] = ['$timeout', '$translate'];

    public $editForm: MoleculeFormEditController;
    public callbackOnChange: (arg0: any) => any;
    public callbackOnChangeParams: any;
    public callbackOnBlur: (arg0: any) => any;
    public callbackOnEsc: (event?: { [key: string]: any }) => any;
    public callbackOnBlurParams: any;
    public callbackOnPaste: (paste: any) => any;
    public customCallbackOnEnter: () => {};
    public doNotSaveOnFormEnter: boolean;
    public disabled: boolean;
    public disableRandomisedId: boolean;
    public hideCharacters: boolean;
    public modelOptions: FormInputModelOptions;
    public readonly: boolean;
    public registerToForm = true;
    public rows: string;
    public placeholder: string;
    public textarea: boolean;
    public labelText: string;
    public validationErrorList = [];
    public validationInstructions: DataObject[];
    public validationSuccessful = false;
    public validationOptions: ValidateOptions;
    public _value: string;
    public inputId: string;
    public splitArray = false;
    public catchFocus: boolean;
    public submit: () => void;
    public additionalObject: any;
    public callbackOnClear: (arg0: any) => any;
    public callbackOnClearParams: any;
    public clearable: boolean;
    public autofill: any;
    public onlyValidateOnChange: boolean;
    public repeatPassword: boolean;
    public step: string;
    public showPasswordStrengthBar = false;
    public showSingleError = false;

    private validator: GeneralValidator;
    private registrationIndex: number;
    private type: string;
    private validationInstructionsOperator: string | '&&' | '||';
    private debounceCallbackTimeout: ng.IPromise<any>;

    constructor(private $timeout: ng.ITimeoutService, private $translate: ng.translate.ITranslateService) {
        this.validator = new GeneralValidator(this.$translate);
    }

    public $onInit() {
        this.disabled = this.disabled || false;
        this.hideCharacters = this.hideCharacters || false;
        this.readonly = this.readonly || false;
        this.rows = this.rows || '3';
        this.textarea = this.textarea || false;
        this.onlyValidateOnChange = this.onlyValidateOnChange || false;
        this.validationErrorList = this.validationErrorList || [];
        this.inputId = this.disableRandomisedId ? this.inputId : `textInputValidation${this.inputId}`;
        if (this.registerToForm || this.registerToForm == null || this.registerToForm === undefined) {
            this.registerToForm = true;
            this.registrationIndex = this.$editForm.registerValidator(this);
        }
    }

    public $onDestroy() {
        if (this.registerToForm) {
            this.$editForm.unregisterValidator(this.registrationIndex);
            this.$editForm.validatorStatus[this.registrationIndex] = true;
        }
    }

    public $onChanges(changes) {
        if (changes.disabled != null && changes.disabled !== undefined) {
            if (changes.disabled.currentValue === true) {
                this.$editForm.validatorStatus[this.registrationIndex] = true;
                this.validationErrorList = [];
            }
        }
        if (
            changes.validationOptions !== undefined
            && changes.validationOptions.currentValue !== undefined
            && changes.validationOptions.currentValue.allowEmpty !== undefined
            && changes.validationOptions.currentValue.allowEmpty
        ) {
            // reset validation text on changing allowEmpty in validation options and allowEmpty is changed to true
            this.callbackOnInputBlur();
        }
    }

    public get value() {
        return this._value;
    }

    public set value(input: string) {
        const oldValue = ng.copy(this._value);
        this._value = input;

        if (
            this._value !== oldValue
            && ([undefined, null, ''].indexOf(this._value) < 0 || [undefined, null, ''].indexOf(oldValue) < 0)
        ) {
            this.callbackOnInputChange();
        }
    }

    public get inputType() {
        switch (true) {
            case this.textarea:
                return 'textarea';
            case this.type !== undefined:
                return this.type;
            default:
                return 'text';
        }
    }

    public callbackOnInputBlur = () => {
        const debounce = this._getModelOptionDebounce();
        this.$timeout(() => {
            if (!(this.onlyValidateOnChange && this.callbackOnChange !== undefined)) {
                if (this.registerToForm) {
                    this.$editForm.validate(this.registrationIndex);
                } else {
                    this.validate();
                }
            }

            if (this.callbackOnBlur !== undefined) {
                if (this.callbackOnBlurParams) {
                    this.callbackOnBlurParams.value = this.value;
                    this.callbackOnBlur(this.callbackOnBlurParams);
                } else {
                    this.callbackOnBlur(this.value);
                }
            }
        }, debounce);
    };

    public callbackOnInputChange = () => {
        let callbackResponse = {};
        const debounce = this._getModelOptionDebounce();

        if ([undefined, null].indexOf(this.debounceCallbackTimeout) < 0) {
            this.$timeout.cancel(this.debounceCallbackTimeout);
        }

        this.debounceCallbackTimeout = this.$timeout(() => {
            if (this.registerToForm) {
                this.$editForm.validate(this.registrationIndex);
            } else {
                this.validate();
            }

            if (this.callbackOnChangeParams !== undefined) {
                callbackResponse = this.callbackOnChangeParams;
            }
            if (this.callbackOnChange !== undefined) {
                this.callbackOnChange(callbackResponse);
            }
        }, debounce);
    };

    public validate = () => {
        let value = '';
        if (this.value) {
            value = this.value;
        }

        if (this.validationInstructions && !this.disabled) {
            this.validationErrorList = this.validator.validate(
                value,
                this.validationInstructions,
                this.validationInstructionsOperator
            );
            this.validationSuccessful = this.validationErrorList.length === 0;
            return this.validationSuccessful;
        } else {
            return true;
        }
    };

    public showPasswordStrength = () => {
        return this.inputType === 'text' && this.showPasswordStrengthBar;
    };

    public showValidationErrors = () => {
        return (
            ['cronjob-script'].indexOf(this.inputType) === -1
            && (this.validationErrorList === undefined || this.validationErrorList.length > 0)
        );
    };

    public enterKeyUp = () => {
        if (this.doNotSaveOnFormEnter) {
            return;
        }
        if (this.customCallbackOnEnter !== undefined) {
            this.customCallbackOnEnter();
            return;
        }

        this.$editForm.save();
    };

    public onPaste = (paste) => {
        if (this.callbackOnPaste !== undefined) {
            // If paste
            this.callbackOnPaste(paste);
        }
    };

    // Allow calling validation from outside the molecule
    public set revalidate({}) {} /* tslint:disable-line:no-empty */
    public get revalidate() {
        return this.validate;
    }

    private _getModelOptionDebounce = () => {
        return this.modelOptions !== undefined && this.modelOptions.debounce !== undefined
            ? this.modelOptions.debounce + 50
            : 0;
    };
}

export class MoleculeInputTextWithValidationComponent implements ng.IComponentOptions {
    public bindings = {
        additionalObject: '=',
        autofill: '<',
        callbackOnBlur: '<',
        callbackOnBlurParams: '<',
        callbackOnChange: '<',
        callbackOnChangeParams: '<',
        callbackOnClear: '<',
        callbackOnClearParams: '<',
        callbackOnEsc: '<?',
        callbackOnPaste: '<?',
        catchFocus: '<',
        clearable: '<',
        customCallbackOnEnter: '<',
        disabled: '<',
        disableRandomisedId: '<?',
        doNotSaveOnFormEnter: '<',
        hideCharacters: '<',
        inputId: '@?',
        labelText: '@',
        modelOptions: '<',
        onlyValidateOnChange: '<',
        placeholder: '@',
        readonly: '<',
        registerToForm: '<',
        revalidate: '=?',
        rows: '<',
        repeatPassword: '<?',
        showPasswordStrengthBar: '<?',
        showSingleError: '<?',
        splitArray: '<',
        step: '@?',
        submit: '<',
        textarea: '<',
        type: '<',
        validationErrorList: '=?',
        validationInstructions: '<',
        validationInstructionsOperator: '@?',
        validationOptions: '<',
        value: '='
    };
    public require = {
        $editForm: '^moleculeFormEdit'
    };
    public controller = MoleculeInputTextWithValidationController;
    public controllerAs = '$inputWithValidation';
    public template = require('./input-text-with-validation.html');
}
