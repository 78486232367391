import * as ng from 'angular';
import { WizardPromoProduct, ProductConfigNextcloudObject } from '@/atomic-components';
import {
    ProductConfigVoucher
} from '@/atomic-components/organisms/wizards/nextcloud/products/nextcloud-wizard-product-config';
import { ProductFamiliesConst } from '@/configuration';
import { AsteriskNoteService } from '@/services';
import { ScrollToValidationError } from '@/services';
import * as Types from '@/types';

export class OrganismNextcloudWizardConfigViewController {
    public static $inject: string[] = ['$timeout', 'asteriskNote'];

    public callback: (metaData: ProductConfigNextcloudObject) => unknown;
    public configCompleted = false;
    public customerVerified = true;
    public hideSufficientCreditCheck = false;
    public metadata: ProductConfigNextcloudObject;
    public outerAccount: Types.AccountApi.Account;
    public outerProductCode: string;
    public productFamilies: string[] = ProductFamiliesConst.nextcloud;
    public promoProductItems: WizardPromoProduct[] = [];
    public selectedProductFamily: string = null;
    public service = 'managedapplication';
    public sufficientCreditAvailable = false;

    private _account: Types.AccountApi.Account | Types.AccountApi.Subaccount;
    private _productCodeList: string[] = [];
    private _selectedProductCode: string = null;

    public constructor(
        private $timeout: ng.ITimeoutService,
        private asteriskNote: AsteriskNoteService
    ) {}

    public $onInit(): void {
        this.asteriskNote.resetNodes();
        this.promoProductItems = [{
            buttonText: /* translationID */ 'TR_170620-e998a6_TR',
            description: /* translationID */ 'TR_170620-5cf01c_TR',
            headLine: /* translationID */ 'TR_170620-ef7de7_TR',
            productFamilyName: 'Managed Nextcloud', // does not have to be translated
            route: 'show.contact.form',
            routeParams: {}
        }];
        this.metadata = {
            account: null,
            addons: {},
            displayname: '',
            maxNumberOfUsers: -1,
            nextcloudBackupProductCode: null,
            nextcloudProductCode: null,
            nextcloudUser: {
                password: '',
                user: {
                    emailAddress: '',
                    name: '',
                    username: ''
                }
            },
            talkUsers: -1,
            voucher: null,
            productMaxStorageQuota: -1
        };
    }

    public productFamilySelected = (productFamily: string): void => {
        this.promoProductItems = (productFamily === 'managed-nextcloud-business')
            ? [{
                buttonText: /* translationID */ 'TR_170620-e998a6_TR',
                description: /* translationID */ 'TR_170620-5cf01c_TR',
                headLine: /* translationID */ 'TR_170620-ef7de7_TR',
                productFamilyName: 'Managed Nextcloud', // does not have to be translated
                route: 'show.contact.form',
                routeParams: {}
            }]
            : [];
    };

    public voucherCodeSelected = (voucher: ProductConfigVoucher): void => {
        void this.$timeout(() => {
            this.metadata.voucher = voucher;
        });
    };

    public showAccountSelection = (): boolean => {
        if (this.outerAccount === undefined) {
            this.account = this.outerAccount;
            return false;
        }

        return true;
    };

    public productAddonsChanged = (): void => {
        this._setProductCodeList();
    };

    public scrollToError = (): void => {
        ScrollToValidationError.scrollToError(this.$timeout);
    };

    public finishConfiguration = (): void => {
        if (this.callback !== undefined) {
            this.callback(this.metadata);
        } else {
            // pherhaps, throw sentry error - callback is not set
        }
    };

    public get account(): Types.AccountApi.Account | Types.AccountApi.Subaccount {
        return this._account;
    }
    public set account(value: Types.AccountApi.Account | Types.AccountApi.Subaccount) {
        if (this.metadata) {
            this.metadata.account = value;
        }
        this._account = value;
    }

    public get selectedProductCode(): string {
        return this._selectedProductCode;
    }
    public set selectedProductCode(value: string) {
        if (this.metadata) {
            this.metadata.nextcloudProductCode = value;
        }

        this._selectedProductCode = value;
        this._setProductCodeList();
    }

    // eslint-disable-next-line no-empty-pattern,@typescript-eslint/no-empty-function
    public set showProductConfiguration({}) {}
    public get showProductConfiguration(): boolean {
        return [undefined, null].indexOf(this.account) < 0
            && [undefined, null].indexOf(this.selectedProductCode) < 0;
    }

    // eslint-disable-next-line no-empty-pattern,@typescript-eslint/no-empty-function
    public set configurationIsCompleted({}) {}
    public get configurationIsCompleted(): boolean {
        return this.sufficientCreditAvailable
            && this.customerVerified
            && this.configCompleted;
    }

    // eslint-disable-next-line no-empty-pattern,@typescript-eslint/no-empty-function
    public set productCodeList({}) {}
    public get productCodeList(): string[] {
        return this._productCodeList;
    }

    private _setProductCodeList = (): void => {
        this.hideSufficientCreditCheck = true;
        const list = [];

        if (this.metadata !== undefined) {
            if (this.metadata.nextcloudProductCode !== null) {
                list.push(this.metadata.nextcloudProductCode);
            }

            if (this.metadata.nextcloudBackupProductCode !== null) {
                list.push(this.metadata.nextcloudBackupProductCode);
            }

            if (typeof this.metadata.addons === 'object') {
                for (const addonKey of Object.keys(this.metadata.addons)) {
                    const currentAddon = this.metadata.addons[addonKey];
                    if (currentAddon.amount > 0) {
                        for (let i = 1; i <= currentAddon.amount; i++) {
                            list.push(currentAddon.productCode);
                        }
                    }
                }
            }
        }

        this._productCodeList = list;

        void this.$timeout(() => {
            this.hideSufficientCreditCheck = false;
        });
    };
}

export class OrganismNextcloudWizardConfigViewComponent implements ng.IComponentOptions {
    public bindings = {
        callback: '<',
        outerAccount: '<',
        outerProductCode: '<'
    };
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    public template = require('./nextcloud-wizard-config-view.html');
    public controller = OrganismNextcloudWizardConfigViewController;
}
