import * as ng from 'angular';
import {
    OrganismEditFormMachineController
} from '../../../../../atomic-components';
import {
    AlertManagerService,
    MachineModelService
} from '../../../../../services';
import * as Types from '../../../../../types';
import {
    EditPanelStatus,
    MoleculeFormEditController,
    PanelType
} from '../../../../molecules';

export class OrganEditPanelMachineRescueController {
    public static $inject: string[] = ['$translate', 'alertManager', 'machineModel'];

    public $editFormMolecule: MoleculeFormEditController;
    public $editFormOrganism: OrganismEditFormMachineController;
    public panelRight;
    public panelTypeOverride = PanelType.MISCELLANEOUS_ACTION;
    public reset = false;
    public machine: Types.MachineApi.VirtualMachine;
    public checkingMachineStatus: boolean;

    private validatorId;
    private _password: string;
    private _keys: string[] = [];

    constructor(
        private $translate: ng.translate.ITranslateService,
        private alertManager: AlertManagerService,
        private machineModel: MachineModelService
    ) {}

    // tslint:disable-next-line:no-empty
    public cancelCallback: () => void = () => {};

    public $onInit() {
        this.rescueModeActive = this.$editFormOrganism.machine.rescue === 'on';
        this.validatorId = this.$editFormMolecule.registerValidator(this);
    }

    public $onDestroy() {
        this.$editFormMolecule.unregisterValidator(this.validatorId);
    }

    get keys(): string {
        return this._keys.join('\n');
    }

    set keys(value: string) {
        const sshKeys = [];
        for (const sshKey of value.split(/[\r\n]+/)) {
            if (!sshKeys.includes(sshKey) && sshKey.length > 0) {
                sshKeys.push(sshKey);
            }
        }
        this._keys = sshKeys;
    }

    public get rescueModeActive() {
        return this.$editFormOrganism.machine.rescue === 'on';
    }

    public set rescueModeActive(value) {
        if (
            [null, undefined].indexOf(this.$editFormOrganism.machine) < 0
            && this.$editFormOrganism.machine.rescue !== (value === true ? 'on' : 'off')
        ) {
            this.$editFormOrganism.machine.rescue = (value === true ? 'on' : 'off');
        }
        this.$editFormMolecule.validateAll();
    }

    public get password() {
        return this._password;
    }

    public set password(value) {
        this._password = value;

        this.$editFormMolecule.validateAll();
    }

    public validate = () => {
        if ([undefined, null].indexOf(this.$editFormMolecule.panel('rescue')) < 0
            && this.$editFormMolecule.panel('rescue').status !== EditPanelStatus.ACTIVE) {
            return true;
        }

        if (this.rescueModeActive) {
            return [undefined, null, ''].indexOf(this.password) < 0;
        } else {
            return true;
        }
    };

    public saveRescueMode = () => {
        if (this.rescueModeActive) {
            this.enableRescueMode();
        } else {
            this.disableRescueMode();
        }
    };

    public enableRescueMode = () => {
        this.machineModel.enableRescue(
            this.$editFormOrganism.machine.id,
            this.password,
            this._keys,
            this.reset
        ).then((apiResponse) => {
            if (apiResponse.response) {
                this.alertManager.success(this.$translate.instant('TR_050219-554bab_TR'));
            }
            this.$editFormOrganism.updateMachineSettings();
        });
    };

    public disableRescueMode = () => {
        this.machineModel.disableRescue(
            this.$editFormOrganism.machine.id,
            this.reset
        ).then((apiResponse) => {
            if (apiResponse.response) {
                this.alertManager.success(this.$translate.instant('TR_050219-55eb94_TR'));
            }
            this.$editFormOrganism.updateMachineSettings();
        });
    };

    public cancel = () => {
        this.keys = '';
        this.reset = false;
        this.password = '';
        if ([undefined, null].indexOf(this.cancelCallback) === -1) {
            this.cancelCallback();
        }
    };
}

export class OrganEditPanelMachineRescueComponent implements ng.IComponentOptions {
    public bindings = {
        cancelCallback: '<cancel',
        checkingMachineStatus: '<',
        panelRight: '<'
    };
    public require = {
        $editFormMolecule: '^moleculeFormEdit',
        $editFormOrganism: '^organismEditFormMachine'
    };
    public controllerAs = '$editPanelOrgan';
    public template = require('./machine-rescue.html');
    public controller = OrganEditPanelMachineRescueController;
}
